package com.crowpay.views.components.project.activity

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.*
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningdb.sort
import kotlinx.coroutines.launch

private fun String.truncate(take: Int, postfix: String = "..."): String =
    if (length > take) take(take) + postfix
    else this

class ProjectMessageThread(val project: UUID, val lens: ProjectLens) : Page {

    private class MessageRenderers(
        val lens: ProjectLens,
        val onReply: suspend (ProjectMessage)->Unit
    ) : RecyclerViewRendererSet<ProjectMessage, UUID> {
        override fun id(item: ProjectMessage): UUID = item._id
        val isClient = when (lens) {
            ProjectLens.Contractor -> false
            ProjectLens.Customer -> true
            ProjectLens.Preview -> true
        }

        private fun ViewWriter.renderReplyOrReference(message: Readable<ProjectMessage>) {
            row {
                existsDefaultFalse {
                    message().let { it.replyingToMessage != null || it.referencesChangeRequest != null || it.referencesPayApp != null }
                }
                themeChoice += BodySemantic + SetCornerRadii { CornerRadii.Constant(10.dp) }
                dynamicTheme {
                    message().let {
                        if (it.referencesChangeRequest != null || it.referencesPayApp != null) DangerSemantic
                        else if (it.replyingToMessage != null) SmallBodySemantic
                        else null
                    }
                }
                icon {
                    existsDefaultFalse {
                        message().let { it.referencesChangeRequest != null || it.referencesPayApp != null }
                    }
                    source = Icon.activity
                    description = "Message References"
                }
                expanding - centered - text {
                    ::content {
                        message().run {
                            referencesChangeRequest?.reason
                                ?: referencesPayApp?.reason
                                ?: replyingToMessage?.message?.truncate(100)
                        } ?: ""
                    }
                }
            }
        }

        inner class MyMessage : RecyclerViewRenderer<ProjectMessage> {
            override fun render(
                viewWriter: ViewWriter,
                data: Readable<ProjectMessage>,
                index: Readable<Int>
            ): ViewModifiable = with(viewWriter) {
                row {
                    expanding - space()
                    sizeConstraints(maxWidth = AppDimensions.pageWidth * 0.9) - col {
                        spacing = 5.dp
                        MessageSemantic(mine = true, isClient).onNext - stack {
                            compact - col {
                                renderReplyOrReference(data)
                                body {
                                    ::content { data().message }
                                }
                            }
                        }
                        atEnd - smallBody { ::content { data().sent.formatRelative() } }
                    }
                }
            }
        }

        inner class OthersMessage : RecyclerViewRenderer<ProjectMessage> {
            override fun render(
                viewWriter: ViewWriter,
                data: Readable<ProjectMessage>,
                index: Readable<Int>
            ): ViewModifiable = with(viewWriter) {
                row {
                    sizeConstraints(maxWidth = AppDimensions.pageWidth * 0.9) - col {
                        spacing = 5.dp
                        MessageSemantic(mine = false, !isClient).onNext - stack {
                            compact - col {
                                renderReplyOrReference(data)
                                body {
                                    ::content { data().message }
                                }
                            }
                        }
                        compact - row {
                            smallBody { ::content { data().sent.formatRelative() } }
                            smallBody(BulletPoint)
                            textButton - button {
                                spacing = 0.px
                                smallBody("Reply")
                                onClick("reply") { onReply(data()) }
                            }
                        }
                    }
                }
            }
        }

        override fun renderer(item: ProjectMessage): RecyclerViewRenderer<ProjectMessage> =
            if (isClient == item.clientMessage) MyMessage()
            else OthersMessage()
    }

    class Renderer(
        val lens: ProjectLens,
        val project: UUID,
        val buffer: Int = 15,
        val increment: Int = 100,
        val requestFocusOnStart: Boolean = false
    ) {
        val messages = shared {
            notNullSession().projectMessages.query(
                Query(
                    condition { it.project eq project },
                    orderBy = sort { it.sent.descending() },
                    limit = 100
                )
            )
        }

        private val newMessage = Property("")
        private val replyingTo = Property<ProjectMessage?>(null)

        fun renderTo(writer: ViewWriter) = with(writer) {
            col {
                sizeConstraints(minHeight = AppDimensions.activityTabMinSize) - expanding - recyclerView {
                    reactive {
                        val limiter = messages()
                        if (limiter().size == limiter.limit && firstIndex() <= buffer) {
                            limiter.limit += increment
                        }
                    }

                    rendererSet = MessageRenderers(lens) { replyingTo.value = it }

                    reactive {
                        data = RecyclerViewData.fromList(messages()().reversed())
                    }
                    launch {
                        scrollToIndex(messages()().lastIndex, align = Align.End, animate = false)
                    }
                }
                fieldTheme - col {
                    removeOutline - lightSection - stack {
                        ::exists { replyingTo() != null }
                        body {
                            ::content { "Replying To:\n${replyingTo()?.message?.truncate(200)}" }
                        }
                        atTopEnd - button {
                            spacing = 3.dp
                            icon(Icon.close.resize(1.rem), "Stop Replying")
                            onClick { replyingTo.value = null }
                        }
                    }
                    stack {
                        sizeConstraints(height = 5.rem) - textArea {
                            if (requestFocusOnStart) requestFocus()
                            content bind newMessage
                        }
                        atBottomEnd - compact - removeOutline - row {
                            secondaryButton - button {
                                ::enabled { newMessage().isNotBlank() }
                                specCenteredText("Clear")
                                onClick("discard") {
                                    newMessage.value = ""
                                    replyingTo.value = null
                                }
                            }
                            primaryButton - button {
                                ::enabled { newMessage().isNotBlank() }
                                specCenteredText("Send")
                                onClick("send") {
                                    val session = notNullSession()
                                    session.projectMessages.insert(
                                        ProjectMessage(
                                            project = project,
                                            sender = session.userId,
                                            message = newMessage.value,
                                            replyingToMessage = replyingTo.value?.let { MessageReply(it) },
                                            clientMessage = lens != ProjectLens.Contractor
                                        )
                                    )
                                    replyingTo.value = null
                                    newMessage.value = ""
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    override fun ViewWriter.render2(): ViewModifiable = Renderer(lens, project).renderTo(this)

    class Dialog(val lens: ProjectLens, val project: Project) : Page {
        override fun ViewWriter.render2(): ViewModifiable = dismissBackground {
            spacing = AppDimensions.sectionIndent
            dialog - gravity(Align.Center, Align.Stretch) - sizeConstraints(width = AppDimensions.pageWidth) - stack {
                spacing = 3.dp
                padded - stack {
                    spacing = AppDimensions.fullIndent / 2
                    removeOutline - col {
                        title("${project.name} Messaging")
                        expanding - Renderer(lens, project._id, requestFocusOnStart = true).renderTo(this)
                    }
                }
                atTopEnd - removeOutline - buttonTheme - button {
                    icon(Icon.close, "Close messages")
                    onClick("Close Messages") { dialogScreenNavigator.dismiss() }
                }
            }
        }
    }
}

fun ProjectView.projectMessages(writer: ViewWriter): ViewModifiable {
    return ProjectMessageThread.Renderer(lens, projectId).renderTo(writer)
}