package com.crowpay.utils.tables

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.weight

class ColumnWeights {
    val weights = ArrayList<Float>()
    private var index = 0

    @ViewModifierDsl3 fun ViewWriter.setColWeight(amount: Float): ViewWrapper {
        weights.add(amount)
        return weight(amount)
    }

    @ViewModifierDsl3 val ViewWriter.getColWeight: ViewWrapper get() {
        weight(weights[index])
        index = (index + 1) % weights.size
        return ViewWrapper
    }
    @ViewModifierDsl3 fun ViewWriter.getColWeight(index: Int) = weight(weights.getOrNull(index) ?: 1f)

    var columnSpacing: Dimension? = null
    var rowSpacing: Dimension? = null
}

fun trackWeights(setup: ColumnWeights.()->Unit) = ColumnWeights().run(setup)