package com.crowpay.views.components.project.work

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.actuals.boldStrongTag
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.inlineKeyValue
import com.crowpay.views.components.project.work.punchList.renderPunchListRow
import com.crowpay.views.dialogs.RootCreateItemTask
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

fun ViewWriter.projectTasks(
    project: Readable<Project>,
    lineItems: Readable<List<AdjustedLineItem>>,
    punchListItems: Readable<List<PunchListItem>>,
    lens: ProjectLens
) {
    col {
        spacing = 0.dp
        val expandedPunchLists = Property<Set<UUID?>>(emptySet())

        if (lens == ProjectLens.Contractor) atEnd - tertiaryButton - button {
            ::enabled {
                lineItems().any { it.state.newTasksAllowed() } && project().state.newTasksAllowed()
            }
            specCenteredText("+ Task")

            onClick {
                dialogScreenNavigator.navigate(
                    RootCreateItemTask(
                        lineItems().filter { it.state < LineItemState.Complete }
                    )
                )
            }
        }

        if (lens == ProjectLens.Customer) col {
            spacing = 1.rem

            dismissibleBanner("task info") {
                notificationBar(AppColors.blue) - stack {
                    existsDefaultFalse { project().state >= ProjectState.Accepted }
                    col {
                        spacing = AppDimensions.cornerRadii

                        body("Tasks, created by the contractor, help you stay informed and ensure all work is completed as expected. You may want to request some specific tasks be listed.")
                        space()
                        boldStrongTag - body {
                            inlineKeyValue(
                                "Punch List Tasks",
                                "Loose ends to address during project closeout. Tasks marked as 'Required' are tied to the punch list and must be resolved before the project is finalized."
                            )
                        }
                        boldStrongTag - body {
                            inlineKeyValue(
                                "Work Item Tasks",
                                "These detail unique tasks to this work on your job, providing clarity on what’s being done and building confidence in your contractor’s process."
                            )
                        }
                    }
                }
            }
        }


        notificationBar(AppColors.blue) - body {
            existsDefaultFalse{
                lens == ProjectLens.Contractor && project().state >= ProjectState.Complete
            }
            content = "You cannot create work items after the project has been completed"
        }

        space()

        col {
            spacing = 0.dp
            renderPunchListRow(
                title = Constant("Punch Tasks"),
                expanded = shared { expandedPunchLists().contains(null) }
                    .withWrite { if (it) expandedPunchLists.value += null else expandedPunchLists.value -= null },
                items = shared { punchListItems().filter { it.lineItem == null || it.required || it.isMinorAdjustment != null } },
                project = project,
                lineItem = null,
                allowReorder = false,
                lens = lens,
                rowHeight = 3.rem,
                buttonText = "+ Punch Task"
            )
            col {
                spacing = 0.dp
                val notComplete = lineItems.lens { items -> items.filter { it.state < LineItemState.Complete } }
                forEachUpdating(notComplete) { item ->
                    renderPunchListRow(
                        title = shared { item().name },
                        expanded = shared { expandedPunchLists().contains(item()._id) }
                            .withWrite { if (it) expandedPunchLists.value += item.awaitOnce()._id else expandedPunchLists.value -= item.awaitOnce()._id },
                        items = shared { punchListItems().filter { it.lineItem == item()._id } },
                        lineItem = item,
                        project = project,
                        lens = lens,
                        rowHeight = 3.rem
                    )
                }
            }
        }
    }
}