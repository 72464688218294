package com.crowpay.views.theming

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter


data class TabSemantics(val color: Color) : Semantic {
    override val key: String = "tabs_${color.toWeb().filter { it.isLetter() || it.isDigit() }}"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        spacing = 4.dp,
        background = color,
        derivations = mapOf(
            ButtonSemantic to {
                it
                    .copy(
                        id = "btn",
                        spacing = 0.95.rem
                    )
                    .withBack
            },
            HoverSemantic to {
                val back = it.background.closestColor()
                it
                    .copy(id = "hvr", background = if (back == Color.white) back else color.lighten(0.6f))
                    .withBack
            },
            DownSemantic to {
                val back = it.background.closestColor()
                it
                    .copy(id = "dwn", background = if (back == Color.white) back else color.lighten(0.8f))
                    .withBack
            },
            SelectedSemantic to {
                it
                    .copy(
                        id = "tabSel",
                        background = Color.white,
                        font = it.font.copy(bold = true),
                        outlineWidth = 0.dp
                    )
                    .withBack
            },
            UnselectedSemantic to {
                it
                    .copy(
                        id = "tabUnsel",
                        outlineWidth = 0.dp
                    ).withBack
            },
        ),
    ).withBack
}

@ViewModifierDsl3
val ViewWriter.projectInfoTabs: ViewWrapper get() = TabSemantics(AppColors.grey.light2).onNext

@ViewModifierDsl3
val ViewWriter.reportsTabs: ViewWrapper get() = TabSemantics(AppColors.grey.light2).onNext

@ViewModifierDsl3
val ViewWriter.workTabs: ViewWrapper get() = TabSemantics(AppColors.grey.light2).onNext
