// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareProjectTimerFields() {
    val props: Array<SerializableProperty<ProjectTimer, *>> = arrayOf(ProjectTimer__id, ProjectTimer_project, ProjectTimer_lineItem, ProjectTimer_incident, ProjectTimer_durationLeft, ProjectTimer_executesAt, ProjectTimer_action, ProjectTimer_businessDaysOnly, ProjectTimer_minimumTimeWhenResumed, ProjectTimer_executed)
    ProjectTimer.serializer().properties { props }
}
val <K> DataClassPath<K, ProjectTimer>._id: DataClassPath<K, UUID> get() = this[ProjectTimer__id]
val <K> DataClassPath<K, ProjectTimer>.project: DataClassPath<K, UUID> get() = this[ProjectTimer_project]
val <K> DataClassPath<K, ProjectTimer>.lineItem: DataClassPath<K, UUID?> get() = this[ProjectTimer_lineItem]
val <K> DataClassPath<K, ProjectTimer>.incident: DataClassPath<K, UUID?> get() = this[ProjectTimer_incident]
val <K> DataClassPath<K, ProjectTimer>.durationLeft: DataClassPath<K, Duration?> get() = this[ProjectTimer_durationLeft]
val <K> DataClassPath<K, ProjectTimer>.executesAt: DataClassPath<K, Instant?> get() = this[ProjectTimer_executesAt]
val <K> DataClassPath<K, ProjectTimer>.action: DataClassPath<K, TimerAction> get() = this[ProjectTimer_action]
val <K> DataClassPath<K, ProjectTimer>.businessDaysOnly: DataClassPath<K, Boolean> get() = this[ProjectTimer_businessDaysOnly]
val <K> DataClassPath<K, ProjectTimer>.minimumTimeWhenResumed: DataClassPath<K, Duration?> get() = this[ProjectTimer_minimumTimeWhenResumed]
val <K> DataClassPath<K, ProjectTimer>.executed: DataClassPath<K, Instant?> get() = this[ProjectTimer_executed]
inline val ProjectTimer.Companion.path: DataClassPath<ProjectTimer, ProjectTimer> get() = path<ProjectTimer>()


object ProjectTimer__id: SerializableProperty<ProjectTimer, UUID> {
    override val name: String by lazy { ProjectTimer.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ProjectTimer): UUID = receiver._id
    override fun setCopy(receiver: ProjectTimer, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ProjectTimer.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ProjectTimer.serializer().descriptor.getElementAnnotations(0) }
}
object ProjectTimer_project: SerializableProperty<ProjectTimer, UUID> {
    override val name: String by lazy { ProjectTimer.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ProjectTimer): UUID = receiver.project
    override fun setCopy(receiver: ProjectTimer, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (ProjectTimer.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ProjectTimer.serializer().descriptor.getElementAnnotations(1) }
}
object ProjectTimer_lineItem: SerializableProperty<ProjectTimer, UUID?> {
    override val name: String by lazy { ProjectTimer.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ProjectTimer): UUID? = receiver.lineItem
    override fun setCopy(receiver: ProjectTimer, value: UUID?) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<UUID?> by lazy { (ProjectTimer.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { ProjectTimer.serializer().descriptor.getElementAnnotations(2) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object ProjectTimer_incident: SerializableProperty<ProjectTimer, UUID?> {
    override val name: String by lazy { ProjectTimer.serializer().descriptor.getElementName(3) }
    override fun get(receiver: ProjectTimer): UUID? = receiver.incident
    override fun setCopy(receiver: ProjectTimer, value: UUID?) = receiver.copy(incident = value)
    override val serializer: KSerializer<UUID?> by lazy { (ProjectTimer.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { ProjectTimer.serializer().descriptor.getElementAnnotations(3) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object ProjectTimer_durationLeft: SerializableProperty<ProjectTimer, Duration?> {
    override val name: String by lazy { ProjectTimer.serializer().descriptor.getElementName(4) }
    override fun get(receiver: ProjectTimer): Duration? = receiver.durationLeft
    override fun setCopy(receiver: ProjectTimer, value: Duration?) = receiver.copy(durationLeft = value)
    override val serializer: KSerializer<Duration?> by lazy { (ProjectTimer.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Duration?> }
    override val annotations: List<Annotation> by lazy { ProjectTimer.serializer().descriptor.getElementAnnotations(4) }
    override val default: Duration? = null
    override val defaultCode: String? = "null" 
}
object ProjectTimer_executesAt: SerializableProperty<ProjectTimer, Instant?> {
    override val name: String by lazy { ProjectTimer.serializer().descriptor.getElementName(5) }
    override fun get(receiver: ProjectTimer): Instant? = receiver.executesAt
    override fun setCopy(receiver: ProjectTimer, value: Instant?) = receiver.copy(executesAt = value)
    override val serializer: KSerializer<Instant?> by lazy { (ProjectTimer.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { ProjectTimer.serializer().descriptor.getElementAnnotations(5) }
}
object ProjectTimer_action: SerializableProperty<ProjectTimer, TimerAction> {
    override val name: String by lazy { ProjectTimer.serializer().descriptor.getElementName(6) }
    override fun get(receiver: ProjectTimer): TimerAction = receiver.action
    override fun setCopy(receiver: ProjectTimer, value: TimerAction) = receiver.copy(action = value)
    override val serializer: KSerializer<TimerAction> by lazy { (ProjectTimer.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<TimerAction> }
    override val annotations: List<Annotation> by lazy { ProjectTimer.serializer().descriptor.getElementAnnotations(6) }
}
object ProjectTimer_businessDaysOnly: SerializableProperty<ProjectTimer, Boolean> {
    override val name: String by lazy { ProjectTimer.serializer().descriptor.getElementName(7) }
    override fun get(receiver: ProjectTimer): Boolean = receiver.businessDaysOnly
    override fun setCopy(receiver: ProjectTimer, value: Boolean) = receiver.copy(businessDaysOnly = value)
    override val serializer: KSerializer<Boolean> by lazy { (ProjectTimer.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ProjectTimer.serializer().descriptor.getElementAnnotations(7) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object ProjectTimer_minimumTimeWhenResumed: SerializableProperty<ProjectTimer, Duration?> {
    override val name: String by lazy { ProjectTimer.serializer().descriptor.getElementName(8) }
    override fun get(receiver: ProjectTimer): Duration? = receiver.minimumTimeWhenResumed
    override fun setCopy(receiver: ProjectTimer, value: Duration?) = receiver.copy(minimumTimeWhenResumed = value)
    override val serializer: KSerializer<Duration?> by lazy { (ProjectTimer.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Duration?> }
    override val annotations: List<Annotation> by lazy { ProjectTimer.serializer().descriptor.getElementAnnotations(8) }
    override val default: Duration? = null
    override val defaultCode: String? = "null" 
}
object ProjectTimer_executed: SerializableProperty<ProjectTimer, Instant?> {
    override val name: String by lazy { ProjectTimer.serializer().descriptor.getElementName(9) }
    override fun get(receiver: ProjectTimer): Instant? = receiver.executed
    override fun setCopy(receiver: ProjectTimer, value: Instant?) = receiver.copy(executed = value)
    override val serializer: KSerializer<Instant?> by lazy { (ProjectTimer.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { ProjectTimer.serializer().descriptor.getElementAnnotations(9) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
