// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareContractorFields() {
    val props: Array<SerializableProperty<Contractor, *>> = arrayOf(Contractor__id, Contractor_name, Contractor_email, Contractor_phoneNumber, Contractor_address, Contractor_image, Contractor_preferredTitle, Contractor_trade, Contractor_contactFirstName, Contractor_contactLastName, Contractor_contactEmail, Contractor_contactPhoneNumber, Contractor_contactAddress, Contractor_stateEntityNumber, Contractor_ein, Contractor_status, Contractor_lastAgreement)
    Contractor.serializer().properties { props }
}
val <K> DataClassPath<K, Contractor>._id: DataClassPath<K, UUID> get() = this[Contractor__id]
val <K> DataClassPath<K, Contractor>.name: DataClassPath<K, String> get() = this[Contractor_name]
val <K> DataClassPath<K, Contractor>.email: DataClassPath<K, String> get() = this[Contractor_email]
val <K> DataClassPath<K, Contractor>.phoneNumber: DataClassPath<K, String> get() = this[Contractor_phoneNumber]
val <K> DataClassPath<K, Contractor>.address: DataClassPath<K, Address> get() = this[Contractor_address]
val <K> DataClassPath<K, Contractor>.image: DataClassPath<K, ServerFile?> get() = this[Contractor_image]
val <K> DataClassPath<K, Contractor>.preferredTitle: DataClassPath<K, PreferredTitle> get() = this[Contractor_preferredTitle]
val <K> DataClassPath<K, Contractor>.trade: DataClassPath<K, String?> get() = this[Contractor_trade]
val <K> DataClassPath<K, Contractor>.contactFirstName: DataClassPath<K, String> get() = this[Contractor_contactFirstName]
val <K> DataClassPath<K, Contractor>.contactLastName: DataClassPath<K, String> get() = this[Contractor_contactLastName]
val <K> DataClassPath<K, Contractor>.contactEmail: DataClassPath<K, String> get() = this[Contractor_contactEmail]
val <K> DataClassPath<K, Contractor>.contactPhoneNumber: DataClassPath<K, String> get() = this[Contractor_contactPhoneNumber]
val <K> DataClassPath<K, Contractor>.contactAddress: DataClassPath<K, Address> get() = this[Contractor_contactAddress]
val <K> DataClassPath<K, Contractor>.stateEntityNumber: DataClassPath<K, String> get() = this[Contractor_stateEntityNumber]
val <K> DataClassPath<K, Contractor>.ein: DataClassPath<K, String> get() = this[Contractor_ein]
val <K> DataClassPath<K, Contractor>.status: DataClassPath<K, ContractorStatus> get() = this[Contractor_status]
val <K> DataClassPath<K, Contractor>.lastAgreement: DataClassPath<K, ContractorTermsAndConditionsAgreement?> get() = this[Contractor_lastAgreement]
inline val Contractor.Companion.path: DataClassPath<Contractor, Contractor> get() = path<Contractor>()


object Contractor__id: SerializableProperty<Contractor, UUID> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Contractor): UUID = receiver._id
    override fun setCopy(receiver: Contractor, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object Contractor_name: SerializableProperty<Contractor, String> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Contractor): String = receiver.name
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(1) }
}
object Contractor_email: SerializableProperty<Contractor, String> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Contractor): String = receiver.email
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(2) }
}
object Contractor_phoneNumber: SerializableProperty<Contractor, String> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(3) }
    override fun get(receiver: Contractor): String = receiver.phoneNumber
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(3) }
}
object Contractor_address: SerializableProperty<Contractor, Address> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(4) }
    override fun get(receiver: Contractor): Address = receiver.address
    override fun setCopy(receiver: Contractor, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Address> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(4) }
}
object Contractor_image: SerializableProperty<Contractor, ServerFile?> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(5) }
    override fun get(receiver: Contractor): ServerFile? = receiver.image
    override fun setCopy(receiver: Contractor, value: ServerFile?) = receiver.copy(image = value)
    override val serializer: KSerializer<ServerFile?> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<ServerFile?> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(5) }
    override val default: ServerFile? = null
    override val defaultCode: String? = "null" 
}
object Contractor_preferredTitle: SerializableProperty<Contractor, PreferredTitle> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(6) }
    override fun get(receiver: Contractor): PreferredTitle = receiver.preferredTitle
    override fun setCopy(receiver: Contractor, value: PreferredTitle) = receiver.copy(preferredTitle = value)
    override val serializer: KSerializer<PreferredTitle> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<PreferredTitle> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(6) }
    override val default: PreferredTitle = PreferredTitle.Contractor
    override val defaultCode: String? = "PreferredTitle.Contractor" 
}
object Contractor_trade: SerializableProperty<Contractor, String?> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(7) }
    override fun get(receiver: Contractor): String? = receiver.trade
    override fun setCopy(receiver: Contractor, value: String?) = receiver.copy(trade = value)
    override val serializer: KSerializer<String?> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(7) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object Contractor_contactFirstName: SerializableProperty<Contractor, String> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(8) }
    override fun get(receiver: Contractor): String = receiver.contactFirstName
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(contactFirstName = value)
    override val serializer: KSerializer<String> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(8) }
}
object Contractor_contactLastName: SerializableProperty<Contractor, String> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(9) }
    override fun get(receiver: Contractor): String = receiver.contactLastName
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(contactLastName = value)
    override val serializer: KSerializer<String> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(9) }
}
object Contractor_contactEmail: SerializableProperty<Contractor, String> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(10) }
    override fun get(receiver: Contractor): String = receiver.contactEmail
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(contactEmail = value)
    override val serializer: KSerializer<String> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(10) }
}
object Contractor_contactPhoneNumber: SerializableProperty<Contractor, String> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(11) }
    override fun get(receiver: Contractor): String = receiver.contactPhoneNumber
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(contactPhoneNumber = value)
    override val serializer: KSerializer<String> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[11] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(11) }
}
object Contractor_contactAddress: SerializableProperty<Contractor, Address> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(12) }
    override fun get(receiver: Contractor): Address = receiver.contactAddress
    override fun setCopy(receiver: Contractor, value: Address) = receiver.copy(contactAddress = value)
    override val serializer: KSerializer<Address> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[12] as KSerializer<Address> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(12) }
}
object Contractor_stateEntityNumber: SerializableProperty<Contractor, String> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(13) }
    override fun get(receiver: Contractor): String = receiver.stateEntityNumber
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(stateEntityNumber = value)
    override val serializer: KSerializer<String> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[13] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(13) }
}
object Contractor_ein: SerializableProperty<Contractor, String> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(14) }
    override fun get(receiver: Contractor): String = receiver.ein
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(ein = value)
    override val serializer: KSerializer<String> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[14] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(14) }
}
object Contractor_status: SerializableProperty<Contractor, ContractorStatus> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(15) }
    override fun get(receiver: Contractor): ContractorStatus = receiver.status
    override fun setCopy(receiver: Contractor, value: ContractorStatus) = receiver.copy(status = value)
    override val serializer: KSerializer<ContractorStatus> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[15] as KSerializer<ContractorStatus> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(15) }
    override val default: ContractorStatus = ContractorStatus.Registering
    override val defaultCode: String? = "ContractorStatus.Registering" 
}
object Contractor_lastAgreement: SerializableProperty<Contractor, ContractorTermsAndConditionsAgreement?> {
    override val name: String by lazy { Contractor.serializer().descriptor.getElementName(16) }
    override fun get(receiver: Contractor): ContractorTermsAndConditionsAgreement? = receiver.lastAgreement
    override fun setCopy(receiver: Contractor, value: ContractorTermsAndConditionsAgreement?) = receiver.copy(lastAgreement = value)
    override val serializer: KSerializer<ContractorTermsAndConditionsAgreement?> by lazy { (Contractor.serializer() as GeneratedSerializer<*>).childSerializers()[16] as KSerializer<ContractorTermsAndConditionsAgreement?> }
    override val annotations: List<Annotation> by lazy { Contractor.serializer().descriptor.getElementAnnotations(16) }
    override val default: ContractorTermsAndConditionsAgreement? = null
    override val defaultCode: String? = "null" 
}
