package com.crowpay.sdk

import com.crowpay.*
import com.crowpay.views.screens.auth.LogInScreen
import com.lightningkite.UUID
import com.lightningkite.kiteui.Async
import com.lightningkite.kiteui.CancelledException
import com.lightningkite.kiteui.HttpMethod
import com.lightningkite.kiteui.asyncGlobal
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.lightningserver.db.ModelCache
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import kotlin.time.Duration.Companion.minutes
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant

class UserSession(
    private val api: LiveApi,
    private val userToken: String,
    private val userAccessToken: suspend () -> String,
    val userId: UUID,
) {

    val nonCached = object : AbstractUserSession(api, userToken, userAccessToken) {
        override val api: Api = this@UserSession.api
        override val authToken: String = this@UserSession.userToken
        override val accessToken: suspend () -> String = this@UserSession.userAccessToken
    }

    val changeRequests = ModelCache(nonCached.changeRequest, ChangeRequest.serializer())
    val clientTermsAndConditionsAgreements =
        ModelCache(nonCached.clientTermsAndConditionsAgreement, ClientTermsAndConditionsAgreement.serializer())
    val clientTermsAndConditionsVersions =
        ModelCache(nonCached.clientTermsAndConditionsVersion, ClientTermsAndConditionsVersion.serializer())
    val clients = ModelCache(nonCached.client, Client.serializer())
    val contractors = ModelCache(nonCached.contractor, Contractor.serializer())
    val contractorDocuments = ModelCache(nonCached.contractorDocument, ContractorDocument.serializer())
    val contractorTermsAndConditionsAgreements =
        ModelCache(nonCached.contractorTermsAndConditionsAgreement, ContractorTermsAndConditionsAgreement.serializer())
    val contractorTermsAndConditionsVersions =
        ModelCache(nonCached.contractorTermsAndConditionsVersion, ContractorTermsAndConditionsVersion.serializer())
    val licenses = ModelCache(nonCached.license, License.serializer())
    val lineItems = ModelCache(nonCached.lineItem, LineItem.serializer())
    val itemChanges = ModelCache(nonCached.itemChange, ItemChange.serializer())
    val projects = ModelCache(nonCached.project, Project.serializer())
    val projectLedgerItems = ModelCache(nonCached.projectLedgerItem, ProjectLedgerItem.serializer())
    val punchLists = ModelCache(nonCached.punchListItem, PunchListItem.serializer())
    val pendingLineItems = ModelCache(nonCached.pendingLineItem, PendingLineItem.serializer())
    val pendingItemChange = ModelCache(nonCached.pendingItemChange, PendingItemChange.serializer())
    val scopeViews = ModelCache(nonCached.scopeView, ScopeView.serializer())
    val trades = ModelCache(nonCached.trade, Trade.serializer())
    val users = ModelCache(nonCached.user, User.serializer())
    val contractorNotes = ModelCache(nonCached.contractorNote, ContractorNote.serializer())
    val payApplications = ModelCache(nonCached.payApplication, PayApplication.serializer())
    val payAppItems = ModelCache(nonCached.payAppItem, PayAppItem.serializer())
    val lineItemCompletionMessages = ModelCache(nonCached.lineItemCompletionMessage, LineItemCompletionMessage.serializer())
    val projectTimers = ModelCache(nonCached.projectTimer, ProjectTimer.serializer())
    val completionMessageComments = ModelCache(nonCached.completionMessageComment, CompletionMessageComment.serializer())
    val disputes = ModelCache(nonCached.dispute, Dispute.serializer())
    val incidents = ModelCache(nonCached.incident, Incident.serializer())
    val incidentMessages = ModelCache(nonCached.incidentMessage, IncidentMessage.serializer())
    val incidentMessageComments = ModelCache(nonCached.incidentMessageComment, IncidentMessageComment.serializer())
    val projectMessages = ModelCache(nonCached.projectMessage, ProjectMessage.serializer())

    val self = shared {
        users[userId]()!!
    }

    fun currentAccess(): AccessInfo = AccessInfo(
        user = userId,
        ip = "",
        userAgent = "",
        timestamp = now(),
    )

    suspend fun bulkRequest2(input: Map<String, BulkRequest>): Map<String, BulkResponse> = fetch(
        url = "${api.httpUrl}/meta/bulk",
        method = HttpMethod.POST,
        token = nonCached.accessToken,
        body = input
    )
}

val sessionToken = PersistentProperty<String?>("sessionToken", null)
var invalidateAccess = BasicListenable()


val userToken: Readable<Triple<LiveApi, String, suspend () -> String>?> = shared {
    rerunOn(invalidateAccess)
    val refresh = sessionToken<String?>() ?: return@shared null
    val api = selectedApi().api

    var lastRefresh: Instant = now()
    var token: Async<String> = asyncGlobal {
        api.userAuth.getTokenSimple(refresh)
    }

    Triple(api, refresh, suspend {
        if (lastRefresh <= now().minus(4.minutes)) {
            lastRefresh = now()
            token = asyncGlobal {
                api.userAuth.getTokenSimple(refresh)
            }
        }
        token.await()
    })

}


val currentSession = sharedSuspending<UserSession?> {
    val (api: LiveApi, userToken: String, access: suspend () -> String) = userToken.await() ?: return@sharedSuspending null
    val self = try {
        api.userAuth.getSelf(access, masquerade = null)
    } catch (e: Exception) {
        return@sharedSuspending null
    }
    UserSession(
        api = api,
        userToken = userToken,
        userAccessToken = access,
        userId = self._id,
    )
}

val notNullSession = shared {
    val result = currentSession()
    if (result == null) {
        val navigator = mainScreen.awaitNotNull()
        navigator.reset(LogInScreen())
        throw CancelledException("No auth")
    }
    result
}


//suspend fun ViewWriter.guaranteedSession(): UserSession {
//    val result = currentSession.await()
//    if (result == null) {
//        screenNavigator.reset(LogInScreen())
//        throw CancelledException()
//    }
//    return result
//}

suspend fun ViewWriter.clearSession() {
    try {
//        currentSession.await()?.nonCached?.userSession?.terminateSession()
    } catch (e: Exception) {
        /*squish*/
    }
    sessionToken set null
}


//inline fun <reified T> Readable<WritableModel<T>>.flatten(): WritableModel<T> {
//    return object : WritableModel<T>, Readable<T?> by shared({ this@flatten.await().await() }) {
//        override suspend fun set(value: T?) {
//            this@flatten.await().set(value)
//        }
//
//        override val serializer: KSerializer<T> = serializerOrContextual()
//
//        override suspend fun delete() {
//            this@flatten.await().delete()
//        }
//
//        override suspend fun modify(modification: Modification<T>): T? {
//            return this@flatten.await().modify(modification)
//        }
//
//        override fun invalidate() {
//            if (this@flatten.state.ready)
//                this@flatten.state.get().invalidate()
//        }
//    }
//}


//inline fun <reified T : HasId<ID>, ID : Comparable<ID>> Readable<CachingModelRestEndpoints<T, ID>>.flatten(): CachingModelRestEndpoints<T, ID> {
//    return object : CachingModelRestEndpoints<T, ID> {
//        override fun get(id: ID): WritableModel<T> = shared { this@flatten.await().get(id) }.flatten()
//        override suspend fun query(query: Query<T>): Readable<List<T>> =
//            shared { this@flatten.await().query(query).await() }
//
//        override suspend fun watch(query: Query<T>): Readable<List<T>> =
//            shared { this@flatten.await().watch(query).await() }
//
//        override suspend fun insert(item: T): WritableModel<T> =
//            shared { this@flatten.awaitRaw().insert(item) }.flatten()
//
//        override suspend fun insert(item: List<T>): List<T> = this@flatten.awaitRaw().insert(item)
//        override suspend fun upsert(item: T): WritableModel<T> =
//            shared { this@flatten.awaitRaw().upsert(item) }.flatten()
//
//        override suspend fun bulkModify(bulkUpdate: MassModification<T>): Int =
//            this@flatten.awaitRaw().bulkModify(bulkUpdate)
//
//        override val skipCache: ModelRestEndpoints<T, ID> get() = TODO()
//    }
//}