package com.crowpay.completionResponse

import com.crowpay.AccessInfo
import com.crowpay.HasDisplayName
import com.crowpay.LineItemState
import com.crowpay.SharedUtils
import kotlinx.serialization.Serializable

@Serializable
enum class LineItemConcern(val displayName: String) {
    Incomplete("Incomplete"), QualityConcern("Quality Concern"), Other("Other")
}

@Serializable
enum class DisputeType { LineItem, ProjectWide }

/**
 *  Basic flow (these get very complicated)
 *  - Contractor requests complete
 *  - Homeowner accepts or says notReady with one or more of these reasons
 *  - Contractor responds with MoreInfo-KeepWorking
 *  - Back and forth until acceptance or issue is raised
 *  - If KeepWorking is selected then the contractor will have to request complete again before back and forth resumes
 *
 *  For more detailed information see [This Google Doc](https://docs.google.com/document/d/1-NGdppIgKjng8nvRAu4CtOIYfJTaP_uTNAJfNJlPWig/edit?tab=t.0)
 */
sealed interface CompletionResponse: HasDisplayName {
    val shortDisplayName: String get() = displayName

    fun resultantState(baseState: LineItemState): LineItemState

    sealed interface NeedsApproval : CompletionResponse
    sealed interface DisputeResolution : NeedsApproval

    // CONTRACTOR

    data class RequestComplete(val accessInfo: AccessInfo) : NeedsApproval {
        override val displayName: String = "Request Sign Off"
        override fun resultantState(baseState: LineItemState): LineItemState = LineItemState.RequestingReview
    }

    // Basic approval C responses
    data object MoreInfo : CompletionResponse {
        override val displayName: String = "More Info"
        override fun resultantState(baseState: LineItemState): LineItemState = baseState
    }
    data object Handled : NeedsApproval {
        override val displayName: String = "Handled"
        override fun resultantState(baseState: LineItemState): LineItemState = LineItemState.RequestingReview
    }
    data object ForceComplete : NeedsApproval {
        override val displayName: String = "Force Complete"
        override fun resultantState(baseState: LineItemState): LineItemState = LineItemState.ForceComplete
    }
    data object KeepWorking : CompletionResponse {
        override val displayName: String = "Keep Working"
        override fun resultantState(baseState: LineItemState): LineItemState = LineItemState.InProgress
    }

    // HOMEOWNER

    // Basic client response
    data class ClientResponse(
        val reasons: Set<LineItemConcern>,
        val raiseIssue: Boolean = false,
        val fileDispute: DisputeType? = null
    ) : CompletionResponse {
        constructor(
            incomplete: Boolean = true,
            qualityConcern: Boolean = false,
            other: Boolean = false,
            raiseIssue: Boolean = false,
            fileDispute: DisputeType? = null
        ) : this(
            setOfNotNull(
                if (incomplete) LineItemConcern.Incomplete else null,
                if (qualityConcern) LineItemConcern.QualityConcern else null,
                if (other) LineItemConcern.Other else null
            ),
            raiseIssue,
            fileDispute
        )

        fun isEmpty(): Boolean = reasons.isEmpty()

        override val displayName: String =
            if (isEmpty()) "Empty"
            else reasons.sortedBy { it.ordinal }.joinToString { it.displayName }

        override val shortDisplayName: String =
            if (isEmpty()) "Empty"
            else reasons.singleOrNull()?.displayName ?: "Multiple"

        override fun resultantState(baseState: LineItemState): LineItemState = when {
            fileDispute != null -> LineItemState.Disputed
            raiseIssue -> LineItemState.Issue
            else -> baseState
        }
    }

    data object ClientResolution : CompletionResponse {
        override val displayName: String = "Resolution"
        override fun resultantState(baseState: LineItemState): LineItemState = LineItemState.Resolving
    }

    data object RequestResolveLater : NeedsApproval {
        override val displayName: String = "Resolve Later"
        override fun resultantState(baseState: LineItemState): LineItemState = LineItemState.Resolving
    }

    data object Appeal : DisputeResolution {
        override val displayName: String = "Appeal"
        override fun resultantState(baseState: LineItemState): LineItemState = LineItemState.Resolving
    }
    data class Remedy(val credit: Long? = null) : DisputeResolution {
        override val displayName: String = "Remedy Plan" + if (credit != null) " (${SharedUtils.formatDollars(credit)})" else ""
        override val shortDisplayName: String = "Remedy Plan"
        override fun resultantState(baseState: LineItemState): LineItemState = LineItemState.Resolving

        // Exists so that the offered credit with a remedy is only applied when remedy work finished and accepted
        data class RemedyComplete(val remedy: Remedy) : NeedsApproval {
            override val displayName: String = "Requesting Remedy Complete"
            override fun resultantState(baseState: LineItemState): LineItemState = LineItemState.RequestingReview
        }
    }
    data class Credit(val credit: Long) : DisputeResolution {
        override val displayName: String = "Credit (${SharedUtils.formatDollars(credit)})"
        override val shortDisplayName: String = "Credit"
        override fun resultantState(baseState: LineItemState): LineItemState = LineItemState.Resolving
    }

    data class Accept(val proposal: NeedsApproval, val accessInfo: AccessInfo) : CompletionResponse {
        override val displayName: String = "Accept ${proposal.shortDisplayName}"

        override fun resultantState(baseState: LineItemState): LineItemState = when(proposal) {
            is RequestComplete,
            ForceComplete,
            Handled -> LineItemState.Complete
            Appeal,
            is Credit -> LineItemState.Resolved
            is Remedy -> LineItemState.WorkingOnRemedy
            is Remedy.RemedyComplete -> LineItemState.Resolved
            RequestResolveLater -> LineItemState.ResolveLater
        }
    }

    // Denial of dispute resolution
    data object Deny : CompletionResponse {
        override val displayName: String = "Deny"
        override fun resultantState(baseState: LineItemState): LineItemState = baseState
    }
}

val CompletionResponse.isClientResponse: Boolean get() =
    this is CompletionResponse.ClientResponse ||
            this is CompletionResponse.Accept ||
            this == CompletionResponse.Deny ||
            this == CompletionResponse.ClientResolution

val CompletionResponse.isContractorResponse: Boolean get() =
    this !is CompletionResponse.ClientResponse && this != CompletionResponse.ClientResolution

fun CompletionResponse.requiresMessageText() =
    !(this is CompletionResponse.RequestComplete ||
            this is CompletionResponse.Accept ||
            this is CompletionResponse.Remedy.RemedyComplete)
