package com.crowpay.completionResponse

import com.crowpay.AccessInfo
import com.lightningkite.lightningdb.GenerateDataClassPaths
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException

/**
 * A struct to serialize CompletionResponse. You should go read the CompletionResponse code to see how data is actually
 * used, this is purely for serialization purposes
 *
 * @see CompletionResponse
 * */
@GenerateDataClassPaths
@Serializable
data class CompletionResponseSerializable(
    val type: CompletionResponseType,
    val accessInfo: AccessInfo? = null,
    val reasons: Set<LineItemConcern> = emptySet(),
    val raiseIssue: Boolean = false,
    val fileDispute: DisputeType? = null,
    val displayName: String? = null,
    val credit: Long? = null,
    val remedyComplete: CompletionResponseSerializable? = null,
    val proposal: CompletionResponseSerializable? = null,
)

@Serializable
enum class CompletionResponseType {
    RequestComplete,
    MoreInfo,
    Handled,
    ForceComplete,
    KeepWorking,
    ClientResponse,
    ClientResolution,
    RequestResolveLater,
    Appeal,
    Remedy,
    RemedyComplete,
    Credit,
    Accept,
    Deny,
}

class CompletionResponseParseError(
    val struct: CompletionResponseSerializable,
    override val message: String,
    override val cause: CompletionResponseParseError? = null,
): SerializationException()

/**
 * Parses the `CompletionResponseSerializable` into a `CompletionResponse`.
 *
 * @throws CompletionResponseParseError if required fields are missing or if parsing fails.
 * @return the parsed `CompletionResponse`.
 */
fun CompletionResponseSerializable.parseResponse(): CompletionResponse {
    fun missingFields(vararg missingFields: String): Nothing = throw CompletionResponseParseError(
        this,
        "Could not find value for ${if (missingFields.size == 1) "field" else "fields"}: ${missingFields.joinToString()}"
    )

    try {
        return when (type) {
            CompletionResponseType.RequestComplete -> CompletionResponse.RequestComplete(
                accessInfo ?: missingFields("accessInfo")
            )

            CompletionResponseType.MoreInfo -> CompletionResponse.MoreInfo
            CompletionResponseType.Handled -> CompletionResponse.Handled
            CompletionResponseType.ForceComplete -> CompletionResponse.ForceComplete
            CompletionResponseType.KeepWorking -> CompletionResponse.KeepWorking
            CompletionResponseType.ClientResponse -> CompletionResponse.ClientResponse(reasons, raiseIssue, fileDispute)
            CompletionResponseType.ClientResolution -> CompletionResponse.ClientResolution
            CompletionResponseType.RequestResolveLater -> CompletionResponse.RequestResolveLater
            CompletionResponseType.Appeal -> CompletionResponse.Appeal
            CompletionResponseType.Remedy -> CompletionResponse.Remedy(credit)
            CompletionResponseType.RemedyComplete -> CompletionResponse.Remedy.RemedyComplete(
                remedyComplete?.let {
                    val parsed = it.parseResponse()
                    if (parsed !is CompletionResponse.Remedy) throw CompletionResponseParseError(it, "CompletionResponse.Remedy Required")
                    parsed
                } ?: missingFields("remedyComplete")
            )

            CompletionResponseType.Credit -> CompletionResponse.Credit(credit ?: missingFields("credit"))
            CompletionResponseType.Accept -> CompletionResponse.Accept(
                proposal?.let {
                    val parsed = it.parseResponse()
                    if (parsed !is CompletionResponse.NeedsApproval) throw CompletionResponseParseError(it, "CompletionResponse.NeedsApproval Required")
                    parsed
                } ?: missingFields("proposal"),
                accessInfo ?: missingFields("accessInfo")
            )
            CompletionResponseType.Deny -> CompletionResponse.Deny
        }
    } catch (e: CompletionResponseParseError) {
        if (e.struct != this) throw CompletionResponseParseError(this, "Parsing failed due to inner parsing error: $e", e)
        else throw e
    }
}

fun CompletionResponse.serializable(): CompletionResponseSerializable = when(this) {
    CompletionResponse.ClientResolution -> CompletionResponseSerializable(CompletionResponseType.ClientResolution)
    CompletionResponse.Deny -> CompletionResponseSerializable(CompletionResponseType.Deny)
    CompletionResponse.KeepWorking -> CompletionResponseSerializable(CompletionResponseType.KeepWorking)
    CompletionResponse.MoreInfo -> CompletionResponseSerializable(CompletionResponseType.MoreInfo)
    CompletionResponse.Appeal -> CompletionResponseSerializable(CompletionResponseType.Appeal)
    CompletionResponse.ForceComplete -> CompletionResponseSerializable(CompletionResponseType.ForceComplete)
    CompletionResponse.Handled -> CompletionResponseSerializable(CompletionResponseType.Handled)
    CompletionResponse.RequestResolveLater -> CompletionResponseSerializable(CompletionResponseType.RequestResolveLater)
    is CompletionResponse.ClientResponse -> CompletionResponseSerializable(CompletionResponseType.ClientResponse, reasons = reasons, raiseIssue = raiseIssue, fileDispute = fileDispute)
    is CompletionResponse.Credit -> CompletionResponseSerializable(CompletionResponseType.Credit, credit = credit)
    is CompletionResponse.Remedy -> CompletionResponseSerializable(CompletionResponseType.Remedy, credit = credit)
    is CompletionResponse.RequestComplete -> CompletionResponseSerializable(CompletionResponseType.RequestComplete, accessInfo = accessInfo)
    is CompletionResponse.Remedy.RemedyComplete -> CompletionResponseSerializable(CompletionResponseType.RemedyComplete, remedyComplete = remedy.serializable())
    is CompletionResponse.Accept -> CompletionResponseSerializable(CompletionResponseType.Accept, accessInfo = accessInfo, proposal = proposal.serializable())
}