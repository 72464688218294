package com.crowpay.views.components.project.work.scope

import com.crowpay.ScopeViewInterface
import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.utils.renderDollars
import com.crowpay.views.components.expandAction
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.renderPriceChange
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.dynamicTheme

private const val nameWeight = 5f
private const val originalWeight = 1.5f
private const val changeWeight = 1.5f
private const val statusWeight = 1.7f
private const val totalWeight = 1.5f

fun ViewWriter.workItemTableHeader(showNameHeader: Boolean) {
    sizeConstraints(height = 3.rem) - row {
        if (showNameHeader)
            weight(nameWeight) - centered - bodyBold("Work Item")
        else
            weight(nameWeight) - space()

        weight(originalWeight) - centered - bodyBold {
            align = Align.Center
            content = "Original"
        }
        weight(changeWeight) - centered - bodyBold {
            align = Align.Center
            content = "Change"
        }
        weight(statusWeight) - centered - bodyBold {
            align = Align.Center
            content = "Status"
        }
        weight(totalWeight) - centered - bodyBold {
            align = Align.Center
            content = "Total"
        }
    }
}

fun ViewWriter.workItemTableRow(
    expanded: Property<Boolean>,
    item: Readable<AdjustedLineItem>,
): RView {
    expandButtonTheme
    return button {
        spacing = 0.px

        sizeConstraints(height = 3.rem) - row {
            weight(nameWeight) - row {
                spacing = AppDimensions.expandButtonSpace
                centered - expandIcon(expanded)
                centered - body {
                    dynamicTheme { if (item().cancelled) StrikeThroughSemantic else null }
                    ::content { item().name }
                }
                centered - smallBody { ::content { item().changeType?.postTense ?: "" } }
            }
            weight(originalWeight) - centered - body {
                align = Align.Center
                ::content { item().wraps.originalPrice.renderDollars() }
            }
            weight(changeWeight) - stack {
                centered - renderPriceChange { item().totalPriceChange }
            }
            weight(statusWeight) - centered - body {
                dynamicTheme { LineItemStateSemantic(item().state) }
                align = Align.Center
                ::content { item().state.displayName }
            }
            weight(totalWeight) - centered - body {
                align = Align.Center
                ::content { item().price.renderDollars() }
            }
        }
        action = expandAction(expanded)
    }
}

fun ViewWriter.workItemTableSummary(
    items: Readable<List<AdjustedLineItem>>,
    scopeView: Readable<ScopeViewInterface>,
) {
    sizeConstraints(height = 3.5.rem) - row {
        weight(nameWeight) - centered - body("Group Summary")
        weight(originalWeight) - centered - body {
            align = Align.Center
            ::content { items().sumOf { it.wraps.originalPrice }.renderDollars() }
        }
        weight(changeWeight) - stack {
            centered - renderPriceChange { items().sumOf { it.totalPriceChange } }
        }
        weight(statusWeight) - space()
        weight(totalWeight) - centered - body {
            align = Align.Center
            ::content { items().sumOf { it.price }.renderDollars() }
        }
    }
}