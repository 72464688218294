package com.crowpay.views.components.project.work.punchList

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.utils.validation.interceptWrite
import com.crowpay.utils.validation.validating
import com.crowpay.views.components.*
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.delay

private val rowHeight = AppDimensions.bodyTextSize * 2.5
private val rowSpacing = 0.65.rem
private val checkboxSize = rowHeight - (rowSpacing * 2.5)

fun ViewWriter.renderPunchListRow(
    title: Readable<String>,
    expanded: Writable<Boolean> = Property(false),
    project: Readable<Project>,
    items: Readable<List<PunchListItem>>,
    lineItem: Readable<AdjustedLineItem>?,
    allowReorder: Boolean = true,
    lens: ProjectLens,
    rowHeight: Dimension? = null,
    buttonText: String = "+ Work Task"
) {
    val newItems = Property(emptyList<Property<PunchListItem>>())
    col {
        spacing = 0.px

        rowHeight?.let { sizeConstraints(height = it) }
        expandButtonTheme - button {
            spacing = AppDimensions.cornerRadii
            gravity(Align.Start, Align.Center) - row {
                spacing = AppDimensions.expandButtonSpace
                centered - expandIcon(expanded)
                subTitle { ::content { title() } }
            }
            action = expandAction(expanded)
        }

        onlyWhen { expanded() } - sectionIndentCol {
            spacing = 1.rem
            space()
            atStart - tertiaryButton - button {
                spacing = 0.3.rem
                existsDefaultFalse {
                    lens == ProjectLens.Contractor &&
                            project().state.newTasksAllowed() &&
                            lineItem?.invoke()?.state?.newTasksAllowed() ?: true
                }
                ::enabled {
                    !project().state.isProjectLocked()
                }
                specCenteredText(buttonText)
                onClick {
                    newItems.value += Property(
                        PunchListItem(
                            project = project.awaitOnce()._id,
                            lineItem = lineItem?.awaitOnce()?._id,
                            content = "",
                            required = false
                        )
                    )
                }
            }
            punchListCol(items, newItems, lineItem, lens, allowReorder)
            space()
        }

        greySeparator()
    }
}

fun ViewWriter.punchListCol(
    items: Readable<List<PunchListItem>>,
    newItems: Property<List<Property<PunchListItem>>>,
    lineItem: Readable<AdjustedLineItem>?,
    lens: ProjectLens,
    allowReorder: Boolean = true,
) {
    col {
        spacing = 0.8.dp
        subTitle {
            exists = false
            ::exists {
                items().isEmpty() and newItems().isEmpty()
            }
            content = "No items"
        }

        renderNewPunchListItems(newItems, lineItem, false)
        space { ::exists { newItems().isNotEmpty() } }

        col {
            spacing = 8.dp
            val active = shared {
                items()
                    .filter { it.complete == null }
                    .sortedWith(
                        compareByDescending<PunchListItem> { it.required }
                            .thenByDescending { it.order }
                            .thenBy { it.content }
                    )
            }
            forEach(active) { punchItem ->
                punchListItem(
                    punchItem,
                    lens,
                    allowReorder,
                    canMoveUp = {
                        val a = active()
                        val (req, not) = a.partition { it.required }
                        punchItem._id != req.firstOrNull()?._id && punchItem._id != not.firstOrNull()?._id
                    },
                    canMoveDown = {
                        val a = active()
                        val (req, not) = a.partition { it.required }
                        punchItem._id != req.lastOrNull()?._id && punchItem._id != not.lastOrNull()?._id
                    }
                )
            }
        }

        space()

        col {
            spacing = 8.dp
            val completed = shared {
                items()
                    .filter { it.complete != null }
                    .sortedWith(
                        compareByDescending<PunchListItem> { it.required }
                            .thenByDescending { it.order }
                            .thenBy { it.content }
                    )
            }
            forEach(completed) { punchItem ->
                punchListItem(
                    punchItem,
                    lens,
                    false,
                    canMoveUp = { false },
                    canMoveDown = { false }
                )
            }
        }
    }
}

fun ViewWriter.punchListItem(
    value: PunchListItem,
    viewType: ProjectLens,
    allowReorder: Boolean,
    canMoveUp: ReactiveContext.() -> Boolean,
    canMoveDown: ReactiveContext.() -> Boolean,
) {
    val completed = Property(value.complete != null).interceptWrite { checked ->
        val session = notNullSession()
        session.nonCached.punchListItem.run {
            if (checked) completePunchListItem(value._id)
            else resetPunchListItem(value._id)
        }

        delay(100)
        session.punchLists.totallyInvalidate()
    }

    lightSection - row {
        spacing = rowSpacing
        centered - checkbox {
            spacing = 0.px
            themeChoice += ThemeDerivation { it.copy(foreground = AppColors.grey.dark).withoutBack }
            checked bind completed
            enabled = (viewType == ProjectLens.Contractor)
        }
        if (value.isMinorAdjustment == null) {
            expanding - centered - body(
                value.run { if (required) "$content (Required)" else content }
            )
        } else {
            expanding - col {
                spacing = 1.dp
                body(value.run { if (required) "$content (Required)" else content })
                smallBody("Minor Adjustment $BulletPoint ${value.isMinorAdjustment}")
            }
        }
        if (viewType == ProjectLens.Contractor && value.isMinorAdjustment == null) centered - deleteButton - button {
            spacing = 5.dp
            centered - body("Delete")
            onClick {
                dialogScreenNavigator.navigate(
                    GenericConfirmationDialog(
                        header = "Confirm",
                        message = "Are you sure you want to delete this task? This action cannot be undone.",
                        confirmationText = "Delete",
                        messageType = MessageType.Danger,
                        allowDismiss = true,
                        onSubmit = { delete ->
                            if (delete) {
                                notNullSession().punchLists[value._id].delete()
                            }
                        }
                    ))
            }
        }
        if (allowReorder) col {
            spacing = 0.px
            expanding - button {
                spacing = 0.px
                ::enabled.invoke(canMoveUp)
                iconAndAction(Icon.chevronUp.copy(height = 1.25.rem), "Move up") {
                    value.moveUp()
                }
            }
            expanding - button {
                spacing = 0.px
                ::enabled.invoke(canMoveDown)
                iconAndAction(Icon.chevronDown.copy(height = 1.25.rem), "Move Down") {
                    value.moveDown()
                }
            }
        }
    }
}

fun ViewWriter.renderNewPunchListItems(
    items: Property<List<Property<PunchListItem>>>,
    lineItem: Readable<AdjustedLineItem>?,
    hideSave: Boolean
) {

    col {
        spacing = 8.dp
        existsDefaultFalse { items().isNotEmpty() }

        forEach(items) { item ->
            val save = Action("Save") {
                notNullSession().punchLists.insert(item())
                items.modify { it - item }
            }

            validating {
                row {
                    spacing = rowSpacing
                    expanding - lightSection - row {
                        spacing = rowSpacing
                        centered - withSpacing(0.25) - row {
                            atTopStart - checkbox {
                                exists = !hideSave
                                existsDefaultFalse { lineItem?.invoke()?.state?.newRequiredTasksAllowed() ?: true }
                                exists = !hideSave
                                checked bind item.lensPath { it.required }
                            }
                            centered - body {
                                exists = !hideSave
                                existsDefaultFalse { lineItem?.invoke()?.state?.newRequiredTasksAllowed() ?: true }
                                exists = !hideSave
                                content = "Required?"
                            }
                        }

                        val name = item.lensPath { it.content }.validate { it.isNotBlank() }
                        expanding - PunchFieldSemantic.onNext - compact - formattedTextInput {
                            val characterLimit = 70
                            spacing = 0.5.rem
                            hint = "Name (Limit $characterLimit Characters)"
                            format(
                                isRawData = { true },
                                formatter = { it.take(characterLimit) }
                            )
                            content bind name
                            action = save
                        }

                        centered - primaryButton - compact - button {
                            exists = !hideSave
                            ::enabled { allValid() }
                            row {
                                spacing = rowSpacing
                                space()
                                centered - body("Save")
                                space()
                            }
                            action = save
                        }
                    }

                    centered - textButton - button {
                        spacing = 0.px
                        centered - body("Discard")
                        onClick { items.modify { it - item } }
                    }
                }
            }
        }
    }
}

// ORDERING
// --------
// All punch item orders are on a scale from 0-1, starting at 0.5. When you move an item up it looks for the two
// next highest items, and then sets its priority to be between them. If there is only one item with a higher priority
// then it sets it priority as the average between the next highest and 1. If there is none higher, then the priority
// is set to the average of its own priority and 1. Moving down does the same thing but averaging toward 0.
suspend fun PunchListItem.moveUp() {
    notNullSession().punchLists.run {
        val nextHighest = query(
            Query(
                condition { (it.lineItem eq lineItem) and (it.order gt order) and (it.required eq required) },
                sort { it.order.ascending() },
                limit = 2
            )
        ).awaitOnce()

//        println("nextHighest: ${nextHighest.size}")

        val newOrder = when (nextHighest.size) {
            0 -> 0.5 * (order + 1.0)
            1 -> 0.5 * (nextHighest.first().order + 1.0)
            else -> 0.5 * (nextHighest[0].order + nextHighest[1].order)
        }

//        println("Current: $order  Next: ${nextHighest.map { it.order }}  New: $newOrder")

        get(_id).modify(
            modification { it.order assign newOrder }
        )
    }
}

suspend fun PunchListItem.moveDown() {
    notNullSession().punchLists.run {
        val nextLowest = query(
            Query(
                condition { (it.lineItem eq lineItem) and (it.order lt order) and (it.required eq required) },
                sort { it.order.descending() },
                limit = 2
            )
        ).awaitOnce()

        val newOrder = when (nextLowest.size) {
            0 -> 0.5 * (order)
            1 -> 0.5 * (nextLowest.first().order)
            else -> 0.5 * (nextLowest[0].order + nextLowest[1].order)
        }

//        println("Current: $order  Next: ${nextLowest.map { it.order }}  New: $newOrder")

        get(_id).modify(
            modification { it.order assign newOrder }
        )
    }
}


fun ViewWriter.punchList(value: PunchListItem, viewType: ProjectLens, allowRequired: Readable<Boolean>) {

    row {
        lazy(allowRequired) { allowRequired ->

            suspend fun handleCheck() {
                val session = notNullSession()
                if (value.complete == null) {
                    session.nonCached.punchListItem.completePunchListItem(value._id)
                } else {
                    session.nonCached.punchListItem.resetPunchListItem(value._id)
                }
                delay(100)
                session.punchLists.totallyInvalidate()
            }

            buttonTheme - button {
                spacing = 0.dp
                sizeConstraints(width = rowHeight, height = rowHeight) - icon(Icon.close, "Remove Punch Item")
                visible = value.complete == null && viewType == ProjectLens.Contractor
                onClick {
                    notNullSession().punchLists[value._id].delete()
                }
            }

            when {
                viewType == ProjectLens.Contractor && (allowRequired || !value.required) -> {
                    centered - button {
                        themeChoice =
                            ThemeDerivation {
                                if (value.complete == null)
                                    it[ButtonSemantic].theme.copy(
                                        spacing = 0.dp,
                                        outlineWidth = AppDimensions.outlineWidth,
                                    )
                                        .withBack
                                else
                                    it[ButtonSemantic].theme.copy(
                                        spacing = 0.dp,
                                        outlineWidth = AppDimensions.outlineWidth,
                                        background = AppColors.grey.light2,
                                    )
                                        .withBack

                            }

                        if (value.complete != null) {
                            icon(Icon.done, "Done") in sizeConstraints(width = rowHeight, height = rowHeight)
                        }
                        onClick(action = ::handleCheck)
                    } in sizeConstraints(width = rowHeight, height = rowHeight)


                    centered - hiddenButton - expanding - button {
                        spacing = 0.dp
                        body {
                            themeChoice += ThemeDerivation {
                                if (value.required)
                                    it
                                        .copy(
                                            outlineWidth = 0.dp,
                                            foreground = AppColors.red.dark,
                                            body = it.font.copy(bold = true)
                                        )
                                        .withoutBack
                                else
                                    it
                                        .copy(outlineWidth = 0.dp)
                                        .withoutBack
                            }
                            content = value.run { if (required) "$content (Required)" else content }
                        }
                        onClick(action = ::handleCheck)
                    }
                }

                else -> {
                    if (value.complete != null)
                        centered -
                                sizeConstraints(width = rowHeight, height = rowHeight) -
                                icon(Icon.done, "Done") {
                                    themeChoice += ThemeDerivation {
                                        it[ButtonSemantic].theme.copy(
                                            spacing = 0.dp,
                                            outlineWidth = AppDimensions.outlineWidth,
                                            background = AppColors.grey.light2,
                                        )
                                            .withBack
                                    }
                                }
                    else {
                        outlined -
                                centered -
                                sizeConstraints(width = rowHeight, height = rowHeight) -
                                stack {
                                    spacing = 0.dp
                                }
                    }


                    centered - expanding - body {
                        body {
                            themeChoice += ThemeDerivation {
                                if (value.required)
                                    it
                                        .copy(
                                            outlineWidth = 0.dp,
                                            foreground = AppColors.red.dark,
                                            body = it.font.copy(bold = true)
                                        )
                                        .withoutBack
                                else
                                    it
                                        .copy(outlineWidth = 0.dp)
                                        .withoutBack
                            }
                            content = value.run { if (required) "$content (Required)" else content }
                        }
                    }

                }
            }
        }

    }
}