package com.crowpay.views.theming

import com.crowpay.actuals.AppDimensions
import com.crowpay.Resources
import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.themeFromLast

@ViewModifierDsl3
val outlineTheme: Theme.() -> Theme = {
    copy(
        cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
        outlineWidth = AppDimensions.outlineWidth,
    )
}

@ViewModifierDsl3
val ViewWriter.outlined: ViewWrapper get() = themeFromLast(outlineTheme)

@ViewModifierDsl3
val ViewWriter.redText: ViewWrapper get() = ForegroundSemantic(AppColors.red.main).onNext

object AuthDialogSemantic: Semantic {
    override val key: String = "athdlg"
    override fun default(theme: Theme): ThemeAndBack =
        theme.copy(
            id = "athdlg",
            cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
            outlineWidth = AppDimensions.outlineWidth,
            outline = AppColors.grey.main,
            background = Color.white,
            revert = true,
            derivations = mapOf(
                CardSemantic to { it.withoutBack }
            )
        )
            .withBack
}

object HiddenButtonSemantic : Semantic {
    override val key: String = "hdnBtn"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "hdnBtn",
        derivations = mapOf(
            ButtonSemantic to {
                it.withBack
            },
            HoverSemantic to {
                it.withBack
            },
            DownSemantic to {
                it.withBack
            },
        )
    ).withoutBack
}

object NotificationCountSemantic : Semantic {
    override val key: String = "notCt"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "notCt",
        background = Color.red,
        foreground = Color.white,
        cornerRadii = CornerRadii.RatioOfSize(0.5f),
        font = theme.font.copy(size = theme.font.size * 2 / 3, bold = true, lineSpacingMultiplier = 1.0),
        spacing = 0.1.rem,
    ).withBack
}

@ViewModifierDsl3
val ViewWriter.hiddenButton: ViewWrapper get() = HiddenButtonSemantic.onNext

data object PunchFieldSemantic: Semantic {
    override val key: String = "pnchfld"
    override fun default(theme: Theme): ThemeAndBack = theme[FieldSemantic].theme.copy(
        id = key,
        background = AppColors.grey.light2
    ).withBack
}

data object RemoveOutline: Semantic {
    override val key: String get() = "rmvout"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(outlineWidth = 0.px).withoutBack
}
@ViewModifierDsl3 val ViewWriter.removeOutline: ViewWrapper get() = RemoveOutline.onNext

data class SetCornerRadii(val revert: Boolean = false, val calculation: (Theme)->CornerRadii?) : Semantic {
    override val key: String = "crnr"
    override fun default(theme: Theme): ThemeAndBack =
        calculation(theme)
            ?.let { cor ->
                theme.copy(
                    id = key + cor.toString().filter { it.isLetterOrDigit() },
                    cornerRadii = cor,
                    revert = revert,
                ).withoutBack
            }
            ?: theme.withoutBack
}

val appTheme = Theme(
    id = "main",
    font = FontAndStyle(font = Resources.fontsRobotoRegular),
    foreground = Color.black,
    background = Color.white,
    elevation = 0.dp,
    cornerRadii = CornerRadii.Constant(0.px),
    outlineWidth = 0.dp,
    outline = AppColors.grey.light1,
    derivations = mapOf(
        ErrorSemantic to {
            it.copy(
                foreground = AppColors.red.main
            ).withoutBack
        },
        ButtonSemantic to {
            it
                .copy(
                    id = "btn",
                    cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
                    spacing = AppDimensions.buttonSpacing,
                )
                .withBack
        },
        HoverSemantic to {
            it
                .copy(background = it.background.darken(0.2f))
                .withBack
        },
        DownSemantic to {
            it
                .copy(background = it.background.darken(0.4f))
                .withBack
        },
        CardSemantic to {
            val currentIsDark = it.background.closestColor().toHSP().brightness < 0.95
            println("Card found that ${it.id} currentIsDark = $currentIsDark")
            outlineTheme(it)
                .copy(
                    id = "crd",
                    background = if (currentIsDark) Color.white else AppColors.grey.light2
                )
                .withBack
        },
        FieldSemantic to {
            outlineTheme(it)
                .withBack
        },
        FocusSemantic to {
            it.copy(
                id = "fcs",
                outline = if (it.outline.closestColor() == AppColors.red.light2) AppColors.red.light1 else AppColors.grey.main
            ).withBack
        },
        DialogSemantic to {
            it.copy(
                id = "dlg",
                cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
                outlineWidth = AppDimensions.outlineWidth,
                derivations = mapOf(
                    CardSemantic to { it.withoutBack }
                )
            )
                .withBack
        },
        NavSemantic to {
            it.copy(
                id = "nav",
                background = AppColors.primary.dark,
                foreground = Color.white,
                iconOverride = Color.white,
                derivations = mapOf(
                    HoverSemantic to {
                        it.copy(background = AppColors.purple.main).withBack
                    },
                    DownSemantic to {
                        it.copy(background = AppColors.purple.dark).withBack
                    },
                    SelectedSemantic to {
                        it.copy(background = AppColors.purple.dark).withBack
                    }
                ),
            ).withBack
        },
        InvalidSemantic to {
            it.copy(
                id = "inv",
                outline = AppColors.red.main,
                outlineWidth = AppDimensions.outlineWidth
            ).withBack
        },
        SelectedSemantic to {
            it
                .copy(
                    id = "sel",
                    background = AppColors.grey.light2,
                    outlineWidth = 0.px,
                    outline = Color.transparent
                )
                .withBack
        },
        UnselectedSemantic to {
            it
                .copy(
                    id = "unsel",
                    background = Color.white,
                    outlineWidth = AppDimensions.outlineWidth,
                    outline = AppColors.grey.main
                ).withBack
        },
        WarningSemantic to {
            it.copy(
                id = "wrn",
                background = AppColors.yellow.light1,
            ).withBack
        },
        DangerSemantic to {
            it.copy(
                id = "dngr",
                background = AppColors.red.light1
            ).withBack
        }
    ),
)