package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.actuals.paymentSetup
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.lazy
import com.crowpay.views.components.inlineKeyValue
import com.crowpay.views.components.label2
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.launch

data class PaymentError(
    val type: String?,
    val code: String?,
    val message: String?,
)

data class PaymentResult(
    val method: String?,
    val error: PaymentError?,
)

data class PaymentConfig(
    val token: String,
    val publicKey: String,
    val user: User,
)

class SetupMethodDialog(
    val onComplete: suspend ViewWriter.(PaymentMethod) -> Unit,
) : Screen {
    val name = Property("")
    val done = Property(false)

    val showMoreInfo = Property(false)

    override fun ViewWriter.render() {
        val keys = sharedSuspending {
            val session = notNullSession()
            PaymentConfig(
                session.nonCached.userAuth.getPaymentSetupToken(),
                session.nonCached.userAuth.getPaymentPublishableKey(),
                session.self.await()
            )
        }
        dismissBackground {
            sizeConstraints(width = 50.rem) - dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                col {
                    themeChoice = ThemeDerivation(appTheme)
                    lazy(keys) { (setup, public, user) ->
                        if (setup == SharedUtils.mockPayment || public == SharedUtils.mockPayment) {
                            body {
                                align = Align.Center
                                content =
                                    "We are currently using mock payments. We will automatically create a mock payment object. No money will be transferred, and no banking information gathered."
                            } in sizeConstraints(maxWidth = 20.rem, minWidth = 10.rem)

                            space()

                            tertiaryButton - button {
                                specCenteredText ("Ok")
                                onClick {
                                    screenNavigator.dismiss()
                                }
                            }
                        } else {

                            title("Connect Account")

                            label2("Account Nickname") {
                                fieldTheme - textInput {
                                    hint = "Account Nickname"
                                    keyboardHints = KeyboardHints.title
                                    content bind name
                                }
                            }

                            smallBody("Powered by Stripe")

                            val confirmSetup = paymentSetup(
                                setupToken = setup,
                                publicKey = public,
                                user = user,
                                onComplete = { isComplete ->
                                    done.value = isComplete
                                },
                            )

                            row {
                                spacing = AppDimensions.buttonRowSpacing

                                primaryButton - button {
                                    ::enabled{ done() }
                                    specCenteredText("Confirm")
                                    onClick {
                                        val result = confirmSetup()
                                        if (result.method != null) {
                                            val result = notNullSession().nonCached.userAuth
                                                    .updatePaymentMethodName(result.method, name.value)
                                            onComplete(result)
                                            screenNavigator.dismiss()
                                        } else {
                                            screenNavigator.reset(
                                                GenericDialog(
                                                    result.error?.message ?: SharedUtils.unknownError
                                                )
                                            )
                                        }
                                    }
                                }

                                secondaryButton - button {
                                    specCenteredText("More Info")
                                    onClick { showMoreInfo.value = true }
                                }

                                textButton - button {
                                    specCenteredText("Go Back")
                                    onClick {
                                        screenNavigator.dismiss()
                                    }
                                }
                            }
                        }
                    }
                }
            }

            dismissBackground {
                ::exists { showMoreInfo() }
                onClick { showMoreInfo.value = false }
                centered - dialog - stack {
                    spacing = AppDimensions.fullIndent
                    sizeConstraints(width = 50.rem) - col {
                        spacing = AppDimensions.sectionIndent
                        title("More Info")
                        col {
                            body("Connecting your account ensures that all project funds are securely managed within CrowPay’s escrow system. Here’s how your money is protected:")
                            body {
                                inlineKeyValue(
                                    "FDIC Insurance",
                                    """
                                        FDIC Insurance: All funds are held in FDIC-insured accounts at Cache Valley Bank, providing protection up to ${'$'}250,000 per depositor, per institution.
                                        Additional Security for Higher Balances: For projects exceeding ${'$'}250,000, CrowPay automatically distributes funds across multiple accounts with partnered banks to maintain full insurance coverage.
                                    """.trimIndent()
                                )
                            }
                            body("CrowPay’s escrow system is compliant with all relevant escrow regulations, ensuring that your funds are secure, traceable, and only released according to the agreed-upon terms. You can connect your account with confidence, knowing your money is fully protected.")
                        }
                        atStart - primaryButton - button {
                            specCenteredText("Close")
                            onClick {
                                showMoreInfo.value = false
                            }
                        }
                    }
                }
            }
        }
    }
}