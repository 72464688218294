package com.crowpay.views

import com.lightningkite.kiteui.navigation.*
import com.crowpay.views.components.project.work.payApps.PayAppForm
import com.crowpay.views.components.project.work.changeOrders.ChangeOrderForm2
import com.crowpay.views.screens.contractor.ContractorBillingInfo
import com.crowpay.views.screens.contractor.CreateProject
import com.crowpay.views.screens.contractor.ContractorDashboard
import com.crowpay.views.screens.contractor.EditProject
import com.crowpay.views.screens.contractor.ContractorProject
import com.crowpay.views.screens.contractor.ContractorProfile
import com.crowpay.views.screens.contractor.ContractorClients
import com.crowpay.views.screens.contractor.ContractorBankInfo
import com.crowpay.views.screens.contractor.ContractorProjects
import com.crowpay.views.screens.contractor.ContractorPayments
import com.crowpay.views.screens.client.ClientTermsAndServices
import com.crowpay.views.screens.client.ClientProjects
import com.crowpay.views.screens.client.ClientProfile
import com.crowpay.views.screens.client.ClientPayments
import com.crowpay.views.screens.client.ClientProject
import com.crowpay.views.screens.client.ClientDashboard
import com.crowpay.views.screens.common.MessagingScreen
import com.crowpay.views.screens.common.AnonymousProjectView
import com.crowpay.views.screens.common.DevProjectView
import com.crowpay.views.screens.common.SelectDevProjectScreen
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.screens.common.ContractorWorkLists
import com.crowpay.views.screens.common.ClientWorkLists
import com.crowpay.views.screens.auth.PaymentSetup
import com.crowpay.views.screens.auth.ContractorRegistrationRouter
import com.crowpay.views.screens.auth.BasicInfoScreen
import com.crowpay.views.screens.auth.AuthenticateBasicInfoAndLogin
import com.crowpay.views.screens.auth.BusinessLicenseInfoScreen
import com.crowpay.views.screens.auth.VerificationAndOFACScreen
import com.crowpay.views.screens.auth.LogInScreen
import com.crowpay.views.screens.auth.UserRegistration
import kotlinx.serialization.ExperimentalSerializationApi


@OptIn(ExperimentalSerializationApi::class)
val AutoRoutes = Routes(
    parsers = listOf(
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "draws") return@label null
            if (it.segments[2] != "edit") return@label null
            PayAppForm(
                payAppID = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "change-order") return@label null
            if (it.segments[2] != "edit") return@label null
            ChangeOrderForm2(
                changeOrderId = UrlProperties.decodeFromString(it.segments[3]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "billing") return@label null
            ContractorBillingInfo(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "project") return@label null
            if (it.segments[2] != "create") return@label null
            CreateProject(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "contractor") return@label null
            ContractorDashboard(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "project") return@label null
            if (it.segments[3] != "edit") return@label null
            EditProject(
                projectId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
                UrlProperties.decodeFromStringMap("selectedTab", it.parameters, this.selectedTab)
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "projects") return@label null
            ContractorProject(
                projectId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "profile") return@label null
            ContractorProfile(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "clients") return@label null
            ContractorClients(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "bank-info") return@label null
            ContractorBankInfo(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "projects") return@label null
            ContractorProjects(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "payments") return@label null
            ContractorPayments(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "accept-project") return@label null
            ClientTermsAndServices(
                projectId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "projects") return@label null
            ClientProjects(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "profile") return@label null
            ClientProfile(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "payments") return@label null
            ClientPayments(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "projects") return@label null
            ClientProject(
                projectId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "client") return@label null
            ClientDashboard(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "messages") return@label null
            MessagingScreen(
                lens = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "anonymous") return@label null
            if (it.segments[1] != "projects") return@label null
            AnonymousProjectView(
                projectId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
                UrlProperties.decodeFromStringMap("authorization", it.parameters, this.authorization)
                UrlProperties.decodeFromStringMap("server", it.parameters, this.server)
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "dev-project") return@label null
            DevProjectView(
                projectId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
                UrlProperties.decodeFromStringMap("tab", it.parameters, this.tab)
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "select-dev-project") return@label null
            SelectDevProjectScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 0) return@label null
            LandingScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "tasks") return@label null
            ContractorWorkLists(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "tasks") return@label null
            ClientWorkLists(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auth") return@label null
            if (it.segments[1] != "payment-setup") return@label null
            PaymentSetup(
            ).apply {
                UrlProperties.decodeFromStringMap("next", it.parameters, this.next)
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "register") return@label null
            ContractorRegistrationRouter
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "register") return@label null
            if (it.segments[2] != "basic-info") return@label null
            BasicInfoScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "register") return@label null
            if (it.segments[2] != "authenticate") return@label null
            AuthenticateBasicInfoAndLogin(
                token = UrlProperties.decodeFromString(it.segments[3]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "register") return@label null
            if (it.segments[2] != "license") return@label null
            BusinessLicenseInfoScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "register") return@label null
            if (it.segments[2] != "OFAC") return@label null
            VerificationAndOFACScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auth") return@label null
            if (it.segments[1] != "log-in") return@label null
            LogInScreen(
            ).apply {
                UrlProperties.decodeFromStringMap("next", it.parameters, this.next)
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auth") return@label null
            if (it.segments[1] != "user-registration") return@label null
            UserRegistration(
            ).apply {
                UrlProperties.decodeFromStringMap("next", it.parameters, this.next)
            }
        },
    ),
    renderers = mapOf(
        PayAppForm::class to label@{
            if (it !is PayAppForm) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("draws", UrlProperties.encodeToString(it.payAppID), "edit"),
                parameters = p
            ), listOf())
        },
        ChangeOrderForm2::class to label@{
            if (it !is ChangeOrderForm2) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "change-order", "edit", UrlProperties.encodeToString(it.changeOrderId)),
                parameters = p
            ), listOf())
        },
        ContractorBillingInfo::class to label@{
            if (it !is ContractorBillingInfo) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "billing"),
                parameters = p
            ), listOf())
        },
        CreateProject::class to label@{
            if (it !is CreateProject) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "project", "create"),
                parameters = p
            ), listOf())
        },
        ContractorDashboard::class to label@{
            if (it !is ContractorDashboard) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor"),
                parameters = p
            ), listOf())
        },
        EditProject::class to label@{
            if (it !is EditProject) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.selectedTab.value, "selectedTab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "project", UrlProperties.encodeToString(it.projectId), "edit"),
                parameters = p
            ), listOf(it.selectedTab))
        },
        ContractorProject::class to label@{
            if (it !is ContractorProject) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "projects", UrlProperties.encodeToString(it.projectId)),
                parameters = p
            ), listOf())
        },
        ContractorProfile::class to label@{
            if (it !is ContractorProfile) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "profile"),
                parameters = p
            ), listOf())
        },
        ContractorClients::class to label@{
            if (it !is ContractorClients) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "clients"),
                parameters = p
            ), listOf())
        },
        ContractorBankInfo::class to label@{
            if (it !is ContractorBankInfo) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "bank-info"),
                parameters = p
            ), listOf())
        },
        ContractorProjects::class to label@{
            if (it !is ContractorProjects) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "projects"),
                parameters = p
            ), listOf())
        },
        ContractorPayments::class to label@{
            if (it !is ContractorPayments) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "payments"),
                parameters = p
            ), listOf())
        },
        ClientTermsAndServices::class to label@{
            if (it !is ClientTermsAndServices) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "accept-project", UrlProperties.encodeToString(it.projectId)),
                parameters = p
            ), listOf())
        },
        ClientProjects::class to label@{
            if (it !is ClientProjects) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "projects"),
                parameters = p
            ), listOf())
        },
        ClientProfile::class to label@{
            if (it !is ClientProfile) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "profile"),
                parameters = p
            ), listOf())
        },
        ClientPayments::class to label@{
            if (it !is ClientPayments) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "payments"),
                parameters = p
            ), listOf())
        },
        ClientProject::class to label@{
            if (it !is ClientProject) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "projects", UrlProperties.encodeToString(it.projectId)),
                parameters = p
            ), listOf())
        },
        ClientDashboard::class to label@{
            if (it !is ClientDashboard) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client"),
                parameters = p
            ), listOf())
        },
        MessagingScreen::class to label@{
            if (it !is MessagingScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("messages", UrlProperties.encodeToString(it.lens)),
                parameters = p
            ), listOf())
        },
        AnonymousProjectView::class to label@{
            if (it !is AnonymousProjectView) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.authorization.value, "authorization", p)
            UrlProperties.encodeToStringMap(it.server.value, "server", p)
            RouteRendered(UrlLikePath(
                segments = listOf("anonymous", "projects", UrlProperties.encodeToString(it.projectId)),
                parameters = p
            ), listOf(it.authorization, it.server))
        },
        DevProjectView::class to label@{
            if (it !is DevProjectView) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.tab.value, "tab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("dev-project", UrlProperties.encodeToString(it.projectId)),
                parameters = p
            ), listOf(it.tab))
        },
        SelectDevProjectScreen::class to label@{
            if (it !is SelectDevProjectScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("select-dev-project"),
                parameters = p
            ), listOf())
        },
        LandingScreen::class to label@{
            if (it !is LandingScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf(),
                parameters = p
            ), listOf())
        },
        ContractorWorkLists::class to label@{
            if (it !is ContractorWorkLists) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "tasks"),
                parameters = p
            ), listOf())
        },
        ClientWorkLists::class to label@{
            if (it !is ClientWorkLists) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "tasks"),
                parameters = p
            ), listOf())
        },
        PaymentSetup::class to label@{
            if (it !is PaymentSetup) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.next.value, "next", p)
            RouteRendered(UrlLikePath(
                segments = listOf("auth", "payment-setup"),
                parameters = p
            ), listOf(it.next))
        },
        ContractorRegistrationRouter::class to label@{
            if (it !is ContractorRegistrationRouter) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "register"),
                parameters = p
            ), listOf())
        },
        BasicInfoScreen::class to label@{
            if (it !is BasicInfoScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "register", "basic-info"),
                parameters = p
            ), listOf())
        },
        AuthenticateBasicInfoAndLogin::class to label@{
            if (it !is AuthenticateBasicInfoAndLogin) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "register", "authenticate", UrlProperties.encodeToString(it.token)),
                parameters = p
            ), listOf())
        },
        BusinessLicenseInfoScreen::class to label@{
            if (it !is BusinessLicenseInfoScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "register", "license"),
                parameters = p
            ), listOf())
        },
        VerificationAndOFACScreen::class to label@{
            if (it !is VerificationAndOFACScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "register", "OFAC"),
                parameters = p
            ), listOf())
        },
        LogInScreen::class to label@{
            if (it !is LogInScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.next.value, "next", p)
            RouteRendered(UrlLikePath(
                segments = listOf("auth", "log-in"),
                parameters = p
            ), listOf(it.next))
        },
        UserRegistration::class to label@{
            if (it !is UserRegistration) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.next.value, "next", p)
            RouteRendered(UrlLikePath(
                segments = listOf("auth", "user-registration"),
                parameters = p
            ), listOf(it.next))
        },
    ),
)
