package com.crowpay

import kotlinx.serialization.Serializable


@Serializable
enum class LicenseType {
    Business,
    Contractor,
}

@Serializable
enum class LineItemResolutionType {
    Cancelled, Completed
}

interface DisplayableState: HasDisplayName {
    val displayColor: String
    val priority: Int
}

// DO NOT REARRANGE THESE!!
@Serializable
enum class LineItemState(
    override val displayName: String,
    override val displayColor: String,
    override val priority: Int = 0
): DisplayableState {
    NotStarted(         displayName = "Not Started",          displayColor = "#626262"),
    InProgress(         displayName = "Working",              displayColor = "#f27c1c"),
    RequestingReview(   displayName = "Requesting Sign Off",  displayColor = "#7b22ed", priority = 1),
    NotApproved(        displayName = "Not Approved",         displayColor = "#f72700", priority = 2),
    ForceComplete(      displayName = "Force Complete",       displayColor = "#00ab4d", priority = 1),
    Issue(              displayName = "Issue",                displayColor = "#F72700", priority = 3),
    ResolveLater(       displayName = "Unresolved",           displayColor = "#626262"),
    OnHold(             displayName = "On Hold",              displayColor = "#626262"),
    Disputed(           displayName = "Disputed",             displayColor = "#f72700", priority = 4),
    Resolving(          displayName = "Resolving",            displayColor = "#7b22ed", priority = 4),
    WorkingOnRemedy(    displayName = "Fixing",               displayColor = "#f27c1c"),
    Resolved(           displayName = "Resolved",             displayColor = "#00ab4d"),
    Complete(           displayName = "Done",                 displayColor = "#00ab4d"),
    Cancelled(          displayName = "Removed",              displayColor = "#626262"),
    ;

    override fun toString(): String = displayName
    companion object
}

@Serializable
enum class IncidentState(
    override val displayName: String,
    override val displayColor: String,
    override val priority: Int = 0
): DisplayableState {
    Issue(              displayName = "Issue",               displayColor = "#F72700", priority = 2),
    Disputed(           displayName = "Disputed",            displayColor = "#F72700", priority = 3),
    Resolving(          displayName = "Resolving",           displayColor = "#7b22ed", priority = 3),
    WorkingOnResolution(displayName = "Fixing",              displayColor = "#f27c1c"),
    RequestingReview(   displayName = "Requesting Sign Off", displayColor = "#7b22ed", priority = 1),
    Resolved(           displayName = "Settled",             displayColor = "#00ab4d"),
    Filed(              displayName = "Reported",            displayColor = "#626262"),
}

// DO NOT REARRANGE THESE!!
@Serializable
enum class PaymentState {
    //    AwaitingFunds, // Payouts to contractors require money to be in the ledger. If there is not enough available we have to wait for a sweep to bring money in.
    AwaitingConfirmation, // The client as initialized an intent, or a line item is marked as complete and funds are available. We are waiting for the user to go through the transferUI for confirmation.
    Pending, // The transfer is initiated, and we are waiting on Plaid to handle the movement.
    Succeeded, // The transfer is complete.
    Cancelled, // The transfer was cancelled.
    Failed, // The confirmation or transfer failed for some reason.
//    Returned, // The transfer was returned after settled for some reason. Funds no longer available.
}

@Serializable
enum class PreferredTitle {
    Contractor,
    Provider,
    Vendor,
    Agency,
}


// DO NOT REARRANGE THESE!!
@Serializable
enum class ProjectState(
    override val displayName: String,
    override val displayColor: String,
    override val priority: Int = 0
): DisplayableState {
    Creating(             displayName = "Creating",                displayColor = "#8b3dff"),
    Invited(              displayName = "Invited",                 displayColor = "#8b3dff"),
    WaitingApproval(      displayName = "Pending Award",           displayColor = "#8b3dff"),
    Accepted(             displayName = "Awarded",                 displayColor = "#8b3dff"),
    AwaitingFunding(      displayName = "Funds Requested",         displayColor = "#8b3dff"),
    TransferringFunds(    displayName = "Transferring Funds",      displayColor = "#8b3dff"),
    PendingStart(         displayName = "Pending Start",           displayColor = "#f27c1c"),
    InProgress(           displayName = "In Progress",             displayColor = "#f27c1c"),
    Disputed(             displayName = "Disputed",                displayColor = "#f72700", priority = 3),
    SubstantialCompletion(displayName = "Substantial completion",  displayColor = "#f27c1c", priority = 2),
    RequestingComplete(   displayName = "Pending Complete",        displayColor = "#f27c1c", priority = 2),
    Locked(               displayName = "Locked",                  displayColor = "#f72700", priority = 4),
    Complete(             displayName = "Complete",                displayColor = "#00ab4d"),
    Warranty(             displayName = "Warranty",                displayColor = "#50c685"),
    Terminated(           displayName = "Terminated",              displayColor = "#f72700"),
    Cancelled(            displayName = "Cancelled",               displayColor = "#f72700"),
    Abandoned(            displayName = "Abandoned",               displayColor = "#f72700")
}

@Serializable
enum class MemberRole { Employee, Manager, Admin }

@Serializable
enum class UserRole { Customer, Admin, Developer, SuperUser }

@Serializable
enum class ContractorStatus { Registering, AwaitingApproval, Approved }

@Serializable
enum class ChangeType(val preTense: String, val postTense: String) {
    New("New", "New"),
    Modify("Modify", "Modified"),
    Remove("Remove", "Removed")
}

@Serializable
enum class State(val display: String, val abbreviated: String) {
    Alabama(            display = "Alabama",                abbreviated = "AL"),
    Alaska(             display = "Alaska",                 abbreviated = "AK"),

    //AmericanSamoa(      display = "American Samoa",         abbreviated = "AS"),
    Arizona(            display = "Arizona",                abbreviated = "AZ"),
    Arkansas(           display = "Arkansas",               abbreviated = "AR"),
    California(         display = "California",             abbreviated = "CA"),
    Colorado(           display = "Colorado",               abbreviated = "CO"),
    Connecticut(        display = "Connecticut",            abbreviated = "CT"),
    Delaware(           display = "Delaware",               abbreviated = "DE"),
    DistrictOfColumbia( display = "District of Columbia",   abbreviated = "DC"),
    Florida(            display = "Florida",                abbreviated = "FL"),
    Georgia(            display = "Georgia",                abbreviated = "GA"),

    //Guam(               display = "Guam",                   abbreviated = "GU"),
    Hawaii(             display = "Hawaii",                 abbreviated = "HI"),
    Idaho(              display = "Idaho",                  abbreviated = "ID"),
    Illinois(           display = "Illinois",               abbreviated = "IL"),
    Indiana(            display = "Indiana",                abbreviated = "IN"),
    Iowa(               display = "Iowa",                   abbreviated = "IA"),
    Kansas(             display = "Kansas",                 abbreviated = "KS"),
    Kentucky(           display = "Kentucky",               abbreviated = "KY"),
    Louisiana(          display = "Louisiana",              abbreviated = "LA"),
    Maine(              display = "Maine",                  abbreviated = "ME"),
    Maryland(           display = "Maryland",               abbreviated = "MD"),
    Massachusetts(      display = "Massachusetts",          abbreviated = "MA"),
    Michigan(           display = "Michigan",               abbreviated = "MI"),
    Minnesota(          display = "Minnesota",              abbreviated = "MN"),
    Mississippi(        display = "Mississippi",            abbreviated = "MS"),
    Missouri(           display = "Missouri",               abbreviated = "MO"),
    Montana(            display = "Montana",                abbreviated = "MT"),
    Nebraska(           display = "Nebraska",               abbreviated = "NE"),
    Nevada(             display = "Nevada",                 abbreviated = "NV"),
    NewHampshire(       display = "New Hampshire",          abbreviated = "NH"),
    NewJersey(          display = "New Jersey",             abbreviated = "NJ"),
    NewMexico(          display = "New Mexico",             abbreviated = "NM"),
    NewYork(            display = "NewYork",                abbreviated = "NY"),
    NorthCarolina(      display = "North Carolina",         abbreviated = "NC"),
    NorthDakota(        display = "North Dakota",           abbreviated = "ND"),

    //NorthernMarianaIslands(display = "Northern Mariana Islands", abbreviated = "MP"),
    Ohio(               display = "Ohio",                   abbreviated = "OH"),
    Oklahoma(           display = "Oklahoma",               abbreviated = "OK"),
    Oregon(             display = "Oregon",                 abbreviated = "OR"),
    Pennsylvania(       display = "Pennsylvania",           abbreviated = "PA"),

    //PuertoRico(         display = "Puerto",                 abbreviated = "PR"),
    RhodeIsland(        display = "Rhode Island",           abbreviated = "RI"),
    SouthCarolina(      display = "South Carolina",         abbreviated = "SC"),
    SouthDakota(        display = "South Dakota",           abbreviated = "SD"),
    Tennessee(          display = "Tennessee",              abbreviated = "TN"),
    Texas(              display = "Texas",                  abbreviated = "TX"),

    //TrustTerritories(   display = "Trust Territories",      abbreviated = "TT"),
    Utah(               display = "Utah",                   abbreviated = "UT"),
    Vermont(            display = "Vermont",                abbreviated = "VT"),

    //VirginIslands(      display = "Virgin Islands",       abbreviated = "VI"),
    Virginia(           display = "Virginia",               abbreviated = "VA"),
    Washington(         display = "Washington",             abbreviated = "WA"),
    WestVirginia(       display = "West Virginia",          abbreviated = "WV"),
    Wisconsin(          display = "Wisconsin",              abbreviated = "WI"),
    Wyoming(            display = "Wyoming",                abbreviated = "WY"),
}

enum class ModifyTimer {
    Resume,
    ResumeOrInsert,
    Pause,
    Cancel
}