package com.crowpay.views.components.project.work.changeOrders.tables

import com.crowpay.*
import com.crowpay.utils.JumpTo
import com.crowpay.views.components.project.work.changeOrders.ChangeRequestItemWrapper
import com.crowpay.views.components.project.work.changeOrders.cancelled
import com.crowpay.views.components.expandAction
import com.crowpay.views.components.files.renderFiles
import com.crowpay.views.components.keyValue
import com.crowpay.views.components.label2
import com.crowpay.views.components.sectionIndentCol
import com.crowpay.views.theming.expandButtonTheme
import com.crowpay.views.theming.greySeparator
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.ImmediateReadable
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*

private fun ViewWriter.lineItemInterfaceDetails(lineItem: LineItemInterface, changeInfo: ChangeRequestItem) {
    padded - sectionIndentCol {
        keyValue("Description", lineItem.description)
        label2("Files") { renderFiles(Constant(lineItem.files), 10.rem) }
    }
}

private fun ViewWriter.pendingItemChangeDetails(itemChange: ItemChangeInterface, changeInfo: ChangeRequestItem) {
    padded - sectionIndentCol {
        keyValue("Updated Description", itemChange.updatedDescription ?: "N/A")
    }
}

fun ViewWriter.renderChangeRequestItemDetails(
    item: ChangeRequestItem
) {
    when (item) {
        is ChangeRequestItemWrapper -> renderChangeRequestItemDetails(item.wrapped)
        is LineItemInterface -> lineItemInterfaceDetails(item, item)
        is ItemChangeInterface -> pendingItemChangeDetails(item, item)
        else -> throw IllegalArgumentException("Unsupported ChangeRequestType")
    }
}

fun ViewWriter.changeRequestItemButton(
    item: ChangeRequestItem,
    tableRow: RView.(expanded: Property<Boolean>)->Unit
) {
    val expanded = Property(false)
    col {
        spacing = 0.px

        if (item.cancelled)
            sizeConstraints(height = 2.5.rem) - tableRow(expanded)
        else
            sizeConstraints(height = 2.5.rem) - expandButtonTheme - button {
                spacing = 0.px
                tableRow(expanded)
                action = expandAction(expanded)
            }

        onlyWhen { expanded() } - renderChangeRequestItemDetails(item)
        greySeparator()
    }
}

