package com.crowpay.views.screens.auth

import com.crowpay.RegisterUser
import com.crowpay.User
import com.crowpay.sdk.notNullSession
import com.crowpay.sdk.selectedApi
import com.crowpay.sdk.sessionToken
import com.crowpay.utils.RestartableProcess
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.field2
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.ScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import kotlinx.coroutines.delay

class EmailProof(
    var token: String,
    val email: ReactiveContext.()->String,
    val onDifferentEmail: suspend ()->Unit,
    val withProof: suspend (Proof) -> Unit
) : Screen, Validator() {
    val pinChars = ('A'..'Z').toList() - setOf('I', 'O')
    val passcode = Property("").validate { str -> (str.length == 6) and (str.all { it in pinChars }) }
    lateinit var internalNav: ScreenNavigator

    val login = Action(
        title = "Log In",
        icon = Icon.login
    ) {
        if (!passcode.valid()) throw IllegalStateException("Enter Viable Passcode")
        val api = selectedApi().api
        val proof = api.emailProof.proveEmailOwnership(
            FinishProof(
                token,
                passcode()
            )
        )
        withProof(proof)
    }

    val confirmation = Property(false)
    val cooldown = RestartableProcess {
        var count = 10
        while (count > 0) {
            emit(count--)
            if (count < 7) confirmation.value = false
            delay(1000)
        }
        emit(null)
    }

    override fun ViewWriter.render() {
        sizeConstraints(width = 30.rem) - col {
            internalNav = screenNavigator
            xlTitle("Confirm Email")

            body {
                ::content { "An email was just sent to ${email()}. Enter the passcode below to confirm your email and finish registering." }
            }

            field2("Passcode") {
                textInput {
                    hint = "Passcode"
                    keyboardHints = KeyboardHints.id
                    content bind passcode

                    action = login
                }
            }

            primaryButton - button {
                ::enabled { allValid() }
                body {
                    align = Align.Center
                    content = "Submit"
                }
                action = login
            }

            centered - col {
                centered - textButton - button {
                    ::enabled { cooldown() == null }
                    smallBody {
                        ::content {
                            if (confirmation()) "Email sent!"
                            else cooldown()
                                ?.let { "Can resend code in $it" }
                                ?: "Send me a new code"
                        }
                    }
                    onClick("Send Code") {
                        token = selectedApi().api.userAuth.sendRegistrationEmail(email())
                        confirmation.value = true
                        cooldown.restart()
                    }
                }
                centered - textButton - button {
                    smallBody("Use a different email")
                    onClick("Use different email") { onDifferentEmail() }
                }
            }
        }
    }
}