package com.crowpay.views.components

import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.theming.body
import com.crowpay.views.theming.bodyBold
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.bold
import com.lightningkite.kiteui.views.direct.TextView
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.kiteui.views.expanding


fun ViewWriter.keyValue(key: String, value: String?) {
    row {
        spacing = 1.rem
        exists = value != null
        bodyBold("$key:")
        expanding - body {
            content = value ?: ""
        }
    }
}


fun ViewWriter.keyValue(key: String, value: ReactiveContext.() -> String?) {
    row {
        spacing = 0.5.rem
        existsDefaultFalse { value() != null }
        bodyBold {
            content = "$key: "
        }
        expanding - body {
            ::content { value() ?: "" }
        }
    }
}

fun ViewWriter.keyValue(key: Readable<String>, value: ReactiveContext.() -> String?) {
    row {
        bodyBold {
            ::content{ "${key()}:" }
        }
        expanding - body {
            ::content { value() ?: "" }
        }
    }
}

fun TextView.inlineKeyValue(key: String, value: String) = setBasicHtmlContent("<strong>$key:</strong> $value")