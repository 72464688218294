package com.crowpay.views.components.project.projectInfo

import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.project.activity.support
import com.crowpay.views.components.project.tabSectionHeader
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.projectInfoTabs
import com.crowpay.views.theming.subTitle
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.bold
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding

fun ProjectView.projectInfoBar(writer: ViewWriter) = with(writer) {
    col {
        existsDefaultFalse { !anonymous() }
        tabSectionHeader("Overview", selectedInfoTab)
        projectInfoTabs - row {
            val availableTabs =
                if (lens == ProjectLens.Preview) setOf(
                    ProjectView.InfoSection.Details,
                    ProjectView.InfoSection.Contacts
                ) else ProjectView.InfoSection.entries
            for (tab in availableTabs) {
                expanding - buttonTheme - radioToggleButton {
                    checked bind selectedInfoTab.equalTo(tab)
                    subTitle {
                        content = tab.displayName
                        align = Align.Center
                    }
                }
            }
        }
        sizeConstraints(height = AppDimensions.projectInfoTabsSize) - scrolls - padded - swapView {
            spacing = AppDimensions.tabSectionPadding
            swapping(
                current = { selectedInfoTab() },
                views = { tab ->
                    when (tab) {
                        ProjectView.InfoSection.Ledger -> ledger(this)
                        ProjectView.InfoSection.Details -> projectDetails(this)
                        ProjectView.InfoSection.Contacts -> contacts(this)
                    }
                }
            )
        }
    }
}