package com.crowpay.views.components.project.work.scope

import com.crowpay.actuals.AppDimensions
import com.crowpay.LineItem
import com.crowpay.actuals.rotation
import com.crowpay.extensions.toFileData
import com.crowpay.extensions.withSpacing
import com.crowpay.sdk.currentSession
import com.crowpay.sdk.notNullSession
import com.crowpay.trash
import com.crowpay.utils.*
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.files.FilePreview
import com.crowpay.views.components.LineItemForm
import com.crowpay.views.components.expandAction
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.files.resolveProjectAttachments
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningserver.files.ServerFile


class EditLineItem(
    ogLine: LineItem?,
    projectId: UUID,
    val allowSave: Readable<Boolean> = Constant(true),
    val onRemove: suspend ViewWriter.(UUID) -> Unit,
    val reorder: (suspend (UUID, ReorderDirection) -> Unit)? = null,
    val canMove: (ReactiveContext.(UUID)->Set<ReorderDirection>)? = null,
    val onSave: suspend (LineItem) -> Unit = {},
) : Screen {
    private val line = ogLine ?: LineItem(project = projectId)
    val id = line._id
    var savedToDB = Property(ogLine != null)
    val draft = Draft(line)
    val files = Property(ogLine?.files?.toFileData() ?: emptyList())
    val expanded = Property(false)
    val expandedDebounced = shared {
        expanded.debounce(200)()
    }
    val form = LineItemForm(draft, files)
    val validator: Validator get() = form

    val save = Action("Save") {
        val session = currentSession() ?: return@Action

        onSave(draft())

        if (savedToDB.value) {
            val filesChanged =
                files.value.size != draft.awaitOnce().files.size ||
                        files.value.any {
                            it.preview is FilePreview.LocalFile ||
                                    it.name.value != (it.original as? FilePreview.RemoteAttachment)?.attachment?.name
                        }
            if (!draft.changesMade.awaitOnce() && !filesChanged) return@Action
            val current = draft.awaitOnce()

            val newFiles = files.awaitOnce().resolveProjectAttachments()
            val newFilesSimplified = newFiles.map {
                it.copy(
                    file = ServerFile(it.file.location.substringBefore('?')),
                    preview = it.preview?.let {
                        ServerFile(
                            it.location.substringBefore('?')
                        )
                    }
                )
            }
            val oldFiles = current.files.map {
                it.copy(
                    file = ServerFile(it.file.location.substringBefore('?')),
                    preview = it.preview?.let {
                        ServerFile(
                            it.location.substringBefore('?')
                        )
                    }
                )
            }

            if (oldFiles != newFilesSimplified) {
                draft.modify {
                    it.copy(files = newFiles)
                }
            }

            val result = draft.pushChanges(notNullSession().lineItems)
            files.set(result.files.toFileData())
        } else {
            println("Inserting line item")
            val current = draft.awaitOnce()
            val newFiles = files.awaitOnce().resolveProjectAttachments()

            val result = session.lineItems.insert(
                current.copy(files = newFiles)/*.also {
                    println(Json.encodeToString(LineItem.serializer(), it))
                }*/
            ).await()!!
            draft.published.set(result)
            draft.cancel()
            files.set(result.files.toFileData())
            savedToDB.set(true)
        }
        expanded.set(false)
    }

    suspend fun ViewWriter.remove() {

        val (header, removeMessage) = if (savedToDB.await()) {
            "Delete Work Item?" to "Deleting a work item can not be undone. Are you sure you want to delete this Work Item?"
        } else {
            "Discard Work Item?" to "Discarding unsaved work cannot be undone. Are you sure you want to discard this unsaved Work Item?"
        }
        dialogScreenNavigator.navigate(
            GenericConfirmationDialog(
                header = header,
                message = removeMessage,
                question = null,
                confirmationText = header.trim('?'),
                declineText = "Go Back",
                messageType = MessageType.Danger,
                onSubmit = { confirmed ->
                    if (confirmed) {
                        val id = draft()._id
                        if (savedToDB.await())
                            notNullSession().nonCached.lineItem.delete(id)
                        onRemove(id)
                    }
                }
            ))
    }

    override fun ViewWriter.render() {

        col {
            spacing = 0.px
            reactive {
                if (expandedDebounced()) scrollIntoView(Align.Center, Align.Start, false)
            }
            withSpacing(0.dp) - row {
                expanding - expandButtonTheme - button {
                    spacing = 0.dp
                    row {
                        centered - expandIcon(expanded)
                        centered - body {
                            ::content{ form.name().takeUnless { it.isBlank() } ?: "New Work Item" }
                        }
                    }
                    action = expandAction(expanded)
                }
                onlyWhen { savedToDB() } - buttonTheme - button {
                    sizeConstraints(width = 2.rem, height = 2.rem) - icon(Icon.trash, "Remove Work Item")
                    onClick { remove() }
                }
                row {
                    exists = reorder != null
                    greySeparator()
                    centered - buttonTheme - button {
                        canMove?.let {
                            ::enabled { it(id).contains(ReorderDirection.UP) }
                        }
                        icon(Icon.chevronRight, "Move item up 1") {
                            rotation = (-90).degrees
                        }
                        onClick {
                            reorder?.invoke(draft()._id, ReorderDirection.UP)
                        }
                    }
                    centered - buttonTheme - button {
                        canMove?.let {
                            ::enabled { it(id).contains(ReorderDirection.DOWN) }
                        }
                        icon(Icon.chevronRight, "Move item down 1") {
                            rotation = 90.degrees
                        }
                        onClick {
                            reorder?.invoke(draft()._id, ReorderDirection.DOWN)
                        }
                    }
                }
            }
            onlyWhen { expanded() } - col {
                spacing = 0.dp
                space(AppDimensions.backgroundIndent)
                row {
                    spacing = 0.dp
                    space(AppDimensions.fullIndent)
                    expanding - col {
                        form.render(this)
                        row {
                            spacing = AppDimensions.buttonRowSpacing
                            expanding - space()
                            textButton - button {
                                specCenteredText("Discard")
                                onClick {
                                    if (savedToDB.await()) {
                                        draft.cancel()
                                        files.set(draft.awaitOnce().files.toFileData())
                                        expanded.set(false)
                                    } else {
                                        remove()
                                    }
                                }
                            }
                            primaryButton - gravity(Align.End, Align.Center) - button {
                                ::enabled{ validator.allValid() }
                                specCenteredText("Save")
                                action = save
                            }
                        }
                    }
                    space(AppDimensions.fullIndent)
                }
                space(AppDimensions.backgroundIndent)
            }
        }
    }
}