package com.crowpay.views.components.project.activity.concerns

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.incidentResponse.IncidentFlowPermissions
import com.crowpay.incidentResponse.IncidentResponseType
import com.crowpay.incidentResponse.ClientConcern
import com.crowpay.incidentResponse.serializable
import com.crowpay.sdk.notFoundError
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.*
import com.crowpay.views.components.files.*
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.screens.common.DialogWithDialog
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.secondDialog
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.forms.FormModule
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.delay

class IncidentReportForm private constructor(
    val project: Project,
    val incident: Incident?,
    val messages: List<IncidentMessage>,
    val client: Client,
    val contractor: Contractor,
    val isClient: Boolean
): DialogWithDialog, Validator() {
    companion object {
        suspend fun createDialog(
            project: Project,
            incident: Incident?,
            messages: List<IncidentMessage>,
            lens: ProjectLens
        ): IncidentReportForm {
            val session = notNullSession()
            val contractor = session.contractors[project.contractor]() ?: notFoundError<Contractor>()
            val client = session.clients[project.client ?: throw IllegalStateException("Project has no client")]() ?: notFoundError<Client>()

            return IncidentReportForm(
                project,
                incident,
                messages.filter { it.incident == incident?._id }.sortedBy { it.created },
                client,
                contractor,
                isClient = when (lens) {
                    ProjectLens.Contractor -> false
                    ProjectLens.Customer -> true
                    ProjectLens.Preview -> true
                }
            )
        }
    }

    val replies =
        if (incident == null) Constant(emptyList())
        else shared {
            notNullSession().incidentMessageComments.query(
                Query(
                    limit = Int.MAX_VALUE,
                    condition = condition { it.incident eq incident._id },
                    orderBy = sort {
                        it.created.ascending()
                    }
                )
            )()
        }

    val previousMessage = messages.lastOrNull()
    val permissions = IncidentFlowPermissions(project, incident, messages, isClient)

    val proposingResolution = Property(false)

    val response = Property<IncidentResponseType?>(
        if (isClient) {
            if (incident == null) IncidentResponseType.FileIncident(ClientConcern.Other)
            else IncidentResponseType.ClientResponse()
        }
        else if (!isClient && permissions.mustRequestReview)
            IncidentResponseType.Remedy.RemedyHandled(
                messages.asReversed().firstNotNullOf { it.type as? IncidentResponseType.Remedy }
            )
        else null
    )
    val clientConcern = Property<ClientConcern?>(null).interceptWrite {
        if (it == null || value == it) return@interceptWrite
        response.value = IncidentResponseType.FileIncident(it, raiseIssue.value)
        value = it
    }
    val raiseIssue = Property(false)
    val fileDispute = Property(false)

    val minChars = 50
    val message = Property("").validate { it.length > minChars }

    val files = SignalingList<FileData>()

    val maxCredit = project.remainingFunds
    val credit = Property<Double?>(null)

    val creditCondition = addCondition {
        when (response()) {
            is IncidentResponseType.Credit -> credit().let {
                it != null && it > 0 && it <= maxCredit
            }
            is IncidentResponseType.Remedy -> credit().let {
                it == null || (it > 0 && it <= maxCredit)
            }
            else -> true
        }
    }

    enum class IncidentFormState(val displayName: String, val theme: ThemeDerivation) {
        Filing("REPORT INCIDENT", DarkSection),
        Filed("INCIDENT", DarkSection),
        RaiseIssue("RAISE ISSUE", WarningSemantic),
        Issue("ISSUE", WarningSemantic),
        FilingDispute("FILE A DISPUTE", DangerSemantic),
        Dispute("DISPUTE", DangerSemantic),
        Resolving("RESOLVING", PurpleSection),
        Resolved("RESOLVED", GreenSection)
    }

    private val formState = shared {
        when {
            raiseIssue() -> IncidentFormState.RaiseIssue
            incident == null -> IncidentFormState.Filing
            fileDispute() -> IncidentFormState.FilingDispute
            else -> when (incident.state) {
                IncidentState.Filed -> IncidentFormState.Filed
                IncidentState.Issue -> IncidentFormState.Issue
                IncidentState.Disputed -> IncidentFormState.Dispute
                IncidentState.Resolving -> IncidentFormState.Resolving
                IncidentState.WorkingOnResolution -> IncidentFormState.Resolving
                IncidentState.RequestingReview ->
                    if (permissions.disputeWasFiled) IncidentFormState.Dispute
                    else if (incident.issue != null) IncidentFormState.Issue
                    else IncidentFormState.Filed
                IncidentState.Resolved -> IncidentFormState.Resolved
            }
        }
    }

    suspend fun sendResponse() {
        val session = notNullSession()
        val sent = session.nonCached.incidentMessage.sendMessage(
            IncidentMessage(
                incident = incident?._id ?: UUID.random(),
                project = project._id,
                clientMessage = isClient,
                typeSerializable = when (val r = response()) {
                    null -> return
                    is IncidentResponseType.Accept ->
                        r.copy(accessInfo = session.currentAccess())
                    is IncidentResponseType.FileIncident ->
                        r.copy(raiseIssue = raiseIssue.value)
                    is IncidentResponseType.ClientResponse ->
                        r.copy(fileDispute = fileDispute.value)
                    is IncidentResponseType.Credit ->
                        r.copy(credit = credit()?.toLong() ?: return)
                    is IncidentResponseType.Remedy ->
                        r.copy(credit = credit()?.toLong())
                    else -> r
                }.serializable(),
                message = message(),
                attachments = files.resolveProjectAttachments()
            )
        )
        session.incidentMessages.localInsert(sent)
        delay(50)
        session.projects.totallyInvalidate()
        session.incidents.totallyInvalidate()
        if (fileDispute.value) session.lineItems.totallyInvalidate()
    }

    private val outerPadding = AppDimensions.fullIndent
    override fun ViewWriter.renderPage(): ViewModifiable = scrolls - col {
        spacing = AppDimensions.fullIndent
        space()
        sizeConstraints(width = AppDimensions.pageWidth) - atTopCenter - dialog - col {
            spacing = 0.px
            row {
                spacing = 0.px
                expanding - compact - xlTitle {
                    dynamicTheme { formState().theme }
                    align = Align.Center
                    ::content { formState().displayName }
                }
                sizeConstraints(width = 3.25.rem) - secondaryButton - button {
                    centered - icon(Icon.close, "Close Form")
                    onClick { dialogScreenNavigator.dismiss() }
                }
            }

            row {
                spacing = 0.px
                space(outerPadding)
                sizeConstraints(height = 5.rem) - expanding - SubtitleSemantic.onNext - row {
                    expanding - compact - row {
                        centered - text("Concern:")
                        centered - text {
                            ::content { incident?.concern?.displayName ?: clientConcern()?.displayName ?: "" }
                        }
                    }
                    centered - sizeConstraints(width = 7.rem) - compact - col {
                        spacing = 2.dp
                        text {
                            align = Align.Center
                            content = (incident?.filed?.timestamp ?: now()).format(Formats.mmddyyyy)
                        }
                        text {
                            align = Align.Center
                            content = "12:34:56"
                        }
                    }
                }
                space(outerPadding)
            }

            separator()

            row {
                spacing = 0.px
                space(outerPadding)
                expanding - col {
                    space(0.2)
                    spacing = AppDimensions.majorColumnSpacing

                    notificationBar(AppColors.blue) - body {
                        content = "Informative (FYI) Bar"
                    }

                    if (messages.isNotEmpty()) col {
                        bodyBold("Previous Messages")
                        previousMessages()
                    }

                    greySeparator()

                    if (incident?.resolved == null) {
                        responseForm()

                        space(0.5)

                        actionBar()
                    }
                }
                space(outerPadding)
            }

            space(outerPadding)
        }

        space(2.0)
    }

    private fun ViewWriter.fileConcernSelector() {
        col {
            requiredLabel("Response Type") {
                reactive {
                    clearChildren()
                    if (!proposingResolution()) col {
                        if (permissions.canRaiseIssue) row {
                            compact - row {
                                centered - checkbox {
                                    checked bind raiseIssue
                                }
                                centered - body("Raise Issue")
                            }
                            space(2.0)
                            secondaryButton - button {
                                specCenteredText("More Info")
                                onClick {
                                    secondDialog.value = GenericDialog(
                                        "More info for incident raise issue dialog",
                                        dismiss = { dismissSecondDialog() }
                                    )
                                }
                            }
                        }
                        fieldTheme - select {
                            bind(
                                clientConcern,
                                data = Constant(listOf(null) + ClientConcern.entries)
                            ) { it?.displayName ?: "Select Response" }
                        }
                    }
                    else resolutionSelector()
                }
            }
            notificationBar(AppColors.yellow) - body {
                content = "Informative (FYI) Bar"
            }
        }
    }

    private enum class ResolutionType(val displayName: String, val hasCredit: Boolean = false) {
        Handled("Handled"), Resolution("Remedy Plan", true), Credit("Credit", true), MoreInfo("More Info")
    }
    private fun ViewWriter.resolutionSelector() {
        val resolution = Property<ResolutionType?>(null).interceptWrite {
            if (it == value) return@interceptWrite
            response.value = when (it) {
                ResolutionType.Handled -> IncidentResponseType.Handled
                ResolutionType.Resolution -> IncidentResponseType.Remedy(null)
                ResolutionType.Credit -> IncidentResponseType.Credit(0)
                ResolutionType.MoreInfo -> IncidentResponseType.MoreInfo
                null -> null
            }
            value = it
        }

        col {
            requiredField("Response Type") {
                val options =
                    if (isClient) listOf(ResolutionType.Resolution, ResolutionType.Credit)
                    else if (permissions.mustRequestReview) listOf(ResolutionType.Handled)
                    else ResolutionType.entries

                select {
                    bind(
                        resolution,
                        data = Constant(listOf(null) + options)
                    ) { it?.displayName ?: "Select Resolution" }
                }
            }
            onlyWhen { resolution()?.hasCredit ?: false } - row {
                centered - row {
                    spacing = 0.px
                    centered - body("Credit:")
                    atTopStart - colored(AppColors.red.main) - bodyBold {
                        content = "*"
                        ::exists { resolution() == ResolutionType.Credit }
                    }
                }
                centered - compact - fieldTheme - validate(creditCondition) - row {
                    centered - body("$")
                    centered - compact - numberInput {
                        keyboardHints = KeyboardHints.integer
                        range = 0.0..Double.MAX_VALUE
                        content bind credit
                        hint = "Max ${maxCredit.renderDollars()}"
                    }
                }
            }
        }
    }

    private fun ViewWriter.responseForm() {
        if (incident?.state == IncidentState.Resolved) return

        if (permissions.awaitingResponse) col {
            if (isClient) {
                subTitle(if (incident == null) "Report To Contractor" else "Respond to Contractor")

                if (incident == null) fileConcernSelector()
                else if (permissions.canFileDispute) row {
                    centered - checkbox {
                        checked bind fileDispute
                    }
                    centered - body("File Dispute")
                    space(2.0)
                    secondaryButton - button {
                        specCenteredText("More Info")
                        onClick {
                            secondDialog.value = GenericDialog(
                                "More info for incident file dispute dialog",
                                dismiss = { dismissSecondDialog() }
                            )
                        }
                    }
                }
            }
            else if (!permissions.mustRequestReview) {
                subTitle("Propose Resolution to Client")

                resolutionSelector()
            }

            col {
                spacing = 0.px
                requiredField({
                    if (incident == null) "Elaborate on Concern"
                    else if (!isClient) "Explain Resolution Selection"
                    else "Explain Response"
                }) {
                    sizeConstraints(minHeight = 10.rem) - textArea {
                        hint = "Minimum $minChars characters"
                        content bind message
                    }
                }
                atEnd - smallBody {
                    ::content {
                        val remaining = minChars - message().length
                        if (remaining <= 0) ""
                        else "($remaining) more characters required"
                    }
                }
            }

            label2("Attachments") {
                existsDefaultFalse { files().isNotEmpty() }
                fileCarousel(files)
            }
        }
        else sizeConstraints(height = 8.rem) - stack {
            subTitle("Waiting on response")
        }
    }

    private fun ViewWriter.previousMessages() {
        fun ViewWriter.formattedBody(text: String) = body {
            wraps = false
            content = text
        }
        fun ViewWriter.setWidth(dimension: Dimension) = sizeConstraints(minWidth = dimension)

        col {
            spacing = 3.dp
            val dateWidth = 5.rem
            val statusWidth = 10.rem
            val responseWidth = 8.rem

            row {
                space(0.05)
                setWidth(dateWidth) - body("Date")
                setWidth(statusWidth) - formattedBody("Status")
                setWidth(responseWidth) - formattedBody("Response Type")
            }
            col {
                for (message in messages) {

                    val messageReplies = replies.shareFilter { it.incidentMessage == message._id }
                    val viewReplies = Property(false)

                    val newReply = Property<String?>(null)

                    val senderName = if (message.clientMessage) client.name else contractor.name

                    col {
                        spacing = AppDimensions.expandButtonSpace

                        lightSection - col {
                            row {
                                setWidth(dateWidth) - body(message.created.format(Formats.mmddyyyy))
                                val baseState =
                                    if (
                                        messages
                                            .filter { it.created < message.created }
                                            .any {
                                                it.clientMessage && (it.type as? IncidentResponseType.ClientResponse)?.fileDispute == true
                                            }
                                    ) IncidentState.Disputed
                                    else if (incident?.issue.let { it != null && it.raised.timestamp < message.created }) IncidentState.Issue
                                    else IncidentState.Filed

                                val state = message.type.resultantState(baseState)
                                setWidth(statusWidth) - IncidentStateSemantic(state).onNext - formattedBody(state.displayName)
                                setWidth(responseWidth) - formattedBody(message.type.displayName)
                            }

                            row {
                                atTopStart - body("$senderName:")
                                expanding - body {
                                    content = message.message
                                    wraps = true
                                }
                            }

                            if (message.attachments.isNotEmpty()) label2("Attachments") {
                                renderFiles(Constant(message.attachments), 5.rem)
                            }
                        }

                        compact - row {
                            expanding - SmallBodySemantic.onNext - leftExpandButton(viewReplies) {
                                centered - text {
                                    ::content {
                                        messageReplies().size.let {
                                            when (it) {
                                                0 -> "No Replies"
                                                1 -> "1 Reply"
                                                else -> "$it Replies"
                                            }
                                        }
                                    }
                                }
                            }
                            textButton - button {
                                ::exists { messageReplies().isEmpty() }
                                smallBody("Reply")
                                onClick {
                                    viewReplies.value = true
                                    newReply.value = ""
                                }
                            }
                        }

                        onlyWhen { viewReplies() } - indentedCol(Indent.Section.before, 0.px) {
                            col {
                                spacing = theme.spacing*1.4
                                forEachUpdating(messageReplies) { reply ->
                                    compact - col {
                                        spacing = AppDimensions.cornerRadii
                                        row {
                                            centered - body {
                                                ::content { if (reply().clientMessage) client.name else contractor.name }
                                            }
                                            centered - smallBody {
                                                ::content { reply().created.format(Formats.mmddyyyy_hmm) }
                                            }
                                        }
                                        lightSection - stack {
                                            col {
                                                spacing = 0.px
                                                body {
                                                    ::content { reply().message }
                                                }
                                                atEnd - textButton - button {
                                                    spacing = 2.dp
                                                    existsDefaultFalse { messageReplies().lastOrNull()?._id == reply()._id }
                                                    smallBody("Reply")
                                                    onClick {
                                                        newReply.value = ""
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            onlyWhen { newReply() != null } - col {
                                space(0.1)
                                field2("New Reply") {
                                    sizeConstraints(height = 8.rem) - textArea {
                                        content bind newReply.lens(
                                            get = { it ?: "" },
                                            set = { it }
                                        )
                                    }
                                }
                                atEnd - row {
                                    secondaryButton - button {
                                        specCenteredText("Discard")
                                        onClick { newReply.value = null }
                                    }
                                    primaryButton - button {
                                        specCenteredText("Send Reply")
                                        onClick {
                                            val session = notNullSession()
                                            session.incidentMessageComments.insert(
                                                IncidentMessageComment(
                                                    incidentMessage = message._id,
                                                    project = project._id,
                                                    incident = incident?._id ?: return@onClick,
                                                    sender = session.userId,
                                                    message = newReply.value ?: return@onClick,
                                                    clientMessage = isClient
                                                )
                                            )
                                            newReply.value = null
                                        }
                                    }
                                }
                            }

                            space(0.25)
                        }
                    }
                }
            }
        }
    }

    private fun FormAction(
        name: String,
        displayName: (ReactiveContext.()->String)? = null,
        theme: ThemeDerivation = PrimaryButtonSemantic,
        requiresValidMessage: Boolean = true,
        action: suspend ()->Unit
    ) = ButtonAction(
        name,
        theme,
        displayName,
        enabled = if (requiresValidMessage) { { allValid() } } else null,
        action = action
    )

    private fun ViewWriter.acceptAction(name: String, proposal: IncidentResponseType.NeedsApproval) =
        FormAction(
            name,
            requiresValidMessage = false
        ) {
            val text: Triple<String, String, String> = when (proposal) {
                IncidentResponseType.Handled -> Triple(
                    "Accept Handled?",
                    "This will mark the incident as resolved.",
                    "Accept Handled"
                )
                is IncidentResponseType.Credit -> Triple(
                    "Accept Credit?",
                    "This will add ${proposal.credit.renderDollars()} of credit to the project and mark the incident as resolved.",
                    "Accept Credit"
                )
                is IncidentResponseType.Remedy -> Triple(
                    "Accept Remedy Plan?",
                    "This will initiate work on the resolution plan. When the contractor feels the work is complete they will sign off.",
                    "Accept Remedy"
                )
                is IncidentResponseType.Remedy.RemedyHandled -> Triple(
                    "Accept Remedy Handled?",
                    if (proposal.remedy.credit != null) "This will apply ${proposal.remedy.credit.renderDollars()} of credit to the project and resolve the incident"
                    else "This will resolve the incident",
                    "Accept Handled"
                )
            }

            secondDialog.value = GenericConfirmationDialog(
                header = text.first,
                message = text.second,
                confirmationText = text.third,
                dismiss = { dismissSecondDialog() }
            ) {
                if (it) {
                    val session = notNullSession()
                    response.value = IncidentResponseType.Accept(proposal, session.currentAccess())
                    sendResponse()
                    dialogScreenNavigator.dismiss()
                }
            }
        }

    private fun ViewWriter.sendResponseConfirmation(
        header: String,
        message: String,
        confirmationText: String
    ) {
        secondDialog.value = GenericConfirmationDialog(
            header,
            message,
            confirmationText = confirmationText,
            dismiss = { dismissSecondDialog() }
        ) {
            if (it) {
                sendResponse()
                dialogScreenNavigator.dismiss()
            }
        }
    }

    private fun ViewWriter.actionBar() {
        val primaryAction = when {
            incident == null -> FormAction("Report Incident", theme = DangerButtonSemantic) {
                sendResponseConfirmation(
                    "Report Incident?",
                    "This will file your incident as a formal complaint for the contractor to see.",
                    "Report Incident"
                )
            }
            isClient && permissions.canAccept -> when (val p = permissions.lastProposedResolution) {
                is IncidentResponseType.Handled -> acceptAction("Accept Handled", p)
                is IncidentResponseType.Credit -> acceptAction("Accept Credit", p)
                is IncidentResponseType.Remedy -> acceptAction("Accept Remedy", p)
                is IncidentResponseType.Remedy.RemedyHandled -> acceptAction("Approve Work", p)
                else -> null
            }
            isClient && previousMessage?.type == IncidentResponseType.MoreInfo -> FormAction("Provide Info", theme = PrimaryButtonSemantic) {
                sendResponseConfirmation(
                    "Provide Info?",
                    "Provide info confirmation text.",
                    "Provide Info"
                )
            }
            !isClient && permissions.mustRequestReview -> FormAction("Request Review") {
                sendResponseConfirmation(
                    "Request Review?",
                    "Request review confirmation text.",
                    "Request Review"
                )
            }
            !isClient && permissions.awaitingResponse -> {
                val actionName: ReactiveContext.()->String = {
                    when (response()) {
                        IncidentResponseType.MoreInfo -> "Request More Info"
                        is IncidentResponseType.Credit -> "Submit Credit"
                        IncidentResponseType.Handled -> "Submit Handled"
                        is IncidentResponseType.Remedy -> "Submit Remedy"
                        else -> "Select Response"
                    }
                }
                FormAction(
                    "Submit Response",
                    displayName = actionName
                ) {
                    val a = actionName()
                    secondDialog.value = GenericConfirmationDialog(
                        a,
                        "$a confirmation text.",
                        confirmationText = a,
                        dismiss = { dismissSecondDialog() }
                    ) {
                        if (it) {
                            sendResponse()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
            }
            else -> null
        }
        val secondaryAction = shared {
            when {
                fileDispute() -> FormAction("File Dispute", theme = DangerButtonSemantic) {
                    sendResponseConfirmation(
                        "File Dispute?",
                        "This will file a dispute on the project, putting line items on hold until resolved.",
                        confirmationText = "File Dispute"
                    )
                }
                raiseIssue() -> FormAction("Raise Issue", theme = WarningButtonSemantic()) {
                    sendResponseConfirmation(
                        "Raise Issue?",
                        "Raise issue confirmation text",
                        "Raise Issue"
                    )
                }
                permissions.canLockProject -> FormAction("Lock Project", theme = DangerButtonSemantic) {
                    secondDialog.value = GenericConfirmationDialog(
                        "Lock Project?",
                        "Locking the project freezes the state of all work. It can only be unlocked by joint resolution or arbitration.",
                        confirmationText = "Lock Project",
                        dismiss = { dismissSecondDialog() }
                    ) confirm@{
                        if (!it) return@confirm
                        val session = notNullSession()
                        val dispute = session.disputes.query(
                            Query(limit = 1) {
                                (it.incident eq incident?._id) and (it.state eq DisputeState.Ongoing)
                            }
                        )().singleOrNull() ?: notFoundError<Dispute>()

                        session.nonCached.dispute.lockProject(dispute._id)
                        delay(50)
                        session.lineItems.totallyInvalidate()
                        session.projects.totallyInvalidate()
                        dialogScreenNavigator.dismiss()
                    }
                }
                permissions.canDeny -> FormAction("Deny ${previousMessage?.type?.shortDisplayName}", theme = DangerButtonSemantic) {
                    val actionName = "Deny ${previousMessage?.type?.shortDisplayName}"
                    sendResponseConfirmation(
                        "$actionName?",
                        "The contractor can propose another remedy or you can propose your own afterwards.",
                        actionName
                    )
                }
                else -> null
            }
        }
        val tertiaryAction =
            if (isClient && permissions.canProposeDisputeResolution)
                FormAction("Resolve", theme = TertiaryButtonSemantic, requiresValidMessage = false) {
                    secondDialog.value = GenericConfirmationDialog(
                        "Propose Resolution?",
                        "Do you have a resolution in mind to end the dispute? If so, you can propose a resolution to the contractor. Once sent, the contractor will review it and send back a formal proposal for you to accept or deny.",
                        confirmationText = "Resolve",
                        dismiss = { dismissSecondDialog() }
                    ) {
                        if (it) proposingResolution.value = true
                    }
                }
            else null

        row {
            if (permissions.awaitingResponse) tertiaryButton - button {
                specCenteredContent {
                    row {
                        centered - icon(Icon.download, "Attachments")   // TODO: attachment icon
                        centered - body("Add Attachment")
                    }
                }
                onClick("Upload Attachments") {
                    ExternalServices.requestFiles()
                        .map { file ->
                            val preview = FilePreview.LocalFile(file)
                            files.add(
                                FileData(
                                    name = Property(""),
                                    preview = preview,
                                    original = preview
                                )
                            )
                            async {
                                preview.uploadAttachment(notNullSession().nonCached.incident::uploadFileForRequest)
                            }
                        }
                        .awaitAll()
                }
            }
            expanding - space()
            if (tertiaryAction != null) button {
                applyActionFormatting(tertiaryAction)
            }
            button {
                applyReactiveActionFormatting(secondaryAction)
            }
            if (primaryAction != null) button {
                applyActionFormatting(primaryAction)
            }
        }
    }
}