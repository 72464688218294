// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareLineItemFields() {
    val props: Array<SerializableProperty<LineItem, *>> = arrayOf(LineItem__id, LineItem_project, LineItem_changeRequest, LineItem_scopeView, LineItem_scopeSet, LineItem_name, LineItem_description, LineItem_originalPrice, LineItem_order, LineItem_files, LineItem_started, LineItem_state, LineItem_acceptedPriceChange, LineItem_allowRaiseIssue, LineItem_allowFileDispute, LineItem_allowLockProject, LineItem_issue, LineItem_credits, LineItem_complete, LineItem_ledgerItems, LineItem_cancelled, LineItem_created, LineItem_contractorDeposits)
    LineItem.serializer().properties { props }
}
val <K> DataClassPath<K, LineItem>._id: DataClassPath<K, UUID> get() = this[LineItem__id]
val <K> DataClassPath<K, LineItem>.project: DataClassPath<K, UUID> get() = this[LineItem_project]
val <K> DataClassPath<K, LineItem>.changeRequest: DataClassPath<K, ChangeRequestInfo?> get() = this[LineItem_changeRequest]
val <K> DataClassPath<K, LineItem>.scopeView: DataClassPath<K, UUID?> get() = this[LineItem_scopeView]
val <K> DataClassPath<K, LineItem>.scopeSet: DataClassPath<K, Boolean?> get() = this[LineItem_scopeSet]
val <K> DataClassPath<K, LineItem>.name: DataClassPath<K, String> get() = this[LineItem_name]
val <K> DataClassPath<K, LineItem>.description: DataClassPath<K, String> get() = this[LineItem_description]
val <K> DataClassPath<K, LineItem>.originalPrice: DataClassPath<K, Long> get() = this[LineItem_originalPrice]
val <K> DataClassPath<K, LineItem>.order: DataClassPath<K, Int> get() = this[LineItem_order]
val <K> DataClassPath<K, LineItem>.files: DataClassPath<K, List<ProjectAttachment>> get() = this[LineItem_files]
val <K> DataClassPath<K, LineItem>.started: DataClassPath<K, Instant?> get() = this[LineItem_started]
val <K> DataClassPath<K, LineItem>.state: DataClassPath<K, LineItemState> get() = this[LineItem_state]
val <K> DataClassPath<K, LineItem>.acceptedPriceChange: DataClassPath<K, Long> get() = this[LineItem_acceptedPriceChange]
val <K> DataClassPath<K, LineItem>.allowRaiseIssue: DataClassPath<K, Boolean> get() = this[LineItem_allowRaiseIssue]
val <K> DataClassPath<K, LineItem>.allowFileDispute: DataClassPath<K, Boolean> get() = this[LineItem_allowFileDispute]
val <K> DataClassPath<K, LineItem>.allowLockProject: DataClassPath<K, Boolean> get() = this[LineItem_allowLockProject]
val <K> DataClassPath<K, LineItem>.issue: DataClassPath<K, Issue?> get() = this[LineItem_issue]
val <K> DataClassPath<K, LineItem>.credits: DataClassPath<K, List<Credit>> get() = this[LineItem_credits]
val <K> DataClassPath<K, LineItem>.complete: DataClassPath<K, AccessInfo?> get() = this[LineItem_complete]
val <K> DataClassPath<K, LineItem>.ledgerItems: DataClassPath<K, Set<UUID>> get() = this[LineItem_ledgerItems]
val <K> DataClassPath<K, LineItem>.cancelled: DataClassPath<K, Instant?> get() = this[LineItem_cancelled]
val <K> DataClassPath<K, LineItem>.created: DataClassPath<K, Instant> get() = this[LineItem_created]
val <K> DataClassPath<K, LineItem>.contractorDeposits: DataClassPath<K, Long> get() = this[LineItem_contractorDeposits]
inline val LineItem.Companion.path: DataClassPath<LineItem, LineItem> get() = path<LineItem>()


object LineItem__id: SerializableProperty<LineItem, UUID> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(0) }
    override fun get(receiver: LineItem): UUID = receiver._id
    override fun setCopy(receiver: LineItem, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object LineItem_project: SerializableProperty<LineItem, UUID> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(1) }
    override fun get(receiver: LineItem): UUID = receiver.project
    override fun setCopy(receiver: LineItem, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(1) }
}
object LineItem_changeRequest: SerializableProperty<LineItem, ChangeRequestInfo?> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(2) }
    override fun get(receiver: LineItem): ChangeRequestInfo? = receiver.changeRequest
    override fun setCopy(receiver: LineItem, value: ChangeRequestInfo?) = receiver.copy(changeRequest = value)
    override val serializer: KSerializer<ChangeRequestInfo?> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<ChangeRequestInfo?> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(2) }
    override val default: ChangeRequestInfo? = null
    override val defaultCode: String? = "null" 
}
object LineItem_scopeView: SerializableProperty<LineItem, UUID?> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(3) }
    override fun get(receiver: LineItem): UUID? = receiver.scopeView
    override fun setCopy(receiver: LineItem, value: UUID?) = receiver.copy(scopeView = value)
    override val serializer: KSerializer<UUID?> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(3) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object LineItem_scopeSet: SerializableProperty<LineItem, Boolean?> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(4) }
    override fun get(receiver: LineItem): Boolean? = receiver.scopeSet
    override fun setCopy(receiver: LineItem, value: Boolean?) = receiver.copy(scopeSet = value)
    override val serializer: KSerializer<Boolean?> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Boolean?> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(4) }
    override val default: Boolean? = false
    override val defaultCode: String? = "false" 
}
object LineItem_name: SerializableProperty<LineItem, String> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(5) }
    override fun get(receiver: LineItem): String = receiver.name
    override fun setCopy(receiver: LineItem, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(5) }
    override val default: String = ""
    override val defaultCode: String? = "\"\"" 
}
object LineItem_description: SerializableProperty<LineItem, String> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(6) }
    override fun get(receiver: LineItem): String = receiver.description
    override fun setCopy(receiver: LineItem, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(6) }
    override val default: String = ""
    override val defaultCode: String? = "\"\"" 
}
object LineItem_originalPrice: SerializableProperty<LineItem, Long> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(7) }
    override fun get(receiver: LineItem): Long = receiver.originalPrice
    override fun setCopy(receiver: LineItem, value: Long) = receiver.copy(originalPrice = value)
    override val serializer: KSerializer<Long> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(7) }
    override val default: Long = 0L
    override val defaultCode: String? = "0L" 
}
object LineItem_order: SerializableProperty<LineItem, Int> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(8) }
    override fun get(receiver: LineItem): Int = receiver.order
    override fun setCopy(receiver: LineItem, value: Int) = receiver.copy(order = value)
    override val serializer: KSerializer<Int> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Int> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(8) }
    override val default: Int = 0
    override val defaultCode: String? = "0" 
}
object LineItem_files: SerializableProperty<LineItem, List<ProjectAttachment>> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(9) }
    override fun get(receiver: LineItem): List<ProjectAttachment> = receiver.files
    override fun setCopy(receiver: LineItem, value: List<ProjectAttachment>) = receiver.copy(files = value)
    override val serializer: KSerializer<List<ProjectAttachment>> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<List<ProjectAttachment>> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(9) }
    override val default: List<ProjectAttachment> = listOf()
    override val defaultCode: String? = "listOf()" 
}
object LineItem_started: SerializableProperty<LineItem, Instant?> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(10) }
    override fun get(receiver: LineItem): Instant? = receiver.started
    override fun setCopy(receiver: LineItem, value: Instant?) = receiver.copy(started = value)
    override val serializer: KSerializer<Instant?> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(10) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
object LineItem_state: SerializableProperty<LineItem, LineItemState> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(11) }
    override fun get(receiver: LineItem): LineItemState = receiver.state
    override fun setCopy(receiver: LineItem, value: LineItemState) = receiver.copy(state = value)
    override val serializer: KSerializer<LineItemState> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[11] as KSerializer<LineItemState> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(11) }
    override val default: LineItemState = LineItemState.NotStarted
    override val defaultCode: String? = "LineItemState.NotStarted" 
}
object LineItem_acceptedPriceChange: SerializableProperty<LineItem, Long> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(12) }
    override fun get(receiver: LineItem): Long = receiver.acceptedPriceChange
    override fun setCopy(receiver: LineItem, value: Long) = receiver.copy(acceptedPriceChange = value)
    override val serializer: KSerializer<Long> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[12] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(12) }
    override val default: Long = 0L
    override val defaultCode: String? = "0L" 
}
object LineItem_allowRaiseIssue: SerializableProperty<LineItem, Boolean> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(13) }
    override fun get(receiver: LineItem): Boolean = receiver.allowRaiseIssue
    override fun setCopy(receiver: LineItem, value: Boolean) = receiver.copy(allowRaiseIssue = value)
    override val serializer: KSerializer<Boolean> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[13] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(13) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object LineItem_allowFileDispute: SerializableProperty<LineItem, Boolean> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(14) }
    override fun get(receiver: LineItem): Boolean = receiver.allowFileDispute
    override fun setCopy(receiver: LineItem, value: Boolean) = receiver.copy(allowFileDispute = value)
    override val serializer: KSerializer<Boolean> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[14] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(14) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object LineItem_allowLockProject: SerializableProperty<LineItem, Boolean> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(15) }
    override fun get(receiver: LineItem): Boolean = receiver.allowLockProject
    override fun setCopy(receiver: LineItem, value: Boolean) = receiver.copy(allowLockProject = value)
    override val serializer: KSerializer<Boolean> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[15] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(15) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object LineItem_issue: SerializableProperty<LineItem, Issue?> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(16) }
    override fun get(receiver: LineItem): Issue? = receiver.issue
    override fun setCopy(receiver: LineItem, value: Issue?) = receiver.copy(issue = value)
    override val serializer: KSerializer<Issue?> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[16] as KSerializer<Issue?> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(16) }
    override val default: Issue? = null
    override val defaultCode: String? = "null" 
}
object LineItem_credits: SerializableProperty<LineItem, List<Credit>> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(17) }
    override fun get(receiver: LineItem): List<Credit> = receiver.credits
    override fun setCopy(receiver: LineItem, value: List<Credit>) = receiver.copy(credits = value)
    override val serializer: KSerializer<List<Credit>> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[17] as KSerializer<List<Credit>> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(17) }
    override val default: List<Credit> = emptyList()
    override val defaultCode: String? = "emptyList()" 
}
object LineItem_complete: SerializableProperty<LineItem, AccessInfo?> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(18) }
    override fun get(receiver: LineItem): AccessInfo? = receiver.complete
    override fun setCopy(receiver: LineItem, value: AccessInfo?) = receiver.copy(complete = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[18] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(18) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object LineItem_ledgerItems: SerializableProperty<LineItem, Set<UUID>> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(19) }
    override fun get(receiver: LineItem): Set<UUID> = receiver.ledgerItems
    override fun setCopy(receiver: LineItem, value: Set<UUID>) = receiver.copy(ledgerItems = value)
    override val serializer: KSerializer<Set<UUID>> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[19] as KSerializer<Set<UUID>> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(19) }
    override val default: Set<UUID> = emptySet()
    override val defaultCode: String? = "emptySet()" 
}
object LineItem_cancelled: SerializableProperty<LineItem, Instant?> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(20) }
    override fun get(receiver: LineItem): Instant? = receiver.cancelled
    override fun setCopy(receiver: LineItem, value: Instant?) = receiver.copy(cancelled = value)
    override val serializer: KSerializer<Instant?> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[20] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(20) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
object LineItem_created: SerializableProperty<LineItem, Instant> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(21) }
    override fun get(receiver: LineItem): Instant = receiver.created
    override fun setCopy(receiver: LineItem, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[21] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(21) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object LineItem_contractorDeposits: SerializableProperty<LineItem, Long> {
    override val name: String by lazy { LineItem.serializer().descriptor.getElementName(22) }
    override fun get(receiver: LineItem): Long = receiver.contractorDeposits
    override fun setCopy(receiver: LineItem, value: Long) = receiver.copy(contractorDeposits = value)
    override val serializer: KSerializer<Long> by lazy { (LineItem.serializer() as GeneratedSerializer<*>).childSerializers()[22] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { LineItem.serializer().descriptor.getElementAnnotations(22) }
    override val default: Long = 0L
    override val defaultCode: String? = "0L" 
}
