package com.crowpay.views.theming

import com.crowpay.Resources
import com.crowpay.actuals.AppDimensions
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.kiteui.views.direct.TextView
import com.lightningkite.kiteui.views.direct.text
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract

fun FontAndStyle.copy(
    font: Font = this.font,
    size: Dimension = this.size,
    italic: Boolean = this.italic,
    bold: Boolean,
    allCaps: Boolean = this.allCaps,
    lineSpacingMultiplier: Double = this.lineSpacingMultiplier,
    additionalLetterSpacing: Dimension = this.additionalLetterSpacing,
) = copy(
    font = font,
    italic = italic,
    allCaps = allCaps,
    lineSpacingMultiplier = lineSpacingMultiplier,
    additionalLetterSpacing = additionalLetterSpacing,
    size = size,
    weight = if (bold) 700 else 400
)

object XLTitleSemantic : Semantic {
    override val key: String = "XLTitle"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        font = theme.font.copy(
            font = Resources.fontsMontserratBold,
            size = AppDimensions.xlTitleTextSize,
        )
    ).withoutBack
}
@ViewDsl fun ViewWriter.xlTitle(setup: TextView.() -> Unit = {}): ViewModifiable {
    return text {
        themeChoice += XLTitleSemantic
        setup()
    }
}
@ViewDsl fun ViewWriter.xlTitle(content: String): ViewModifiable {
    return text {
        themeChoice += XLTitleSemantic
        this.content = content
    }
}

object TitleSemantic : Semantic {
    override val key: String = "title"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        font = theme.font.copy(
            font = Resources.fontsMontserratBold,
            size = AppDimensions.titleTextSize,
            bold = true,
        ),
    ).withoutBack
}
@ViewDsl fun ViewWriter.title(setup: TextView.() -> Unit = {}): ViewModifiable {
    return text {
        themeChoice += TitleSemantic
        setup()
    }
}
@ViewDsl fun ViewWriter.title(content: String): ViewModifiable {
    return text {
        themeChoice += TitleSemantic
        this.content = content
    }
}
@ViewDsl fun ViewWriter.titleWithClose(content: String, onClose: () -> Unit): ViewModifiable {
    return row {
        text {
            themeChoice += TitleSemantic
            this.content = content
        }
        expanding - space()
        centered - sizeConstraints(aspectRatio = 1.0) - button {
            spacing = 1.dp
            icon(Icon.close, "Close Dialog")
            onClick { onClose() }
        }
    }
}

object SubtitleSemantic : Semantic {
    override val key: String = "subTitle"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "subTitle",
        font = theme.font.copy(size = AppDimensions.subtitleTextSize)
    ).withoutBack
}
@ViewDsl fun ViewWriter.subTitle(setup: TextView.() -> Unit = {}) =
    text {
        themeChoice += SubtitleSemantic
        setup()
    }

@ViewDsl fun ViewWriter.subTitle(content: String) =
    text {
        themeChoice += SubtitleSemantic
        this.content = content
    }

object BodySemantic : Semantic {
    override val key: String = "body"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "body",
        font = theme.font.copy(size = AppDimensions.bodyTextSize)
    ).withoutBack
}
@ViewDsl fun ViewWriter.body(setup: TextView.() -> Unit = {}): ViewModifiable {
    return text {
        themeChoice += BodySemantic
        setup()
    }
}
@ViewDsl fun ViewWriter.body(content: String): ViewModifiable {
    return text {
        themeChoice += BodySemantic
        this.content = content
    }
}

object BodyBoldSemantic : Semantic {
    override val key: String = "bodybold"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "bodyBold",
        font = theme.font.copy(size = AppDimensions.bodyTextSize, bold = true)
    ).withoutBack
}
@ViewDsl fun ViewWriter.bodyBold(setup: TextView.() -> Unit = {}): ViewModifiable {
    return text {
        themeChoice += BodyBoldSemantic
        setup()
    }
}
@ViewDsl fun ViewWriter.bodyBold(content: String): ViewModifiable {
    return text {
        themeChoice += BodyBoldSemantic
        this.content = content
    }
}
@ViewDsl fun ViewWriter.bodyBold(content: String, setup: TextView.() -> Unit): ViewModifiable {
    return text {
        themeChoice += BodyBoldSemantic
        this.content = content
        setup()
    }
}

object SmallBodySemantic : Semantic {
    override val key: String = "smallBody"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "smallBody",
        font = theme.font.copy(size = AppDimensions.smallBodyTextSize),
        foreground = AppColors.grey.main
    ).withoutBack
}
@ViewDsl fun ViewWriter.smallBody(setup: TextView.() -> Unit = {}): ViewModifiable {
    return text {
        themeChoice += SmallBodySemantic
        setup()
    }
}
@ViewDsl fun ViewWriter.smallBody(content: String): ViewModifiable {
    return text {
        themeChoice += SmallBodySemantic
        this.content = content
    }
}