
package com.crowpay.sdk

import com.lightningkite.*
import com.lightningkite.lightningdb.*
import com.lightningkite.kiteui.*
import kotlinx.datetime.*
import com.lightningkite.serialization.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.auth.*
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.lightningkite.lightningserver.exceptions.ReportedExceptionGroup
import kotlin.Long
import com.crowpay.ChangeRequest
import com.lightningkite.lightningdb.ModelPermissions
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.serialization.Partial
import com.lightningkite.UUID
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import kotlin.Unit
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import kotlin.String
import com.crowpay.ClientTermsAndConditionsAgreement
import com.crowpay.ClientTermsAndConditionsVersion
import kotlinx.datetime.Instant
import com.crowpay.Client
import com.crowpay.Contractor
import com.lightningkite.lightningserver.files.UploadInformation
import com.crowpay.AccountInfo
import com.crowpay.PaymentSetupRequest
import com.crowpay.PendingCashOut
import com.crowpay.RegisterContractor
import com.crowpay.ContractorDocument
import com.crowpay.ContractorTermsAndConditionsAgreement
import com.crowpay.ContractorTermsAndConditionsVersion
import com.crowpay.ItemChange
import com.crowpay.License
import com.crowpay.LineItem
import com.crowpay.Credit
import com.crowpay.ContractorNote
import com.crowpay.PendingItemChange
import com.crowpay.PendingLineItem
import com.crowpay.Payout
import com.crowpay.Project
import com.crowpay.FullProject
import com.crowpay.PaymentRequest
import com.crowpay.ProjectLedgerItem
import com.crowpay.FiledForArbitration
import com.crowpay.PunchListItem
import com.crowpay.ScopeView
import com.crowpay.PayApplication
import com.crowpay.PayAppItem
import com.crowpay.Trade
import com.crowpay.User
import com.crowpay.LineItemCompletionMessage
import com.crowpay.CompletionMessageComment
import com.crowpay.ProjectTimer
import com.crowpay.Dispute
import com.crowpay.Incident
import com.crowpay.IncidentMessage
import com.crowpay.IncidentMessageComment
import com.crowpay.ProjectMessage
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.OtpSecret
import com.lightningkite.lightningserver.auth.proof.EstablishOtp
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.lightningkite.lightningserver.auth.subject.ProofsCheckResult
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.crowpay.RegisterUser
import com.crowpay.PaymentMethod
import com.lightningkite.lightningserver.auth.subject.Session
import com.lightningkite.lightningserver.monitoring.FunnelSummary
import com.lightningkite.lightningserver.monitoring.FunnelInstance
import kotlinx.datetime.LocalDate
import com.lightningkite.lightningserver.monitoring.FunnelStart

class LiveApi(val httpUrl: String, val socketUrl: String): Api {
    override val adminReports: Api.AdminReportsApi = LiveAdminReportsApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val changeRequest: Api.ChangeRequestApi = LiveChangeRequestApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val clientTermsAndConditionsAgreement: Api.ClientTermsAndConditionsAgreementApi = LiveClientTermsAndConditionsAgreementApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val clientTermsAndConditionsVersion: Api.ClientTermsAndConditionsVersionApi = LiveClientTermsAndConditionsVersionApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val client: Api.ClientApi = LiveClientApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val contractor: Api.ContractorApi = LiveContractorApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val contractorDocument: Api.ContractorDocumentApi = LiveContractorDocumentApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val contractorTermsAndConditionsAgreement: Api.ContractorTermsAndConditionsAgreementApi = LiveContractorTermsAndConditionsAgreementApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val contractorTermsAndConditionsVersion: Api.ContractorTermsAndConditionsVersionApi = LiveContractorTermsAndConditionsVersionApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val itemChange: Api.ItemChangeApi = LiveItemChangeApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val license: Api.LicenseApi = LiveLicenseApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val lineItem: Api.LineItemApi = LiveLineItemApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val contractorNote: Api.ContractorNoteApi = LiveContractorNoteApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val pendingItemChange: Api.PendingItemChangeApi = LivePendingItemChangeApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val pendingLineItem: Api.PendingLineItemApi = LivePendingLineItemApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val payout: Api.PayoutApi = LivePayoutApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val project: Api.ProjectApi = LiveProjectApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val projectLedgerItem: Api.ProjectLedgerItemApi = LiveProjectLedgerItemApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val punchListItem: Api.PunchListItemApi = LivePunchListItemApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val scopeView: Api.ScopeViewApi = LiveScopeViewApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val payApplication: Api.PayApplicationApi = LivePayApplicationApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val payAppItem: Api.PayAppItemApi = LivePayAppItemApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val trade: Api.TradeApi = LiveTradeApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val user: Api.UserApi = LiveUserApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val lineItemCompletionMessage: Api.LineItemCompletionMessageApi = LiveLineItemCompletionMessageApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val completionMessageComment: Api.CompletionMessageCommentApi = LiveCompletionMessageCommentApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val projectTimer: Api.ProjectTimerApi = LiveProjectTimerApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val dispute: Api.DisputeApi = LiveDisputeApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val incident: Api.IncidentApi = LiveIncidentApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val incidentMessage: Api.IncidentMessageApi = LiveIncidentMessageApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val incidentMessageComment: Api.IncidentMessageCommentApi = LiveIncidentMessageCommentApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val projectMessage: Api.ProjectMessageApi = LiveProjectMessageApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val emailProof: Api.EmailProofApi = LiveEmailProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val otpSecret: Api.OtpSecretApi = LiveOtpSecretApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val oneTimePasswordProof: Api.OneTimePasswordProofApi = LiveOneTimePasswordProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val userAuth: Api.UserAuthApi = LiveUserAuthApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val userSession: Api.UserSessionApi = LiveUserSessionApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val funnelSummary: Api.FunnelSummaryApi = LiveFunnelSummaryApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val funnelInstance: Api.FunnelInstanceApi = LiveFunnelInstanceApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override suspend fun getServerHealth(): ServerHealth = fetch(
        url = "$httpUrl/meta/health",
        method = HttpMethod.GET,
    )
    override suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = fetch(
        url = "$httpUrl/meta/bulk",
        method = HttpMethod.POST,
        body = input
    )
    override suspend fun listRecentExceptions(accessToken: suspend () -> String, masquerade: String?): List<ReportedExceptionGroup> = fetch(
        url = "$httpUrl/exceptions",
        method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
    )
    class LiveAdminReportsApi(val httpUrl: String, val socketUrl: String): Api.AdminReportsApi {
        override suspend fun totalInEscrow(accessToken: suspend () -> String, masquerade: String?): Long = fetch(
            url = "$httpUrl/admin/total-in-escrow",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun totalCashOutPending(accessToken: suspend () -> String, masquerade: String?): Long = fetch(
            url = "$httpUrl/admin/total-cash-out-pending",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun totalInProgress(accessToken: suspend () -> String, masquerade: String?): Long = fetch(
            url = "$httpUrl/admin/total-in-progress",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun totalSubstantialCompletion(accessToken: suspend () -> String, masquerade: String?): Long = fetch(
            url = "$httpUrl/admin/total-substantial-completion",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun totalByState(accessToken: suspend () -> String, masquerade: String?): Map<String, Long> = fetch(
            url = "$httpUrl/admin/total-by-state",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveChangeRequestApi(val httpUrl: String, val socketUrl: String): Api.ChangeRequestApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ChangeRequest = fetch(
            url = "$httpUrl/change-requests/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ChangeRequest> = fetch(
            url = "$httpUrl/change-requests/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): List<ChangeRequest> = fetch(
            url = "$httpUrl/change-requests/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ChangeRequest>> = fetch(
            url = "$httpUrl/change-requests/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ChangeRequest = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): List<ChangeRequest> = fetch(
            url = "$httpUrl/change-requests/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ChangeRequest, accessToken: suspend () -> String, masquerade: String?): ChangeRequest = fetch(
            url = "$httpUrl/change-requests/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ChangeRequest, accessToken: suspend () -> String, masquerade: String?): ChangeRequest = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): List<ChangeRequest> = fetch(
            url = "$httpUrl/change-requests/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ChangeRequest, accessToken: suspend () -> String, masquerade: String?): ChangeRequest = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/change-requests/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ChangeRequest> = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): ChangeRequest = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): ChangeRequest = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/change-requests/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/change-requests/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/change-requests/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/change-requests/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ChangeRequest>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/change-requests/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun publishChangeRequest(id: UUID, accessToken: suspend () -> String, masquerade: String?): ChangeRequest = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}/publish",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun unPublishChangeRequest(id: UUID, accessToken: suspend () -> String, masquerade: String?): ChangeRequest = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}/un-publish",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun approveLinkedItems(id: UUID, input: String, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}/approved-linked",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun denyLinkedItems(id: UUID, input: String, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/change-requests/rest/${id.urlify()}/deny-linked",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveClientTermsAndConditionsAgreementApi(val httpUrl: String, val socketUrl: String): Api.ClientTermsAndConditionsAgreementApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ClientTermsAndConditionsAgreement> = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): List<ClientTermsAndConditionsAgreement> = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ClientTermsAndConditionsAgreement>> = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): List<ClientTermsAndConditionsAgreement> = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ClientTermsAndConditionsAgreement, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ClientTermsAndConditionsAgreement, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): List<ClientTermsAndConditionsAgreement> = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ClientTermsAndConditionsAgreement, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ClientTermsAndConditionsAgreement> = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ClientTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/client-terms-and-conditions-agreements/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveClientTermsAndConditionsVersionApi(val httpUrl: String, val socketUrl: String): Api.ClientTermsAndConditionsVersionApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsVersion = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ClientTermsAndConditionsVersion> = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): List<ClientTermsAndConditionsVersion> = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ClientTermsAndConditionsVersion>> = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: Instant, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsVersion = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): List<ClientTermsAndConditionsVersion> = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ClientTermsAndConditionsVersion, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsVersion = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: Instant, input: ClientTermsAndConditionsVersion, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsVersion = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): List<ClientTermsAndConditionsVersion> = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: Instant, input: ClientTermsAndConditionsVersion, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsVersion = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: Instant, input: Modification<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ClientTermsAndConditionsVersion> = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: Instant, input: Modification<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsVersion = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: Instant, input: Partial<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): ClientTermsAndConditionsVersion = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: Instant, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ClientTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/client-terms-and-conditions-versions/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveClientApi(val httpUrl: String, val socketUrl: String): Api.ClientApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Client = fetch(
            url = "$httpUrl/clients/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Client> = fetch(
            url = "$httpUrl/clients/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Client>, accessToken: suspend () -> String, masquerade: String?): List<Client> = fetch(
            url = "$httpUrl/clients/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Client>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Client>> = fetch(
            url = "$httpUrl/clients/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Client = fetch(
            url = "$httpUrl/clients/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Client>, accessToken: suspend () -> String, masquerade: String?): List<Client> = fetch(
            url = "$httpUrl/clients/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Client, accessToken: suspend () -> String, masquerade: String?): Client = fetch(
            url = "$httpUrl/clients/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Client, accessToken: suspend () -> String, masquerade: String?): Client = fetch(
            url = "$httpUrl/clients/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Client>, accessToken: suspend () -> String, masquerade: String?): List<Client> = fetch(
            url = "$httpUrl/clients/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Client, accessToken: suspend () -> String, masquerade: String?): Client = fetch(
            url = "$httpUrl/clients/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Client>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/clients/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Client>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Client> = fetch(
            url = "$httpUrl/clients/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Client>, accessToken: suspend () -> String, masquerade: String?): Client = fetch(
            url = "$httpUrl/clients/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Client>, accessToken: suspend () -> String, masquerade: String?): Client = fetch(
            url = "$httpUrl/clients/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Client>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/clients/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/clients/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Client>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/clients/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Client>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/clients/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Client>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/clients/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Client>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/clients/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveContractorApi(val httpUrl: String, val socketUrl: String): Api.ContractorApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Contractor = fetch(
            url = "$httpUrl/contractors/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Contractor> = fetch(
            url = "$httpUrl/contractors/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Contractor>, accessToken: suspend () -> String, masquerade: String?): List<Contractor> = fetch(
            url = "$httpUrl/contractors/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Contractor>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Contractor>> = fetch(
            url = "$httpUrl/contractors/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Contractor = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Contractor>, accessToken: suspend () -> String, masquerade: String?): List<Contractor> = fetch(
            url = "$httpUrl/contractors/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Contractor, accessToken: suspend () -> String, masquerade: String?): Contractor = fetch(
            url = "$httpUrl/contractors/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Contractor, accessToken: suspend () -> String, masquerade: String?): Contractor = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Contractor>, accessToken: suspend () -> String, masquerade: String?): List<Contractor> = fetch(
            url = "$httpUrl/contractors/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Contractor, accessToken: suspend () -> String, masquerade: String?): Contractor = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Contractor>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractors/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Contractor>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Contractor> = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Contractor>, accessToken: suspend () -> String, masquerade: String?): Contractor = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Contractor>, accessToken: suspend () -> String, masquerade: String?): Contractor = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Contractor>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractors/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Contractor>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractors/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Contractor>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/contractors/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Contractor>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/contractors/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Contractor>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/contractors/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun uploadFileForRequest(accessToken: suspend () -> String, masquerade: String?): UploadInformation = fetch(
            url = "$httpUrl/contractors/rest/file-upload-url",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun submitForApproval(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}/submit-for-approval",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun getPaymentInfo(id: UUID, accessToken: suspend () -> String, masquerade: String?): AccountInfo? = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}/payment-info",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun setupPaymentInfo(id: UUID, input: PaymentSetupRequest, accessToken: suspend () -> String, masquerade: String?): AccountInfo = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}/setup-payment-info",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun clearPaymentInfo(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}/clear-payment-info",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun pendingCashOut(id: UUID, accessToken: suspend () -> String, masquerade: String?): PendingCashOut = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}/pending-cash-out",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun requestCashOut(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/contractors/rest/${id.urlify()}/request-cash-out",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun register(input: RegisterContractor): Unit = fetch(
            url = "$httpUrl/contractors/rest/register",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveContractorDocumentApi(val httpUrl: String, val socketUrl: String): Api.ContractorDocumentApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ContractorDocument = fetch(
            url = "$httpUrl/contractor-documents/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ContractorDocument> = fetch(
            url = "$httpUrl/contractor-documents/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): List<ContractorDocument> = fetch(
            url = "$httpUrl/contractor-documents/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ContractorDocument>> = fetch(
            url = "$httpUrl/contractor-documents/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ContractorDocument = fetch(
            url = "$httpUrl/contractor-documents/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): List<ContractorDocument> = fetch(
            url = "$httpUrl/contractor-documents/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ContractorDocument, accessToken: suspend () -> String, masquerade: String?): ContractorDocument = fetch(
            url = "$httpUrl/contractor-documents/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ContractorDocument, accessToken: suspend () -> String, masquerade: String?): ContractorDocument = fetch(
            url = "$httpUrl/contractor-documents/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): List<ContractorDocument> = fetch(
            url = "$httpUrl/contractor-documents/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ContractorDocument, accessToken: suspend () -> String, masquerade: String?): ContractorDocument = fetch(
            url = "$httpUrl/contractor-documents/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-documents/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ContractorDocument> = fetch(
            url = "$httpUrl/contractor-documents/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): ContractorDocument = fetch(
            url = "$httpUrl/contractor-documents/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): ContractorDocument = fetch(
            url = "$httpUrl/contractor-documents/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-documents/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/contractor-documents/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-documents/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/contractor-documents/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/contractor-documents/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorDocument>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/contractor-documents/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun uploadFileForRequest(accessToken: suspend () -> String, masquerade: String?): UploadInformation = fetch(
            url = "$httpUrl/contractor-documents/rest/file-upload-url",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveContractorTermsAndConditionsAgreementApi(val httpUrl: String, val socketUrl: String): Api.ContractorTermsAndConditionsAgreementApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ContractorTermsAndConditionsAgreement> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): List<ContractorTermsAndConditionsAgreement> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ContractorTermsAndConditionsAgreement>> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): List<ContractorTermsAndConditionsAgreement> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ContractorTermsAndConditionsAgreement, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ContractorTermsAndConditionsAgreement, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): List<ContractorTermsAndConditionsAgreement> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ContractorTermsAndConditionsAgreement, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ContractorTermsAndConditionsAgreement> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsAgreement = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorTermsAndConditionsAgreement>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-agreements/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveContractorTermsAndConditionsVersionApi(val httpUrl: String, val socketUrl: String): Api.ContractorTermsAndConditionsVersionApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsVersion = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ContractorTermsAndConditionsVersion> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): List<ContractorTermsAndConditionsVersion> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ContractorTermsAndConditionsVersion>> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: Instant, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsVersion = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): List<ContractorTermsAndConditionsVersion> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ContractorTermsAndConditionsVersion, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsVersion = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: Instant, input: ContractorTermsAndConditionsVersion, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsVersion = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): List<ContractorTermsAndConditionsVersion> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: Instant, input: ContractorTermsAndConditionsVersion, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsVersion = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: Instant, input: Modification<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ContractorTermsAndConditionsVersion> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: Instant, input: Modification<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsVersion = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: Instant, input: Partial<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): ContractorTermsAndConditionsVersion = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: Instant, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorTermsAndConditionsVersion>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/contractor-terms-and-conditions-versions/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveItemChangeApi(val httpUrl: String, val socketUrl: String): Api.ItemChangeApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ItemChange = fetch(
            url = "$httpUrl/item-changes/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ItemChange> = fetch(
            url = "$httpUrl/item-changes/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ItemChange>, accessToken: suspend () -> String, masquerade: String?): List<ItemChange> = fetch(
            url = "$httpUrl/item-changes/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ItemChange>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ItemChange>> = fetch(
            url = "$httpUrl/item-changes/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ItemChange = fetch(
            url = "$httpUrl/item-changes/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ItemChange>, accessToken: suspend () -> String, masquerade: String?): List<ItemChange> = fetch(
            url = "$httpUrl/item-changes/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ItemChange, accessToken: suspend () -> String, masquerade: String?): ItemChange = fetch(
            url = "$httpUrl/item-changes/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ItemChange, accessToken: suspend () -> String, masquerade: String?): ItemChange = fetch(
            url = "$httpUrl/item-changes/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ItemChange>, accessToken: suspend () -> String, masquerade: String?): List<ItemChange> = fetch(
            url = "$httpUrl/item-changes/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ItemChange, accessToken: suspend () -> String, masquerade: String?): ItemChange = fetch(
            url = "$httpUrl/item-changes/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ItemChange>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/item-changes/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ItemChange>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ItemChange> = fetch(
            url = "$httpUrl/item-changes/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ItemChange>, accessToken: suspend () -> String, masquerade: String?): ItemChange = fetch(
            url = "$httpUrl/item-changes/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ItemChange>, accessToken: suspend () -> String, masquerade: String?): ItemChange = fetch(
            url = "$httpUrl/item-changes/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ItemChange>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/item-changes/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/item-changes/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ItemChange>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/item-changes/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ItemChange>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/item-changes/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ItemChange>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/item-changes/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ItemChange>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/item-changes/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveLicenseApi(val httpUrl: String, val socketUrl: String): Api.LicenseApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): License = fetch(
            url = "$httpUrl/licenses/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<License> = fetch(
            url = "$httpUrl/licenses/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<License>, accessToken: suspend () -> String, masquerade: String?): List<License> = fetch(
            url = "$httpUrl/licenses/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<License>, accessToken: suspend () -> String, masquerade: String?): List<Partial<License>> = fetch(
            url = "$httpUrl/licenses/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): License = fetch(
            url = "$httpUrl/licenses/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<License>, accessToken: suspend () -> String, masquerade: String?): List<License> = fetch(
            url = "$httpUrl/licenses/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: License, accessToken: suspend () -> String, masquerade: String?): License = fetch(
            url = "$httpUrl/licenses/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: License, accessToken: suspend () -> String, masquerade: String?): License = fetch(
            url = "$httpUrl/licenses/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<License>, accessToken: suspend () -> String, masquerade: String?): List<License> = fetch(
            url = "$httpUrl/licenses/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: License, accessToken: suspend () -> String, masquerade: String?): License = fetch(
            url = "$httpUrl/licenses/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<License>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/licenses/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<License>, accessToken: suspend () -> String, masquerade: String?): EntryChange<License> = fetch(
            url = "$httpUrl/licenses/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<License>, accessToken: suspend () -> String, masquerade: String?): License = fetch(
            url = "$httpUrl/licenses/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<License>, accessToken: suspend () -> String, masquerade: String?): License = fetch(
            url = "$httpUrl/licenses/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<License>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/licenses/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/licenses/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<License>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/licenses/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<License>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/licenses/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<License>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/licenses/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<License>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/licenses/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun uploadFileForRequest(accessToken: suspend () -> String, masquerade: String?): UploadInformation = fetch(
            url = "$httpUrl/licenses/rest/file-upload-url",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveLineItemApi(val httpUrl: String, val socketUrl: String): Api.LineItemApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): LineItem = fetch(
            url = "$httpUrl/line-items/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<LineItem> = fetch(
            url = "$httpUrl/line-items/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<LineItem>, accessToken: suspend () -> String, masquerade: String?): List<LineItem> = fetch(
            url = "$httpUrl/line-items/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<LineItem>, accessToken: suspend () -> String, masquerade: String?): List<Partial<LineItem>> = fetch(
            url = "$httpUrl/line-items/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): LineItem = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<LineItem>, accessToken: suspend () -> String, masquerade: String?): List<LineItem> = fetch(
            url = "$httpUrl/line-items/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: LineItem, accessToken: suspend () -> String, masquerade: String?): LineItem = fetch(
            url = "$httpUrl/line-items/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: LineItem, accessToken: suspend () -> String, masquerade: String?): LineItem = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<LineItem>, accessToken: suspend () -> String, masquerade: String?): List<LineItem> = fetch(
            url = "$httpUrl/line-items/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: LineItem, accessToken: suspend () -> String, masquerade: String?): LineItem = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<LineItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/line-items/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<LineItem>, accessToken: suspend () -> String, masquerade: String?): EntryChange<LineItem> = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<LineItem>, accessToken: suspend () -> String, masquerade: String?): LineItem = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<LineItem>, accessToken: suspend () -> String, masquerade: String?): LineItem = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<LineItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/line-items/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<LineItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/line-items/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<LineItem>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/line-items/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<LineItem>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/line-items/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<LineItem>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/line-items/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun forceRefreshLineItemState(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}/force-refresh-state",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun uploadFileForRequest(accessToken: suspend () -> String, masquerade: String?): UploadInformation = fetch(
            url = "$httpUrl/line-items/rest/file-upload-url",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun requestReview(id: UUID, accessToken: suspend () -> String, masquerade: String?): LineItem = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}/request-review",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun complete(id: UUID, accessToken: suspend () -> String, masquerade: String?): LineItem = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}/complete",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun addCredit(id: UUID, input: Credit, accessToken: suspend () -> String, masquerade: String?): LineItem = fetch(
            url = "$httpUrl/line-items/rest/${id.urlify()}/add-credit",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveContractorNoteApi(val httpUrl: String, val socketUrl: String): Api.ContractorNoteApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ContractorNote = fetch(
            url = "$httpUrl/contractor-notes/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ContractorNote> = fetch(
            url = "$httpUrl/contractor-notes/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): List<ContractorNote> = fetch(
            url = "$httpUrl/contractor-notes/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ContractorNote>> = fetch(
            url = "$httpUrl/contractor-notes/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ContractorNote = fetch(
            url = "$httpUrl/contractor-notes/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): List<ContractorNote> = fetch(
            url = "$httpUrl/contractor-notes/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ContractorNote, accessToken: suspend () -> String, masquerade: String?): ContractorNote = fetch(
            url = "$httpUrl/contractor-notes/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ContractorNote, accessToken: suspend () -> String, masquerade: String?): ContractorNote = fetch(
            url = "$httpUrl/contractor-notes/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): List<ContractorNote> = fetch(
            url = "$httpUrl/contractor-notes/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ContractorNote, accessToken: suspend () -> String, masquerade: String?): ContractorNote = fetch(
            url = "$httpUrl/contractor-notes/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-notes/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ContractorNote> = fetch(
            url = "$httpUrl/contractor-notes/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): ContractorNote = fetch(
            url = "$httpUrl/contractor-notes/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): ContractorNote = fetch(
            url = "$httpUrl/contractor-notes/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-notes/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/contractor-notes/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/contractor-notes/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/contractor-notes/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/contractor-notes/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorNote>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/contractor-notes/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LivePendingItemChangeApi(val httpUrl: String, val socketUrl: String): Api.PendingItemChangeApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): PendingItemChange = fetch(
            url = "$httpUrl/pending-item-changes/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<PendingItemChange> = fetch(
            url = "$httpUrl/pending-item-changes/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): List<PendingItemChange> = fetch(
            url = "$httpUrl/pending-item-changes/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): List<Partial<PendingItemChange>> = fetch(
            url = "$httpUrl/pending-item-changes/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): PendingItemChange = fetch(
            url = "$httpUrl/pending-item-changes/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): List<PendingItemChange> = fetch(
            url = "$httpUrl/pending-item-changes/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: PendingItemChange, accessToken: suspend () -> String, masquerade: String?): PendingItemChange = fetch(
            url = "$httpUrl/pending-item-changes/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: PendingItemChange, accessToken: suspend () -> String, masquerade: String?): PendingItemChange = fetch(
            url = "$httpUrl/pending-item-changes/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): List<PendingItemChange> = fetch(
            url = "$httpUrl/pending-item-changes/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: PendingItemChange, accessToken: suspend () -> String, masquerade: String?): PendingItemChange = fetch(
            url = "$httpUrl/pending-item-changes/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pending-item-changes/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): EntryChange<PendingItemChange> = fetch(
            url = "$httpUrl/pending-item-changes/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): PendingItemChange = fetch(
            url = "$httpUrl/pending-item-changes/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): PendingItemChange = fetch(
            url = "$httpUrl/pending-item-changes/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pending-item-changes/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pending-item-changes/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pending-item-changes/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/pending-item-changes/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/pending-item-changes/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<PendingItemChange>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/pending-item-changes/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun acceptPendingItemChange(id: UUID, input: String, accessToken: suspend () -> String, masquerade: String?): PendingItemChange = fetch(
            url = "$httpUrl/pending-item-changes/rest/${id.urlify()}/accept",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun denyPendingItemChange(id: UUID, input: String, accessToken: suspend () -> String, masquerade: String?): EntryChange<ChangeRequest> = fetch(
            url = "$httpUrl/pending-item-changes/rest/${id.urlify()}/deny",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LivePendingLineItemApi(val httpUrl: String, val socketUrl: String): Api.PendingLineItemApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): PendingLineItem = fetch(
            url = "$httpUrl/pending-line-items/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<PendingLineItem> = fetch(
            url = "$httpUrl/pending-line-items/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): List<PendingLineItem> = fetch(
            url = "$httpUrl/pending-line-items/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): List<Partial<PendingLineItem>> = fetch(
            url = "$httpUrl/pending-line-items/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): PendingLineItem = fetch(
            url = "$httpUrl/pending-line-items/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): List<PendingLineItem> = fetch(
            url = "$httpUrl/pending-line-items/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: PendingLineItem, accessToken: suspend () -> String, masquerade: String?): PendingLineItem = fetch(
            url = "$httpUrl/pending-line-items/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: PendingLineItem, accessToken: suspend () -> String, masquerade: String?): PendingLineItem = fetch(
            url = "$httpUrl/pending-line-items/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): List<PendingLineItem> = fetch(
            url = "$httpUrl/pending-line-items/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: PendingLineItem, accessToken: suspend () -> String, masquerade: String?): PendingLineItem = fetch(
            url = "$httpUrl/pending-line-items/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pending-line-items/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): EntryChange<PendingLineItem> = fetch(
            url = "$httpUrl/pending-line-items/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): PendingLineItem = fetch(
            url = "$httpUrl/pending-line-items/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): PendingLineItem = fetch(
            url = "$httpUrl/pending-line-items/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pending-line-items/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pending-line-items/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pending-line-items/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/pending-line-items/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/pending-line-items/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<PendingLineItem>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/pending-line-items/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun acceptPendingLineItem(id: UUID, input: String, accessToken: suspend () -> String, masquerade: String?): PendingLineItem = fetch(
            url = "$httpUrl/pending-line-items/rest/${id.urlify()}/accept",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun denyPendingLineItem(id: UUID, input: String, accessToken: suspend () -> String, masquerade: String?): EntryChange<ChangeRequest> = fetch(
            url = "$httpUrl/pending-line-items/rest/${id.urlify()}/deny",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LivePayoutApi(val httpUrl: String, val socketUrl: String): Api.PayoutApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Payout = fetch(
            url = "$httpUrl/payouts/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Payout> = fetch(
            url = "$httpUrl/payouts/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Payout>, accessToken: suspend () -> String, masquerade: String?): List<Payout> = fetch(
            url = "$httpUrl/payouts/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Payout>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Payout>> = fetch(
            url = "$httpUrl/payouts/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: Instant, accessToken: suspend () -> String, masquerade: String?): Payout = fetch(
            url = "$httpUrl/payouts/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Payout>, accessToken: suspend () -> String, masquerade: String?): List<Payout> = fetch(
            url = "$httpUrl/payouts/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Payout, accessToken: suspend () -> String, masquerade: String?): Payout = fetch(
            url = "$httpUrl/payouts/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: Instant, input: Payout, accessToken: suspend () -> String, masquerade: String?): Payout = fetch(
            url = "$httpUrl/payouts/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Payout>, accessToken: suspend () -> String, masquerade: String?): List<Payout> = fetch(
            url = "$httpUrl/payouts/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: Instant, input: Payout, accessToken: suspend () -> String, masquerade: String?): Payout = fetch(
            url = "$httpUrl/payouts/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Payout>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/payouts/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: Instant, input: Modification<Payout>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Payout> = fetch(
            url = "$httpUrl/payouts/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: Instant, input: Modification<Payout>, accessToken: suspend () -> String, masquerade: String?): Payout = fetch(
            url = "$httpUrl/payouts/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: Instant, input: Partial<Payout>, accessToken: suspend () -> String, masquerade: String?): Payout = fetch(
            url = "$httpUrl/payouts/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Payout>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/payouts/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: Instant, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/payouts/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Payout>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/payouts/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Payout>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/payouts/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Payout>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/payouts/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Payout>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/payouts/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveProjectApi(val httpUrl: String, val socketUrl: String): Api.ProjectApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Project> = fetch(
            url = "$httpUrl/projects/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Project>, accessToken: suspend () -> String, masquerade: String?): List<Project> = fetch(
            url = "$httpUrl/projects/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Project>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Project>> = fetch(
            url = "$httpUrl/projects/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Project>, accessToken: suspend () -> String, masquerade: String?): List<Project> = fetch(
            url = "$httpUrl/projects/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Project, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Project, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Project>, accessToken: suspend () -> String, masquerade: String?): List<Project> = fetch(
            url = "$httpUrl/projects/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Project, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Project>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/projects/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Project>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Project> = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Project>, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Project>, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Project>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/projects/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Project>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/projects/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Project>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/projects/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Project>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/projects/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Project>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/projects/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun forceRefreshProjectState(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/force-refresh-state",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun cancelProject(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/cancel",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun abandonProject(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/abandon",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun archiveProject(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/archive",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun publishProject(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/publish",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun unpublishProject(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/unpublish",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun anonymousLink(project: UUID, accessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/anonymous-link",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun viewProjectAnonymously(accessToken: suspend () -> String, masquerade: String?): FullProject = fetch(
            url = "$httpUrl/projects/rest/anonymous",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun notifyViewed(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/notify-viewed",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun acceptProject(project: UUID, input: String, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/accept",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun requestFunding(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/request-funding",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun cancelFundingRequest(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/cancel-funding-request",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun payIntoEscrow(project: UUID, input: PaymentRequest, accessToken: suspend () -> String, masquerade: String?): ProjectLedgerItem? = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/pay-into-escrow",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun disputeProject(project: UUID, input: String, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/create-dispute",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun endDispute(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/end-dispute",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun addArbitrationDocuments(project: UUID, input: FiledForArbitration, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/add-arbitration-documents",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun requestComplete(project: UUID, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/request-complete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun acceptComplete(project: UUID, input: String, accessToken: suspend () -> String, masquerade: String?): Project = fetch(
            url = "$httpUrl/projects/rest/${project.urlify()}/accept-complete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun uploadFileForRequest(accessToken: suspend () -> String, masquerade: String?): UploadInformation = fetch(
            url = "$httpUrl/projects/rest/file-upload-url",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveProjectLedgerItemApi(val httpUrl: String, val socketUrl: String): Api.ProjectLedgerItemApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ProjectLedgerItem = fetch(
            url = "$httpUrl/project-ledger-items/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ProjectLedgerItem> = fetch(
            url = "$httpUrl/project-ledger-items/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): List<ProjectLedgerItem> = fetch(
            url = "$httpUrl/project-ledger-items/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ProjectLedgerItem>> = fetch(
            url = "$httpUrl/project-ledger-items/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ProjectLedgerItem = fetch(
            url = "$httpUrl/project-ledger-items/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): List<ProjectLedgerItem> = fetch(
            url = "$httpUrl/project-ledger-items/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ProjectLedgerItem, accessToken: suspend () -> String, masquerade: String?): ProjectLedgerItem = fetch(
            url = "$httpUrl/project-ledger-items/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ProjectLedgerItem, accessToken: suspend () -> String, masquerade: String?): ProjectLedgerItem = fetch(
            url = "$httpUrl/project-ledger-items/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): List<ProjectLedgerItem> = fetch(
            url = "$httpUrl/project-ledger-items/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ProjectLedgerItem, accessToken: suspend () -> String, masquerade: String?): ProjectLedgerItem = fetch(
            url = "$httpUrl/project-ledger-items/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/project-ledger-items/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ProjectLedgerItem> = fetch(
            url = "$httpUrl/project-ledger-items/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): ProjectLedgerItem = fetch(
            url = "$httpUrl/project-ledger-items/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): ProjectLedgerItem = fetch(
            url = "$httpUrl/project-ledger-items/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/project-ledger-items/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/project-ledger-items/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/project-ledger-items/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/project-ledger-items/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/project-ledger-items/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ProjectLedgerItem>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/project-ledger-items/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LivePunchListItemApi(val httpUrl: String, val socketUrl: String): Api.PunchListItemApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): PunchListItem = fetch(
            url = "$httpUrl/punch-lists-items/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<PunchListItem> = fetch(
            url = "$httpUrl/punch-lists-items/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): List<PunchListItem> = fetch(
            url = "$httpUrl/punch-lists-items/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): List<Partial<PunchListItem>> = fetch(
            url = "$httpUrl/punch-lists-items/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): PunchListItem = fetch(
            url = "$httpUrl/punch-lists-items/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): List<PunchListItem> = fetch(
            url = "$httpUrl/punch-lists-items/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: PunchListItem, accessToken: suspend () -> String, masquerade: String?): PunchListItem = fetch(
            url = "$httpUrl/punch-lists-items/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: PunchListItem, accessToken: suspend () -> String, masquerade: String?): PunchListItem = fetch(
            url = "$httpUrl/punch-lists-items/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): List<PunchListItem> = fetch(
            url = "$httpUrl/punch-lists-items/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: PunchListItem, accessToken: suspend () -> String, masquerade: String?): PunchListItem = fetch(
            url = "$httpUrl/punch-lists-items/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/punch-lists-items/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): EntryChange<PunchListItem> = fetch(
            url = "$httpUrl/punch-lists-items/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): PunchListItem = fetch(
            url = "$httpUrl/punch-lists-items/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): PunchListItem = fetch(
            url = "$httpUrl/punch-lists-items/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/punch-lists-items/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/punch-lists-items/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/punch-lists-items/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/punch-lists-items/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/punch-lists-items/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<PunchListItem>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/punch-lists-items/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun completePunchListItem(id: UUID, accessToken: suspend () -> String, masquerade: String?): PunchListItem = fetch(
            url = "$httpUrl/punch-lists-items/rest/${id.urlify()}/complete",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun resetPunchListItem(id: UUID, accessToken: suspend () -> String, masquerade: String?): PunchListItem = fetch(
            url = "$httpUrl/punch-lists-items/rest/${id.urlify()}/reset",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveScopeViewApi(val httpUrl: String, val socketUrl: String): Api.ScopeViewApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ScopeView = fetch(
            url = "$httpUrl/scope-views/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ScopeView> = fetch(
            url = "$httpUrl/scope-views/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ScopeView>, accessToken: suspend () -> String, masquerade: String?): List<ScopeView> = fetch(
            url = "$httpUrl/scope-views/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ScopeView>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ScopeView>> = fetch(
            url = "$httpUrl/scope-views/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ScopeView = fetch(
            url = "$httpUrl/scope-views/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ScopeView>, accessToken: suspend () -> String, masquerade: String?): List<ScopeView> = fetch(
            url = "$httpUrl/scope-views/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ScopeView, accessToken: suspend () -> String, masquerade: String?): ScopeView = fetch(
            url = "$httpUrl/scope-views/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ScopeView, accessToken: suspend () -> String, masquerade: String?): ScopeView = fetch(
            url = "$httpUrl/scope-views/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ScopeView>, accessToken: suspend () -> String, masquerade: String?): List<ScopeView> = fetch(
            url = "$httpUrl/scope-views/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ScopeView, accessToken: suspend () -> String, masquerade: String?): ScopeView = fetch(
            url = "$httpUrl/scope-views/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ScopeView>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/scope-views/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ScopeView>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ScopeView> = fetch(
            url = "$httpUrl/scope-views/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ScopeView>, accessToken: suspend () -> String, masquerade: String?): ScopeView = fetch(
            url = "$httpUrl/scope-views/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ScopeView>, accessToken: suspend () -> String, masquerade: String?): ScopeView = fetch(
            url = "$httpUrl/scope-views/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ScopeView>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/scope-views/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/scope-views/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ScopeView>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/scope-views/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ScopeView>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/scope-views/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ScopeView>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/scope-views/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ScopeView>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/scope-views/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun updateItems(id: UUID, input: List<UUID>, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/scope-views/rest/${id.urlify()}/update-items",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun addItems(id: UUID, input: List<UUID>, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/scope-views/rest/${id.urlify()}/add-items",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LivePayApplicationApi(val httpUrl: String, val socketUrl: String): Api.PayApplicationApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): PayApplication = fetch(
            url = "$httpUrl/pay-applications/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<PayApplication> = fetch(
            url = "$httpUrl/pay-applications/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<PayApplication>, accessToken: suspend () -> String, masquerade: String?): List<PayApplication> = fetch(
            url = "$httpUrl/pay-applications/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<PayApplication>, accessToken: suspend () -> String, masquerade: String?): List<Partial<PayApplication>> = fetch(
            url = "$httpUrl/pay-applications/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): PayApplication = fetch(
            url = "$httpUrl/pay-applications/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<PayApplication>, accessToken: suspend () -> String, masquerade: String?): List<PayApplication> = fetch(
            url = "$httpUrl/pay-applications/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: PayApplication, accessToken: suspend () -> String, masquerade: String?): PayApplication = fetch(
            url = "$httpUrl/pay-applications/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: PayApplication, accessToken: suspend () -> String, masquerade: String?): PayApplication = fetch(
            url = "$httpUrl/pay-applications/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<PayApplication>, accessToken: suspend () -> String, masquerade: String?): List<PayApplication> = fetch(
            url = "$httpUrl/pay-applications/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: PayApplication, accessToken: suspend () -> String, masquerade: String?): PayApplication = fetch(
            url = "$httpUrl/pay-applications/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<PayApplication>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pay-applications/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PayApplication>, accessToken: suspend () -> String, masquerade: String?): EntryChange<PayApplication> = fetch(
            url = "$httpUrl/pay-applications/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<PayApplication>, accessToken: suspend () -> String, masquerade: String?): PayApplication = fetch(
            url = "$httpUrl/pay-applications/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<PayApplication>, accessToken: suspend () -> String, masquerade: String?): PayApplication = fetch(
            url = "$httpUrl/pay-applications/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<PayApplication>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pay-applications/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pay-applications/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<PayApplication>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pay-applications/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<PayApplication>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/pay-applications/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<PayApplication>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/pay-applications/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<PayApplication>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/pay-applications/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun submitPayApplication(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pay-applications/rest/${id.urlify()}/submit",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun withdrawPayApplication(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pay-applications/rest/${id.urlify()}/withdraw",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LivePayAppItemApi(val httpUrl: String, val socketUrl: String): Api.PayAppItemApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): PayAppItem = fetch(
            url = "$httpUrl/pay-app-items/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<PayAppItem> = fetch(
            url = "$httpUrl/pay-app-items/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): List<PayAppItem> = fetch(
            url = "$httpUrl/pay-app-items/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): List<Partial<PayAppItem>> = fetch(
            url = "$httpUrl/pay-app-items/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): PayAppItem = fetch(
            url = "$httpUrl/pay-app-items/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): List<PayAppItem> = fetch(
            url = "$httpUrl/pay-app-items/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: PayAppItem, accessToken: suspend () -> String, masquerade: String?): PayAppItem = fetch(
            url = "$httpUrl/pay-app-items/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: PayAppItem, accessToken: suspend () -> String, masquerade: String?): PayAppItem = fetch(
            url = "$httpUrl/pay-app-items/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): List<PayAppItem> = fetch(
            url = "$httpUrl/pay-app-items/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: PayAppItem, accessToken: suspend () -> String, masquerade: String?): PayAppItem = fetch(
            url = "$httpUrl/pay-app-items/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pay-app-items/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): EntryChange<PayAppItem> = fetch(
            url = "$httpUrl/pay-app-items/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): PayAppItem = fetch(
            url = "$httpUrl/pay-app-items/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): PayAppItem = fetch(
            url = "$httpUrl/pay-app-items/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pay-app-items/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pay-app-items/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/pay-app-items/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/pay-app-items/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/pay-app-items/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<PayAppItem>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/pay-app-items/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun approvePayApp(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pay-app-items/rest/${id.urlify()}/approve",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun approvePayApps(input: List<UUID>, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pay-app-items/rest/approve-bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun denyPayApp(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pay-app-items/rest/${id.urlify()}/deny",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun denyPayApps(input: List<UUID>, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pay-app-items/rest/deny-bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun voidPayApp(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/pay-app-items/rest/${id.urlify()}/void",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveTradeApi(val httpUrl: String, val socketUrl: String): Api.TradeApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Trade = fetch(
            url = "$httpUrl/trades/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Trade> = fetch(
            url = "$httpUrl/trades/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Trade>, accessToken: suspend () -> String, masquerade: String?): List<Trade> = fetch(
            url = "$httpUrl/trades/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Trade>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Trade>> = fetch(
            url = "$httpUrl/trades/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: String, accessToken: suspend () -> String, masquerade: String?): Trade = fetch(
            url = "$httpUrl/trades/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Trade>, accessToken: suspend () -> String, masquerade: String?): List<Trade> = fetch(
            url = "$httpUrl/trades/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Trade, accessToken: suspend () -> String, masquerade: String?): Trade = fetch(
            url = "$httpUrl/trades/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: String, input: Trade, accessToken: suspend () -> String, masquerade: String?): Trade = fetch(
            url = "$httpUrl/trades/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Trade>, accessToken: suspend () -> String, masquerade: String?): List<Trade> = fetch(
            url = "$httpUrl/trades/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: String, input: Trade, accessToken: suspend () -> String, masquerade: String?): Trade = fetch(
            url = "$httpUrl/trades/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Trade>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/trades/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: String, input: Modification<Trade>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Trade> = fetch(
            url = "$httpUrl/trades/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: String, input: Modification<Trade>, accessToken: suspend () -> String, masquerade: String?): Trade = fetch(
            url = "$httpUrl/trades/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: String, input: Partial<Trade>, accessToken: suspend () -> String, masquerade: String?): Trade = fetch(
            url = "$httpUrl/trades/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Trade>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/trades/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: String, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/trades/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Trade>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/trades/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Trade>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/trades/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Trade>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/trades/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Trade>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/trades/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveUserApi(val httpUrl: String, val socketUrl: String): Api.UserApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<User> = fetch(
            url = "$httpUrl/users/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<User>, accessToken: suspend () -> String, masquerade: String?): List<User> = fetch(
            url = "$httpUrl/users/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<User>, accessToken: suspend () -> String, masquerade: String?): List<Partial<User>> = fetch(
            url = "$httpUrl/users/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<User>, accessToken: suspend () -> String, masquerade: String?): List<User> = fetch(
            url = "$httpUrl/users/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: User, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: User, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<User>, accessToken: suspend () -> String, masquerade: String?): List<User> = fetch(
            url = "$httpUrl/users/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: User, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<User>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/users/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>, accessToken: suspend () -> String, masquerade: String?): EntryChange<User> = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<User>, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<User>, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<User>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/users/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<User>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/users/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<User>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/users/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<User>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/users/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/users/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveLineItemCompletionMessageApi(val httpUrl: String, val socketUrl: String): Api.LineItemCompletionMessageApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): LineItemCompletionMessage = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<LineItemCompletionMessage> = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): List<LineItemCompletionMessage> = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): List<Partial<LineItemCompletionMessage>> = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): LineItemCompletionMessage = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): List<LineItemCompletionMessage> = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: LineItemCompletionMessage, accessToken: suspend () -> String, masquerade: String?): LineItemCompletionMessage = fetch(
            url = "$httpUrl/line-item-completion-messages/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: LineItemCompletionMessage, accessToken: suspend () -> String, masquerade: String?): LineItemCompletionMessage = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): List<LineItemCompletionMessage> = fetch(
            url = "$httpUrl/line-item-completion-messages/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: LineItemCompletionMessage, accessToken: suspend () -> String, masquerade: String?): LineItemCompletionMessage = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): EntryChange<LineItemCompletionMessage> = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): LineItemCompletionMessage = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): LineItemCompletionMessage = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<LineItemCompletionMessage>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun sendMessage(input: LineItemCompletionMessage, accessToken: suspend () -> String, masquerade: String?): LineItemCompletionMessage = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/send-message",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun uploadFileForRequest(accessToken: suspend () -> String, masquerade: String?): UploadInformation = fetch(
            url = "$httpUrl/line-item-completion-messages/rest/file-upload-url",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveCompletionMessageCommentApi(val httpUrl: String, val socketUrl: String): Api.CompletionMessageCommentApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): CompletionMessageComment = fetch(
            url = "$httpUrl/completion-message-comments/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<CompletionMessageComment> = fetch(
            url = "$httpUrl/completion-message-comments/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): List<CompletionMessageComment> = fetch(
            url = "$httpUrl/completion-message-comments/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): List<Partial<CompletionMessageComment>> = fetch(
            url = "$httpUrl/completion-message-comments/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): CompletionMessageComment = fetch(
            url = "$httpUrl/completion-message-comments/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): List<CompletionMessageComment> = fetch(
            url = "$httpUrl/completion-message-comments/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: CompletionMessageComment, accessToken: suspend () -> String, masquerade: String?): CompletionMessageComment = fetch(
            url = "$httpUrl/completion-message-comments/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: CompletionMessageComment, accessToken: suspend () -> String, masquerade: String?): CompletionMessageComment = fetch(
            url = "$httpUrl/completion-message-comments/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): List<CompletionMessageComment> = fetch(
            url = "$httpUrl/completion-message-comments/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: CompletionMessageComment, accessToken: suspend () -> String, masquerade: String?): CompletionMessageComment = fetch(
            url = "$httpUrl/completion-message-comments/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/completion-message-comments/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): EntryChange<CompletionMessageComment> = fetch(
            url = "$httpUrl/completion-message-comments/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): CompletionMessageComment = fetch(
            url = "$httpUrl/completion-message-comments/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): CompletionMessageComment = fetch(
            url = "$httpUrl/completion-message-comments/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/completion-message-comments/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/completion-message-comments/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/completion-message-comments/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/completion-message-comments/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/completion-message-comments/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<CompletionMessageComment>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/completion-message-comments/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveProjectTimerApi(val httpUrl: String, val socketUrl: String): Api.ProjectTimerApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ProjectTimer = fetch(
            url = "$httpUrl/project-timers/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ProjectTimer> = fetch(
            url = "$httpUrl/project-timers/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): List<ProjectTimer> = fetch(
            url = "$httpUrl/project-timers/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ProjectTimer>> = fetch(
            url = "$httpUrl/project-timers/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ProjectTimer = fetch(
            url = "$httpUrl/project-timers/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): List<ProjectTimer> = fetch(
            url = "$httpUrl/project-timers/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ProjectTimer, accessToken: suspend () -> String, masquerade: String?): ProjectTimer = fetch(
            url = "$httpUrl/project-timers/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ProjectTimer, accessToken: suspend () -> String, masquerade: String?): ProjectTimer = fetch(
            url = "$httpUrl/project-timers/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): List<ProjectTimer> = fetch(
            url = "$httpUrl/project-timers/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ProjectTimer, accessToken: suspend () -> String, masquerade: String?): ProjectTimer = fetch(
            url = "$httpUrl/project-timers/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/project-timers/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ProjectTimer> = fetch(
            url = "$httpUrl/project-timers/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): ProjectTimer = fetch(
            url = "$httpUrl/project-timers/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): ProjectTimer = fetch(
            url = "$httpUrl/project-timers/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/project-timers/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/project-timers/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/project-timers/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/project-timers/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/project-timers/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ProjectTimer>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/project-timers/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun refreshTimers(accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/project-timers/rest/refresh",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveDisputeApi(val httpUrl: String, val socketUrl: String): Api.DisputeApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Dispute = fetch(
            url = "$httpUrl/disputes/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Dispute> = fetch(
            url = "$httpUrl/disputes/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Dispute>, accessToken: suspend () -> String, masquerade: String?): List<Dispute> = fetch(
            url = "$httpUrl/disputes/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Dispute>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Dispute>> = fetch(
            url = "$httpUrl/disputes/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Dispute = fetch(
            url = "$httpUrl/disputes/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Dispute>, accessToken: suspend () -> String, masquerade: String?): List<Dispute> = fetch(
            url = "$httpUrl/disputes/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Dispute, accessToken: suspend () -> String, masquerade: String?): Dispute = fetch(
            url = "$httpUrl/disputes/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Dispute, accessToken: suspend () -> String, masquerade: String?): Dispute = fetch(
            url = "$httpUrl/disputes/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Dispute>, accessToken: suspend () -> String, masquerade: String?): List<Dispute> = fetch(
            url = "$httpUrl/disputes/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Dispute, accessToken: suspend () -> String, masquerade: String?): Dispute = fetch(
            url = "$httpUrl/disputes/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Dispute>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/disputes/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Dispute>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Dispute> = fetch(
            url = "$httpUrl/disputes/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Dispute>, accessToken: suspend () -> String, masquerade: String?): Dispute = fetch(
            url = "$httpUrl/disputes/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Dispute>, accessToken: suspend () -> String, masquerade: String?): Dispute = fetch(
            url = "$httpUrl/disputes/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Dispute>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/disputes/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/disputes/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Dispute>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/disputes/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Dispute>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/disputes/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Dispute>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/disputes/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Dispute>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/disputes/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun lockProject(id: UUID, accessToken: suspend () -> String, masquerade: String?): Dispute = fetch(
            url = "$httpUrl/disputes/rest/${id.urlify()}/lock-project",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveIncidentApi(val httpUrl: String, val socketUrl: String): Api.IncidentApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Incident = fetch(
            url = "$httpUrl/incidents/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Incident> = fetch(
            url = "$httpUrl/incidents/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Incident>, accessToken: suspend () -> String, masquerade: String?): List<Incident> = fetch(
            url = "$httpUrl/incidents/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Incident>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Incident>> = fetch(
            url = "$httpUrl/incidents/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Incident = fetch(
            url = "$httpUrl/incidents/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Incident>, accessToken: suspend () -> String, masquerade: String?): List<Incident> = fetch(
            url = "$httpUrl/incidents/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Incident, accessToken: suspend () -> String, masquerade: String?): Incident = fetch(
            url = "$httpUrl/incidents/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Incident, accessToken: suspend () -> String, masquerade: String?): Incident = fetch(
            url = "$httpUrl/incidents/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Incident>, accessToken: suspend () -> String, masquerade: String?): List<Incident> = fetch(
            url = "$httpUrl/incidents/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Incident, accessToken: suspend () -> String, masquerade: String?): Incident = fetch(
            url = "$httpUrl/incidents/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Incident>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/incidents/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Incident>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Incident> = fetch(
            url = "$httpUrl/incidents/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Incident>, accessToken: suspend () -> String, masquerade: String?): Incident = fetch(
            url = "$httpUrl/incidents/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Incident>, accessToken: suspend () -> String, masquerade: String?): Incident = fetch(
            url = "$httpUrl/incidents/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Incident>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/incidents/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/incidents/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Incident>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/incidents/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Incident>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/incidents/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Incident>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/incidents/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Incident>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/incidents/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun fileIncident(input: IncidentMessage, accessToken: suspend () -> String, masquerade: String?): Incident = fetch(
            url = "$httpUrl/incidents/rest/file-incident",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun uploadFileForRequest(accessToken: suspend () -> String, masquerade: String?): UploadInformation = fetch(
            url = "$httpUrl/incidents/rest/file-upload-url",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveIncidentMessageApi(val httpUrl: String, val socketUrl: String): Api.IncidentMessageApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): IncidentMessage = fetch(
            url = "$httpUrl/incident-messages/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<IncidentMessage> = fetch(
            url = "$httpUrl/incident-messages/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): List<IncidentMessage> = fetch(
            url = "$httpUrl/incident-messages/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): List<Partial<IncidentMessage>> = fetch(
            url = "$httpUrl/incident-messages/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): IncidentMessage = fetch(
            url = "$httpUrl/incident-messages/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): List<IncidentMessage> = fetch(
            url = "$httpUrl/incident-messages/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: IncidentMessage, accessToken: suspend () -> String, masquerade: String?): IncidentMessage = fetch(
            url = "$httpUrl/incident-messages/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: IncidentMessage, accessToken: suspend () -> String, masquerade: String?): IncidentMessage = fetch(
            url = "$httpUrl/incident-messages/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): List<IncidentMessage> = fetch(
            url = "$httpUrl/incident-messages/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: IncidentMessage, accessToken: suspend () -> String, masquerade: String?): IncidentMessage = fetch(
            url = "$httpUrl/incident-messages/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/incident-messages/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): EntryChange<IncidentMessage> = fetch(
            url = "$httpUrl/incident-messages/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): IncidentMessage = fetch(
            url = "$httpUrl/incident-messages/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): IncidentMessage = fetch(
            url = "$httpUrl/incident-messages/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/incident-messages/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/incident-messages/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/incident-messages/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/incident-messages/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/incident-messages/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<IncidentMessage>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/incident-messages/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun sendMessage(input: IncidentMessage, accessToken: suspend () -> String, masquerade: String?): IncidentMessage = fetch(
            url = "$httpUrl/incident-messages/rest/send-message",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveIncidentMessageCommentApi(val httpUrl: String, val socketUrl: String): Api.IncidentMessageCommentApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): IncidentMessageComment = fetch(
            url = "$httpUrl/incident-message-comments/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<IncidentMessageComment> = fetch(
            url = "$httpUrl/incident-message-comments/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): List<IncidentMessageComment> = fetch(
            url = "$httpUrl/incident-message-comments/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): List<Partial<IncidentMessageComment>> = fetch(
            url = "$httpUrl/incident-message-comments/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): IncidentMessageComment = fetch(
            url = "$httpUrl/incident-message-comments/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): List<IncidentMessageComment> = fetch(
            url = "$httpUrl/incident-message-comments/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: IncidentMessageComment, accessToken: suspend () -> String, masquerade: String?): IncidentMessageComment = fetch(
            url = "$httpUrl/incident-message-comments/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: IncidentMessageComment, accessToken: suspend () -> String, masquerade: String?): IncidentMessageComment = fetch(
            url = "$httpUrl/incident-message-comments/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): List<IncidentMessageComment> = fetch(
            url = "$httpUrl/incident-message-comments/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: IncidentMessageComment, accessToken: suspend () -> String, masquerade: String?): IncidentMessageComment = fetch(
            url = "$httpUrl/incident-message-comments/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/incident-message-comments/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): EntryChange<IncidentMessageComment> = fetch(
            url = "$httpUrl/incident-message-comments/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): IncidentMessageComment = fetch(
            url = "$httpUrl/incident-message-comments/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): IncidentMessageComment = fetch(
            url = "$httpUrl/incident-message-comments/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/incident-message-comments/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/incident-message-comments/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/incident-message-comments/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/incident-message-comments/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/incident-message-comments/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<IncidentMessageComment>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/incident-message-comments/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveProjectMessageApi(val httpUrl: String, val socketUrl: String): Api.ProjectMessageApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ProjectMessage = fetch(
            url = "$httpUrl/project-messages/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ProjectMessage> = fetch(
            url = "$httpUrl/project-messages/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): List<ProjectMessage> = fetch(
            url = "$httpUrl/project-messages/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ProjectMessage>> = fetch(
            url = "$httpUrl/project-messages/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ProjectMessage = fetch(
            url = "$httpUrl/project-messages/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): List<ProjectMessage> = fetch(
            url = "$httpUrl/project-messages/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ProjectMessage, accessToken: suspend () -> String, masquerade: String?): ProjectMessage = fetch(
            url = "$httpUrl/project-messages/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ProjectMessage, accessToken: suspend () -> String, masquerade: String?): ProjectMessage = fetch(
            url = "$httpUrl/project-messages/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): List<ProjectMessage> = fetch(
            url = "$httpUrl/project-messages/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ProjectMessage, accessToken: suspend () -> String, masquerade: String?): ProjectMessage = fetch(
            url = "$httpUrl/project-messages/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/project-messages/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ProjectMessage> = fetch(
            url = "$httpUrl/project-messages/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): ProjectMessage = fetch(
            url = "$httpUrl/project-messages/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): ProjectMessage = fetch(
            url = "$httpUrl/project-messages/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/project-messages/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/project-messages/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/project-messages/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/project-messages/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/project-messages/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ProjectMessage>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/project-messages/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveEmailProofApi(val httpUrl: String, val socketUrl: String): Api.EmailProofApi {
        override suspend fun beginEmailOwnershipProof(input: String): String = fetch(
            url = "$httpUrl/auth/proof/email/start",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = fetch(
            url = "$httpUrl/auth/proof/email/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveOtpSecretApi(val httpUrl: String, val socketUrl: String): Api.OtpSecretApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<OtpSecret> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): List<OtpSecret> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): List<Partial<OtpSecret>> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): List<OtpSecret> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: OtpSecret, accessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/auth/proof/otp/secrets",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: OtpSecret, accessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): List<OtpSecret> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: OtpSecret, accessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): EntryChange<OtpSecret> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<OtpSecret>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveOneTimePasswordProofApi(val httpUrl: String, val socketUrl: String): Api.OneTimePasswordProofApi {
        override suspend fun establishOneTimePassword(input: EstablishOtp, accessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/auth/proof/otp/establish",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = fetch(
            url = "$httpUrl/auth/proof/otp/prove",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun confirmOneTimePassword(input: String, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/proof/otp/existing",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveUserAuthApi(val httpUrl: String, val socketUrl: String): Api.UserAuthApi {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = fetch(
            url = "$httpUrl/auth/user/login",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = fetch(
            url = "$httpUrl/auth/user/login2",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = fetch(
            url = "$httpUrl/auth/user/proofs-check",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun openSession(input: String): String = fetch(
            url = "$httpUrl/auth/user/open-session",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun createSubSession(input: SubSessionRequest, accessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/auth/user/sub-session",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = fetch(
            url = "$httpUrl/auth/user/token",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getTokenSimple(input: String): String = fetch(
            url = "$httpUrl/auth/user/token/simple",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getSelf(accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/auth/user/self",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun terminateSession(accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/user/terminate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun terminateOtherSession(sessionId: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/user/${sessionId.urlify()}/terminate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun sendRegistrationEmail(input: String): String = fetch(
            url = "$httpUrl/auth/user/register-start",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun registerUser(input: RegisterUser): User = fetch(
            url = "$httpUrl/auth/user/register",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getPaymentSetupToken(accessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/auth/user/payments/payment-setup",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun getPaymentPublishableKey(accessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/auth/user/payments/payment-publishable-key",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun getPaymentMethods(accessToken: suspend () -> String, masquerade: String?): List<PaymentMethod> = fetch(
            url = "$httpUrl/auth/user/payments",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun getPaymentMethod(methodId: String, accessToken: suspend () -> String, masquerade: String?): PaymentMethod = fetch(
            url = "$httpUrl/auth/user/payments/${methodId.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun updatePaymentMethodName(methodId: String, input: String, accessToken: suspend () -> String, masquerade: String?): PaymentMethod = fetch(
            url = "$httpUrl/auth/user/payments/${methodId.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun deletePaymentMethod(methodId: String, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/user/payments/${methodId.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveUserSessionApi(val httpUrl: String, val socketUrl: String): Api.UserSessionApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/user/sessions/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/user/sessions/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Session<User, UUID>>> = fetch(
            url = "$httpUrl/auth/user/sessions/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/user/sessions/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Session<User, UUID>, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Session<User, UUID>, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/user/sessions",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Session<User, UUID>, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/user/sessions/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/user/sessions/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/user/sessions/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/auth/user/sessions/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/auth/user/sessions/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/auth/user/sessions/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveFunnelSummaryApi(val httpUrl: String, val socketUrl: String): Api.FunnelSummaryApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): List<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): List<Partial<FunnelSummary>> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): List<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: FunnelSummary, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: FunnelSummary, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): List<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: FunnelSummary, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): EntryChange<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveFunnelInstanceApi(val httpUrl: String, val socketUrl: String): Api.FunnelInstanceApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<FunnelInstance> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): List<FunnelInstance> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): List<Partial<FunnelInstance>> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): List<FunnelInstance> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: FunnelInstance, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: FunnelInstance, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): List<FunnelInstance> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: FunnelInstance, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): EntryChange<FunnelInstance> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun getFunnelHealth(date: LocalDate, accessToken: suspend () -> String, masquerade: String?): List<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summaries/${date.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun summarizeFunnelsNow(input: LocalDate, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/meta/funnels/summarize-now",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun startFunnelInstance(input: FunnelStart, accessToken: (suspend () -> String)?, masquerade: String?): UUID = fetch(
            url = "$httpUrl/meta/funnels/start",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun errorFunnelInstance(id: UUID, input: String): Unit = fetch(
            url = "$httpUrl/meta/funnels/error/${id.urlify()}",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun setStepFunnelInstance(id: UUID, input: Int): Unit = fetch(
            url = "$httpUrl/meta/funnels/step/${id.urlify()}",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun successFunnelInstance(id: UUID): Unit = fetch(
            url = "$httpUrl/meta/funnels/success/${id.urlify()}",
            method = HttpMethod.POST,
        )
    }
}

