// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareIncidentFields() {
    val props: Array<SerializableProperty<Incident, *>> = arrayOf(Incident__id, Incident_project, Incident_filed, Incident_issue, Incident_resolved, Incident_concern, Incident_state, Incident_canFileDispute, Incident_canLockProject)
    Incident.serializer().properties { props }
}
val <K> DataClassPath<K, Incident>._id: DataClassPath<K, UUID> get() = this[Incident__id]
val <K> DataClassPath<K, Incident>.project: DataClassPath<K, UUID> get() = this[Incident_project]
val <K> DataClassPath<K, Incident>.filed: DataClassPath<K, AccessInfo> get() = this[Incident_filed]
val <K> DataClassPath<K, Incident>.issue: DataClassPath<K, Issue?> get() = this[Incident_issue]
val <K> DataClassPath<K, Incident>.resolved: DataClassPath<K, AccessInfo?> get() = this[Incident_resolved]
val <K> DataClassPath<K, Incident>.concern: DataClassPath<K, ClientConcern> get() = this[Incident_concern]
val <K> DataClassPath<K, Incident>.state: DataClassPath<K, IncidentState> get() = this[Incident_state]
val <K> DataClassPath<K, Incident>.canFileDispute: DataClassPath<K, Boolean> get() = this[Incident_canFileDispute]
val <K> DataClassPath<K, Incident>.canLockProject: DataClassPath<K, Boolean> get() = this[Incident_canLockProject]
inline val Incident.Companion.path: DataClassPath<Incident, Incident> get() = path<Incident>()


object Incident__id: SerializableProperty<Incident, UUID> {
    override val name: String by lazy { Incident.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Incident): UUID = receiver._id
    override fun setCopy(receiver: Incident, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (Incident.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Incident.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object Incident_project: SerializableProperty<Incident, UUID> {
    override val name: String by lazy { Incident.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Incident): UUID = receiver.project
    override fun setCopy(receiver: Incident, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (Incident.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Incident.serializer().descriptor.getElementAnnotations(1) }
}
object Incident_filed: SerializableProperty<Incident, AccessInfo> {
    override val name: String by lazy { Incident.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Incident): AccessInfo = receiver.filed
    override fun setCopy(receiver: Incident, value: AccessInfo) = receiver.copy(filed = value)
    override val serializer: KSerializer<AccessInfo> by lazy { (Incident.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<AccessInfo> }
    override val annotations: List<Annotation> by lazy { Incident.serializer().descriptor.getElementAnnotations(2) }
}
object Incident_issue: SerializableProperty<Incident, Issue?> {
    override val name: String by lazy { Incident.serializer().descriptor.getElementName(3) }
    override fun get(receiver: Incident): Issue? = receiver.issue
    override fun setCopy(receiver: Incident, value: Issue?) = receiver.copy(issue = value)
    override val serializer: KSerializer<Issue?> by lazy { (Incident.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Issue?> }
    override val annotations: List<Annotation> by lazy { Incident.serializer().descriptor.getElementAnnotations(3) }
    override val default: Issue? = null
    override val defaultCode: String? = "null" 
}
object Incident_resolved: SerializableProperty<Incident, AccessInfo?> {
    override val name: String by lazy { Incident.serializer().descriptor.getElementName(4) }
    override fun get(receiver: Incident): AccessInfo? = receiver.resolved
    override fun setCopy(receiver: Incident, value: AccessInfo?) = receiver.copy(resolved = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (Incident.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { Incident.serializer().descriptor.getElementAnnotations(4) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object Incident_concern: SerializableProperty<Incident, ClientConcern> {
    override val name: String by lazy { Incident.serializer().descriptor.getElementName(5) }
    override fun get(receiver: Incident): ClientConcern = receiver.concern
    override fun setCopy(receiver: Incident, value: ClientConcern) = receiver.copy(concern = value)
    override val serializer: KSerializer<ClientConcern> by lazy { (Incident.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<ClientConcern> }
    override val annotations: List<Annotation> by lazy { Incident.serializer().descriptor.getElementAnnotations(5) }
    override val default: ClientConcern = ClientConcern.Other
    override val defaultCode: String? = "ClientConcern.Other" 
}
object Incident_state: SerializableProperty<Incident, IncidentState> {
    override val name: String by lazy { Incident.serializer().descriptor.getElementName(6) }
    override fun get(receiver: Incident): IncidentState = receiver.state
    override fun setCopy(receiver: Incident, value: IncidentState) = receiver.copy(state = value)
    override val serializer: KSerializer<IncidentState> by lazy { (Incident.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<IncidentState> }
    override val annotations: List<Annotation> by lazy { Incident.serializer().descriptor.getElementAnnotations(6) }
    override val default: IncidentState = IncidentState.Filed
    override val defaultCode: String? = "IncidentState.Filed" 
}
object Incident_canFileDispute: SerializableProperty<Incident, Boolean> {
    override val name: String by lazy { Incident.serializer().descriptor.getElementName(7) }
    override fun get(receiver: Incident): Boolean = receiver.canFileDispute
    override fun setCopy(receiver: Incident, value: Boolean) = receiver.copy(canFileDispute = value)
    override val serializer: KSerializer<Boolean> by lazy { (Incident.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { Incident.serializer().descriptor.getElementAnnotations(7) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object Incident_canLockProject: SerializableProperty<Incident, Boolean> {
    override val name: String by lazy { Incident.serializer().descriptor.getElementName(8) }
    override fun get(receiver: Incident): Boolean = receiver.canLockProject
    override fun setCopy(receiver: Incident, value: Boolean) = receiver.copy(canLockProject = value)
    override val serializer: KSerializer<Boolean> by lazy { (Incident.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { Incident.serializer().descriptor.getElementAnnotations(8) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
