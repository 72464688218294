// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareContractorNoteFields() {
    val props: Array<SerializableProperty<ContractorNote, *>> = arrayOf(ContractorNote__id, ContractorNote_project, ContractorNote_lineItem, ContractorNote_message, ContractorNote_files, ContractorNote_at)
    ContractorNote.serializer().properties { props }
}
val <K> DataClassPath<K, ContractorNote>._id: DataClassPath<K, UUID> get() = this[ContractorNote__id]
val <K> DataClassPath<K, ContractorNote>.project: DataClassPath<K, UUID> get() = this[ContractorNote_project]
val <K> DataClassPath<K, ContractorNote>.lineItem: DataClassPath<K, UUID?> get() = this[ContractorNote_lineItem]
val <K> DataClassPath<K, ContractorNote>.message: DataClassPath<K, String> get() = this[ContractorNote_message]
val <K> DataClassPath<K, ContractorNote>.files: DataClassPath<K, List<ProjectAttachment>> get() = this[ContractorNote_files]
val <K> DataClassPath<K, ContractorNote>.at: DataClassPath<K, Instant> get() = this[ContractorNote_at]
inline val ContractorNote.Companion.path: DataClassPath<ContractorNote, ContractorNote> get() = path<ContractorNote>()


object ContractorNote__id: SerializableProperty<ContractorNote, UUID> {
    override val name: String by lazy { ContractorNote.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ContractorNote): UUID = receiver._id
    override fun setCopy(receiver: ContractorNote, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ContractorNote.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ContractorNote.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object ContractorNote_project: SerializableProperty<ContractorNote, UUID> {
    override val name: String by lazy { ContractorNote.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ContractorNote): UUID = receiver.project
    override fun setCopy(receiver: ContractorNote, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (ContractorNote.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ContractorNote.serializer().descriptor.getElementAnnotations(1) }
}
object ContractorNote_lineItem: SerializableProperty<ContractorNote, UUID?> {
    override val name: String by lazy { ContractorNote.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ContractorNote): UUID? = receiver.lineItem
    override fun setCopy(receiver: ContractorNote, value: UUID?) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<UUID?> by lazy { (ContractorNote.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { ContractorNote.serializer().descriptor.getElementAnnotations(2) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object ContractorNote_message: SerializableProperty<ContractorNote, String> {
    override val name: String by lazy { ContractorNote.serializer().descriptor.getElementName(3) }
    override fun get(receiver: ContractorNote): String = receiver.message
    override fun setCopy(receiver: ContractorNote, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> by lazy { (ContractorNote.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ContractorNote.serializer().descriptor.getElementAnnotations(3) }
}
object ContractorNote_files: SerializableProperty<ContractorNote, List<ProjectAttachment>> {
    override val name: String by lazy { ContractorNote.serializer().descriptor.getElementName(4) }
    override fun get(receiver: ContractorNote): List<ProjectAttachment> = receiver.files
    override fun setCopy(receiver: ContractorNote, value: List<ProjectAttachment>) = receiver.copy(files = value)
    override val serializer: KSerializer<List<ProjectAttachment>> by lazy { (ContractorNote.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<List<ProjectAttachment>> }
    override val annotations: List<Annotation> by lazy { ContractorNote.serializer().descriptor.getElementAnnotations(4) }
    override val default: List<ProjectAttachment> = emptyList()
    override val defaultCode: String? = "emptyList()" 
}
object ContractorNote_at: SerializableProperty<ContractorNote, Instant> {
    override val name: String by lazy { ContractorNote.serializer().descriptor.getElementName(5) }
    override fun get(receiver: ContractorNote): Instant = receiver.at
    override fun setCopy(receiver: ContractorNote, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> by lazy { (ContractorNote.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { ContractorNote.serializer().descriptor.getElementAnnotations(5) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
