package com.crowpay

import com.lightningkite.kiteui.models.*

actual object Resources {
    actual val fontsMontserrat: Font = Font(cssFontFamilyName = "fontsMontserrat", direct = FontDirect(normal = mapOf(400 to "common/fonts/Montserrat.ttf"), italics = mapOf()))
actual val fontsMontserratBold: Font = Font(cssFontFamilyName = "fontsMontserratBold", direct = FontDirect(normal = mapOf(400 to "common/fonts/Montserrat-Bold.ttf"), italics = mapOf()))
actual val fontsRobotoRegular: Font = Font(cssFontFamilyName = "fontsRobotoRegular", direct = FontDirect(normal = mapOf(400 to "common/fonts/Roboto-Regular.ttf"), italics = mapOf()))
actual val imagesFullLogoBlack: ImageResource = ImageResource("common/images/Full_Logo_Black.webp")
actual val imagesFullLogoColor: ImageResource = ImageResource("common/images/Full_Logo_Color.webp")
actual val imagesLogoColor: ImageResource = ImageResource("common/images/Logo_Color.webp")
actual val imagesNewActivityIcon: ImageResource = ImageResource("common/images/New Activity Icon.png")
}