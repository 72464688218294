package com.crowpay.views.screens.common

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.currentSession
import com.crowpay.utils.*
import com.crowpay.views.components.activityIcon
import com.crowpay.views.components.project.*
import com.crowpay.views.components.project.activity.activityBar
import com.crowpay.views.components.project.projectInfo.projectInfoBar
import com.crowpay.views.components.project.work.workTabs
import com.crowpay.views.screens.contractor.MightBeContractorScreen
import com.crowpay.views.theming.body
import com.crowpay.views.theming.bodyBold
import com.crowpay.views.theming.textButton
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.times
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.launch
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable

@Serializable
enum class ProjectLens {
    Contractor,
    Customer,
    Preview
    ;

    fun isClient() = this != Contractor
}

// Class used for sharing data across extension functions, hopefully will make things more readable
abstract class ProjectView(override val lens: ProjectLens) : MightBeContractorScreen {
    @Serializable
    enum class WorkSection(val displayName: String) {
        Scope("Scope"),
        Tasks("Tasks"),
        ChangeOrders("Changes"),
        PayApps("Draws"),
    }

    @Serializable
    enum class InfoSection(val displayName: String) {
        Ledger("Ledger"),
        Details("Details"),
        Contacts("Contacts")
    }

    @Serializable
    enum class ActivitySection(val displayName: String) {
        ToDos("To-Do's"),
        Concerns("Concerns"),
        Messaging("Messaging"),
    }


    class PinnableTab<T>(val name: String, serializer: KSerializer<T>, val default: T): ImmediateWritable<T> {
        val pinned = PersistentProperty("$name-pinned-tab", default, serializer)
        val selected = Property(pinned.value)

        fun pinSelected() { pinned.value = selected.value }
        val selectedIsPinned = shared { selected() == pinned() }.withWrite { if (it) pinSelected() }

        // Just defer to the selected tab
        override var value: T by selected::value
        override fun addListener(listener: () -> Unit): () -> Unit = selected.addListener(listener)
        override val state: ReadableState<T> get() = selected.state
    }

    val selectedInfoTab = PinnableTab("info", InfoSection.serializer(), InfoSection.Details)
    val selectedActivityTab = PinnableTab("activity", ActivitySection.serializer(), ActivitySection.Concerns)
    val selectedWorkTab = PinnableTab("work", WorkSection.serializer(), WorkSection.Scope)

    open suspend fun ViewWriter.onAccept() {}
    open suspend fun ViewWriter.onLogin() {}
    open suspend fun ViewWriter.onRegister() {}

    //--------
    //  DATA
    //--------
    abstract val projectId: UUID
    abstract val project: Readable<Project>
    abstract val lineItems: Readable<List<AdjustedLineItem>>
    abstract val contractorNotes: Readable<List<ContractorNote>>
    abstract val client: Readable<Client>
    abstract val contractor: Readable<Contractor>
    abstract val punchListItems: Readable<List<PunchListItem>>
    abstract val changeOrders: Readable<List<ChangeRequest>>
    abstract val disputes: Readable<List<Dispute>>
    abstract val scopes: Readable<List<ScopeView>>
    abstract val pendingItemChanges: Readable<List<PendingItemChange>>
    abstract val pendingLineItems: Readable<List<PendingLineItem>>
    abstract val payApps: Readable<List<PayApplication>>
    abstract val payAppItems: Readable<List<PayAppItem>>
    abstract val lineItemCompletionMessages: Readable<List<LineItemCompletionMessage>>
    abstract val incidents: Readable<List<Incident>>
    abstract val incidentMessages: Readable<List<IncidentMessage>>

    private val cache = HashMap<String, Any?>()
    fun <T> caching(key: String, getter: ()->T): T =
        try {
            cache.getOrPut(key, getter) as T
        } catch (e: ClassCastException) {
            throw IllegalArgumentException("ProjectView cache key $key has multiple conflicting types", e)
        }

    //------------
    // Visibility
    //------------
    val anonymous = shared {
        val p = project()
        lens == ProjectLens.Preview &&
                (p.state == ProjectState.WaitingApproval || p.state == ProjectState.Invited) &&
                currentSession() == null
    }
    val needsFundingRequested = shared {
        project().let { it.state == ProjectState.Accepted && it.fullFundingRequested == null && it.fundingNeeded == 0L }
    }
    val fundingNeeded = shared { project().fundingNeeded > 0 }
    val inSubstantialCompletion = shared {
        project().state == ProjectState.SubstantialCompletion
    }
    val requestedComplete = shared {
        project().state == ProjectState.RequestingComplete
    }
    val canRequestComplete = shared { lens == ProjectLens.Contractor && inSubstantialCompletion() }
    val canAcceptComplete = shared { lens == ProjectLens.Customer && requestedComplete() }
    val canMakePayment = shared { lens == ProjectLens.Customer && fundingNeeded() }
    val canRequestFunding = shared { lens == ProjectLens.Contractor && needsFundingRequested() }
    val canCancelFundingRequest =
        shared { lens == ProjectLens.Contractor && project().state == ProjectState.AwaitingFunding }
    val canAbandonProject = shared {
        val p = project()
        lens == ProjectLens.Contractor &&
                (p.state == ProjectState.WaitingApproval || p.state == ProjectState.Invited)
    }
    val canCancelProject = shared {
        val p = project()
        p.state >= ProjectState.Accepted &&
                p.state <= ProjectState.AwaitingFunding
    }
    val canAcceptProject = shared {
        val p = project()
        lens == ProjectLens.Preview &&
                (p.state == ProjectState.WaitingApproval || p.state == ProjectState.Invited) &&
                currentSession() != null
    }
    val ongoingIncidents = shared { incidents().filter { it.resolved == null } }

    val pullMethods = BasicListenable()
    val needsPaymentMethods: Readable<Boolean> = sharedSuspending {
        rerunOn(pullMethods)
        val session = currentSession()
        session != null && session.nonCached.userAuth.getPaymentMethods().isEmpty()
    }

    val itemsRequiringReview = shared {
        lineItems().filter {
            if (lens == ProjectLens.Contractor) it.state == LineItemState.NotApproved
            else it.state == LineItemState.RequestingReview
        }
    }

    val rejectedItems = shared {
        lineItems().filter { it.state == LineItemState.NotApproved }
    }

    val pendingChanges: Readable<List<ChangeRequestItem>> =
        if (lens == ProjectLens.Customer)
            shared {
                val published = changeOrders().filter { it.published != null }.map { it._id }
                pendingItemChanges().filter { it.pending && it.changeRequest in published } +
                        pendingLineItems().filter { it.pending && it.changeRequest in published }
            }
        else Constant(emptyList())

    val todo = shared {
        var n = 0
        val debugger = StringBuilder()
        debugger.appendLine("TODO:")
        fun addToDo(name: String, amount: Int) {
            if (amount > 0) debugger.append("- $name")
            if (amount > 1) debugger.append(" ($amount)")
            debugger.append('\n')
            n += amount
        }
        if (canAcceptProject()) addToDo("accept project", 1)
        if (canMakePayment()) addToDo("make payment", 1)
        if (canRequestFunding()) addToDo("request funding", 1)
        if (canMakePayment() && needsPaymentMethods()) addToDo("make payment and setup method", 1)
        if (lens == ProjectLens.Contractor && project().state == ProjectState.PendingStart) addToDo("start project", 1)

        if (lens == ProjectLens.Contractor) {
            addToDo("rejected work items", rejectedItems().size)
        }

        if (lens == ProjectLens.Customer) {
            addToDo("work items needing review", itemsRequiringReview().size)
            addToDo("pending item changes", pendingChanges().size)
            addToDo("pending draws", payAppItems().distinctBy { it.payApp }.count { it.pending })
        }

//        println(debugger.toString())

        n
    }


    //-------
    // Views
    //-------
    fun ViewWriter.projectView() {
        launch {
            if (todo() > 0) selectedActivityTab.value = ActivitySection.ToDos
        }
        scrolls - col {
            // Actions for scrolling items into view
            JumpTo.LineItem
                .addListener { selectedWorkTab.value = WorkSection.Scope }
                .also { onRemove(it) }

            JumpTo.PendingChangeOrder
                .addListener { selectedWorkTab.value = WorkSection.ChangeOrders }
                .also { onRemove(it) }

            JumpTo.ChangeItem
                .addListener { selectedWorkTab.value = WorkSection.ChangeOrders }
                .also { onRemove(it) }

            JumpTo.ProjectDetails
                .addListener { selectedInfoTab.value = InfoSection.Details }
                .also { onRemove(it) }

            JumpTo.ToDos
                .addListener { selectedActivityTab.value = ActivitySection.ToDos }
                .also { onRemove(it) }

            JumpTo.PayApp
                .addListener { selectedWorkTab.value = WorkSection.PayApps }
                .also { onRemove(it) }

            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = AppDimensions.majorColumnSpacing

                header(this)

                disputeBar(this)

                projectLevelMessages(this)

                compact - row {
                    existsDefaultFalse { todo() > 0 }
                    centered - activityIcon()
                    centered - body {
                        ::content { "To-Do (${todo()}) - You have required To-Do's" }
                    }
                    centered - textButton - button {
                        bodyBold("View")
                        onClick { JumpTo.ToDos() }
                    }
                }

                // Visual separation
                actionBar2(this)

                if (lens == ProjectLens.Preview) previewInformation(this)
                col {
                    spacing = AppDimensions.majorColumnSpacing * 1.5

                    projectInfoBar(this)

                    activityBar(this)

                    workTabs(this)
                }

                actionBar2(this)
            }
        }
    }

    // Has to be separated out for anonymous viewing
    override fun ViewWriter.renderMainContent() = projectView()
}