package com.crowpay.views.components.files

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.withSpacing
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.lazy
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import kotlinx.coroutines.*

fun ViewWriter.renderContractorDocuments(
    documents: Readable<List<ContractorDocument>>,
    mimeTypes: List<String> = listOf("*/*")
) {
    stack {
        spacing = AppDimensions.sectionIndent

        col {
            val editingDocuments = Property(false)

            col {
                ::exists{ !editingDocuments() }
                withSpacing(0.dp) - col {
                    spacing = 8.dp
                    ::exists{
                        documents.await().isNotEmpty()
                    }
                    lazy(AppState.windowInfo) { window ->
                        val raw = AppDimensions.largeImagePreview.px
                        val count =
                            if (window.width > AppDimensions.pageWidth + AppDimensions.leftNavWidth) {
                                AppDimensions.pageWidth.px / raw
                            } else {
                                (window.width.px - AppDimensions.leftNavWidth.px) / raw
                            }.toInt() - 1

                        val chunked = shared {
                            documents.await().chunked(count)
                        }

                        forEachUpdating(chunked, placeholdersWhileLoading = 1) { chunk ->
                            row {
                                spacing = 8.dp
                                forEachUpdating(
                                    chunk,
                                    placeholdersWhileLoading = count
                                ) { attachment: Readable<ContractorDocument> ->
                                    expanding - stack {
                                        centered - renderAttachment(
                                            attachment,
                                            documents,
                                            AppDimensions.largeImagePreview
                                        )
                                    }
                                }
                            }
                        }
                    }
                }

                body {
                    exists = false
                    ::exists{
                        documents.await().isEmpty()
                    }
                    align = Align.Center
                    content = "No Documents uploaded"
                }


                tertiaryButton - button {
                    icon(Icon.edit, "Edit Documents")
                    onClick {
                        editingDocuments.set(true)
                    }
                } in gravity(Align.End, Align.Center)
            }

            col {
                exists = false
                ::exists{ editingDocuments() }
                val data = Property<List<FileData>>(emptyList())

                suspend fun reset() {
                    data.value = documents.await()
                        .map {
                            FileData(
                                it._id,
                                name = Property(it.name),
                                preview = FilePreview.RemoteAttachment(it),
                                original = FilePreview.RemoteAttachment(it),
                            )
                        }
                }

                launch {
                    reset()
                }

                fileManager(
                    data,
                    "No Documents Uploaded",
                    mimeTypes
                ) { notNullSession().nonCached.contractor::uploadFileForRequest }
                row {
                    spacing = AppDimensions.buttonRowSpacing
                    textButton - button {
                        specCenteredText("Cancel")
                        onClick {
                            editingDocuments.set(false)
                            reset()
                        }
                    }
                    tertiaryButton - button {
                        specCenteredText("Save")
                        onClick {

                            val c = guaranteedContractor()

                            val updateddocuments = data.await().resolveContractorDocuments(c)

                            val originaldocuments = documents.await()

                            val newdocuments =
                                updateddocuments.filter { updated -> originaldocuments.none { og -> og._id == updated._id } }
                            val modified = updateddocuments.mapNotNull { updated ->
                                originaldocuments.find { og -> og._id == updated._id }
                                    ?.let { it to updated }
                            }
                            val deleted =
                                originaldocuments.filter { og -> updateddocuments.none { updated -> og._id == updated._id } }

                            coroutineScope {
                                val calls = buildList {
                                    if (newdocuments.isNotEmpty())
                                        add(async(start = CoroutineStart.LAZY) {
                                            notNullSession().contractorDocuments.insert(newdocuments)
                                        })
                                    if (deleted.isNotEmpty())
                                        deleted.forEach { doc ->
                                            add(async(start = CoroutineStart.LAZY) {
                                                notNullSession().contractorDocuments[doc._id].delete()
                                            })
                                        }
                                    modified.forEach { (og, updated) ->
                                        val mods =
                                            Modification.Chain<ContractorDocument>(buildList {
                                                if (og.name != updated.name) add(modification { it.name assign updated.name })
                                                if (og.fileType != updated.fileType) add(
                                                    modification { it.fileType assign updated.fileType })
                                                if (og.file.location.substringBefore('?') != updated.file.location.substringBefore(
                                                        '?'
                                                    )
                                                ) add(modification { it.file assign updated.file })
                                                if (og.preview?.location?.substringBefore('?') != updated.preview?.location?.substringBefore(
                                                        '?'
                                                    )
                                                ) add(modification { it.preview assign updated.preview })
                                            })
                                        if (mods.modifications.isNotEmpty())
                                            add(async(start = CoroutineStart.LAZY) {
                                                notNullSession().contractorDocuments[og._id]
                                                    .modify(mods)
                                            })
                                    }
                                }

                                calls.awaitAll()
                            }

                            editingDocuments.set(false)
                            reset()
                        }
                    }
                } in gravity(Align.End, Align.Center)
            }
        }
    }
}