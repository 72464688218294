package com.crowpay.actuals

import com.lightningkite.kiteui.models.*

private val scaleFactor = 0.8
actual object AppDimensions {
    actual val xlTitleTextSize = 32.dp * scaleFactor
    actual val titleTextSize = 28.dp * scaleFactor

    actual val subtitleTextSize = 22.dp * scaleFactor
    actual val bodyTextSize = 16.dp * 0.9
    actual val smallBodyTextSize = 14.dp * 0.9

    actual val fullIndent = 4.5.rem * scaleFactor
    actual val sectionIndent = 2.rem * scaleFactor
    actual val backgroundIndent = (fullIndent - sectionIndent) * scaleFactor

    actual val majorColumnSpacing = 3.rem * scaleFactor

    actual val tabSectionPadding: Dimension = 0.5.rem
    actual val projectInfoTabsSize: Dimension = 25.rem
    actual val activityTabMinSize: Dimension = 25.rem

    actual val navMenuWidth: Dimension = 15.rem

    actual val pageWidth = 65.rem
    actual val wideCollapseWidth = pageWidth + (17.rem * scaleFactor)
    actual val normalCollapseWidth = pageWidth
    actual val thinCollapseWidth = pageWidth - (17.rem * scaleFactor)

    actual val buttonRowSpacing = 20.dp * scaleFactor
    actual val buttonSpacing = 10.dp * scaleFactor
    actual val buttonHorizontalSpacing = 22.dp * scaleFactor
    actual val buttonMinimumWidth = 100.dp * scaleFactor

    actual val expandButtonSpace: Dimension = 5.dp * scaleFactor

    actual val outlineWidth = 1.px
    actual val cornerRadii = 3.dp * scaleFactor

    actual val largeImagePreview = 15.rem * scaleFactor
    actual val smallImagePreview = 10.rem * scaleFactor

    actual val leftNavWidth = 10.rem * scaleFactor
    actual val topNavHeight = 7.rem * scaleFactor

    actual val actionBarHeight: Dimension = 4.5.rem * scaleFactor
}