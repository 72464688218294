package com.crowpay.views.components.project.work.changeOrders.tables

import com.crowpay.ChangeRequest
import com.crowpay.ChangeType
import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.Formats
import com.crowpay.utils.JumpTo
import com.crowpay.utils.format
import com.crowpay.utils.renderDollars
import com.crowpay.views.components.project.work.changeOrders.ChangeRequestItemWrapper
import com.crowpay.views.components.project.work.changeOrders.ChangeStatus
import com.crowpay.views.components.project.work.changeOrders.cancelled
import com.crowpay.views.components.project.work.changeOrders.status
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.renderPriceChange
import com.crowpay.views.components.sectionIndentCol
import com.crowpay.views.components.space
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant

fun ViewWriter.renderReviewedChangeOrder(
    changeOrder: Readable<ChangeRequest>,
    changeItems: Readable<List<ChangeRequestItemWrapper>>,
    cardinal: Boolean
) {
    val expanded = Property(false)
    val commonStatus = shared {
        changeItems().map { it.status }.toSet().singleOrNull()
    }

    col {
        spacing = 0.px
        expandButtonTheme - button {
            row {
                spacing = AppDimensions.expandButtonSpace
                centered - expandIcon(expanded)
                subTitle {
                    ::content {
                        val c = changeOrder()
                        "Change #${c.number}: ${c.title}"
                    }
                }
            }
            onClick("Toggle Expanded") { expanded.toggle() }
        }

        onlyWhen { expanded() } - sectionIndentCol {
            spacing = 0.px
            space(1.rem)
            body {
                ::content {
                    "Description: ${changeOrder().description}"
                }
            }
            space(1.rem)

            sizeConstraints(height = 2.rem) - row {
                spacing = 1.rem
                if (cardinal) weight(5f) - space() else weight(5f) - bodyBold("Work Items")
                weight(1f) - centered - bodyBold {
                    align = Align.Center
                    content = "ID"
                }
                weight(1.5f) - centered - bodyBold {
                    align = Align.Center
                    content = "Submitted"
                }
                weight(2.5f) - centered - bodyBold {
                    align = Align.Center
                    content = "Status"
                }
                weight(1f) - centered - bodyBold {
                    align = Align.Center
                    content = "Original"
                }
                weight(1f) - centered - bodyBold {
                    align = Align.Center
                    content = "Total"
                }
                weight(1.5f) - centered - bodyBold {
                    align = Align.Center
                    content = "Date"
                }
            }
            greySeparator()

            fun ViewWriter.tableRow(
                expanded: Readable<Boolean>? = null,
                label: Pair<String, ChangeType>? = null,
                id: ReactiveContext.() -> String,
                submitted: ReactiveContext.() -> Long?,
                status: ReactiveContext.() -> ChangeStatus?,
                original: ReactiveContext.() -> Long?,
                date: ReactiveContext.() -> Instant?
            ): ViewModifiable {
                return row {
                    spacing = 1.rem
                    if (expanded == null)
                        weight(5f) - centered - bodyBold("Group Summary")
                    else
                        weight(5f) - row {
                            spacing = AppDimensions.expandButtonSpace
                            centered - expandIcon(expanded)
                            centered - body(label?.first ?: "")
                            centered - smallBody(label?.second?.postTense ?: "")
                        }

                    weight(1f) - centered - body {
                        align = Align.Center
                        ::content.invoke(id)
                    }
                    weight(1.5f) - stack {
                        centered - renderPriceChange(submitted)
                    }
                    weight(2.5f) - centered - body {
                        dynamicTheme {
                            status()?.let { ForegroundSemantic(it.displayColor) }
                        }
                        align = Align.Center
                        ::content { status()?.displayName ?: "-" }
                    }
                    weight(1f) - centered - body {
                        align = Align.Center
                        ::content { original()?.renderDollars() ?: "-" }
                    }
                    weight(1f) - centered - body {
                        align = Align.Center
                        ::content {
                            val o = original()
                            val s = submitted()
                            if (o != null && s != null) (o + s).renderDollars() else "-"
                        }
                    }
                    weight(1.5f) - centered - body {
                        align = Align.Center

                        ::content {
                            date()?.format(Formats.mmddyyyy) ?: "-"
                        }
                    }
                }
            }

            if (cardinal) col {
                spacing = 0.px
                sizeConstraints(height = 3.rem) - tableRow(
                    label = null,
                    id = { changeOrder().number },
                    submitted = { changeItems().sumOf { it.priceChange ?: 0 } },
                    status = { commonStatus() },
                    original = { if (commonStatus() == null) null else 0 },
                    date = {
                        changeItems().map { it.changeAccepted ?: it.changeRejected }.toSet().singleOrNull()
                    }
                )
            }
            greySeparator()
            col {
                spacing = 0.px
                forEach(changeItems) { item ->
                    changeRequestItemButton(item) { itemExpanded ->
                        JumpTo.ChangeItem.newTarget(item._id, this@changeRequestItemButton) {
                            expanded.value = true
                            itemExpanded.value = true
                        }
                        tableRow(
                            itemExpanded,
                            label = Pair(item.name, item.changeType),
                            id = { item.itemNumber },
                            submitted = { if(item.cancelled) -item.originalPrice else item.priceChange },
                            status = {
                                if (cardinal && commonStatus() != null) null
                                else item.status
                            },
                            original = {
                                if (cardinal && commonStatus() != null) null
                                else item.originalPrice
                            },
                            date = {
                                item.let { it.changeAccepted ?: it.changeRejected }
                            }
                        )
                    }
                }
            }
            space()
        }

        greySeparator { ::exists { !expanded() } }
    }
}