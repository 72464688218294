package com.crowpay.views.components.project.activity

import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.activityIcon
import com.crowpay.views.components.project.activity.concerns.concerns
import com.crowpay.views.components.project.tabSectionHeader
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.AppColors
import com.crowpay.views.theming.TabSemantics
import com.crowpay.views.theming.subTitle
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.ThemeDerivation.Companion.invoke
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.models.times
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

fun ProjectView.activityBar(writer: ViewWriter) = with(writer) {
    col {
        tabSectionHeader("Activity", selectedActivityTab)

        TabSemantics(AppColors.grey.light2).onNext - row {
            for (tab in ProjectView.ActivitySection.entries) {
                expanding - buttonTheme - radioToggleButton {
                    centered - compact - row {
                        if (tab == ProjectView.ActivitySection.ToDos) activityIcon {
                            existsDefaultFalse { todo() > 0 }
                        }
                        subTitle {
                            content = tab.displayName
                            align = Align.Center
                        }
                    }
                    checked bind selectedActivityTab.equalTo(tab)
                }
            }
        }

        sizeConstraints(
            minHeight = AppDimensions.activityTabMinSize,
            maxHeight = AppDimensions.activityTabMinSize * 2.5
        ) - scrolls - padded - swapView {
            spacing = AppDimensions.tabSectionPadding
            swapping(
                current = { selectedActivityTab() },
                views = { tab ->
                    when (tab) {
                        ProjectView.ActivitySection.ToDos -> projectToDos(this)
                        ProjectView.ActivitySection.Concerns -> concerns(this)
                        ProjectView.ActivitySection.Messaging -> projectMessages(this)
                    }
                }
            )
        }
    }
}