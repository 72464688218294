// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareChangeRequestFields() {
    val props: Array<SerializableProperty<ChangeRequest, *>> = arrayOf(ChangeRequest__id, ChangeRequest_project, ChangeRequest_title, ChangeRequest_description, ChangeRequest_projectDescription, ChangeRequest_published, ChangeRequest_cardinalChange, ChangeRequest_linkItems, ChangeRequest_scopeSet, ChangeRequest_fullyLinked, ChangeRequest_pending, ChangeRequest_number, ChangeRequest_created)
    ChangeRequest.serializer().properties { props }
}
val <K> DataClassPath<K, ChangeRequest>._id: DataClassPath<K, UUID> get() = this[ChangeRequest__id]
val <K> DataClassPath<K, ChangeRequest>.project: DataClassPath<K, UUID> get() = this[ChangeRequest_project]
val <K> DataClassPath<K, ChangeRequest>.title: DataClassPath<K, String> get() = this[ChangeRequest_title]
val <K> DataClassPath<K, ChangeRequest>.description: DataClassPath<K, String> get() = this[ChangeRequest_description]
val <K> DataClassPath<K, ChangeRequest>.projectDescription: DataClassPath<K, String?> get() = this[ChangeRequest_projectDescription]
val <K> DataClassPath<K, ChangeRequest>.published: DataClassPath<K, Instant?> get() = this[ChangeRequest_published]
val <K> DataClassPath<K, ChangeRequest>.cardinalChange: DataClassPath<K, Boolean> get() = this[ChangeRequest_cardinalChange]
val <K> DataClassPath<K, ChangeRequest>.linkItems: DataClassPath<K, Boolean> get() = this[ChangeRequest_linkItems]
val <K> DataClassPath<K, ChangeRequest>.scopeSet: DataClassPath<K, Boolean> get() = this[ChangeRequest_scopeSet]
val <K> DataClassPath<K, ChangeRequest>.fullyLinked: DataClassPath<K, Boolean> get() = this[ChangeRequest_fullyLinked]
val <K> DataClassPath<K, ChangeRequest>.pending: DataClassPath<K, Int> get() = this[ChangeRequest_pending]
val <K> DataClassPath<K, ChangeRequest>.number: DataClassPath<K, String> get() = this[ChangeRequest_number]
val <K> DataClassPath<K, ChangeRequest>.created: DataClassPath<K, Instant> get() = this[ChangeRequest_created]
inline val ChangeRequest.Companion.path: DataClassPath<ChangeRequest, ChangeRequest> get() = path<ChangeRequest>()


object ChangeRequest__id: SerializableProperty<ChangeRequest, UUID> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ChangeRequest): UUID = receiver._id
    override fun setCopy(receiver: ChangeRequest, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object ChangeRequest_project: SerializableProperty<ChangeRequest, UUID> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ChangeRequest): UUID = receiver.project
    override fun setCopy(receiver: ChangeRequest, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(1) }
}
object ChangeRequest_title: SerializableProperty<ChangeRequest, String> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ChangeRequest): String = receiver.title
    override fun setCopy(receiver: ChangeRequest, value: String) = receiver.copy(title = value)
    override val serializer: KSerializer<String> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(2) }
    override val default: String = ""
    override val defaultCode: String? = "\"\"" 
}
object ChangeRequest_description: SerializableProperty<ChangeRequest, String> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(3) }
    override fun get(receiver: ChangeRequest): String = receiver.description
    override fun setCopy(receiver: ChangeRequest, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(3) }
}
object ChangeRequest_projectDescription: SerializableProperty<ChangeRequest, String?> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(4) }
    override fun get(receiver: ChangeRequest): String? = receiver.projectDescription
    override fun setCopy(receiver: ChangeRequest, value: String?) = receiver.copy(projectDescription = value)
    override val serializer: KSerializer<String?> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(4) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object ChangeRequest_published: SerializableProperty<ChangeRequest, Instant?> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(5) }
    override fun get(receiver: ChangeRequest): Instant? = receiver.published
    override fun setCopy(receiver: ChangeRequest, value: Instant?) = receiver.copy(published = value)
    override val serializer: KSerializer<Instant?> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(5) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
object ChangeRequest_cardinalChange: SerializableProperty<ChangeRequest, Boolean> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(6) }
    override fun get(receiver: ChangeRequest): Boolean = receiver.cardinalChange
    override fun setCopy(receiver: ChangeRequest, value: Boolean) = receiver.copy(cardinalChange = value)
    override val serializer: KSerializer<Boolean> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(6) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object ChangeRequest_linkItems: SerializableProperty<ChangeRequest, Boolean> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(7) }
    override fun get(receiver: ChangeRequest): Boolean = receiver.linkItems
    override fun setCopy(receiver: ChangeRequest, value: Boolean) = receiver.copy(linkItems = value)
    override val serializer: KSerializer<Boolean> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(7) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object ChangeRequest_scopeSet: SerializableProperty<ChangeRequest, Boolean> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(8) }
    override fun get(receiver: ChangeRequest): Boolean = receiver.scopeSet
    override fun setCopy(receiver: ChangeRequest, value: Boolean) = receiver.copy(scopeSet = value)
    override val serializer: KSerializer<Boolean> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(8) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object ChangeRequest_fullyLinked: SerializableProperty<ChangeRequest, Boolean> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(9) }
    override fun get(receiver: ChangeRequest): Boolean = receiver.fullyLinked
    override fun setCopy(receiver: ChangeRequest, value: Boolean) = receiver.copy(fullyLinked = value)
    override val serializer: KSerializer<Boolean> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(9) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object ChangeRequest_pending: SerializableProperty<ChangeRequest, Int> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(10) }
    override fun get(receiver: ChangeRequest): Int = receiver.pending
    override fun setCopy(receiver: ChangeRequest, value: Int) = receiver.copy(pending = value)
    override val serializer: KSerializer<Int> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<Int> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(10) }
    override val default: Int = 0
    override val defaultCode: String? = "0" 
}
object ChangeRequest_number: SerializableProperty<ChangeRequest, String> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(11) }
    override fun get(receiver: ChangeRequest): String = receiver.number
    override fun setCopy(receiver: ChangeRequest, value: String) = receiver.copy(number = value)
    override val serializer: KSerializer<String> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[11] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(11) }
}
object ChangeRequest_created: SerializableProperty<ChangeRequest, Instant> {
    override val name: String by lazy { ChangeRequest.serializer().descriptor.getElementName(12) }
    override fun get(receiver: ChangeRequest): Instant = receiver.created
    override fun setCopy(receiver: ChangeRequest, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (ChangeRequest.serializer() as GeneratedSerializer<*>).childSerializers()[12] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { ChangeRequest.serializer().descriptor.getElementAnnotations(12) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
