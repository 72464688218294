package com.crowpay.views.dialogs

import com.crowpay.Project
import com.crowpay.completionResponse.CompletionResponse
import com.crowpay.actuals.AppDimensions
import com.crowpay.completionResponse.DisputeType
import com.crowpay.views.components.project.activity.concerns.IncidentReportForm
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.secondDialog
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

class FileIncidentConfirmation(val project: Project, val lens: ProjectLens): Screen {
    override fun ViewWriter.render() {
        dismissBackground {
            sizeConstraints(maxWidth = 40.rem) - dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                removeOutline - col {
                    title("Are You Sure?")

                    body("""
                        Informally resolve your concern if it's minor or early in the project by sending a message to your contractor.
                        
                        Report an incident if this concern needs to be documented.
                    """.trimIndent())

                    notificationBar(AppColors.blue) - body("""
                        Informally make aware by sending a message?
                        Do you need to formally report this issue?
                        Formally make aware X happened and could lead to an issue.
                    """.trimIndent())

                    space(0.5)

                    row {
                        spacing = AppDimensions.buttonRowSpacing

                        textButton - button {
                            body("Go Back")
                            onClick { dialogScreenNavigator.dismiss() }
                        }

                        warning - buttonTheme - button {
                            specCenteredText("Message")
                            onClick {

                            }
                        }
                        dangerButton - button {
                            specCenteredText("Report Incident")
                            onClick {
                                dialogScreenNavigator.reset(
                                    IncidentReportForm.createDialog(project, null, emptyList(), lens)
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}