package com.crowpay.views.dialogs

import com.crowpay.views.theming.AppColors
import com.crowpay.views.theming.body
import com.crowpay.views.theming.colored
import com.crowpay.views.theming.specCenteredText
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.bold
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*

class HelpDialog: Screen {
    override fun ViewWriter.render() = dialogView {
        col {
            spacing = 2.rem
            title("Help")
            row {
                spacing = 0.3.rem
                centered - body("For CrowPay troubleshooting and help please contact:")
                centered - externalLink {
                    spacing = 1.dp
                    bold - colored(AppColors.blue.main) - body("help@crowpay.com")
                    to = "mailto:help@crowpay.com"
                }
            }
            row {
                buttonTheme - button {
                    specCenteredText("OK")
                    onClick { dialogScreenNavigator.dismiss() }
                }
            }
        }
    }
}