package com.crowpay.views.screens.common

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.fullIndentCol
import com.crowpay.views.components.project.work.projectTasks
import com.crowpay.views.screens.contractor.MightBeContractorScreen
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*

@Routable("contractor/tasks")
class ContractorWorkLists : WorkListsScreen(ProjectLens.Contractor) {
    override val projects = shared {
        val session = notNullSession()
        session.projects
            .query(
                Query(
                    limit = Int.MAX_VALUE,
                    condition = condition {
                        (it.contractor eq notNullContractor()).and(it.state.isActive())
                    },
                    orderBy = sort {
                        it.state.ascending()
                        it.name.ascending()
                    }
                )
            )()
    }
}

@Routable("client/tasks")
class ClientWorkLists : WorkListsScreen(ProjectLens.Customer) {
    override val projects = shared {
        val session = notNullSession()
        session.projects
            .query(
                Query(
                    limit = Int.MAX_VALUE,
                    condition = condition {
                        (it.customer eq session.userId).and(it.state.isActive())
                    },
                    orderBy = sort {
                        it.state.ascending()
                        it.name.ascending()
                    }
                )
            )()
    }
}

abstract class WorkListsScreen(override val lens: ProjectLens = ProjectLens.Customer) : Screen,
    MightBeContractorScreen {
    abstract val projects: Readable<List<Project>>
    val projectIDs = shared { projects().map { it._id } }

    val itemChanges = shared {
        notNullSession().itemChanges
            .query(
                Query(limit = Int.MAX_VALUE) { it.project inside projectIDs() }
            )()
    }
    val lineItems = shared {
        notNullSession().lineItems
            .query(
                Query(
                condition = condition<LineItem> { it.project inside projectIDs() },
                orderBy = sort { it.order.ascending() }
            ))()
            .map { item -> AdjustedLineItem(item, itemChanges().filter { it.itemId == item._id }) }
    }
    val punchLists = shared {
        notNullSession().punchLists.query(
            Query(
                condition { it.project inside projectIDs() },
                limit = Int.MAX_VALUE
            )
        )()
    }

    val expanded = shared {
        projectIDs().associateWith { Property(false) }
    }

    override fun ViewWriter.renderMainContent() {
        sizeConstraints(maxWidth = AppDimensions.pageWidth) - col {
            existsDefaultFalse { projects().isEmpty() }
            centered - subTitle("You have no work lists")
        }
        scrolls - unpadded - col {
            ::exists { projects().isNotEmpty() }
            xlTitle("Tasks")
            subtext("Manage and add tasks for all projects in one spot.")
            sizeConstraints(maxWidth = AppDimensions.pageWidth) - col {
                spacing = 0.px
                forEachUpdating(projects) { proj ->
                    val line = lineItems.share { list -> list.filter { it.project == proj()._id } }
                    val punch = punchLists.share { list -> list.filter { it.project == proj()._id } }

                    col {
                        spacing = 0.px
                        button {
                            row {
                                centered - expandIcon { expanded()[proj()._id]?.invoke() ?: false }
                                title {
                                    ::content { proj().name }
                                }
                                expanding - gravity(Align.End, Align.Center) - body {
                                    align = Align.End
                                    ::content{ proj().state.displayName }
                                    dynamicTheme {
                                        ProjectStateSemantic(proj().state)
                                    }
                                }
                            }
                            action = Action("Expand Project List") {
                                expanded()[proj()._id]?.toggle()
                            }
                        }
                        onlyWhen { expanded()[proj()._id]?.invoke() ?: false } - fullIndentCol {
                            space()
                            projectTasks(
                                proj,
                                line,
                                punch,
                                lens
                            )
                            space()
                        }
                        greySeparator()
                    }
                }
            }
        }
    }
}