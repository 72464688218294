package com.crowpay.views.screens.auth

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.basicInfoComplete
import com.crowpay.extensions.businessInfoComplete
import com.crowpay.extensions.isNotBlank
import com.crowpay.sdk.*
import com.crowpay.utils.goBackOrNavigateTo
import com.crowpay.utils.iconAndAction
import com.crowpay.utils.pushChanges
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.*
import com.crowpay.views.components.files.renderContractorDocuments
import com.crowpay.views.components.files.renderLicenses
import com.crowpay.views.screens.contractor.ContractorDashboard
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.errorText
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.and
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.eq
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

abstract class ContractorRegistrationSubscreen : Screen, Validator() {
    val preExisting = Property(false)
    val contractor = Draft {
        selectedContractor()?.let {
            currentSession()?.contractors?.get(it)?.invoke()?.also { preExisting.value = true }
        } ?: Contractor(
            name = "",
            email = "",
            phoneNumber = "",
            address = Address(),
            contactFirstName = "",
            contactLastName = "",
            contactEmail = "",
            contactPhoneNumber = "",
            contactAddress = Address(),
            stateEntityNumber = "",
            ein = ""
        )
    }

    suspend fun updateDataFrom(other: ContractorRegistrationSubscreen) = apply {
        preExisting.value = other.preExisting.value
        contractor set other.contractor.awaitOnce()
    }

    fun ViewWriter.backButton(prev: ()->ContractorRegistrationSubscreen) {
        button {
            spacing = 0.5.rem
            iconAndAction(Icon.arrowBack, "Back To Previous Screen") {
                screenNavigator.navigate(prev().updateDataFrom(this@ContractorRegistrationSubscreen))
            }
        }
    }

    val contractorID = contractor.lens { it._id }

    open val center: Boolean get() = false
    abstract fun ViewWriter.subscreen()

    override fun ViewWriter.render() = handleAuthRendering(center) { subscreen() }
}

@Routable("contractor/register")
object ContractorRegistrationRouter : Screen {
    override fun ViewWriter.render() {
        AuthBackgroundSemantic.onNext - stack {
            launch {
                val goto: Screen = currentContractor()?.let { contractor ->
                    if (!contractor.basicInfoComplete()) return@let BasicInfoScreen()
                    if (!contractor.businessInfoComplete()) return@let BusinessLicenseInfoScreen()
                    val session = currentSession() ?: return@let BasicInfoScreen()

                    val license = session.licenses.query(
                        Query(limit = 1) { it.contractor eq contractor._id }
                    )().firstOrNull()

                    val doc = session.contractorDocuments.query(
                        Query(limit = 1) { it.contractor eq contractor._id }
                    )().firstOrNull()

                    if (license == null || doc == null) return@let BusinessLicenseInfoScreen()

                    BusinessLicenseInfoScreen() // This will eventually be the OFAC screen, but for now this will suffice
                } ?: BasicInfoScreen()

                screenNavigator.navigate(goto)
            }
            centered - activityIndicator()
        }
    }
}


@Routable("contractor/register/basic-info")
class BasicInfoScreen : Screen, ContractorRegistrationSubscreen() {

    val contactAddress = contractor.lensPath { it.contactAddress }
    val sameAsBusiness = LazyProperty {
        val bus = contractor().address
        if (bus.isNotBlank()) bus == contactAddress() else false
    }

    override fun ViewWriter.subscreen() {
        sizeConstraints(width = AppDimensions.pageWidth) - col {
            spacing = AppDimensions.majorColumnSpacing
            col {
                xlTitle {
                    ::content { if (preExisting()) "Continue Registration" else "Contractor Registration" }
                }
                greySeparator()
                indicatesRequiredFieldKey()
            }

            col {
                title("Business Info")

                requiredField("Business/Contractor Name") {
                    val name = contractor.lensPath { it.name }.validateNotBlank()
                    textInput {
                        hint = "Your Business Name Here"
                        content bind name
                    }
                }

                requiredField("Email") {
                    val email = contractor.lensPath { it.email }.validate { it.matches(SharedUtils.emailPattern) }
                    textInput {
                        hint = "Email"
                        content bind email
                    }
                }

                requiredField("Phone Number") {
                    val phone = contractor.lensPath { it.phoneNumber }.validate { it.length == 10 }
                    phoneNumberInput {
                        hint = "Phone Number"
                        content bind phone
                    }
                }

                space()

                subTitle("Address")
                addressForm(contractor.lensPath { it.address }, this@BasicInfoScreen)
            }

            col {
                title("Contact Info")
                body {
                    ::exists { !preExisting() }
                    content = "This will be the information for your user"
                }

                requiredLabel("Name") {
                    val first = contractor.lensPath { it.contactFirstName }.validateNotBlank()
                    val last = contractor.lensPath { it.contactLastName }.validateNotBlank()

                    row {
                        expanding - fieldTheme - textInput {
                            hint = "First"
                            content bind first
                        }
                        expanding - fieldTheme - textInput {
                            hint = "Last"
                            content bind last
                        }
                    }
                }

                requiredField("Email") {
                    val email =
                        contractor.lensPath { it.contactEmail }.validate { it.matches(SharedUtils.emailPattern) }
                    textInput {
                        hint = "Email"
                        content bind email
                    }
                }

                requiredField("Phone Number") {
                    val phone = contractor.lensPath { it.contactPhoneNumber }.validate { it.length == 10 }
                    phoneNumberInput {
                        hint = "Phone Number"
                        content bind phone
                    }
                }

                space()

                subTitle("Address")
                row {
                    centered - checkbox { checked bind sameAsBusiness }
                    centered - body("Same As Business Address")
                }
                onlyWhen { !sameAsBusiness() } - addressForm(
                    contactAddress,
                    this@BasicInfoScreen,
                    forceValid = { sameAsBusiness() }
                )
            }

            lightSection - row {
                expanding - space()
                secondaryButton - button {
                    ::exists { currentSession() == null }
                    specCenteredText("Cancel")
                    onClick {
                        screenNavigator.reset(LogInScreen())
                    }
                }
                primaryButton - button {
                    ::enabled { allValid() }
                    specCenteredText("Next")
                    onClick {
                        if (sameAsBusiness()) contractor.modify {
                            it.copy(
                                contactAddress = it.address
                            )
                        }
                        contractor.publish()

                        val goto =
                            if (currentSession() == null) {
                                val token = selectedApi().api.userAuth.sendRegistrationEmail(contractor().contactEmail)
                                AuthenticateBasicInfoAndLogin(token).updateDataFrom(this@BasicInfoScreen)
                            } else {
                                contractor.pushChanges(notNullSession().contractors)
                                BusinessLicenseInfoScreen().updateDataFrom(this@BasicInfoScreen)
                            }

                        screenNavigator.navigate(goto)
                    }
                }
            }
        }
    }
}

@Routable("contractor/register/authenticate/{token}")
class AuthenticateBasicInfoAndLogin(val token: String) : ContractorRegistrationSubscreen() {
    override val center: Boolean = true

    override fun ViewWriter.subscreen() {
        EmailProof(
            token,
            { contractor().contactEmail },
            { screenNavigator.goBackOrNavigateTo { BasicInfoScreen() } }
        ) { proof ->
            val api = selectedApi().api

            api.contractor.register(RegisterContractor(contractor(), proof))

            val session = api.userAuth.logIn(listOf(proof))
            sessionToken set session.session!!
            selectedContractor set contractor()._id

            screenNavigator.navigate(BusinessLicenseInfoScreen().updateDataFrom(this@AuthenticateBasicInfoAndLogin))
        }.render(this)
    }
}

@Routable("contractor/register/license")
class BusinessLicenseInfoScreen : ContractorRegistrationSubscreen() {
    val licenses = shared {
        notNullSession().licenses.query(Query(condition { it.contractor eq contractorID() }))()
    }
    val documents = shared {
        notNullSession().contractorDocuments
            .query(
                Query(condition {
                    (it.contractor eq contractorID()) and (it.fileType eq "application/pdf")
                })
            )()
    }

    val showCustomTrade = Property(false)
    val trade = Property("").validate { it.isNotBlank() && it != "Other" && it != "Unselected" && it.length > 3 }

    override fun ViewWriter.subscreen() {
        scrolls - stack {
            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = AppDimensions.majorColumnSpacing
                col {
                    row {
                        button {
                            spacing = 0.5.rem
                            iconAndAction(Icon.arrowBack, "Back To Basic Info") {
                                screenNavigator.navigate(BasicInfoScreen().updateDataFrom(this@BusinessLicenseInfoScreen))
                            }
                        }
                        centered - xlTitle("Business License Info")
                    }
                    greySeparator()
                }
                reactiveSuspending {
                    val t = trade()
                    if (t == "Other" || t == "Unselected") {
                        contractor.modify {
                            it.copy(
                                trade = null
                            )
                        }
                    } else if (t.length > 3) {
                        contractor.modify {
                            it.copy(
                                trade = t
                            )
                        }
                    }
                }
                row {
                    expanding - requiredField("Trade") {
                        val trades = shared {
                            listOf("Unselected", "Other") + notNullSession().trades.query(Query(limit = Int.MAX_VALUE))().map { it._id }
                        }
                        select {
                            bind(trade, trades) { it }
                        }
                        reactiveSuspending {
                            if (trade() == "Other") {
                                trade.set("")
                                showCustomTrade.set(true)
                            } else if (
                                trades().any { it == trade() }
                            ) {
                                showCustomTrade.set(false)
                            }
                        }
                    }
                    expanding - requiredField("EIN/Social") {
                        val ein = contractor.lensPath { it.ein }.validateNotBlank()
                        textInput {
                            hint = "EIN/Social"
                            content bind ein
                        }
                    }
                }
                row {
                    exists = false
                    ::exists {
                        showCustomTrade()
                    }
                    expanding - field("Enter Trade") {
                        textInput {
                            hint = "Enter Trade"
                            content bind trade
                        }
                    }
                }
                requiredField("State Entity Number") {
                    val sen = contractor.lensPath { it.stateEntityNumber }.validateNotBlank()
                    textInput {
                        hint = "State Entity Number"
                        content bind sen
                    }
                }
                requiredLabel("State License(s)") {
                    addCondition { licenses().isNotEmpty() }
                    section - renderLicenses(licenses)
                    errorText()
                }
                requiredLabel("Articles of Incorporation") {
                    addCondition { documents().isNotEmpty() }
                    section - renderContractorDocuments(
                        documents,
                        listOf("application/pdf")
                    )
                    errorText()
                }

                lightSection - row {
                    expanding - space()
                    secondaryButton - button {
                        specCenteredText("Save")
                        onClick {
                            contractor.pushChanges(notNullSession().contractors)
                        }
                    }
                    primaryButton - button {
                        ::enabled { allValid() }
                        specCenteredText("Finish")
                        onClick {
                            val session = notNullSession()
                            contractor.pushChanges(session.contractors)
                            session.nonCached.contractor.submitForApproval(contractor()._id)
                            session.contractors.totallyInvalidate()
                            delay(500)
                            mainScreenNavigator.reset(ContractorDashboard())
                        }
                    }
                }
            }
        }
    }
}

@Routable("contractor/register/OFAC")
class VerificationAndOFACScreen : ContractorRegistrationSubscreen() {
    override fun ViewWriter.subscreen() {
        sizeConstraints(width = AppDimensions.pageWidth) - col {
            spacing = AppDimensions.majorColumnSpacing
            col {
                row {
                    button {
                        spacing = 0.5.rem
                        iconAndAction(Icon.arrowBack, "Back To License Info") {
                            screenNavigator.navigate(BusinessLicenseInfoScreen())
                        }
                    }
                    centered - xlTitle("Verification")
                }
                greySeparator()
            }
            subTitle("Under Construction")
            lightSection - row {
                expanding - space()
                primaryButton - button {
                    specCenteredText("Finish")
                    onClick {
                        val session = notNullSession()
//                            contractor.pushChanges(session.contractors)
                        session.nonCached.contractor.submitForApproval(contractor()._id)
                        session.contractors.totallyInvalidate()
                        delay(500)
                        mainScreenNavigator.reset(ContractorDashboard())
                    }
                }
            }
        }
    }
}