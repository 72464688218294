// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareIssueFields() {
    val props: Array<SerializableProperty<Issue, *>> = arrayOf(Issue_raised, Issue_resolved)
    Issue.serializer().properties { props }
}
val <K> DataClassPath<K, Issue>.raised: DataClassPath<K, AccessInfo> get() = this[Issue_raised]
val <K> DataClassPath<K, Issue>.resolved: DataClassPath<K, AccessInfo?> get() = this[Issue_resolved]
inline val Issue.Companion.path: DataClassPath<Issue, Issue> get() = path<Issue>()


object Issue_raised: SerializableProperty<Issue, AccessInfo> {
    override val name: String by lazy { Issue.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Issue): AccessInfo = receiver.raised
    override fun setCopy(receiver: Issue, value: AccessInfo) = receiver.copy(raised = value)
    override val serializer: KSerializer<AccessInfo> by lazy { (Issue.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<AccessInfo> }
    override val annotations: List<Annotation> by lazy { Issue.serializer().descriptor.getElementAnnotations(0) }
}
object Issue_resolved: SerializableProperty<Issue, AccessInfo?> {
    override val name: String by lazy { Issue.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Issue): AccessInfo? = receiver.resolved
    override fun setCopy(receiver: Issue, value: AccessInfo?) = receiver.copy(resolved = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (Issue.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { Issue.serializer().descriptor.getElementAnnotations(1) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
