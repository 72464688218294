package com.crowpay.utils

import com.crowpay.SharedUtils
import com.lightningkite.kiteui.Console
import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.ScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.Button
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.Condition
import com.lightningkite.lightningdb.HasId
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.SortPart
import com.lightningkite.lightningserver.db.ClientModelRestEndpoints
import kotlin.jvm.JvmName

fun <T> Writable<List<T>>.contains(value: T): Writable<Boolean> =
    lens(
        get = { value in it },
        modify = { old, bool -> if (bool) old + value else old - value }
    )

fun <T> Writable<List<T>>.containsDynamic(value: ReactiveContext.() -> T): Writable<Boolean> =
    dynamicLens(
        get = { value() in it },
        modify = { old, bool -> if (bool) old + value() else old - value() }
    )

fun <T, WRITE : ImmediateWritable<T>> WRITE.interceptImmediateWrite(action: WRITE.(T) -> Unit): ImmediateWritable<T> =
    object : ImmediateWritable<T>, ImmediateReadable<T> by this {
        override var value: T
            get() = this@interceptImmediateWrite.value
            set(value) {
                this@interceptImmediateWrite.action(value)
            }
    }

fun Long.renderDollars(): String = SharedUtils.formatDollars(this)

fun Long?.renderDollars(): String = this?.renderDollars() ?: "$0.00"

private val lowercaseBoundary = Regex("\\b[a-z]")
fun String.allCapsToTitle(): String = lowercase().replace(lowercaseBoundary) { it.value.uppercase() }

fun Double.renderPercent(decimals: Int = 2): String {
    val str = toString()
    return if (decimals <= 0) "%${str.substringBefore('.')}"
    else "%${str.substringBefore('.')}.${str.substringAfter('.', "0".repeat(decimals)).take(decimals)}"
}

val loremIpsum =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

val Icon.Companion.empty get() = Icon(2.rem, 2.rem, 0, -960, 960, 960, listOf())
val Icon.Companion.money
    get() = Icon(
        2.rem,
        2.rem,
        0,
        -960,
        960,
        960,
        listOf("M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z")
    )

suspend fun <T : HasId<ID>, ID : Comparable<ID>> ClientModelRestEndpoints<T, ID>.query(
    condition: Condition<T> = Condition.Always(),
    orderBy: List<SortPart<T>> = listOf(),
    skip: Int = 0,
    limit: Int = 100,
) = query(Query(condition, orderBy, skip, limit))


fun <T> RView.lazy(readable: Readable<T>, render: RView.(T) -> Unit) {
    reactiveScope {
        val item = readable()
        clearChildren()
        render(item)
    }
}


fun <T, S> RView.lazy(readable1: Readable<T>, readable2: Readable<S>, render: RView.(T, S) -> Unit) {
    val split = split()
    reactiveScope {
        val item1 = readable1()
        val item2 = readable2()
        this@lazy.clearChildren()
        with(split) {
            render(item1, item2)
        }
    }
}

fun RView.existsDefaultFalse(action: ReactiveContext.() -> Boolean) {
    exists = false
    ::exists.invoke(action)
}

fun <T, V> Readable<T>.share(action: ReactiveContext.(T) -> V): Readable<V> = shared { action(this@share()) }

fun <T, V> Writable<T>.dynamicLens(
    get: ReactiveContext.(T) -> V,
    set: suspend (V) -> T,
): Writable<V> = shared {
    get(this@dynamicLens())
}.withWrite {
    this@dynamicLens set set(it)
}

fun <T, V> Writable<T>.dynamicLens(
    get: ReactiveContext.(T) -> V,
    modify: suspend (T, V) -> T,
): Writable<V> = shared {
    get(this@dynamicLens())
}.withWrite {
    this@dynamicLens set modify(this@dynamicLens(), it)
}


fun <T, V> Readable<Writable<T>>.flatLens(
    get: (T) -> V,
    modify: (T, V) -> T,
): Writable<V> =
    shared { get(this@flatLens()()) }.withWrite { value ->
        val write = this@flatLens.await()
        write set modify(write(), value)
    }

fun Action(name: String, action: suspend () -> Unit): Action = Action(title = name, icon = Icon.empty, action = action)

fun Writable<Long>.toNullableDouble(): Writable<Double?> =
    lens(
        get = { it.toDouble() },
        modify = { o, it -> it?.toLong() ?: o }
    )

@JvmName(name = "NullableToNullableDouble")
fun Writable<Long?>.toNullableDouble(): Writable<Double?> =
    lens(
        get = { it?.toDouble() },
        modify = { _, it -> it?.toLong() }
    )

fun Writable<Long>.zeroToNull(): Writable<Double?> =
    lens(
        get = { it.takeUnless { it == 0L }?.toDouble() },
        set = { it?.toLong() ?: 0L }
    )

fun <T> Writable<T>.equalToDynamic(value: suspend () -> T): Writable<Boolean> =
    sharedSuspending { this@equalToDynamic.await() == value() }.withWrite {
        if (it) this@equalToDynamic.set(value())
    }

fun <T> Writable<T>.equalToReadable(readable: Readable<T>) = equalToDynamic { readable() }

fun Button.iconAndAction(icon: Icon, title: String, action: suspend ()->Unit) {
    centered - icon(icon, title)
    this.action = Action(title, icon = icon, action = action)
}

val <T:Any> Writable<T?>.notNull: Writable<T> get() =
    object : Writable<T>, Readable<T> by this.waitForNotNull {
        override suspend fun set(value: T) = this@notNull.set(value)
    }

fun <T> Readable<List<T>>.shareFilter(debug: Console? = null, selector: ReactiveContext.(T)->Boolean): Readable<List<T>>
    = SharedReadable(log = debug) { this@shareFilter().filter { selector(it) } }

const val BulletPoint = "•"

fun ScreenNavigator.goBackOrNavigateTo(screen: ()->Screen) { if (!goBack()) navigate(screen()) }

fun Icon.resize(size: Dimension) = copy(width = size, height = size)

fun <C, T> Readable<C>.flatLens(getWrite: (C)->Writable<T>): Writable<T> =
    shared {
        getWrite(this@flatLens()).invoke()
    }.withWrite {
        getWrite(this@flatLens()).set(it)
    }

suspend fun <T> Readable<T>.awaitSuspending(): T = await()

infix fun ScreenNavigator.navigateTo(to: Screen) = navigate(to)

//@ViewDsl
//fun ViewWriter.icon(icon: suspend ()->Icon, description: String, setup: Image.()->Unit = {}) {
//    image {
//        val currentTheme = currentTheme
//        ::source { icon().toImageSource(currentTheme().foreground) }
//        this.description = description
//        setup(this)
//    }
//}
//
//@ViewModifierDsl3 inline fun ViewWriter.maybeThemeFromLast(crossinline calculate: suspend (Theme)-> Theme?): ViewWrapper {
//    var x: Theme? = null
//    transitionNextView = ViewWriter.TransitionNextView.Maybe { x != null }
//    return themeModifier {
//        val previous = it()
//        val result = calculate(previous)
//        x = result
//        result ?: previous
//    }
//}
//