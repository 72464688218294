// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareLicenseFields() {
    val props: Array<SerializableProperty<License, *>> = arrayOf(License__id, License_contractor, License_number, License_city, License_state, License_file, License_fileType, License_preview, License_type)
    License.serializer().properties { props }
}
val <K> DataClassPath<K, License>._id: DataClassPath<K, UUID> get() = this[License__id]
val <K> DataClassPath<K, License>.contractor: DataClassPath<K, UUID> get() = this[License_contractor]
val <K> DataClassPath<K, License>.number: DataClassPath<K, String> get() = this[License_number]
val <K> DataClassPath<K, License>.city: DataClassPath<K, String?> get() = this[License_city]
val <K> DataClassPath<K, License>.state: DataClassPath<K, State> get() = this[License_state]
val <K> DataClassPath<K, License>.file: DataClassPath<K, ServerFile> get() = this[License_file]
val <K> DataClassPath<K, License>.fileType: DataClassPath<K, String> get() = this[License_fileType]
val <K> DataClassPath<K, License>.preview: DataClassPath<K, ServerFile?> get() = this[License_preview]
val <K> DataClassPath<K, License>.type: DataClassPath<K, LicenseType> get() = this[License_type]
inline val License.Companion.path: DataClassPath<License, License> get() = path<License>()


object License__id: SerializableProperty<License, UUID> {
    override val name: String by lazy { License.serializer().descriptor.getElementName(0) }
    override fun get(receiver: License): UUID = receiver._id
    override fun setCopy(receiver: License, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (License.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { License.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object License_contractor: SerializableProperty<License, UUID> {
    override val name: String by lazy { License.serializer().descriptor.getElementName(1) }
    override fun get(receiver: License): UUID = receiver.contractor
    override fun setCopy(receiver: License, value: UUID) = receiver.copy(contractor = value)
    override val serializer: KSerializer<UUID> by lazy { (License.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { License.serializer().descriptor.getElementAnnotations(1) }
}
object License_number: SerializableProperty<License, String> {
    override val name: String by lazy { License.serializer().descriptor.getElementName(2) }
    override fun get(receiver: License): String = receiver.number
    override fun setCopy(receiver: License, value: String) = receiver.copy(number = value)
    override val serializer: KSerializer<String> by lazy { (License.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { License.serializer().descriptor.getElementAnnotations(2) }
}
object License_city: SerializableProperty<License, String?> {
    override val name: String by lazy { License.serializer().descriptor.getElementName(3) }
    override fun get(receiver: License): String? = receiver.city
    override fun setCopy(receiver: License, value: String?) = receiver.copy(city = value)
    override val serializer: KSerializer<String?> by lazy { (License.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { License.serializer().descriptor.getElementAnnotations(3) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object License_state: SerializableProperty<License, State> {
    override val name: String by lazy { License.serializer().descriptor.getElementName(4) }
    override fun get(receiver: License): State = receiver.state
    override fun setCopy(receiver: License, value: State) = receiver.copy(state = value)
    override val serializer: KSerializer<State> by lazy { (License.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<State> }
    override val annotations: List<Annotation> by lazy { License.serializer().descriptor.getElementAnnotations(4) }
}
object License_file: SerializableProperty<License, ServerFile> {
    override val name: String by lazy { License.serializer().descriptor.getElementName(5) }
    override fun get(receiver: License): ServerFile = receiver.file
    override fun setCopy(receiver: License, value: ServerFile) = receiver.copy(file = value)
    override val serializer: KSerializer<ServerFile> by lazy { (License.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<ServerFile> }
    override val annotations: List<Annotation> by lazy { License.serializer().descriptor.getElementAnnotations(5) }
}
object License_fileType: SerializableProperty<License, String> {
    override val name: String by lazy { License.serializer().descriptor.getElementName(6) }
    override fun get(receiver: License): String = receiver.fileType
    override fun setCopy(receiver: License, value: String) = receiver.copy(fileType = value)
    override val serializer: KSerializer<String> by lazy { (License.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { License.serializer().descriptor.getElementAnnotations(6) }
}
object License_preview: SerializableProperty<License, ServerFile?> {
    override val name: String by lazy { License.serializer().descriptor.getElementName(7) }
    override fun get(receiver: License): ServerFile? = receiver.preview
    override fun setCopy(receiver: License, value: ServerFile?) = receiver.copy(preview = value)
    override val serializer: KSerializer<ServerFile?> by lazy { (License.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<ServerFile?> }
    override val annotations: List<Annotation> by lazy { License.serializer().descriptor.getElementAnnotations(7) }
    override val default: ServerFile? = null
    override val defaultCode: String? = "null" 
}
object License_type: SerializableProperty<License, LicenseType> {
    override val name: String by lazy { License.serializer().descriptor.getElementName(8) }
    override fun get(receiver: License): LicenseType = receiver.type
    override fun setCopy(receiver: License, value: LicenseType) = receiver.copy(type = value)
    override val serializer: KSerializer<LicenseType> by lazy { (License.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<LicenseType> }
    override val annotations: List<Annotation> by lazy { License.serializer().descriptor.getElementAnnotations(8) }
}
