package com.crowpay.views.theming

import com.lightningkite.kiteui.models.Semantic
import com.lightningkite.kiteui.models.Theme
import com.lightningkite.kiteui.models.ThemeAndBack

data object AuthBackgroundSemantic : Semantic {
    override val key: String = "authback"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        background = AppColors.grey.light1,
        revert = true
    ).withBack
}