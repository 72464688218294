// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareFiledForArbitrationFields() {
    val props: Array<SerializableProperty<FiledForArbitration, *>> = arrayOf(FiledForArbitration_accessInfo, FiledForArbitration_evidence)
    FiledForArbitration.serializer().properties { props }
}
val <K> DataClassPath<K, FiledForArbitration>.accessInfo: DataClassPath<K, AccessInfo> get() = this[FiledForArbitration_accessInfo]
val <K> DataClassPath<K, FiledForArbitration>.evidence: DataClassPath<K, List<ServerFile>> get() = this[FiledForArbitration_evidence]
inline val FiledForArbitration.Companion.path: DataClassPath<FiledForArbitration, FiledForArbitration> get() = path<FiledForArbitration>()


object FiledForArbitration_accessInfo: SerializableProperty<FiledForArbitration, AccessInfo> {
    override val name: String by lazy { FiledForArbitration.serializer().descriptor.getElementName(0) }
    override fun get(receiver: FiledForArbitration): AccessInfo = receiver.accessInfo
    override fun setCopy(receiver: FiledForArbitration, value: AccessInfo) = receiver.copy(accessInfo = value)
    override val serializer: KSerializer<AccessInfo> by lazy { (FiledForArbitration.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<AccessInfo> }
    override val annotations: List<Annotation> by lazy { FiledForArbitration.serializer().descriptor.getElementAnnotations(0) }
}
object FiledForArbitration_evidence: SerializableProperty<FiledForArbitration, List<ServerFile>> {
    override val name: String by lazy { FiledForArbitration.serializer().descriptor.getElementName(1) }
    override fun get(receiver: FiledForArbitration): List<ServerFile> = receiver.evidence
    override fun setCopy(receiver: FiledForArbitration, value: List<ServerFile>) = receiver.copy(evidence = value)
    override val serializer: KSerializer<List<ServerFile>> by lazy { (FiledForArbitration.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<List<ServerFile>> }
    override val annotations: List<Annotation> by lazy { FiledForArbitration.serializer().descriptor.getElementAnnotations(1) }
}
