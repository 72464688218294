package com.crowpay.actuals

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import kotlinx.browser.document

private object BoldTextCssClass {
    init {
        val styleNode = document.createElement("style")
        styleNode.innerHTML = """
            .inlineKeyValue strong {
                font-weight: bold !important
            }
        """.trimIndent()
        document.head?.appendChild(styleNode)
    }
}

@ViewModifierDsl3
actual val ViewWriter.boldStrongTag: ViewWrapper
    get() {
        beforeNextElementSetup {
            BoldTextCssClass
            this.native.classes.add("inlineKeyValue")
        }
        return ViewWrapper
    }