// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun preparePayApplicationFields() {
    val props: Array<SerializableProperty<PayApplication, *>> = arrayOf(PayApplication__id, PayApplication_project, PayApplication_number, PayApplication_created, PayApplication_submitted)
    PayApplication.serializer().properties { props }
}
val <K> DataClassPath<K, PayApplication>._id: DataClassPath<K, UUID> get() = this[PayApplication__id]
val <K> DataClassPath<K, PayApplication>.project: DataClassPath<K, UUID> get() = this[PayApplication_project]
val <K> DataClassPath<K, PayApplication>.number: DataClassPath<K, Int> get() = this[PayApplication_number]
val <K> DataClassPath<K, PayApplication>.created: DataClassPath<K, Instant> get() = this[PayApplication_created]
val <K> DataClassPath<K, PayApplication>.submitted: DataClassPath<K, Instant?> get() = this[PayApplication_submitted]
inline val PayApplication.Companion.path: DataClassPath<PayApplication, PayApplication> get() = path<PayApplication>()


object PayApplication__id: SerializableProperty<PayApplication, UUID> {
    override val name: String by lazy { PayApplication.serializer().descriptor.getElementName(0) }
    override fun get(receiver: PayApplication): UUID = receiver._id
    override fun setCopy(receiver: PayApplication, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (PayApplication.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PayApplication.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object PayApplication_project: SerializableProperty<PayApplication, UUID> {
    override val name: String by lazy { PayApplication.serializer().descriptor.getElementName(1) }
    override fun get(receiver: PayApplication): UUID = receiver.project
    override fun setCopy(receiver: PayApplication, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (PayApplication.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PayApplication.serializer().descriptor.getElementAnnotations(1) }
}
object PayApplication_number: SerializableProperty<PayApplication, Int> {
    override val name: String by lazy { PayApplication.serializer().descriptor.getElementName(2) }
    override fun get(receiver: PayApplication): Int = receiver.number
    override fun setCopy(receiver: PayApplication, value: Int) = receiver.copy(number = value)
    override val serializer: KSerializer<Int> by lazy { (PayApplication.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<Int> }
    override val annotations: List<Annotation> by lazy { PayApplication.serializer().descriptor.getElementAnnotations(2) }
}
object PayApplication_created: SerializableProperty<PayApplication, Instant> {
    override val name: String by lazy { PayApplication.serializer().descriptor.getElementName(3) }
    override fun get(receiver: PayApplication): Instant = receiver.created
    override fun setCopy(receiver: PayApplication, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (PayApplication.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { PayApplication.serializer().descriptor.getElementAnnotations(3) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object PayApplication_submitted: SerializableProperty<PayApplication, Instant?> {
    override val name: String by lazy { PayApplication.serializer().descriptor.getElementName(4) }
    override fun get(receiver: PayApplication): Instant? = receiver.submitted
    override fun setCopy(receiver: PayApplication, value: Instant?) = receiver.copy(submitted = value)
    override val serializer: KSerializer<Instant?> by lazy { (PayApplication.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { PayApplication.serializer().descriptor.getElementAnnotations(4) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
