// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareProjectLedgerItemFields() {
    val props: Array<SerializableProperty<ProjectLedgerItem, *>> = arrayOf(ProjectLedgerItem__id, ProjectLedgerItem_project, ProjectLedgerItem_lineItem, ProjectLedgerItem_contractor, ProjectLedgerItem_amount, ProjectLedgerItem_intentId, ProjectLedgerItem_state, ProjectLedgerItem_message, ProjectLedgerItem_lastStateChange, ProjectLedgerItem_created)
    ProjectLedgerItem.serializer().properties { props }
}
val <K> DataClassPath<K, ProjectLedgerItem>._id: DataClassPath<K, UUID> get() = this[ProjectLedgerItem__id]
val <K> DataClassPath<K, ProjectLedgerItem>.project: DataClassPath<K, UUID> get() = this[ProjectLedgerItem_project]
val <K> DataClassPath<K, ProjectLedgerItem>.lineItem: DataClassPath<K, UUID?> get() = this[ProjectLedgerItem_lineItem]
val <K> DataClassPath<K, ProjectLedgerItem>.contractor: DataClassPath<K, Boolean> get() = this[ProjectLedgerItem_contractor]
val <K> DataClassPath<K, ProjectLedgerItem>.amount: DataClassPath<K, Long> get() = this[ProjectLedgerItem_amount]
val <K> DataClassPath<K, ProjectLedgerItem>.intentId: DataClassPath<K, IntentId?> get() = this[ProjectLedgerItem_intentId]
val <K> DataClassPath<K, ProjectLedgerItem>.state: DataClassPath<K, PaymentState> get() = this[ProjectLedgerItem_state]
val <K> DataClassPath<K, ProjectLedgerItem>.message: DataClassPath<K, String?> get() = this[ProjectLedgerItem_message]
val <K> DataClassPath<K, ProjectLedgerItem>.lastStateChange: DataClassPath<K, Instant> get() = this[ProjectLedgerItem_lastStateChange]
val <K> DataClassPath<K, ProjectLedgerItem>.created: DataClassPath<K, Instant> get() = this[ProjectLedgerItem_created]
inline val ProjectLedgerItem.Companion.path: DataClassPath<ProjectLedgerItem, ProjectLedgerItem> get() = path<ProjectLedgerItem>()


object ProjectLedgerItem__id: SerializableProperty<ProjectLedgerItem, UUID> {
    override val name: String by lazy { ProjectLedgerItem.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ProjectLedgerItem): UUID = receiver._id
    override fun setCopy(receiver: ProjectLedgerItem, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ProjectLedgerItem.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ProjectLedgerItem.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object ProjectLedgerItem_project: SerializableProperty<ProjectLedgerItem, UUID> {
    override val name: String by lazy { ProjectLedgerItem.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ProjectLedgerItem): UUID = receiver.project
    override fun setCopy(receiver: ProjectLedgerItem, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (ProjectLedgerItem.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ProjectLedgerItem.serializer().descriptor.getElementAnnotations(1) }
}
object ProjectLedgerItem_lineItem: SerializableProperty<ProjectLedgerItem, UUID?> {
    override val name: String by lazy { ProjectLedgerItem.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ProjectLedgerItem): UUID? = receiver.lineItem
    override fun setCopy(receiver: ProjectLedgerItem, value: UUID?) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<UUID?> by lazy { (ProjectLedgerItem.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { ProjectLedgerItem.serializer().descriptor.getElementAnnotations(2) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object ProjectLedgerItem_contractor: SerializableProperty<ProjectLedgerItem, Boolean> {
    override val name: String by lazy { ProjectLedgerItem.serializer().descriptor.getElementName(3) }
    override fun get(receiver: ProjectLedgerItem): Boolean = receiver.contractor
    override fun setCopy(receiver: ProjectLedgerItem, value: Boolean) = receiver.copy(contractor = value)
    override val serializer: KSerializer<Boolean> by lazy { (ProjectLedgerItem.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ProjectLedgerItem.serializer().descriptor.getElementAnnotations(3) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object ProjectLedgerItem_amount: SerializableProperty<ProjectLedgerItem, Long> {
    override val name: String by lazy { ProjectLedgerItem.serializer().descriptor.getElementName(4) }
    override fun get(receiver: ProjectLedgerItem): Long = receiver.amount
    override fun setCopy(receiver: ProjectLedgerItem, value: Long) = receiver.copy(amount = value)
    override val serializer: KSerializer<Long> by lazy { (ProjectLedgerItem.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { ProjectLedgerItem.serializer().descriptor.getElementAnnotations(4) }
    override val default: Long = 0
    override val defaultCode: String? = "0" 
}
object ProjectLedgerItem_intentId: SerializableProperty<ProjectLedgerItem, IntentId?> {
    override val name: String by lazy { ProjectLedgerItem.serializer().descriptor.getElementName(5) }
    override fun get(receiver: ProjectLedgerItem): IntentId? = receiver.intentId
    override fun setCopy(receiver: ProjectLedgerItem, value: IntentId?) = receiver.copy(intentId = value)
    override val serializer: KSerializer<IntentId?> by lazy { (ProjectLedgerItem.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<IntentId?> }
    override val annotations: List<Annotation> by lazy { ProjectLedgerItem.serializer().descriptor.getElementAnnotations(5) }
    override val default: IntentId? = null
    override val defaultCode: String? = "null" 
}
object ProjectLedgerItem_state: SerializableProperty<ProjectLedgerItem, PaymentState> {
    override val name: String by lazy { ProjectLedgerItem.serializer().descriptor.getElementName(6) }
    override fun get(receiver: ProjectLedgerItem): PaymentState = receiver.state
    override fun setCopy(receiver: ProjectLedgerItem, value: PaymentState) = receiver.copy(state = value)
    override val serializer: KSerializer<PaymentState> by lazy { (ProjectLedgerItem.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<PaymentState> }
    override val annotations: List<Annotation> by lazy { ProjectLedgerItem.serializer().descriptor.getElementAnnotations(6) }
}
object ProjectLedgerItem_message: SerializableProperty<ProjectLedgerItem, String?> {
    override val name: String by lazy { ProjectLedgerItem.serializer().descriptor.getElementName(7) }
    override fun get(receiver: ProjectLedgerItem): String? = receiver.message
    override fun setCopy(receiver: ProjectLedgerItem, value: String?) = receiver.copy(message = value)
    override val serializer: KSerializer<String?> by lazy { (ProjectLedgerItem.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { ProjectLedgerItem.serializer().descriptor.getElementAnnotations(7) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object ProjectLedgerItem_lastStateChange: SerializableProperty<ProjectLedgerItem, Instant> {
    override val name: String by lazy { ProjectLedgerItem.serializer().descriptor.getElementName(8) }
    override fun get(receiver: ProjectLedgerItem): Instant = receiver.lastStateChange
    override fun setCopy(receiver: ProjectLedgerItem, value: Instant) = receiver.copy(lastStateChange = value)
    override val serializer: KSerializer<Instant> by lazy { (ProjectLedgerItem.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { ProjectLedgerItem.serializer().descriptor.getElementAnnotations(8) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object ProjectLedgerItem_created: SerializableProperty<ProjectLedgerItem, Instant> {
    override val name: String by lazy { ProjectLedgerItem.serializer().descriptor.getElementName(9) }
    override fun get(receiver: ProjectLedgerItem): Instant = receiver.created
    override fun setCopy(receiver: ProjectLedgerItem, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (ProjectLedgerItem.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { ProjectLedgerItem.serializer().descriptor.getElementAnnotations(9) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
