
package com.crowpay.sdk

import com.lightningkite.*
import com.lightningkite.kiteui.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.auth.*
import com.lightningkite.serialization.*
import kotlinx.datetime.*
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.crowpay.RegisterContractor
import kotlin.Unit
import kotlin.String
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.lightningkite.lightningserver.auth.subject.ProofsCheckResult
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.crowpay.RegisterUser
import com.crowpay.User
import com.lightningkite.lightningserver.monitoring.FunnelStart
import kotlin.Int
import com.lightningkite.lightningserver.exceptions.ReportedExceptionGroup
import kotlin.Long
import com.crowpay.ChangeRequest
import com.lightningkite.lightningdb.ModelPermissions
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.serialization.Partial
import com.lightningkite.lightningdb.MassModification
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.crowpay.ClientTermsAndConditionsAgreement
import com.crowpay.ClientTermsAndConditionsVersion
import kotlinx.datetime.Instant
import com.crowpay.Client
import com.crowpay.Contractor
import com.lightningkite.lightningserver.files.UploadInformation
import com.crowpay.AccountInfo
import com.crowpay.PaymentSetupRequest
import com.crowpay.PendingCashOut
import com.crowpay.ContractorDocument
import com.crowpay.ContractorTermsAndConditionsAgreement
import com.crowpay.ContractorTermsAndConditionsVersion
import com.crowpay.ItemChange
import com.crowpay.License
import com.crowpay.LineItem
import com.crowpay.Credit
import com.crowpay.ContractorNote
import com.crowpay.PendingItemChange
import com.crowpay.PendingLineItem
import com.crowpay.Payout
import com.crowpay.Project
import com.crowpay.PaymentRequest
import com.crowpay.ProjectLedgerItem
import com.crowpay.FiledForArbitration
import com.crowpay.PunchListItem
import com.crowpay.ScopeView
import com.crowpay.PayApplication
import com.crowpay.PayAppItem
import com.crowpay.Trade
import com.crowpay.LineItemCompletionMessage
import com.crowpay.CompletionMessageComment
import com.crowpay.ProjectTimer
import com.crowpay.Dispute
import com.crowpay.Incident
import com.crowpay.IncidentMessage
import com.crowpay.IncidentMessageComment
import com.crowpay.ProjectMessage
import com.lightningkite.lightningserver.auth.proof.OtpSecret
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.crowpay.PaymentMethod
import com.lightningkite.lightningserver.auth.subject.Session
import com.lightningkite.lightningserver.monitoring.FunnelSummary
import com.lightningkite.lightningserver.monitoring.FunnelInstance
import kotlinx.datetime.LocalDate

open class AbstractAnonymousSession(val api: Api) {
    val adminReports: AbstractAnonymousSessionAdminReportsApi = AbstractAnonymousSessionAdminReportsApi(api.adminReports)
    val changeRequest: AbstractAnonymousSessionChangeRequestApi = AbstractAnonymousSessionChangeRequestApi(api.changeRequest)
    val clientTermsAndConditionsAgreement: AbstractAnonymousSessionClientTermsAndConditionsAgreementApi = AbstractAnonymousSessionClientTermsAndConditionsAgreementApi(api.clientTermsAndConditionsAgreement)
    val clientTermsAndConditionsVersion: AbstractAnonymousSessionClientTermsAndConditionsVersionApi = AbstractAnonymousSessionClientTermsAndConditionsVersionApi(api.clientTermsAndConditionsVersion)
    val client: AbstractAnonymousSessionClientApi = AbstractAnonymousSessionClientApi(api.client)
    val contractor: AbstractAnonymousSessionContractorApi = AbstractAnonymousSessionContractorApi(api.contractor)
    val contractorDocument: AbstractAnonymousSessionContractorDocumentApi = AbstractAnonymousSessionContractorDocumentApi(api.contractorDocument)
    val contractorTermsAndConditionsAgreement: AbstractAnonymousSessionContractorTermsAndConditionsAgreementApi = AbstractAnonymousSessionContractorTermsAndConditionsAgreementApi(api.contractorTermsAndConditionsAgreement)
    val contractorTermsAndConditionsVersion: AbstractAnonymousSessionContractorTermsAndConditionsVersionApi = AbstractAnonymousSessionContractorTermsAndConditionsVersionApi(api.contractorTermsAndConditionsVersion)
    val itemChange: AbstractAnonymousSessionItemChangeApi = AbstractAnonymousSessionItemChangeApi(api.itemChange)
    val license: AbstractAnonymousSessionLicenseApi = AbstractAnonymousSessionLicenseApi(api.license)
    val lineItem: AbstractAnonymousSessionLineItemApi = AbstractAnonymousSessionLineItemApi(api.lineItem)
    val contractorNote: AbstractAnonymousSessionContractorNoteApi = AbstractAnonymousSessionContractorNoteApi(api.contractorNote)
    val pendingItemChange: AbstractAnonymousSessionPendingItemChangeApi = AbstractAnonymousSessionPendingItemChangeApi(api.pendingItemChange)
    val pendingLineItem: AbstractAnonymousSessionPendingLineItemApi = AbstractAnonymousSessionPendingLineItemApi(api.pendingLineItem)
    val payout: AbstractAnonymousSessionPayoutApi = AbstractAnonymousSessionPayoutApi(api.payout)
    val project: AbstractAnonymousSessionProjectApi = AbstractAnonymousSessionProjectApi(api.project)
    val projectLedgerItem: AbstractAnonymousSessionProjectLedgerItemApi = AbstractAnonymousSessionProjectLedgerItemApi(api.projectLedgerItem)
    val punchListItem: AbstractAnonymousSessionPunchListItemApi = AbstractAnonymousSessionPunchListItemApi(api.punchListItem)
    val scopeView: AbstractAnonymousSessionScopeViewApi = AbstractAnonymousSessionScopeViewApi(api.scopeView)
    val payApplication: AbstractAnonymousSessionPayApplicationApi = AbstractAnonymousSessionPayApplicationApi(api.payApplication)
    val payAppItem: AbstractAnonymousSessionPayAppItemApi = AbstractAnonymousSessionPayAppItemApi(api.payAppItem)
    val trade: AbstractAnonymousSessionTradeApi = AbstractAnonymousSessionTradeApi(api.trade)
    val user: AbstractAnonymousSessionUserApi = AbstractAnonymousSessionUserApi(api.user)
    val lineItemCompletionMessage: AbstractAnonymousSessionLineItemCompletionMessageApi = AbstractAnonymousSessionLineItemCompletionMessageApi(api.lineItemCompletionMessage)
    val completionMessageComment: AbstractAnonymousSessionCompletionMessageCommentApi = AbstractAnonymousSessionCompletionMessageCommentApi(api.completionMessageComment)
    val projectTimer: AbstractAnonymousSessionProjectTimerApi = AbstractAnonymousSessionProjectTimerApi(api.projectTimer)
    val dispute: AbstractAnonymousSessionDisputeApi = AbstractAnonymousSessionDisputeApi(api.dispute)
    val incident: AbstractAnonymousSessionIncidentApi = AbstractAnonymousSessionIncidentApi(api.incident)
    val incidentMessage: AbstractAnonymousSessionIncidentMessageApi = AbstractAnonymousSessionIncidentMessageApi(api.incidentMessage)
    val incidentMessageComment: AbstractAnonymousSessionIncidentMessageCommentApi = AbstractAnonymousSessionIncidentMessageCommentApi(api.incidentMessageComment)
    val projectMessage: AbstractAnonymousSessionProjectMessageApi = AbstractAnonymousSessionProjectMessageApi(api.projectMessage)
    val emailProof: AbstractAnonymousSessionEmailProofApi = AbstractAnonymousSessionEmailProofApi(api.emailProof)
    val otpSecret: AbstractAnonymousSessionOtpSecretApi = AbstractAnonymousSessionOtpSecretApi(api.otpSecret)
    val oneTimePasswordProof: AbstractAnonymousSessionOneTimePasswordProofApi = AbstractAnonymousSessionOneTimePasswordProofApi(api.oneTimePasswordProof)
    val userAuth: AbstractAnonymousSessionUserAuthApi = AbstractAnonymousSessionUserAuthApi(api.userAuth)
    val userSession: AbstractAnonymousSessionUserSessionApi = AbstractAnonymousSessionUserSessionApi(api.userSession)
    val funnelSummary: AbstractAnonymousSessionFunnelSummaryApi = AbstractAnonymousSessionFunnelSummaryApi(api.funnelSummary)
    val funnelInstance: AbstractAnonymousSessionFunnelInstanceApi = AbstractAnonymousSessionFunnelInstanceApi(api.funnelInstance)
    suspend fun getServerHealth(): ServerHealth = api.getServerHealth()
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    open class AbstractAnonymousSessionAdminReportsApi(val api: Api.AdminReportsApi) {
    }
    open class AbstractAnonymousSessionChangeRequestApi(val api: Api.ChangeRequestApi) {
    }
    open class AbstractAnonymousSessionClientTermsAndConditionsAgreementApi(val api: Api.ClientTermsAndConditionsAgreementApi) {
    }
    open class AbstractAnonymousSessionClientTermsAndConditionsVersionApi(val api: Api.ClientTermsAndConditionsVersionApi) {
    }
    open class AbstractAnonymousSessionClientApi(val api: Api.ClientApi) {
    }
    open class AbstractAnonymousSessionContractorApi(val api: Api.ContractorApi) {
        suspend fun register(input: RegisterContractor): Unit = api.register(input)
    }
    open class AbstractAnonymousSessionContractorDocumentApi(val api: Api.ContractorDocumentApi) {
    }
    open class AbstractAnonymousSessionContractorTermsAndConditionsAgreementApi(val api: Api.ContractorTermsAndConditionsAgreementApi) {
    }
    open class AbstractAnonymousSessionContractorTermsAndConditionsVersionApi(val api: Api.ContractorTermsAndConditionsVersionApi) {
    }
    open class AbstractAnonymousSessionItemChangeApi(val api: Api.ItemChangeApi) {
    }
    open class AbstractAnonymousSessionLicenseApi(val api: Api.LicenseApi) {
    }
    open class AbstractAnonymousSessionLineItemApi(val api: Api.LineItemApi) {
    }
    open class AbstractAnonymousSessionContractorNoteApi(val api: Api.ContractorNoteApi) {
    }
    open class AbstractAnonymousSessionPendingItemChangeApi(val api: Api.PendingItemChangeApi) {
    }
    open class AbstractAnonymousSessionPendingLineItemApi(val api: Api.PendingLineItemApi) {
    }
    open class AbstractAnonymousSessionPayoutApi(val api: Api.PayoutApi) {
    }
    open class AbstractAnonymousSessionProjectApi(val api: Api.ProjectApi) {
    }
    open class AbstractAnonymousSessionProjectLedgerItemApi(val api: Api.ProjectLedgerItemApi) {
    }
    open class AbstractAnonymousSessionPunchListItemApi(val api: Api.PunchListItemApi) {
    }
    open class AbstractAnonymousSessionScopeViewApi(val api: Api.ScopeViewApi) {
    }
    open class AbstractAnonymousSessionPayApplicationApi(val api: Api.PayApplicationApi) {
    }
    open class AbstractAnonymousSessionPayAppItemApi(val api: Api.PayAppItemApi) {
    }
    open class AbstractAnonymousSessionTradeApi(val api: Api.TradeApi) {
    }
    open class AbstractAnonymousSessionUserApi(val api: Api.UserApi) {
    }
    open class AbstractAnonymousSessionLineItemCompletionMessageApi(val api: Api.LineItemCompletionMessageApi) {
    }
    open class AbstractAnonymousSessionCompletionMessageCommentApi(val api: Api.CompletionMessageCommentApi) {
    }
    open class AbstractAnonymousSessionProjectTimerApi(val api: Api.ProjectTimerApi) {
    }
    open class AbstractAnonymousSessionDisputeApi(val api: Api.DisputeApi) {
    }
    open class AbstractAnonymousSessionIncidentApi(val api: Api.IncidentApi) {
    }
    open class AbstractAnonymousSessionIncidentMessageApi(val api: Api.IncidentMessageApi) {
    }
    open class AbstractAnonymousSessionIncidentMessageCommentApi(val api: Api.IncidentMessageCommentApi) {
    }
    open class AbstractAnonymousSessionProjectMessageApi(val api: Api.ProjectMessageApi) {
    }
    open class AbstractAnonymousSessionEmailProofApi(val api: Api.EmailProofApi): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    open class AbstractAnonymousSessionOtpSecretApi(val api: Api.OtpSecretApi) {
    }
    open class AbstractAnonymousSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi): OneTimePasswordProofClientEndpoints {
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    open class AbstractAnonymousSessionUserAuthApi(val api: Api.UserAuthApi): UserAuthClientEndpoints<UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
        suspend fun sendRegistrationEmail(input: String): String = api.sendRegistrationEmail(input)
        suspend fun registerUser(input: RegisterUser): User = api.registerUser(input)
    }
    open class AbstractAnonymousSessionUserSessionApi(val api: Api.UserSessionApi) {
    }
    open class AbstractAnonymousSessionFunnelSummaryApi(val api: Api.FunnelSummaryApi) {
    }
    open class AbstractAnonymousSessionFunnelInstanceApi(val api: Api.FunnelInstanceApi) {
        suspend fun startFunnelInstance(input: FunnelStart): UUID = api.startFunnelInstance(input, null, null)
        suspend fun errorFunnelInstance(id: UUID, input: String): Unit = api.errorFunnelInstance(id, input)
        suspend fun setStepFunnelInstance(id: UUID, input: Int): Unit = api.setStepFunnelInstance(id, input)
        suspend fun successFunnelInstance(id: UUID): Unit = api.successFunnelInstance(id)
    }
}

abstract class AbstractUserSession(api: Api, authToken: String, accessToken: suspend () -> String, masquerade: String? = null) {
    abstract val api: Api
    abstract val authToken: String
    abstract val accessToken: suspend () -> String
    open val masquerade: String? = null
    val adminReports: UserSessionAdminReportsApi = UserSessionAdminReportsApi(api.adminReports, authToken, accessToken, masquerade)
    val changeRequest: UserSessionChangeRequestApi = UserSessionChangeRequestApi(api.changeRequest, authToken, accessToken, masquerade)
    val clientTermsAndConditionsAgreement: UserSessionClientTermsAndConditionsAgreementApi = UserSessionClientTermsAndConditionsAgreementApi(api.clientTermsAndConditionsAgreement, authToken, accessToken, masquerade)
    val clientTermsAndConditionsVersion: UserSessionClientTermsAndConditionsVersionApi = UserSessionClientTermsAndConditionsVersionApi(api.clientTermsAndConditionsVersion, authToken, accessToken, masquerade)
    val client: UserSessionClientApi = UserSessionClientApi(api.client, authToken, accessToken, masquerade)
    val contractor: UserSessionContractorApi = UserSessionContractorApi(api.contractor, authToken, accessToken, masquerade)
    val contractorDocument: UserSessionContractorDocumentApi = UserSessionContractorDocumentApi(api.contractorDocument, authToken, accessToken, masquerade)
    val contractorTermsAndConditionsAgreement: UserSessionContractorTermsAndConditionsAgreementApi = UserSessionContractorTermsAndConditionsAgreementApi(api.contractorTermsAndConditionsAgreement, authToken, accessToken, masquerade)
    val contractorTermsAndConditionsVersion: UserSessionContractorTermsAndConditionsVersionApi = UserSessionContractorTermsAndConditionsVersionApi(api.contractorTermsAndConditionsVersion, authToken, accessToken, masquerade)
    val itemChange: UserSessionItemChangeApi = UserSessionItemChangeApi(api.itemChange, authToken, accessToken, masquerade)
    val license: UserSessionLicenseApi = UserSessionLicenseApi(api.license, authToken, accessToken, masquerade)
    val lineItem: UserSessionLineItemApi = UserSessionLineItemApi(api.lineItem, authToken, accessToken, masquerade)
    val contractorNote: UserSessionContractorNoteApi = UserSessionContractorNoteApi(api.contractorNote, authToken, accessToken, masquerade)
    val pendingItemChange: UserSessionPendingItemChangeApi = UserSessionPendingItemChangeApi(api.pendingItemChange, authToken, accessToken, masquerade)
    val pendingLineItem: UserSessionPendingLineItemApi = UserSessionPendingLineItemApi(api.pendingLineItem, authToken, accessToken, masquerade)
    val payout: UserSessionPayoutApi = UserSessionPayoutApi(api.payout, authToken, accessToken, masquerade)
    val project: UserSessionProjectApi = UserSessionProjectApi(api.project, authToken, accessToken, masquerade)
    val projectLedgerItem: UserSessionProjectLedgerItemApi = UserSessionProjectLedgerItemApi(api.projectLedgerItem, authToken, accessToken, masquerade)
    val punchListItem: UserSessionPunchListItemApi = UserSessionPunchListItemApi(api.punchListItem, authToken, accessToken, masquerade)
    val scopeView: UserSessionScopeViewApi = UserSessionScopeViewApi(api.scopeView, authToken, accessToken, masquerade)
    val payApplication: UserSessionPayApplicationApi = UserSessionPayApplicationApi(api.payApplication, authToken, accessToken, masquerade)
    val payAppItem: UserSessionPayAppItemApi = UserSessionPayAppItemApi(api.payAppItem, authToken, accessToken, masquerade)
    val trade: UserSessionTradeApi = UserSessionTradeApi(api.trade, authToken, accessToken, masquerade)
    val user: UserSessionUserApi = UserSessionUserApi(api.user, authToken, accessToken, masquerade)
    val lineItemCompletionMessage: UserSessionLineItemCompletionMessageApi = UserSessionLineItemCompletionMessageApi(api.lineItemCompletionMessage, authToken, accessToken, masquerade)
    val completionMessageComment: UserSessionCompletionMessageCommentApi = UserSessionCompletionMessageCommentApi(api.completionMessageComment, authToken, accessToken, masquerade)
    val projectTimer: UserSessionProjectTimerApi = UserSessionProjectTimerApi(api.projectTimer, authToken, accessToken, masquerade)
    val dispute: UserSessionDisputeApi = UserSessionDisputeApi(api.dispute, authToken, accessToken, masquerade)
    val incident: UserSessionIncidentApi = UserSessionIncidentApi(api.incident, authToken, accessToken, masquerade)
    val incidentMessage: UserSessionIncidentMessageApi = UserSessionIncidentMessageApi(api.incidentMessage, authToken, accessToken, masquerade)
    val incidentMessageComment: UserSessionIncidentMessageCommentApi = UserSessionIncidentMessageCommentApi(api.incidentMessageComment, authToken, accessToken, masquerade)
    val projectMessage: UserSessionProjectMessageApi = UserSessionProjectMessageApi(api.projectMessage, authToken, accessToken, masquerade)
    val emailProof: UserSessionEmailProofApi = UserSessionEmailProofApi(api.emailProof, authToken, accessToken, masquerade)
    val otpSecret: UserSessionOtpSecretApi = UserSessionOtpSecretApi(api.otpSecret, authToken, accessToken, masquerade)
    val oneTimePasswordProof: UserSessionOneTimePasswordProofApi = UserSessionOneTimePasswordProofApi(api.oneTimePasswordProof, authToken, accessToken, masquerade)
    val userAuth: UserSessionUserAuthApi = UserSessionUserAuthApi(api.userAuth, authToken, accessToken, masquerade)
    val userSession: UserSessionUserSessionApi = UserSessionUserSessionApi(api.userSession, authToken, accessToken, masquerade)
    val funnelSummary: UserSessionFunnelSummaryApi = UserSessionFunnelSummaryApi(api.funnelSummary, authToken, accessToken, masquerade)
    val funnelInstance: UserSessionFunnelInstanceApi = UserSessionFunnelInstanceApi(api.funnelInstance, authToken, accessToken, masquerade)
    suspend fun listRecentExceptions(): List<ReportedExceptionGroup> = api.listRecentExceptions(accessToken, masquerade)
    class UserSessionAdminReportsApi(val api: Api.AdminReportsApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?) {
        suspend fun totalInEscrow(): Long = api.totalInEscrow(accessToken, masquerade)
        suspend fun totalCashOutPending(): Long = api.totalCashOutPending(accessToken, masquerade)
        suspend fun totalInProgress(): Long = api.totalInProgress(accessToken, masquerade)
        suspend fun totalSubstantialCompletion(): Long = api.totalSubstantialCompletion(accessToken, masquerade)
        suspend fun totalByState(): Map<String, Long> = api.totalByState(accessToken, masquerade)
    }
    class UserSessionChangeRequestApi(val api: Api.ChangeRequestApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ChangeRequest, UUID> {
        override suspend fun default(): ChangeRequest = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ChangeRequest> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ChangeRequest>): List<ChangeRequest> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ChangeRequest>): List<Partial<ChangeRequest>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ChangeRequest = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ChangeRequest>): List<ChangeRequest> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ChangeRequest): ChangeRequest = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ChangeRequest): ChangeRequest = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ChangeRequest>): List<ChangeRequest> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ChangeRequest): ChangeRequest = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ChangeRequest>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ChangeRequest>): EntryChange<ChangeRequest> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ChangeRequest>): ChangeRequest = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ChangeRequest>): ChangeRequest = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ChangeRequest>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ChangeRequest>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ChangeRequest>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ChangeRequest>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ChangeRequest>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun publishChangeRequest(id: UUID): ChangeRequest = api.publishChangeRequest(id, accessToken, masquerade)
        suspend fun unPublishChangeRequest(id: UUID): ChangeRequest = api.unPublishChangeRequest(id, accessToken, masquerade)
        suspend fun approveLinkedItems(id: UUID, input: String): Unit = api.approveLinkedItems(id, input, accessToken, masquerade)
        suspend fun denyLinkedItems(id: UUID, input: String): Unit = api.denyLinkedItems(id, input, accessToken, masquerade)
    }
    class UserSessionClientTermsAndConditionsAgreementApi(val api: Api.ClientTermsAndConditionsAgreementApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ClientTermsAndConditionsAgreement, UUID> {
        override suspend fun default(): ClientTermsAndConditionsAgreement = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ClientTermsAndConditionsAgreement> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ClientTermsAndConditionsAgreement>): List<ClientTermsAndConditionsAgreement> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ClientTermsAndConditionsAgreement>): List<Partial<ClientTermsAndConditionsAgreement>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ClientTermsAndConditionsAgreement = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ClientTermsAndConditionsAgreement>): List<ClientTermsAndConditionsAgreement> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ClientTermsAndConditionsAgreement): ClientTermsAndConditionsAgreement = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ClientTermsAndConditionsAgreement): ClientTermsAndConditionsAgreement = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ClientTermsAndConditionsAgreement>): List<ClientTermsAndConditionsAgreement> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ClientTermsAndConditionsAgreement): ClientTermsAndConditionsAgreement = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ClientTermsAndConditionsAgreement>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ClientTermsAndConditionsAgreement>): EntryChange<ClientTermsAndConditionsAgreement> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ClientTermsAndConditionsAgreement>): ClientTermsAndConditionsAgreement = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ClientTermsAndConditionsAgreement>): ClientTermsAndConditionsAgreement = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ClientTermsAndConditionsAgreement>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ClientTermsAndConditionsAgreement>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ClientTermsAndConditionsAgreement>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ClientTermsAndConditionsAgreement>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ClientTermsAndConditionsAgreement>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionClientTermsAndConditionsVersionApi(val api: Api.ClientTermsAndConditionsVersionApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ClientTermsAndConditionsVersion, Instant> {
        override suspend fun default(): ClientTermsAndConditionsVersion = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ClientTermsAndConditionsVersion> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ClientTermsAndConditionsVersion>): List<ClientTermsAndConditionsVersion> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ClientTermsAndConditionsVersion>): List<Partial<ClientTermsAndConditionsVersion>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: Instant): ClientTermsAndConditionsVersion = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ClientTermsAndConditionsVersion>): List<ClientTermsAndConditionsVersion> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ClientTermsAndConditionsVersion): ClientTermsAndConditionsVersion = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: Instant, input: ClientTermsAndConditionsVersion): ClientTermsAndConditionsVersion = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ClientTermsAndConditionsVersion>): List<ClientTermsAndConditionsVersion> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: Instant, input: ClientTermsAndConditionsVersion): ClientTermsAndConditionsVersion = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ClientTermsAndConditionsVersion>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: Instant, input: Modification<ClientTermsAndConditionsVersion>): EntryChange<ClientTermsAndConditionsVersion> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: Instant, input: Modification<ClientTermsAndConditionsVersion>): ClientTermsAndConditionsVersion = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: Instant, input: Partial<ClientTermsAndConditionsVersion>): ClientTermsAndConditionsVersion = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ClientTermsAndConditionsVersion>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: Instant): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ClientTermsAndConditionsVersion>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ClientTermsAndConditionsVersion>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ClientTermsAndConditionsVersion>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ClientTermsAndConditionsVersion>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionClientApi(val api: Api.ClientApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Client, UUID> {
        override suspend fun default(): Client = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Client> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Client>): List<Client> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Client>): List<Partial<Client>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Client = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Client>): List<Client> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Client): Client = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Client): Client = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Client>): List<Client> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Client): Client = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Client>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Client>): EntryChange<Client> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Client>): Client = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Client>): Client = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Client>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Client>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Client>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Client>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Client>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionContractorApi(val api: Api.ContractorApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Contractor, UUID> {
        override suspend fun default(): Contractor = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Contractor> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Contractor>): List<Contractor> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Contractor>): List<Partial<Contractor>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Contractor = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Contractor>): List<Contractor> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Contractor): Contractor = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Contractor): Contractor = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Contractor>): List<Contractor> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Contractor): Contractor = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Contractor>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Contractor>): EntryChange<Contractor> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Contractor>): Contractor = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Contractor>): Contractor = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Contractor>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Contractor>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Contractor>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Contractor>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Contractor>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(accessToken, masquerade)
        suspend fun submitForApproval(id: UUID): Unit = api.submitForApproval(id, accessToken, masquerade)
        suspend fun getPaymentInfo(id: UUID): AccountInfo? = api.getPaymentInfo(id, accessToken, masquerade)
        suspend fun setupPaymentInfo(id: UUID, input: PaymentSetupRequest): AccountInfo = api.setupPaymentInfo(id, input, accessToken, masquerade)
        suspend fun clearPaymentInfo(id: UUID): Unit = api.clearPaymentInfo(id, accessToken, masquerade)
        suspend fun pendingCashOut(id: UUID): PendingCashOut = api.pendingCashOut(id, accessToken, masquerade)
        suspend fun requestCashOut(id: UUID): Unit = api.requestCashOut(id, accessToken, masquerade)
    }
    class UserSessionContractorDocumentApi(val api: Api.ContractorDocumentApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ContractorDocument, UUID> {
        override suspend fun default(): ContractorDocument = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ContractorDocument> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ContractorDocument>): List<ContractorDocument> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ContractorDocument>): List<Partial<ContractorDocument>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ContractorDocument = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ContractorDocument>): List<ContractorDocument> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ContractorDocument): ContractorDocument = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ContractorDocument): ContractorDocument = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ContractorDocument>): List<ContractorDocument> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ContractorDocument): ContractorDocument = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ContractorDocument>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ContractorDocument>): EntryChange<ContractorDocument> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ContractorDocument>): ContractorDocument = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ContractorDocument>): ContractorDocument = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ContractorDocument>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ContractorDocument>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ContractorDocument>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ContractorDocument>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorDocument>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(accessToken, masquerade)
    }
    class UserSessionContractorTermsAndConditionsAgreementApi(val api: Api.ContractorTermsAndConditionsAgreementApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ContractorTermsAndConditionsAgreement, UUID> {
        override suspend fun default(): ContractorTermsAndConditionsAgreement = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ContractorTermsAndConditionsAgreement> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ContractorTermsAndConditionsAgreement>): List<ContractorTermsAndConditionsAgreement> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ContractorTermsAndConditionsAgreement>): List<Partial<ContractorTermsAndConditionsAgreement>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ContractorTermsAndConditionsAgreement = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ContractorTermsAndConditionsAgreement>): List<ContractorTermsAndConditionsAgreement> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ContractorTermsAndConditionsAgreement): ContractorTermsAndConditionsAgreement = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ContractorTermsAndConditionsAgreement): ContractorTermsAndConditionsAgreement = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ContractorTermsAndConditionsAgreement>): List<ContractorTermsAndConditionsAgreement> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ContractorTermsAndConditionsAgreement): ContractorTermsAndConditionsAgreement = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ContractorTermsAndConditionsAgreement>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ContractorTermsAndConditionsAgreement>): EntryChange<ContractorTermsAndConditionsAgreement> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ContractorTermsAndConditionsAgreement>): ContractorTermsAndConditionsAgreement = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ContractorTermsAndConditionsAgreement>): ContractorTermsAndConditionsAgreement = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ContractorTermsAndConditionsAgreement>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ContractorTermsAndConditionsAgreement>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ContractorTermsAndConditionsAgreement>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ContractorTermsAndConditionsAgreement>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorTermsAndConditionsAgreement>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionContractorTermsAndConditionsVersionApi(val api: Api.ContractorTermsAndConditionsVersionApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ContractorTermsAndConditionsVersion, Instant> {
        override suspend fun default(): ContractorTermsAndConditionsVersion = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ContractorTermsAndConditionsVersion> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ContractorTermsAndConditionsVersion>): List<ContractorTermsAndConditionsVersion> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ContractorTermsAndConditionsVersion>): List<Partial<ContractorTermsAndConditionsVersion>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: Instant): ContractorTermsAndConditionsVersion = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ContractorTermsAndConditionsVersion>): List<ContractorTermsAndConditionsVersion> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ContractorTermsAndConditionsVersion): ContractorTermsAndConditionsVersion = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: Instant, input: ContractorTermsAndConditionsVersion): ContractorTermsAndConditionsVersion = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ContractorTermsAndConditionsVersion>): List<ContractorTermsAndConditionsVersion> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: Instant, input: ContractorTermsAndConditionsVersion): ContractorTermsAndConditionsVersion = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ContractorTermsAndConditionsVersion>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: Instant, input: Modification<ContractorTermsAndConditionsVersion>): EntryChange<ContractorTermsAndConditionsVersion> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: Instant, input: Modification<ContractorTermsAndConditionsVersion>): ContractorTermsAndConditionsVersion = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: Instant, input: Partial<ContractorTermsAndConditionsVersion>): ContractorTermsAndConditionsVersion = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ContractorTermsAndConditionsVersion>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: Instant): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ContractorTermsAndConditionsVersion>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ContractorTermsAndConditionsVersion>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ContractorTermsAndConditionsVersion>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorTermsAndConditionsVersion>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionItemChangeApi(val api: Api.ItemChangeApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ItemChange, UUID> {
        override suspend fun default(): ItemChange = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ItemChange> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ItemChange>): List<ItemChange> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ItemChange>): List<Partial<ItemChange>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ItemChange = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ItemChange>): List<ItemChange> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ItemChange): ItemChange = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ItemChange): ItemChange = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ItemChange>): List<ItemChange> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ItemChange): ItemChange = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ItemChange>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ItemChange>): EntryChange<ItemChange> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ItemChange>): ItemChange = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ItemChange>): ItemChange = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ItemChange>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ItemChange>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ItemChange>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ItemChange>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ItemChange>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionLicenseApi(val api: Api.LicenseApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<License, UUID> {
        override suspend fun default(): License = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<License> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<License>): List<License> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<License>): List<Partial<License>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): License = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<License>): List<License> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: License): License = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: License): License = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<License>): List<License> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: License): License = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<License>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<License>): EntryChange<License> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<License>): License = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<License>): License = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<License>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<License>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<License>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<License>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<License>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(accessToken, masquerade)
    }
    class UserSessionLineItemApi(val api: Api.LineItemApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<LineItem, UUID> {
        override suspend fun default(): LineItem = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<LineItem> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<LineItem>): List<LineItem> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<LineItem>): List<Partial<LineItem>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): LineItem = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<LineItem>): List<LineItem> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: LineItem): LineItem = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: LineItem): LineItem = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<LineItem>): List<LineItem> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: LineItem): LineItem = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<LineItem>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<LineItem>): EntryChange<LineItem> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<LineItem>): LineItem = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<LineItem>): LineItem = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<LineItem>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<LineItem>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<LineItem>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<LineItem>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<LineItem>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun forceRefreshLineItemState(id: UUID): Unit = api.forceRefreshLineItemState(id, accessToken, masquerade)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(accessToken, masquerade)
        suspend fun requestReview(id: UUID): LineItem = api.requestReview(id, accessToken, masquerade)
        suspend fun complete(id: UUID): LineItem = api.complete(id, accessToken, masquerade)
        suspend fun addCredit(id: UUID, input: Credit): LineItem = api.addCredit(id, input, accessToken, masquerade)
    }
    class UserSessionContractorNoteApi(val api: Api.ContractorNoteApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ContractorNote, UUID> {
        override suspend fun default(): ContractorNote = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ContractorNote> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ContractorNote>): List<ContractorNote> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ContractorNote>): List<Partial<ContractorNote>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ContractorNote = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ContractorNote>): List<ContractorNote> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ContractorNote): ContractorNote = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ContractorNote): ContractorNote = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ContractorNote>): List<ContractorNote> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ContractorNote): ContractorNote = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ContractorNote>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ContractorNote>): EntryChange<ContractorNote> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ContractorNote>): ContractorNote = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ContractorNote>): ContractorNote = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ContractorNote>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ContractorNote>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ContractorNote>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ContractorNote>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorNote>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionPendingItemChangeApi(val api: Api.PendingItemChangeApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<PendingItemChange, UUID> {
        override suspend fun default(): PendingItemChange = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<PendingItemChange> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<PendingItemChange>): List<PendingItemChange> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<PendingItemChange>): List<Partial<PendingItemChange>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): PendingItemChange = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<PendingItemChange>): List<PendingItemChange> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: PendingItemChange): PendingItemChange = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: PendingItemChange): PendingItemChange = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<PendingItemChange>): List<PendingItemChange> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: PendingItemChange): PendingItemChange = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<PendingItemChange>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PendingItemChange>): EntryChange<PendingItemChange> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<PendingItemChange>): PendingItemChange = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<PendingItemChange>): PendingItemChange = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<PendingItemChange>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<PendingItemChange>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<PendingItemChange>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<PendingItemChange>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PendingItemChange>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun acceptPendingItemChange(id: UUID, input: String): PendingItemChange = api.acceptPendingItemChange(id, input, accessToken, masquerade)
        suspend fun denyPendingItemChange(id: UUID, input: String): EntryChange<ChangeRequest> = api.denyPendingItemChange(id, input, accessToken, masquerade)
    }
    class UserSessionPendingLineItemApi(val api: Api.PendingLineItemApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<PendingLineItem, UUID> {
        override suspend fun default(): PendingLineItem = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<PendingLineItem> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<PendingLineItem>): List<PendingLineItem> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<PendingLineItem>): List<Partial<PendingLineItem>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): PendingLineItem = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<PendingLineItem>): List<PendingLineItem> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: PendingLineItem): PendingLineItem = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: PendingLineItem): PendingLineItem = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<PendingLineItem>): List<PendingLineItem> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: PendingLineItem): PendingLineItem = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<PendingLineItem>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PendingLineItem>): EntryChange<PendingLineItem> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<PendingLineItem>): PendingLineItem = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<PendingLineItem>): PendingLineItem = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<PendingLineItem>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<PendingLineItem>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<PendingLineItem>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<PendingLineItem>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PendingLineItem>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun acceptPendingLineItem(id: UUID, input: String): PendingLineItem = api.acceptPendingLineItem(id, input, accessToken, masquerade)
        suspend fun denyPendingLineItem(id: UUID, input: String): EntryChange<ChangeRequest> = api.denyPendingLineItem(id, input, accessToken, masquerade)
    }
    class UserSessionPayoutApi(val api: Api.PayoutApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Payout, Instant> {
        override suspend fun default(): Payout = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Payout> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Payout>): List<Payout> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Payout>): List<Partial<Payout>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: Instant): Payout = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Payout>): List<Payout> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Payout): Payout = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: Instant, input: Payout): Payout = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Payout>): List<Payout> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: Instant, input: Payout): Payout = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Payout>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: Instant, input: Modification<Payout>): EntryChange<Payout> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: Instant, input: Modification<Payout>): Payout = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: Instant, input: Partial<Payout>): Payout = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Payout>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: Instant): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Payout>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Payout>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Payout>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Payout>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionProjectApi(val api: Api.ProjectApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Project, UUID> {
        override suspend fun default(): Project = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Project> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Project>): List<Project> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Project>): List<Partial<Project>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Project = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Project>): List<Project> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Project): Project = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Project): Project = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Project>): List<Project> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Project): Project = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Project>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Project>): EntryChange<Project> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Project>): Project = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Project>): Project = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Project>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Project>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Project>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Project>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Project>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun forceRefreshProjectState(project: UUID): Project = api.forceRefreshProjectState(project, accessToken, masquerade)
        suspend fun cancelProject(project: UUID): Project = api.cancelProject(project, accessToken, masquerade)
        suspend fun abandonProject(project: UUID): Project = api.abandonProject(project, accessToken, masquerade)
        suspend fun archiveProject(project: UUID): Project = api.archiveProject(project, accessToken, masquerade)
        suspend fun publishProject(project: UUID): Project = api.publishProject(project, accessToken, masquerade)
        suspend fun unpublishProject(project: UUID): Project = api.unpublishProject(project, accessToken, masquerade)
        suspend fun anonymousLink(project: UUID): String = api.anonymousLink(project, accessToken, masquerade)
        suspend fun notifyViewed(project: UUID): Project = api.notifyViewed(project, accessToken, masquerade)
        suspend fun acceptProject(project: UUID, input: String): Project = api.acceptProject(project, input, accessToken, masquerade)
        suspend fun requestFunding(project: UUID): Project = api.requestFunding(project, accessToken, masquerade)
        suspend fun cancelFundingRequest(project: UUID): Project = api.cancelFundingRequest(project, accessToken, masquerade)
        suspend fun payIntoEscrow(project: UUID, input: PaymentRequest): ProjectLedgerItem? = api.payIntoEscrow(project, input, accessToken, masquerade)
        suspend fun disputeProject(project: UUID, input: String): Project = api.disputeProject(project, input, accessToken, masquerade)
        suspend fun endDispute(project: UUID): Project = api.endDispute(project, accessToken, masquerade)
        suspend fun addArbitrationDocuments(project: UUID, input: FiledForArbitration): Unit = api.addArbitrationDocuments(project, input, accessToken, masquerade)
        suspend fun requestComplete(project: UUID): Project = api.requestComplete(project, accessToken, masquerade)
        suspend fun acceptComplete(project: UUID, input: String): Project = api.acceptComplete(project, input, accessToken, masquerade)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(accessToken, masquerade)
    }
    class UserSessionProjectLedgerItemApi(val api: Api.ProjectLedgerItemApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ProjectLedgerItem, UUID> {
        override suspend fun default(): ProjectLedgerItem = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ProjectLedgerItem> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ProjectLedgerItem>): List<ProjectLedgerItem> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ProjectLedgerItem>): List<Partial<ProjectLedgerItem>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ProjectLedgerItem = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ProjectLedgerItem>): List<ProjectLedgerItem> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ProjectLedgerItem): ProjectLedgerItem = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ProjectLedgerItem): ProjectLedgerItem = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ProjectLedgerItem>): List<ProjectLedgerItem> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ProjectLedgerItem): ProjectLedgerItem = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ProjectLedgerItem>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ProjectLedgerItem>): EntryChange<ProjectLedgerItem> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ProjectLedgerItem>): ProjectLedgerItem = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ProjectLedgerItem>): ProjectLedgerItem = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ProjectLedgerItem>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ProjectLedgerItem>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ProjectLedgerItem>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ProjectLedgerItem>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ProjectLedgerItem>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionPunchListItemApi(val api: Api.PunchListItemApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<PunchListItem, UUID> {
        override suspend fun default(): PunchListItem = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<PunchListItem> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<PunchListItem>): List<PunchListItem> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<PunchListItem>): List<Partial<PunchListItem>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): PunchListItem = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<PunchListItem>): List<PunchListItem> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: PunchListItem): PunchListItem = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: PunchListItem): PunchListItem = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<PunchListItem>): List<PunchListItem> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: PunchListItem): PunchListItem = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<PunchListItem>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PunchListItem>): EntryChange<PunchListItem> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<PunchListItem>): PunchListItem = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<PunchListItem>): PunchListItem = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<PunchListItem>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<PunchListItem>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<PunchListItem>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<PunchListItem>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PunchListItem>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun completePunchListItem(id: UUID): PunchListItem = api.completePunchListItem(id, accessToken, masquerade)
        suspend fun resetPunchListItem(id: UUID): PunchListItem = api.resetPunchListItem(id, accessToken, masquerade)
    }
    class UserSessionScopeViewApi(val api: Api.ScopeViewApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ScopeView, UUID> {
        override suspend fun default(): ScopeView = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ScopeView> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ScopeView>): List<ScopeView> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ScopeView>): List<Partial<ScopeView>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ScopeView = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ScopeView>): List<ScopeView> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ScopeView): ScopeView = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ScopeView): ScopeView = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ScopeView>): List<ScopeView> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ScopeView): ScopeView = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ScopeView>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ScopeView>): EntryChange<ScopeView> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ScopeView>): ScopeView = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ScopeView>): ScopeView = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ScopeView>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ScopeView>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ScopeView>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ScopeView>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ScopeView>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun updateItems(id: UUID, input: List<UUID>): Unit = api.updateItems(id, input, accessToken, masquerade)
        suspend fun addItems(id: UUID, input: List<UUID>): Unit = api.addItems(id, input, accessToken, masquerade)
    }
    class UserSessionPayApplicationApi(val api: Api.PayApplicationApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<PayApplication, UUID> {
        override suspend fun default(): PayApplication = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<PayApplication> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<PayApplication>): List<PayApplication> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<PayApplication>): List<Partial<PayApplication>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): PayApplication = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<PayApplication>): List<PayApplication> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: PayApplication): PayApplication = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: PayApplication): PayApplication = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<PayApplication>): List<PayApplication> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: PayApplication): PayApplication = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<PayApplication>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PayApplication>): EntryChange<PayApplication> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<PayApplication>): PayApplication = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<PayApplication>): PayApplication = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<PayApplication>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<PayApplication>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<PayApplication>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<PayApplication>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PayApplication>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun submitPayApplication(id: UUID): Unit = api.submitPayApplication(id, accessToken, masquerade)
        suspend fun withdrawPayApplication(id: UUID): Unit = api.withdrawPayApplication(id, accessToken, masquerade)
    }
    class UserSessionPayAppItemApi(val api: Api.PayAppItemApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<PayAppItem, UUID> {
        override suspend fun default(): PayAppItem = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<PayAppItem> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<PayAppItem>): List<PayAppItem> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<PayAppItem>): List<Partial<PayAppItem>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): PayAppItem = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<PayAppItem>): List<PayAppItem> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: PayAppItem): PayAppItem = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: PayAppItem): PayAppItem = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<PayAppItem>): List<PayAppItem> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: PayAppItem): PayAppItem = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<PayAppItem>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PayAppItem>): EntryChange<PayAppItem> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<PayAppItem>): PayAppItem = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<PayAppItem>): PayAppItem = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<PayAppItem>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<PayAppItem>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<PayAppItem>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<PayAppItem>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PayAppItem>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun approvePayApp(id: UUID): Unit = api.approvePayApp(id, accessToken, masquerade)
        suspend fun approvePayApps(input: List<UUID>): Unit = api.approvePayApps(input, accessToken, masquerade)
        suspend fun denyPayApp(id: UUID): Unit = api.denyPayApp(id, accessToken, masquerade)
        suspend fun denyPayApps(input: List<UUID>): Unit = api.denyPayApps(input, accessToken, masquerade)
        suspend fun voidPayApp(id: UUID): Unit = api.voidPayApp(id, accessToken, masquerade)
    }
    class UserSessionTradeApi(val api: Api.TradeApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Trade, String> {
        override suspend fun default(): Trade = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Trade> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Trade>): List<Trade> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Trade>): List<Partial<Trade>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: String): Trade = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Trade>): List<Trade> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Trade): Trade = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: String, input: Trade): Trade = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Trade>): List<Trade> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: String, input: Trade): Trade = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Trade>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: String, input: Modification<Trade>): EntryChange<Trade> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: String, input: Modification<Trade>): Trade = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: String, input: Partial<Trade>): Trade = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Trade>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: String): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Trade>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Trade>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Trade>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Trade>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionUserApi(val api: Api.UserApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<User, UUID> {
        override suspend fun default(): User = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<User> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<User>): List<User> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<User>): List<Partial<User>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): User = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<User>): List<User> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: User): User = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: User): User = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<User>): List<User> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: User): User = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<User>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>): EntryChange<User> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<User>): User = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<User>): User = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<User>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<User>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<User>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<User>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionLineItemCompletionMessageApi(val api: Api.LineItemCompletionMessageApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<LineItemCompletionMessage, UUID> {
        override suspend fun default(): LineItemCompletionMessage = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<LineItemCompletionMessage> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<LineItemCompletionMessage>): List<LineItemCompletionMessage> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<LineItemCompletionMessage>): List<Partial<LineItemCompletionMessage>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): LineItemCompletionMessage = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<LineItemCompletionMessage>): List<LineItemCompletionMessage> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: LineItemCompletionMessage): LineItemCompletionMessage = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: LineItemCompletionMessage): LineItemCompletionMessage = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<LineItemCompletionMessage>): List<LineItemCompletionMessage> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: LineItemCompletionMessage): LineItemCompletionMessage = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<LineItemCompletionMessage>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<LineItemCompletionMessage>): EntryChange<LineItemCompletionMessage> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<LineItemCompletionMessage>): LineItemCompletionMessage = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<LineItemCompletionMessage>): LineItemCompletionMessage = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<LineItemCompletionMessage>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<LineItemCompletionMessage>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<LineItemCompletionMessage>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<LineItemCompletionMessage>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<LineItemCompletionMessage>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun sendMessage(input: LineItemCompletionMessage): LineItemCompletionMessage = api.sendMessage(input, accessToken, masquerade)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(accessToken, masquerade)
    }
    class UserSessionCompletionMessageCommentApi(val api: Api.CompletionMessageCommentApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<CompletionMessageComment, UUID> {
        override suspend fun default(): CompletionMessageComment = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<CompletionMessageComment> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<CompletionMessageComment>): List<CompletionMessageComment> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<CompletionMessageComment>): List<Partial<CompletionMessageComment>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): CompletionMessageComment = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<CompletionMessageComment>): List<CompletionMessageComment> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: CompletionMessageComment): CompletionMessageComment = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: CompletionMessageComment): CompletionMessageComment = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<CompletionMessageComment>): List<CompletionMessageComment> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: CompletionMessageComment): CompletionMessageComment = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<CompletionMessageComment>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<CompletionMessageComment>): EntryChange<CompletionMessageComment> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<CompletionMessageComment>): CompletionMessageComment = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<CompletionMessageComment>): CompletionMessageComment = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<CompletionMessageComment>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<CompletionMessageComment>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<CompletionMessageComment>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<CompletionMessageComment>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<CompletionMessageComment>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionProjectTimerApi(val api: Api.ProjectTimerApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ProjectTimer, UUID> {
        override suspend fun default(): ProjectTimer = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ProjectTimer> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ProjectTimer>): List<ProjectTimer> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ProjectTimer>): List<Partial<ProjectTimer>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ProjectTimer = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ProjectTimer>): List<ProjectTimer> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ProjectTimer): ProjectTimer = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ProjectTimer): ProjectTimer = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ProjectTimer>): List<ProjectTimer> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ProjectTimer): ProjectTimer = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ProjectTimer>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ProjectTimer>): EntryChange<ProjectTimer> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ProjectTimer>): ProjectTimer = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ProjectTimer>): ProjectTimer = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ProjectTimer>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ProjectTimer>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ProjectTimer>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ProjectTimer>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ProjectTimer>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun refreshTimers(): Unit = api.refreshTimers(accessToken, masquerade)
    }
    class UserSessionDisputeApi(val api: Api.DisputeApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Dispute, UUID> {
        override suspend fun default(): Dispute = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Dispute> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Dispute>): List<Dispute> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Dispute>): List<Partial<Dispute>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Dispute = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Dispute>): List<Dispute> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Dispute): Dispute = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Dispute): Dispute = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Dispute>): List<Dispute> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Dispute): Dispute = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Dispute>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Dispute>): EntryChange<Dispute> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Dispute>): Dispute = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Dispute>): Dispute = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Dispute>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Dispute>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Dispute>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Dispute>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Dispute>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun lockProject(id: UUID): Dispute = api.lockProject(id, accessToken, masquerade)
    }
    class UserSessionIncidentApi(val api: Api.IncidentApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Incident, UUID> {
        override suspend fun default(): Incident = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Incident> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Incident>): List<Incident> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Incident>): List<Partial<Incident>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Incident = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Incident>): List<Incident> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Incident): Incident = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Incident): Incident = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Incident>): List<Incident> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Incident): Incident = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Incident>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Incident>): EntryChange<Incident> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Incident>): Incident = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Incident>): Incident = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Incident>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Incident>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Incident>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Incident>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Incident>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun fileIncident(input: IncidentMessage): Incident = api.fileIncident(input, accessToken, masquerade)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(accessToken, masquerade)
    }
    class UserSessionIncidentMessageApi(val api: Api.IncidentMessageApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<IncidentMessage, UUID> {
        override suspend fun default(): IncidentMessage = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<IncidentMessage> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<IncidentMessage>): List<IncidentMessage> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<IncidentMessage>): List<Partial<IncidentMessage>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): IncidentMessage = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<IncidentMessage>): List<IncidentMessage> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: IncidentMessage): IncidentMessage = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: IncidentMessage): IncidentMessage = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<IncidentMessage>): List<IncidentMessage> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: IncidentMessage): IncidentMessage = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<IncidentMessage>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<IncidentMessage>): EntryChange<IncidentMessage> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<IncidentMessage>): IncidentMessage = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<IncidentMessage>): IncidentMessage = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<IncidentMessage>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<IncidentMessage>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<IncidentMessage>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<IncidentMessage>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<IncidentMessage>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun sendMessage(input: IncidentMessage): IncidentMessage = api.sendMessage(input, accessToken, masquerade)
    }
    class UserSessionIncidentMessageCommentApi(val api: Api.IncidentMessageCommentApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<IncidentMessageComment, UUID> {
        override suspend fun default(): IncidentMessageComment = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<IncidentMessageComment> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<IncidentMessageComment>): List<IncidentMessageComment> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<IncidentMessageComment>): List<Partial<IncidentMessageComment>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): IncidentMessageComment = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<IncidentMessageComment>): List<IncidentMessageComment> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: IncidentMessageComment): IncidentMessageComment = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: IncidentMessageComment): IncidentMessageComment = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<IncidentMessageComment>): List<IncidentMessageComment> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: IncidentMessageComment): IncidentMessageComment = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<IncidentMessageComment>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<IncidentMessageComment>): EntryChange<IncidentMessageComment> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<IncidentMessageComment>): IncidentMessageComment = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<IncidentMessageComment>): IncidentMessageComment = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<IncidentMessageComment>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<IncidentMessageComment>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<IncidentMessageComment>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<IncidentMessageComment>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<IncidentMessageComment>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionProjectMessageApi(val api: Api.ProjectMessageApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ProjectMessage, UUID> {
        override suspend fun default(): ProjectMessage = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ProjectMessage> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ProjectMessage>): List<ProjectMessage> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ProjectMessage>): List<Partial<ProjectMessage>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ProjectMessage = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ProjectMessage>): List<ProjectMessage> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ProjectMessage): ProjectMessage = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ProjectMessage): ProjectMessage = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ProjectMessage>): List<ProjectMessage> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ProjectMessage): ProjectMessage = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ProjectMessage>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ProjectMessage>): EntryChange<ProjectMessage> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ProjectMessage>): ProjectMessage = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ProjectMessage>): ProjectMessage = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ProjectMessage>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ProjectMessage>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ProjectMessage>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ProjectMessage>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ProjectMessage>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionEmailProofApi(val api: Api.EmailProofApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?) {
    }
    class UserSessionOtpSecretApi(val api: Api.OtpSecretApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<OtpSecret, UUID> {
        override suspend fun default(): OtpSecret = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<OtpSecret> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<OtpSecret>): List<OtpSecret> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<OtpSecret>): List<Partial<OtpSecret>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): OtpSecret = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<OtpSecret>): List<OtpSecret> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: OtpSecret): OtpSecret = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: OtpSecret): OtpSecret = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<OtpSecret>): List<OtpSecret> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: OtpSecret): OtpSecret = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<OtpSecret>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<OtpSecret>): EntryChange<OtpSecret> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<OtpSecret>): OtpSecret = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<OtpSecret>): OtpSecret = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<OtpSecret>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<OtpSecret>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<OtpSecret>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<OtpSecret>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<OtpSecret>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?) {
    }
    class UserSessionUserAuthApi(val api: Api.UserAuthApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): AuthenticatedUserAuthClientEndpoints<User, UUID> {
        override suspend fun createSubSession(input: SubSessionRequest): String = api.createSubSession(input, accessToken, masquerade)
        override suspend fun getSelf(): User = api.getSelf(accessToken, masquerade)
        override suspend fun terminateSession(): Unit = api.terminateSession(accessToken, masquerade)
        override suspend fun terminateOtherSession(sessionId: UUID): Unit = api.terminateOtherSession(sessionId, accessToken, masquerade)
        suspend fun getPaymentSetupToken(): String = api.getPaymentSetupToken(accessToken, masquerade)
        suspend fun getPaymentPublishableKey(): String = api.getPaymentPublishableKey(accessToken, masquerade)
        suspend fun getPaymentMethods(): List<PaymentMethod> = api.getPaymentMethods(accessToken, masquerade)
        suspend fun getPaymentMethod(methodId: String): PaymentMethod = api.getPaymentMethod(methodId, accessToken, masquerade)
        suspend fun updatePaymentMethodName(methodId: String, input: String): PaymentMethod = api.updatePaymentMethodName(methodId, input, accessToken, masquerade)
        suspend fun deletePaymentMethod(methodId: String): Unit = api.deletePaymentMethod(methodId, accessToken, masquerade)
    }
    class UserSessionUserSessionApi(val api: Api.UserSessionApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Session<User, UUID>, UUID> {
        override suspend fun default(): Session<User, UUID> = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Session<User, UUID>> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Session<User, UUID>>): List<Session<User, UUID>> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>): List<Partial<Session<User, UUID>>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Session<User, UUID> = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Session<User, UUID>): Session<User, UUID> = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>): EntryChange<Session<User, UUID>> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>): Session<User, UUID> = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Session<User, UUID>>): Session<User, UUID> = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Session<User, UUID>>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionFunnelSummaryApi(val api: Api.FunnelSummaryApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<FunnelSummary, UUID> {
        override suspend fun default(): FunnelSummary = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<FunnelSummary> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<FunnelSummary>): List<FunnelSummary> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<FunnelSummary>): List<Partial<FunnelSummary>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): FunnelSummary = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<FunnelSummary>): List<FunnelSummary> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: FunnelSummary): FunnelSummary = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: FunnelSummary): FunnelSummary = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<FunnelSummary>): List<FunnelSummary> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: FunnelSummary): FunnelSummary = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<FunnelSummary>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<FunnelSummary>): EntryChange<FunnelSummary> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<FunnelSummary>): FunnelSummary = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<FunnelSummary>): FunnelSummary = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<FunnelSummary>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<FunnelSummary>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<FunnelSummary>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<FunnelSummary>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<FunnelSummary>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionFunnelInstanceApi(val api: Api.FunnelInstanceApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<FunnelInstance, UUID> {
        override suspend fun default(): FunnelInstance = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<FunnelInstance> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<FunnelInstance>): List<FunnelInstance> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<FunnelInstance>): List<Partial<FunnelInstance>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): FunnelInstance = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<FunnelInstance>): List<FunnelInstance> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: FunnelInstance): FunnelInstance = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: FunnelInstance): FunnelInstance = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<FunnelInstance>): List<FunnelInstance> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: FunnelInstance): FunnelInstance = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<FunnelInstance>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<FunnelInstance>): EntryChange<FunnelInstance> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<FunnelInstance>): FunnelInstance = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<FunnelInstance>): FunnelInstance = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<FunnelInstance>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<FunnelInstance>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<FunnelInstance>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<FunnelInstance>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<FunnelInstance>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun getFunnelHealth(date: LocalDate): List<FunnelSummary> = api.getFunnelHealth(date, accessToken, masquerade)
        suspend fun summarizeFunnelsNow(input: LocalDate): Unit = api.summarizeFunnelsNow(input, accessToken, masquerade)
    }
}

