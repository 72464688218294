package com.crowpay.views.screens.auth

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.ApiOption
import com.crowpay.sdk.selectedApi
import com.crowpay.sdk.sessionToken
import com.crowpay.views.AutoRoutes
import com.crowpay.views.screens.client.ClientForm
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*


@Routable("auth/user-registration")
class UserRegistration : Screen, AuthScreen {

    override val title: Readable<String> = shared {
        projectData.project.name
    }

    @QueryParameter
    val next: Property<String?> = Property(null)
    var nextScreen: Screen? = null
        set(value) {
            field = value
            next.value = value?.let { AutoRoutes.render(it)?.urlLikePath?.render() }
        }

    lateinit var projectData: FullProject
    lateinit var apiOption: ApiOption
    val userData = Property(
        User(
            email = "",
            phoneNumber = "",
            firstName = "",
            lastName = "",
            address = Address(),
            role = UserRole.Customer,
        )
    )

    val token = Property<String?>(null)

    val clientForm = ClientForm(
        user = userData,
        widthLimit = 40.rem
    )

    fun ViewWriter.dataEntry() {
        stack {
            spacing = AppDimensions.fullIndent
            col {
                centered - image {
                    source = Resources.imagesFullLogoColor
                } in sizeConstraints(height = 4.rem, width = 19.135.rem)

                centered - body {
                    align = Align.Center
                    content =
                        "Welcome to CrowPay, your escrow solution. You need to create a user before you can accept the project."
                }

                clientForm.render(this)

                space()

                centered - sizeConstraints(width = 25.rem) - primaryButton - button {
                    ::enabled{ clientForm.allValid() }
                    specCenteredText("Register")
                    onClick {
                        val newUser = clientForm.userDraft.publish()
                        token.value = apiOption.api.userAuth.sendRegistrationEmail(newUser.email)
                    }
                }

                centered - smallBody("You will be signed in after registering")
            }
        }
    }

    fun ViewWriter.confirmation(token: String, data: User) {
        EmailProof(
            token,
            { data.email },
            { this@UserRegistration.token set null }
        ) { proof ->
            val api = selectedApi().api

            api.userAuth.registerUser(
                RegisterUser(data, proof)
            )

            val session = api.userAuth.logIn(listOf(proof))
            sessionToken set session.session!!
            screenNavigator.reset(nextScreen ?: LandingScreen())
        }.render(this)
    }

    override fun ViewWriter.render() {
        try {
            projectData
            apiOption
        } catch (e: Exception) {
            screenNavigator.reset(LandingScreen())
            return
        }

        swapView {
            spacing = 0.px
            swapping(
                transition = { ScreenTransition.ShrinkFade },
                current = {
                    val t = token()
                    if (t == null) null
                    else Pair(t, userData())
                },
                views = { pair ->
                    if (pair == null) scrolls - authCol {
                        sizeConstraints(width = AppDimensions.pageWidth) - dataEntry()
                    }
                    else scrolls - authStack { confirmation(pair.first, pair.second) }
                }
            )
        }
    }
}