package com.crowpay.views.components

import com.crowpay.views.theming.AppColors
import com.crowpay.actuals.rotation
import com.crowpay.arrowUp
import com.crowpay.utils.renderDollars
import com.crowpay.views.theming.ForegroundSemantic
import com.crowpay.views.theming.body
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Angle
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.icon
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.dynamicTheme

fun ViewWriter.priceArrowIcon(price: ReactiveContext.() -> Long?) {
    icon {
        ::visible { price().let { it != null && it != 0L } }
        dynamicTheme {
            price().let {
                if (it == null || it == 0L) null
                else if (it > 0) ForegroundSemantic(AppColors.secondary.main)
                else ForegroundSemantic(AppColors.red.main)
            }
        }
        source = Icon.arrowUp
        ::description {
            price().let {
                if (it == null || it == 0L) "Unchanged"
                else if (it > 0) "Increased"
                else "Decreased"
            }
        }
        ::rotation {
            val p = price()
            if (p == null || p >= 0) Angle.zero else Angle.halfTurn
        }
    }
}

fun ViewWriter.renderPriceChange(change: ReactiveContext.() -> Long?) {
    row {
        spacing = 0.2.rem
        space(0.7.rem)
        centered - body {
            align = Align.Center
            ::content { change().renderDollars() }
        }
        centered - priceArrowIcon(change)
    }
}
