package com.crowpay.views.components.project.activity.concerns

import com.crowpay.DisplayableState
import com.crowpay.FlowMessage
import com.crowpay.LineItemState
import com.crowpay.utils.Formats
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.utils.format
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.datetime.Instant

data class ConcernCardInfo(
    val type: Type,
    val title: String,
    val state: DisplayableState,
    val timestamp: Instant,
    val mostRecentMessage: FlowMessage,
    val dialog: suspend ()-> Page
): Comparable<ConcernCardInfo> {
    enum class Type(val displayName: String) { LineItem("Line Item"), Incident("Incident") }

    override fun compareTo(other: ConcernCardInfo): Int = comparator.compare(this, other)

    companion object {
        val comparator = compareBy<ConcernCardInfo> { it.state.priority }.thenBy { it.timestamp }
    }
}

fun LineItemState.showsCard(): Boolean = this in setOf(
    LineItemState.NotApproved,
    LineItemState.ForceComplete,
    LineItemState.Issue,
    LineItemState.Disputed,
    LineItemState.Resolving,
)