// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareContractorTermsAndConditionsVersionFields() {
    val props: Array<SerializableProperty<ContractorTermsAndConditionsVersion, *>> = arrayOf(ContractorTermsAndConditionsVersion__id, ContractorTermsAndConditionsVersion_establishedAt, ContractorTermsAndConditionsVersion_contents)
    ContractorTermsAndConditionsVersion.serializer().properties { props }
}
val <K> DataClassPath<K, ContractorTermsAndConditionsVersion>._id: DataClassPath<K, Instant> get() = this[ContractorTermsAndConditionsVersion__id]
val <K> DataClassPath<K, ContractorTermsAndConditionsVersion>.establishedAt: DataClassPath<K, Instant> get() = this[ContractorTermsAndConditionsVersion_establishedAt]
val <K> DataClassPath<K, ContractorTermsAndConditionsVersion>.contents: DataClassPath<K, String> get() = this[ContractorTermsAndConditionsVersion_contents]
inline val ContractorTermsAndConditionsVersion.Companion.path: DataClassPath<ContractorTermsAndConditionsVersion, ContractorTermsAndConditionsVersion> get() = path<ContractorTermsAndConditionsVersion>()


object ContractorTermsAndConditionsVersion__id: SerializableProperty<ContractorTermsAndConditionsVersion, Instant> {
    override val name: String by lazy { ContractorTermsAndConditionsVersion.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ContractorTermsAndConditionsVersion): Instant = receiver._id
    override fun setCopy(receiver: ContractorTermsAndConditionsVersion, value: Instant) = receiver.copy(_id = value)
    override val serializer: KSerializer<Instant> by lazy { (ContractorTermsAndConditionsVersion.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { ContractorTermsAndConditionsVersion.serializer().descriptor.getElementAnnotations(0) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object ContractorTermsAndConditionsVersion_establishedAt: SerializableProperty<ContractorTermsAndConditionsVersion, Instant> {
    override val name: String by lazy { ContractorTermsAndConditionsVersion.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ContractorTermsAndConditionsVersion): Instant = receiver.establishedAt
    override fun setCopy(receiver: ContractorTermsAndConditionsVersion, value: Instant) = receiver.copy(establishedAt = value)
    override val serializer: KSerializer<Instant> by lazy { (ContractorTermsAndConditionsVersion.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { ContractorTermsAndConditionsVersion.serializer().descriptor.getElementAnnotations(1) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object ContractorTermsAndConditionsVersion_contents: SerializableProperty<ContractorTermsAndConditionsVersion, String> {
    override val name: String by lazy { ContractorTermsAndConditionsVersion.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ContractorTermsAndConditionsVersion): String = receiver.contents
    override fun setCopy(receiver: ContractorTermsAndConditionsVersion, value: String) = receiver.copy(contents = value)
    override val serializer: KSerializer<String> by lazy { (ContractorTermsAndConditionsVersion.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ContractorTermsAndConditionsVersion.serializer().descriptor.getElementAnnotations(2) }
}
