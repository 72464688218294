package com.crowpay.views.components.files

import com.crowpay.views.theming.AppColors
import com.crowpay.Attachment
import com.crowpay.ProjectAttachment
import com.crowpay.extensions.withSpacing
import com.crowpay.sdk.notNullSession
import com.crowpay.views.dialogs.ImagePreview
import com.crowpay.views.theming.body
import com.crowpay.views.theming.colored
import com.crowpay.views.theming.subTitle
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.RecyclerViewPagingPlacer
import com.lightningkite.kiteui.views.l2.RecyclerViewPlacer
import com.lightningkite.kiteui.views.l2.RecyclerViewPlacerHorizontalGrid
import com.lightningkite.lightningserver.files.ServerFile
import kotlin.math.max
import kotlin.math.round


fun ViewWriter.renderAttachment(
    attachment: Readable<Attachment>,
    files: Readable<List<Attachment>>,
    size: Dimension,
) {
    withSpacing(0.dp) - stack {
        themeChoice += ThemeDerivation {
            it.copy(background = AppColors.grey.light2).withBack
        }
        button {
            stack {
                renderAttachmentImage(attachment)
                gravity(Align.Stretch, Align.End) - themeFromLast {
                    it.copy(
                        spacing = 8.dp,
                        background = it.background.closestColor().darken(0.2f).copy(alpha = 0.8f),
                    )
                } - subTitle {
                    ::exists{
                        attachment().name.isNotBlank()
                    }
                    align = Align.Center
                    ::content{ attachment().name }
                }

            }
            onClick {
                val a = attachment.await()
                if (a.fileType.startsWith("image")) {
                    val f = files.await()
                    val index = max(f.indexOf(a), 0)
                    dialogScreenNavigator.navigate(ImagePreview(f, index))
                } else {
                    ExternalServices.openTab(a.file.location)
                }
            }
        } in sizeConstraints(width = size, height = size)
    } in sizeConstraints(width = size, height = size)
}

fun ViewWriter.renderFiles(files: Readable<List<Attachment>>, size: Dimension) {
    withSpacing(0.0) - sizeConstraints(minHeight = size) - stack {
        row {
            ::exists { files().isNotEmpty() }
            forEachUpdating(files) { attachment ->
                renderAttachment(attachment, files, size)
            }
        }
        colored(AppColors.grey.light1) - centered - h3 {
            exists = false
            ::exists { files().isEmpty() }
            content = "No Files"
        }
    }
}

fun RView.renderFilePreview(filePreview: FilePreview, size: Dimension) {
    when (filePreview) {
        is FilePreview.RemoteAttachment -> {
            renderAttachmentImage(filePreview.attachment)
        }

        is FilePreview.RemoteFile -> {
            renderAttachmentImage(ProjectAttachment("", "image/*", ServerFile(filePreview.location)))
        }

        is FilePreview.LocalFile -> {
            val mimeType = filePreview.value.mimeType()
            when {
                mimeType.startsWith("image") -> {
                    image {
                        scaleType = ImageScaleType.Fit
                        ::source {
                            ImageLocal(filePreview.value)
                        }
                    }
                }

                else -> {
                    centered - title {
                        ::content {
                            filePreview.value.fileName()
                        }
                    }
                }
            }

            themeFromLast {
                it.copy(
                    background = it.background.closestColor().darken(0.4f).withAlpha(0.4f),
                )
            } - stack {
                ::exists{
                    filePreview.uploaded()() == null
                }
                centered - activityIndicator {}
            }

            themeFromLast {
                it.copy(
                    background = it.background.closestColor().darken(0.4f).withAlpha(0.8f),
                    outlineWidth = 0.px,
                    body = it.font.copy(bold = true),
                    foreground = AppColors.red.light1,
                )
            } - stack {
                exists = false
                ::exists{ filePreview.uploaded()() == false }
                centered - col {
                    centered - body {
                        content = "Upload Failed"
                    }
                    centered - button {
                        body {
                            content = "Try Again"
                        }
                        onClick {
                            filePreview.reset()
                            filePreview.uploadAttachment(notNullSession().nonCached.contractor::uploadFileForRequest)
                        }
                    }

                }
            }

        }

        else -> stack {}
    }/* in sizeConstraints(width = size, height = size)*/
}