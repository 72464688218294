package com.crowpay.views.screens.contractor

import com.crowpay.views.screens.common.AuthorizedProjectView
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.lightningkite.UUID
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.reactive.ImmediateWritable
import com.lightningkite.kiteui.reactive.Property

@Routable("contractor/projects/{projectId}")
class ContractorProject(projectId: UUID) : AuthorizedProjectView(ProjectLens.Contractor, projectId)