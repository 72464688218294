// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareCompletionRequestFields() {
    val props: Array<SerializableProperty<CompletionRequest, *>> = arrayOf(CompletionRequest_contractor, CompletionRequest_customer)
    CompletionRequest.serializer().properties { props }
}
val <K> DataClassPath<K, CompletionRequest>.contractor: DataClassPath<K, AccessInfo> get() = this[CompletionRequest_contractor]
val <K> DataClassPath<K, CompletionRequest>.customer: DataClassPath<K, AccessInfo?> get() = this[CompletionRequest_customer]
inline val CompletionRequest.Companion.path: DataClassPath<CompletionRequest, CompletionRequest> get() = path<CompletionRequest>()


object CompletionRequest_contractor: SerializableProperty<CompletionRequest, AccessInfo> {
    override val name: String by lazy { CompletionRequest.serializer().descriptor.getElementName(0) }
    override fun get(receiver: CompletionRequest): AccessInfo = receiver.contractor
    override fun setCopy(receiver: CompletionRequest, value: AccessInfo) = receiver.copy(contractor = value)
    override val serializer: KSerializer<AccessInfo> by lazy { (CompletionRequest.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<AccessInfo> }
    override val annotations: List<Annotation> by lazy { CompletionRequest.serializer().descriptor.getElementAnnotations(0) }
}
object CompletionRequest_customer: SerializableProperty<CompletionRequest, AccessInfo?> {
    override val name: String by lazy { CompletionRequest.serializer().descriptor.getElementName(1) }
    override fun get(receiver: CompletionRequest): AccessInfo? = receiver.customer
    override fun setCopy(receiver: CompletionRequest, value: AccessInfo?) = receiver.copy(customer = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (CompletionRequest.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { CompletionRequest.serializer().descriptor.getElementAnnotations(1) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
