package com.crowpay.views.components

import com.crowpay.Resources
import com.crowpay.activity
import com.crowpay.views.theming.AppColors
import com.crowpay.views.theming.body
import com.crowpay.views.theming.colored
import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.views.ViewDsl
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon

@ViewDsl
fun ViewWriter.infoBubble(popover: PopoverPreferredDirection, message: String) =
    icon {
        description = "Additional Information"
        source = Icon.info.copy(width = 1.rem, height = 1.rem)
    } in hintPopover(popover) {
        sizeConstraints(maxWidth = 25.rem) - body(message)
    }

@ViewDsl
fun ViewWriter.infoBubble(message: String) = infoBubble(PopoverPreferredDirection.aboveRight, message)

@ViewDsl
fun ViewWriter.activityIcon() = colored(AppColors.yellow.main) - icon(Icon.activity, "Activity")

@ViewDsl
fun ViewWriter.activityIcon(setup: IconView.()->Unit) = colored(AppColors.yellow.main) - icon {
    setup()
    source = Icon.activity
}