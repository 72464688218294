package com.crowpay

import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.fullName
import com.crowpay.sdk.*
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.activityIcon
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.HelpDialog
import com.crowpay.views.screens.auth.AuthScreen
import com.crowpay.views.screens.auth.LogInScreen
import com.crowpay.views.screens.client.*
import com.crowpay.views.screens.common.*
import com.crowpay.views.screens.contractor.*
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.CancelledException
import com.lightningkite.kiteui.exceptions.ExceptionToMessages
import com.lightningkite.kiteui.exceptions.installLsError
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.locale.renderDateToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.kiteui.views.l2.navigatorView
import com.lightningkite.kiteui.views.l2.navigatorViewDialog
import com.lightningkite.kiteui.views.l2.overlayStack
import com.lightningkite.lightningdb.HasId
import com.lightningkite.now
import com.lightningkite.serialization.ClientModule
import kotlinx.datetime.Instant

private val _selectedContractor = PersistentProperty<String?>("SelectedContractor", null)
val selectedContractor: Writable<UUID?> = _selectedContractor.lens(
    get = { it?.let { UUID.parse(it) } },
    set = { it?.toString() },
)
val currentContractor = shared {
    val session = currentSession() ?: return@shared null
    val selected = selectedContractor() ?: return@shared null
    session.contractors[selected]()
}
val contractorActive = shared(useLastWhileLoading = true) {
    currentContractor()?.status.also { println("Got new active state: $it") }
}

fun ReactiveContext.contractorInfoComplete(): Boolean = currentContractor()?.status != ContractorStatus.Registering

private var _guaranteedContractor: Readable<UUID>? = null
val ViewWriter.guaranteedContractor: Readable<UUID>
    get() = _guaranteedContractor ?: run {
        _guaranteedContractor = shared {
            selectedContractor() ?: run {
                mainScreenNavigator.navigate(LandingScreen())
                throw CancelledException("")
            }
        }
        _guaranteedContractor!!
    }
val CalculationContext.notNullContractor: Readable<UUID>
    get() = _guaranteedContractor ?: run {
        _guaranteedContractor = shared {
            selectedContractor() ?: run {
                mainScreen.awaitNotNull().navigate(LandingScreen())
                throw CancelledException("")
            }
        }
        _guaranteedContractor!!
    }

// Kinda jank ya, but this is for navigating back to login/landing screen whn session is not available
val mainScreen = Property<ScreenNavigator?>(null)

enum class NavOption(val display: String) {
    Home("Home"),
    Projects("Projects"),
    Clients("Clients"),
    Tasks("Tasks"),
    Messages("Messages"),
    Finances("Finances"),
    DevProjects("Dev Projects"),
    None("");
}

data class MockupNotification(
    override val _id: UUID,
    val category: String,
    val timeStamp: Instant = now(),
    val shortDescription: String,
    val description: String,
    var hasBeenViewed: Boolean = false
) : HasId<UUID>

fun ViewWriter.app(main: ScreenNavigator, dialog: ScreenNavigator) {
    prepareModelsShared()
    com.lightningkite.prepareModelsShared()
    ExceptionToMessages.root.installLsError()
    ExceptionToMessages.root += RequestException.Handler

    DefaultSerializersModule = ClientModule
    screenNavigator = main
    mainScreenNavigator = main
    launchGlobal { mainScreen.set(main) }
    dialogScreenNavigator = dialog
    main.bindToPlatform(context)

    val highlightedNavOption = shared {
        when (screenNavigator.currentScreen()) {
            is HomeScreen -> NavOption.Home
            is ProjectScreen -> NavOption.Projects
            is ClientsScreen -> NavOption.Clients
            is WorkListsScreen -> NavOption.Tasks
            is PaymentScreen -> NavOption.Finances
            is DevProjectScreen -> NavOption.DevProjects
            else -> NavOption.None
        }
    }

    stack {
        fun ReactiveContext.navExists(): Boolean {
            return currentSession() != null
        }

        overlayStack = this
        spacing = 0.rem
        col {
            spacing = 0.rem

            col {
                spacing = 0.rem
                ::exists {
                    screenNavigator.currentScreen() !is AuthScreen && navExists()
                }
                padded - stack {
                    spacing = 2.rem
                    row {
                        sizeConstraints(height = 2.rem, width = 9.567.rem) - centered - image {
                            source = Resources.imagesFullLogoColor
                            scaleType = ImageScaleType.Fit
                        }

                        expanding - space()

                        row {
                            ::exists { navExists() }

                            fun ViewWriter.navOption(
                                text: Readable<String>,
                                hasNext: Boolean,
                                onClick: suspend () -> Unit,
                            ) {
                                button {
                                    row {
                                        centered - body {
                                            ::content{ text() }
                                        }
                                    }

                                    this.onClick(action = onClick)
                                }
                                if (hasNext)
                                    greySeparator()
                            }

                            buttonTheme - centered - sizeConstraints(height = 2.rem) - menuButton {
                                this.requireClick = true
                                spacing = 0.rem

                                row {
                                    spacing = 0.rem
                                    sizeConstraints(height = 2.rem, width = 2.rem) - centered - image {
                                        ::source {
                                            Icon.help2Inverse.toImageSource(AppColors.primary.main)
                                        }
                                        description = "Open Help Menu"
                                    }
                                }
                                preferredDirection = PopoverPreferredDirection.belowLeft
                                this.opensMenu {
                                    val close = {
                                        closePopovers()
                                    }
                                    stack {
                                        col {
                                            spacing = 0.rem
                                            navOption(
                                                text = Constant("Contact CrowPay"),
                                                hasNext = true,
                                            ) {
                                                dialogScreenNavigator.navigate(HelpDialog())
                                                close()
                                            }
                                            navOption(
                                                text = Constant("Support Library"),
                                                hasNext = false,
                                            ) {
                                                println("nav to support library")
                                                close()
                                            }
                                        }
                                    }
                                }
                            }

                            buttonTheme - centered - sizeConstraints(height = 2.rem) - menuButton {
                                this.requireClick = true
                                spacing = 0.rem

                                val exampleNotifications = shared {
                                    listOf<MockupNotification>(
                                        MockupNotification(
                                            _id = UUID.random(),
                                            category = "Example",
                                            timeStamp = now(),
                                            shortDescription = "These is an example Notification!",
                                            description = "Nothing in this example is set in stone, structure, style, placement, all still very up in the air."
                                        ),
                                        MockupNotification(
                                            _id = UUID.random(),
                                            category = "Payment Processed",
                                            timeStamp = now(),
                                            shortDescription = "Your Project1 has processed a payment!",
                                            description = "Your project1 has had request1 payment process, you should see the funds in your bank account in 2-3 days."
                                        ),
                                        MockupNotification(
                                            _id = UUID.random(),
                                            category = "Fund Request Fulfilled",
                                            timeStamp = now(),
                                            shortDescription = "Your Project1 has received funds!",
                                            description = "Your Project1 Client has provided the requested funds in X.XX amount."
                                        ),
                                        MockupNotification(
                                            _id = UUID.random(),
                                            category = "Bank Connected",
                                            timeStamp = now(),
                                            shortDescription = "Your Bank has been connected!",
                                            description = "You can now request funds."
                                        )
                                    )
                                }

                                spacing = 0.rem
                                ::exists { contractorInfoComplete() }
                                sizeConstraints(height = 2.rem, width = 2.rem) - image {
                                    source = Icon.notificationBell.toImageSource(AppColors.primary.main)
                                }
                                atTopEnd - sizeConstraints(
                                    aspectRatio = 1.0,
                                    minHeight = 1.rem
                                ) - NotificationCountSemantic.onNext - stack {
                                    // ::exists { count > 0 }
                                    centered - text {
                                        // ::content = {
                                        //    put count here, you should probably truncate it to 9+ for everything more than 9.
                                        //    the current badge behavior doesn't stretch correctly to fit 3 or more digits.
                                        //    it will grow to fit the longer string, and it won't keep the current badge to icon ratio.
                                        // }
                                        ::content {
                                            val count = exampleNotifications().filter { !it.hasBeenViewed }.size
                                            if (count > 9) {
                                                "9+"
                                            } else {
                                                exampleNotifications().size.toString()
                                            }
                                        }
                                        align = Align.Center
                                        themeChoice += ThemeDerivation {
                                            it[ButtonSemantic].theme.copy(cornerRadii = CornerRadii.Constant(1.rem)).withoutBack
                                        }
                                    }
                                }
                                preferredDirection = PopoverPreferredDirection.belowCenter
                                this.opensMenu {
                                    val close = {
                                        closePopovers()
                                    }
                                    stack {
                                        sizeConstraints(maxWidth = 25.rem) - col {
                                            spacing = 0.rem
                                            subTitle("Alerts")
                                            greySeparator()
                                            col {
                                                spacing = 0.rem
                                                forEach(exampleNotifications) { notification ->
                                                    button {
                                                        row {
                                                            spacing = 0.rem
                                                            // this icon is a placeholder
                                                            activityIcon()
                                                            space()
                                                            col {
                                                                spacing = 0.rem
                                                                body("${notification.category} - ${notification.timeStamp.renderDateToString()}")
                                                                bold - subTitle(notification.shortDescription)
                                                                body(notification.description)
                                                            }
                                                        }
                                                        onClick {
                                                            exampleNotifications().find { it._id == notification._id }?.hasBeenViewed =
                                                                true
                                                        }
                                                    }
                                                }
                                            }
                                            greySeparator()
                                            centered - subTitle("No more Alerts")
                                        }
                                    }
                                }
                            }

//                            hintPopover {
//                                    text{
//                                        ::content{ currentSession()?.self()?.fullName ?: "" }
//                                    }
//                                } -
                            buttonTheme - centered - sizeConstraints(height = 2.rem) - menuButton {

                                requireClick = true
                                spacing = 0.rem

                                val membership = shared {
                                    val session = currentSession() ?: return@shared null
                                    val ship = session.self().membership ?: return@shared null
                                    session.contractors[ship.contractor]()
                                }

                                row {
                                    sizeConstraints(height = 2.rem, width = 2.rem) - centered - image {
                                        existsDefaultFalse {
                                            membership() != null && membership()?.image?.location?.isNotBlank() == true
                                        }
                                        ::source {
                                            val logo = membership()?.image
                                            if (logo != null && logo.location != "") {
                                                ImageRemote(
                                                    logo.location
                                                )
                                            } else {
                                                Icon.profile.toImageSource(AppColors.primary.main)
                                            }
                                        }
                                        description = "Switch Profile"
                                    }

                                    centered - sizeConstraints(height = 1.9.rem, aspectRatio = 1.0) - row {
                                        existsDefaultFalse {
                                            membership() == null || membership()?.image?.location.isNullOrBlank()
                                        }
                                        spacing = 0.rem
                                        centered - expanding - text {
                                            ::content {
                                                align = Align.Center
                                                currentSession.awaitNotNull().self()
                                                    .let { "${it.firstName.take(1)}${it.lastName.take(1)}" }
                                            }
                                        }
                                        themeChoice += ThemeDerivation {
                                            it[ButtonSemantic].theme.copy(
                                                cornerRadii = CornerRadii.RatioOfSize(1f),
                                                background = AppColors.primary.main,
                                                foreground = AppColors.primary.light3,
                                                font = it.font.copy(
                                                    lineSpacingMultiplier = 1.0,
                                                    weight = 800,
                                                    size = 1.2.rem
                                                )
                                            ).withBack
                                        }
                                    }

                                    centered - subTitle {
                                        align = Align.Center
                                        ::content{
                                            membership()?.name?.take(20)
                                                ?: currentSession()?.self?.invoke()?.fullName
                                                ?: ""
                                        }
                                    }
                                }
                                preferredDirection = PopoverPreferredDirection.belowLeft
                                opensMenu {
                                    val close = { closePopovers() }

                                    sizeConstraints(minWidth = AppDimensions.navMenuWidth) - col {
                                        compact - col {
                                            subTitle {
                                                align = Align.Center
                                                ::content {
                                                    currentSession()?.self?.invoke()?.fullName ?: "Not Logged In"
                                                }
                                            }
                                            smallBody {
                                                existsDefaultFalse { membership() != null }
                                                align = Align.Center
                                                ::content { membership()?.name ?: "" }
                                            }
                                        }
                                        col {
                                            spacing = 0.rem
                                            navOption(
                                                text = Constant("User Profile"),
                                                hasNext = true,
                                            ) {
                                                screenNavigator.reset(ClientProfile())
                                                close()
                                            }
                                            col {
                                                spacing = 0.rem
                                                existsDefaultFalse {
                                                    membership() != null
                                                }
                                                reactive {
                                                    clearChildren()
                                                    navOption(
                                                        text = Constant("Business Info"),
                                                        hasNext = true,
                                                    ) {
                                                        if (selectedContractor() == null) {
                                                            selectedContractor.set(membership()?._id)
                                                        }
                                                        screenNavigator.reset(ContractorProfile())
                                                        close()
                                                    }
                                                    navOption(
                                                        text = Constant("Bank Info"),
                                                        hasNext = true
                                                    ) {
                                                        if (selectedContractor() == null) {
                                                            selectedContractor.set(membership()?._id)
                                                        }
                                                        screenNavigator.reset(ContractorBankInfo())
                                                        close()
                                                    }
                                                    navOption(
                                                        text = Constant("Billing"),
                                                        hasNext = true
                                                    ) {
                                                        if (selectedContractor() == null) {
                                                            selectedContractor.set(membership()?._id)
                                                        }
                                                        screenNavigator.reset(ContractorBillingInfo())
                                                        close()
                                                    }
                                                }
                                            }
                                            navOption(
                                                text = Constant("Logout"),
                                                hasNext = false,
                                            ) {
                                                try {
                                                    currentSession()?.nonCached?.userAuth?.terminateSession()
                                                } catch (_: Exception) {
                                                }
                                                sessionToken.set(null)
                                                screenNavigator.reset(LogInScreen())
                                                close()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            sizeConstraints(
                height = 2.dp,
            ) - themeFromLast { it.copy(background = AppColors.secondary.main, spacing = 1.dp) } - stack {}

            expanding - row {
                spacing = 0.rem
                nav - sizeConstraints(minWidth = 12.rem) - col {
                    spacing = 0.rem
                    ::exists{
                        val c = screenNavigator.currentScreen()
                        (c !is AuthScreen) && navExists()
                    }
                    space(AppDimensions.fullIndent)

                    val settingsOpen = Property(false)

                    fun ViewWriter.navOption(
                        text: String,
                        hasNext: Boolean,
                        onClick: suspend () -> Unit,
                    ) {
                        button {
                            row {
                                space(1.5.rem)
                                centered - body {
                                    content = text
                                }
                            }
                            onClick(action = onClick)
                        }
                        if (hasNext)
                            greySeparator()
                    }

                    fun ViewWriter.renderNavItem(
                        icon: Icon,
                        text: String,
                        exists: ReactiveContext.() -> Boolean = { true },
                        onClick: suspend () -> Unit,
                    ) {
                        col {
                            spacing = 0.rem
                            existsDefaultFalse(exists)
                            button {
                                spacing = 0.25.rem
                                themeChoice = ThemeDerivation.none
                                ::themeChoice {
                                    if (highlightedNavOption().display == text) {
                                        ThemeDerivation { currentTheme ->
                                            HoverSemantic.invoke(currentTheme)
                                        }
                                    } else {
                                        ThemeDerivation.none
                                    }
                                }
                                row {
                                    spacing = 0.rem
                                    space(AppDimensions.fullIndent / 2)
                                    centered - sizeConstraints(height = 1.5.rem, width = 1.5.rem) -
                                            icon(icon, text)
                                    space(1.rem)
                                    centered - subTitle {
                                        content = text
                                    }
                                    space(AppDimensions.fullIndent / 2)
                                    onClick(action = onClick)
                                }
                            }
                            space(1.rem)
                        }
                    }

                    // when adding new nav options, add an empty interface to the
                    // root nav screen with an updated highlightedNavOption property
                    renderNavItem(
                        Icon.simpleHome,
                        NavOption.Home.display,
                    ) {
                        if (selectedContractor.awaitOnce() != null) {
                            screenNavigator.reset(ContractorDashboard())
                        } else {
                            screenNavigator.reset(ClientDashboard())
                        }
                    }
                    renderNavItem(Icon.projects, NavOption.Projects.display) {
                        if (selectedContractor.awaitOnce() != null) {
                            screenNavigator.reset(ContractorProjects())
                        } else {
                            screenNavigator.reset(ClientProjects())
                        }
                    }
                    renderNavItem(
                        Icon.clients,
                        NavOption.Clients.display,
                        exists = { selectedContractor() != null }
                    ) {
                        screenNavigator.reset(ContractorClients())
                    }
                    renderNavItem(Icon.workList, NavOption.Tasks.display) {
                        if (selectedContractor.awaitOnce() != null) {
                            screenNavigator.reset(ContractorWorkLists())
                        } else {
                            screenNavigator.reset(ClientWorkLists())
                        }
                    }
                    renderNavItem(Icon.messageBubble, NavOption.Messages.display) {
                        screenNavigator.reset(
                            MessagingScreen(
                                if (selectedContractor.awaitOnce() != null) ProjectLens.Contractor
                                else ProjectLens.Customer
                            )
                        )
                    }
                    renderNavItem(Icon.finances, NavOption.Finances.display) {
                        if (selectedContractor.awaitOnce() != null) {
                            screenNavigator.reset(ContractorPayments())
                        } else {
                            screenNavigator.reset(ClientPayments())
                        }
                    }
                    renderNavItem(
                        Icon.edit,
                        NavOption.DevProjects.display,
                        exists = { selectedApi() in ApiOption.SAFE }
                    ) {
                        screenNavigator.reset(SelectDevProjectScreen())
                    }
                    expanding - space()
                    onlyWhen { settingsOpen() } - col {
                        spacing = 0.rem
                        navOption(
                            text = "Alerts",
                            hasNext = false
                        ) {
                            println("open alerts")
                            settingsOpen.set(false)
                        }
                        navOption(
                            text = "Specifications",
                            hasNext = false
                        ) {
                            println("open specifications")
                            settingsOpen.set(false)
                        }
                    }
                    renderNavItem(Icon.settings, "Settings") {
                        println("pressed settings button")
                        settingsOpen.set(!settingsOpen())
                    }
                    space(AppDimensions.fullIndent - 1.25.rem)
                }
                expanding - crowPayNavigatorView(screenNavigator)
            }
        }
        navigatorViewDialog()
    }
}

private fun ViewWriter.crowPayNavigatorView(navigator: ScreenNavigator): ViewModifiable {
    return swapView {
        var lastStack = navigator.stack.value
        this@swapView.swapping(
            transition = {
                val newStack = navigator.stack.value
                val transitionSet = theme.bodyTransitions
                when {
                    newStack.size - lastStack.size > 0 -> transitionSet.forward
                    newStack.size - lastStack.size < 0 && newStack.firstOrNull() == lastStack.firstOrNull() -> transitionSet.reverse
                    else -> transitionSet.neutral
                }.also { lastStack = newStack }
            },
            current = { navigator.currentScreen<Page?>() to (currentSession() != null) },
            views = { (screen, loggedIn) ->
                with(split()) {
                    this.screenNavigator = navigator
                    if (screen != null)
                        with(screen) {
                            mainContent - padded - stack {
                                spacing = if (loggedIn) AppDimensions.fullIndent else 0.px
                                render2()
                            }
                        }
                }
            }
        )
    }
}