package com.crowpay.views.components.project

import com.crowpay.ProjectState
import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.ProgressDelegate
import com.crowpay.views.components.space
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.lens
import com.lightningkite.kiteui.reactive.reactiveScope
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.dynamicTheme

fun ProjectView.header(writer: ViewWriter) = with(writer) {
    rowCollapsingToColumn(AppDimensions.pageWidth) {
        spacing = AppDimensions.backgroundIndent
        col {
            title("Project")
            lightSection - row {
                spacing = AppDimensions.sectionIndent / 2
                space(0.dp)
                col {
                    spacing = 0.35.rem
                    colored(AppColors.purple.dark) - xlTitle { ::content { project().name } }
                    subTitle {
                        ::content {
                            when (lens) {
                                ProjectLens.Contractor -> client().name
                                ProjectLens.Customer -> contractor().name
                                ProjectLens.Preview -> contractor().name
                            }
                        }
                    }
                }
                space(0.dp)
            }
        }
        col {
            title("Status")
            lightSection - row {
                spacing = AppDimensions.sectionIndent / 2
                space(0.dp)
                col {
                    spacing = 0.35.rem
                    xlTitle {
                        ::content{ project().state.displayName }
                        dynamicTheme {
                            val p = project()
                            ForegroundSemantic(Color.fromHexString(p.state.displayColor))
                        }
                    }
                    sizeConstraints(height = AppDimensions.subtitleTextSize / 0.73) - stack {

                        gravity(
                            horizontal = Align.Start,
                            vertical = Align.Center
                        ) - sizeConstraints(height = 1.rem, width = 12.rem) - canvas {
                            existsDefaultFalse {
                                project().state <= ProjectState.Warranty
                            }
                            reactiveScope {
                                rerunOn(project)
                                delegate?.invalidate?.invoke()
                            }
                            delegate = ProgressDelegate(
                                color = project.lens { Color.fromHexString(it.state.displayColor) },
                                progress = project.lens { it.state.ordinal.toFloat() / ProjectState.Complete.ordinal.toFloat() }
                            )
                        }
                    }
                }
                space(0.dp)
            }
        }
    }
}