package com.crowpay.views.screens.contractor

import com.crowpay.actuals.AppDimensions
import com.crowpay.notNullContractor
import com.crowpay.sdk.notNullSession
import com.crowpay.views.theming.section
import com.crowpay.views.theming.subTitle
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.padded
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.scrolls
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.kiteui.views.direct.space
import com.lightningkite.kiteui.views.direct.stack
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.kiteui.views.expanding

@Routable("contractor/billing")
class ContractorBillingInfo() : ContractorScreen, PaymentScreen {

    override val title: Readable<String> = Constant("Billing")

    val contractor = shared { notNullSession().contractors[notNullContractor()]()!! }

    override fun ViewWriter.renderMainContent() {
        scrolls - col {
            spacing = 0.dp

            title("Billing")

            sizeConstraints(width = AppDimensions.pageWidth) - padded - stack {
                spacing = AppDimensions.backgroundIndent

                // contains mock data for now
                // displays two things
                // 1. summary section
                //  this contains total amount CrowPay has charged to contractor
                //  this contains total amount and percent per project

                section - expanding - stack {
                    spacing = AppDimensions.sectionIndent
                    col {
                        title {
                            ::content { "Summary" }
                        }
                        row {
                            text {
                                content = "Total: $34.88"
                            }
                            expanding - space()
                            col {
                                text {
                                    content = "Project1: $22.23"
                                }
                                subTitle("rate: 2%")
                            }
                            expanding - space()
                            col {
                                text {
                                    content = "Project2: $12.65"
                                }
                                subTitle("rate: 1%")
                            }
                        }
                    }
                }

                // 2. list of money movements
                //  this holds a list of transactions made, with project name, total amount moved, total withdrawn, and percent

//                col {
//                    section - expanding - stack {
//                        spacing = AppDimensions.sectionIndent
//                        row {
//                            spacing = 0.rem
//                        }
//                    }
//                }
            }
        }
    }
}