package com.crowpay.views.components

import com.crowpay.actuals.AppDimensions
import com.crowpay.actuals.rotation
import com.crowpay.chevronUp
import com.crowpay.extensions.withSpacing
import com.crowpay.utils.Action
import com.crowpay.views.theming.ExpandButtonSemantic
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.models.Angle
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.withSpacing

fun RView.expandIcon(expanded: Readable<Boolean>, extraSetup: RView.()->Unit = {})
    = icon {
        source = Icon.chevronUp
        ::rotation { if (expanded()) Angle.zero else Angle.halfTurn }
        ::description { if (expanded()) "collapse" else "expand" }
        extraSetup()
    }


fun RView.expandIcon(expanded: ReactiveContext.()->Boolean) {
    icon {
        source = Icon.chevronUp
        ::description { if (expanded()) "collapse" else "expand" }
        ::rotation { if (expanded()) Angle.zero else Angle.halfTurn }
    }
}

fun expandAction(expanded: Writable<Boolean>) = Action("Toggle Expanded", expanded::toggle)

fun RView.expandButton(expanded: Writable<Boolean>, contents: RView.()->Unit) {
    ExpandButtonSemantic.onNext - button {
        spacing = 0.px
        row {
            spacing = AppDimensions.expandButtonSpace
            this@row.contents()
            centered - expandIcon(expanded)
        }
        action = expandAction(expanded)
    }
}

fun RView.leftExpandButton(expanded: Writable<Boolean>, contents: RView.()->Unit) {
    ExpandButtonSemantic.onNext - button {
        spacing = AppDimensions.cornerRadii
        row {
            spacing = AppDimensions.expandButtonSpace
            centered - expandIcon(expanded)
            centered - this@row.contents()
        }
        action = expandAction(expanded)
    }
}