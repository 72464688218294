package com.crowpay.views.components.project.work.changeOrders.tables

import com.crowpay.ChangeRequest
import com.crowpay.actuals.AppDimensions
import com.crowpay.edit
import com.crowpay.views.components.project.work.changeOrders.ChangeOrderForm2
import com.crowpay.views.components.project.work.changeOrders.ChangeRequestItemWrapper
import com.crowpay.views.components.expandAction
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.sectionIndentCol
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon

fun ViewWriter.renderDraftChangeOrder(
    changeOrder: Readable<ChangeRequest>,
    changeItems: Readable<List<ChangeRequestItemWrapper>>
) {
    val expanded = Property(false)
    col {
        spacing = 0.px
        expandButtonTheme - button {
            row {
                spacing = AppDimensions.expandButtonSpace
                centered - expandIcon(expanded)
                subTitle {
                    ::content {
                        val c = changeOrder()
                        "Change #${c.number}: ${c.title}"
                    }
                }
            }
            action = expandAction(expanded)
        }

        onlyWhen { expanded() } - sectionIndentCol {
            spacing = 0.px
            body {
                ::content {
                    "Description: ${changeOrder().description}"
                }
            }

            row {
                weight(6f) - space()
                weight(1f) - centered - bodyBold {
                    align = Align.Center
                    content = "ID"
                }
                weight(1f) - centered - bodyBold {
                    align = Align.Center
                    content = "Status"
                }
                weight(1f) - centered - bodyBold {
                    align = Align.Center
                    content = "Date"
                }
                weight(2f) - stack {
                    gravity(Align.End, Align.Center) - sizeConstraints(width = 5.rem) - buttonTheme - link {
                        spacing = 0.25.rem
                        ::to { { ChangeOrderForm2(changeOrder()._id) } }
                        centered - icon(Icon.edit, "Edit Change Order")
                    }
                }
            }
            greySeparator()
            col {
                spacing = 0.px
                forEach(changeItems) { item ->
                    changeRequestItemButton(item) {
                        row {
                            spacing = 1.rem
                            weight(6f) - centered - row {
                                spacing = AppDimensions.expandButtonSpace
                                centered - expandIcon(it)
                                centered - body(item.name)
                                centered - smallBody(item.changeType.preTense)
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                ::content { item.itemNumber }
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                content = "Draft"
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                content = "UC"
                            }
                            weight(2f) - space()
                        }
                    }
                }
            }
            space()
        }

        greySeparator { ::exists { !expanded() } }
    }
}
