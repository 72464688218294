package com.crowpay.views.screens.auth

import com.crowpay.ContractorStatus
import com.crowpay.actuals.AppDimensions
import com.crowpay.currentContractor
import com.crowpay.sdk.currentSession
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.theming.AuthBackgroundSemantic
import com.crowpay.views.theming.AuthDialogSemantic
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import kotlinx.coroutines.launch

fun ViewWriter.authCol(setup: RowOrCol.() -> Unit) {
    expanding - AuthBackgroundSemantic.onNext - col {
        space()
        centered - AuthDialogSemantic.onNext - stack {
            spacing = AppDimensions.fullIndent
            col(setup)
        }
        space()
    }
}

fun ViewWriter.authStack(setup: Stack.() -> Unit) {
    expanding - AuthBackgroundSemantic.onNext - stack {
        centered - AuthDialogSemantic.onNext - stack {
            spacing = AppDimensions.fullIndent
            stack(setup)
        }
    }
}

fun ViewWriter.handleAuthRendering(center: Boolean = false, render: RView.(loggedIn: Boolean)->Unit) {
    swapView {
        launch {
            val session = currentSession()
            val con = currentContractor() ?: return@launch
            if (session != null && con.status != ContractorStatus.Registering)
                screenNavigator.reset(LandingScreen())
        }

        spacing = 0.px
        swapping(
            current = {
                currentSession() != null
            },
            views = { loggedIn ->
                // This weird stuff handles the different theming needed for when logged in vs not logged in.
                if (!loggedIn) {
                    if (center) scrolls - authStack { render(loggedIn) }
                    else scrolls - authCol { render(loggedIn) }
                } else scrolls - stack { render(loggedIn) }
            }
        )
    }
}