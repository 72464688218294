package com.crowpay.views.screens.client

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.fullName
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.renderDollars
import com.crowpay.views.components.ProgressDelegate
import com.crowpay.views.components.keyValue
import com.crowpay.views.components.space
import com.crowpay.views.screens.common.generalToDos
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*

interface HomeScreen

@Routable("/client")
class ClientDashboard() : Screen, HomeScreen {
    override val title: Readable<String> = Constant("Home")

    companion object {
        object SnapShotSemantic : Semantic {
            override val key: String = "snpsht"

            override fun default(theme: Theme): ThemeAndBack {
                return theme[CardSemantic].theme.copy(
                    id = key,
                    derivations = mapOf(
                        HoverSemantic to { it.withBack },
                        DownSemantic to { it.withBack },
                    )
                ).withBack
            }
        }

    }

    override fun ViewWriter.render() {

        scrolls - col {
            spacing = 0.dp
            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = 0.dp

                generalToDos()

                col {
                    spacing = 1.rem
                    title {
                        ::content{ notNullSession().self().fullName }
                    }

                    subTitle("")
                }

                space(AppDimensions.majorColumnSpacing - AppDimensions.backgroundIndent)

                padded - col {
                    spacing = AppDimensions.backgroundIndent

                    val projects = shared {
                        val session = notNullSession()
                        val projects = session.projects
                            .query(
                                Query(
                                    condition = Condition.And(
                                        listOf(
                                        condition { (it.customer eq session.userId) },
                                        condition {
                                            it.state notInside setOf(
                                                ProjectState.Terminated,
                                                ProjectState.Cancelled,
                                                ProjectState.Complete,
                                            )
                                        }
                                    )
                                ),
                                orderBy = sort {
                                    it.state.ascending()
                                    it.name.ascending()
                                }
                            ))
                            .await()
                        val lineItems = notNullSession().lineItems
                            .query(
                                Query(
                                    condition = Condition.And(
                                        listOf(
                                            condition { it.project inside projects.map { it._id } },
                                            condition { it.cancelled eq null },
                                        )
                                    )
                                )
                            )
                            .await()

                        lineItems
                            .groupBy { line -> projects.find { it._id == line.project }!! }
                            .entries.toList()
                            .sortedBy {
                                it.key.state.ordinal +
                                        (it.value.count { it.complete != null }.toFloat() / it.value.size.toFloat())
                            }

                    }

                    forEach(projects) { data ->
                        val project = data.key
                        val items = data.value

                        section - link {
                            spacing = AppDimensions.sectionIndent
                            this.to = { ClientProject(project._id) }
                            col {

                                row {
                                    centered - h3(project.name)
                                    centered - body("-")
                                    centered - ProjectStateSemantic(project.state).onNext - body(project.state.displayName)
                                }

                                rowCollapsingToColumn(AppDimensions.pageWidth) {

                                    weight(1.5f) - stack {
                                        themeChoice += SnapShotSemantic
                                        spacing = AppDimensions.sectionIndent
                                        centered - col {
                                            h4 {
                                                align = Align.Center
                                                content = "Project Progress"
                                            }
                                            col {
                                                spacing = 0.px
                                                val done = items.count { it.complete != null }
                                                sizeConstraints(height = 1.rem) - canvas {
                                                    delegate = ProgressDelegate(
                                                        color = AppColors.secondary.main,
                                                        progress = done.toFloat() / items.size.toFloat()
                                                    )
                                                }
                                                smallBody("${done}/${items.size} work items met")
                                            }
                                        }
                                    }
                                    weight(1.5f) - col {
                                        expanding - stack {
                                            themeChoice += SnapShotSemantic
                                            centered - col {
//                                            spacing = AppDimensions.sectionIndent
                                                h4 {
                                                    align = Align.Center
                                                    content = "Project Balance"
                                                }
                                                body {
                                                    align = Align.Center
                                                    content = project.balance.renderDollars()
                                                }
                                            }
                                        }
                                        expanding - stack {
                                            themeChoice += SnapShotSemantic
                                            centered - col {
//                                            spacing = AppDimensions.sectionIndent
                                                h4 {
                                                    align = Align.Center
                                                    content = "Funds Released"
                                                }
                                                body {
                                                    align = Align.Center
                                                    content = project.contractorPayments.renderDollars()
                                                }
                                            }
                                        }
                                    }
                                    weight(2f) - col {
                                        themeChoice += SnapShotSemantic
                                        spacing = AppDimensions.sectionIndent

                                        val contractor = shared { notNullSession().contractors[project.contractor]() }

                                        h4 {
                                            align = Align.Center
                                            ::content { "${contractor()?.preferredTitle} Information" }
                                        }
                                        col {
                                            keyValue("Name") { contractor()?.name }
                                            keyValue("Address") { contractor()?.address.toString() }
                                            keyValue("Email") { contractor()?.email }
                                            keyValue("Phone") {
                                                contractor()?.phoneNumber
                                                    ?.let { PhoneNumberFormat.USA.format(it.filter { it.isDigit() }) }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}