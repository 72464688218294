// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareTradeFields() {
    val props: Array<SerializableProperty<Trade, *>> = arrayOf(Trade__id)
    Trade.serializer().properties { props }
}
val <K> DataClassPath<K, Trade>._id: DataClassPath<K, String> get() = this[Trade__id]
inline val Trade.Companion.path: DataClassPath<Trade, Trade> get() = path<Trade>()


object Trade__id: SerializableProperty<Trade, String> {
    override val name: String by lazy { Trade.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Trade): String = receiver._id
    override fun setCopy(receiver: Trade, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> by lazy { (Trade.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Trade.serializer().descriptor.getElementAnnotations(0) }
}
