// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareCreditFields() {
    val props: Array<SerializableProperty<Credit, *>> = arrayOf(Credit_amount, Credit_reason, Credit_group)
    Credit.serializer().properties { props }
}
val <K> DataClassPath<K, Credit>.amount: DataClassPath<K, Long> get() = this[Credit_amount]
val <K> DataClassPath<K, Credit>.reason: DataClassPath<K, String> get() = this[Credit_reason]
val <K> DataClassPath<K, Credit>.group: DataClassPath<K, UUID?> get() = this[Credit_group]
inline val Credit.Companion.path: DataClassPath<Credit, Credit> get() = path<Credit>()


object Credit_amount: SerializableProperty<Credit, Long> {
    override val name: String by lazy { Credit.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Credit): Long = receiver.amount
    override fun setCopy(receiver: Credit, value: Long) = receiver.copy(amount = value)
    override val serializer: KSerializer<Long> by lazy { (Credit.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { Credit.serializer().descriptor.getElementAnnotations(0) }
}
object Credit_reason: SerializableProperty<Credit, String> {
    override val name: String by lazy { Credit.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Credit): String = receiver.reason
    override fun setCopy(receiver: Credit, value: String) = receiver.copy(reason = value)
    override val serializer: KSerializer<String> by lazy { (Credit.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Credit.serializer().descriptor.getElementAnnotations(1) }
}
object Credit_group: SerializableProperty<Credit, UUID?> {
    override val name: String by lazy { Credit.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Credit): UUID? = receiver.group
    override fun setCopy(receiver: Credit, value: UUID?) = receiver.copy(group = value)
    override val serializer: KSerializer<UUID?> by lazy { (Credit.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { Credit.serializer().descriptor.getElementAnnotations(2) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
