package com.crowpay.incidentResponse

import com.crowpay.AccessInfo
import com.crowpay.completionResponse.CompletionResponseParseError
import com.crowpay.completionResponse.parseResponse
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException

@Serializable
data class IncidentResponseTypeSerializable(
    val type: IncidentResponseTypeType,
    val concern: ClientConcern? = null,
    val raiseIssue: Boolean = false,
    val fileDispute: Boolean = false,
    val proposingResolution: Boolean = false,
    val credit: Long? = null,
    val proposal: IncidentResponseTypeSerializable? = null,
    val remedy: IncidentResponseTypeSerializable? = null,
    val accessInfo: AccessInfo? = null
)

@Serializable
enum class IncidentResponseTypeType {
    FileIncident,
    ClientResponse,
    ClientResolution,
    Credit,
    Remedy,
    RemedyHandled,
    Handled,
    MoreInfo,
    Accept
}

class IncidentResponseParseError(
    val struct: IncidentResponseTypeSerializable,
    override val message: String,
    override val cause: IncidentResponseParseError? = null
) : SerializationException()

fun IncidentResponseTypeSerializable.parseResponse(): IncidentResponseType {
    fun missingFields(vararg missingFields: String): Nothing = throw IncidentResponseParseError(
        this,
        "Could not find value for ${if (missingFields.size == 1) "field" else "fields"}: ${missingFields.joinToString()}"
    )

    try {
        return when (this.type) {
            IncidentResponseTypeType.FileIncident -> IncidentResponseType.FileIncident(
                concern = concern ?: missingFields("concern"),
                raiseIssue = raiseIssue
            )

            IncidentResponseTypeType.ClientResponse -> IncidentResponseType.ClientResponse(
                fileDispute = fileDispute,
                proposingResolution = proposingResolution
            )

            IncidentResponseTypeType.ClientResolution -> IncidentResponseType.ClientResolution
            IncidentResponseTypeType.Credit -> IncidentResponseType.Credit(
                credit = credit ?: missingFields("credit")
            )

            IncidentResponseTypeType.Remedy -> IncidentResponseType.Remedy(
                credit = credit
            )

            IncidentResponseTypeType.RemedyHandled -> IncidentResponseType.Remedy.RemedyHandled(
                remedy = proposal?.let {
                    val parsed = it.parseResponse()
                    if (parsed !is IncidentResponseType.Remedy) throw IncidentResponseParseError(
                        it,
                        "IncidentResponseType.Remedy required"
                    )
                    parsed
                } ?: missingFields("proposal")
            )

            IncidentResponseTypeType.Handled -> IncidentResponseType.Handled
            IncidentResponseTypeType.MoreInfo -> IncidentResponseType.MoreInfo
            IncidentResponseTypeType.Accept -> IncidentResponseType.Accept(
                proposal = proposal?.let {
                    val parsed = it.parseResponse()
                    if (parsed !is IncidentResponseType.NeedsApproval) throw IncidentResponseParseError(
                        it,
                        "IncidentResponseType.NeedsApproval required"
                    )
                    parsed
                } ?: missingFields("proposal"),
                accessInfo = this.accessInfo ?: missingFields("accessInfo")
            )
        }
    } catch (e: IncidentResponseParseError) {
        if (e.struct != this) throw IncidentResponseParseError(this, "Could not parse response due to inner parsing error: $e", e)
        else throw e
    }
}

fun IncidentResponseType.serializable(): IncidentResponseTypeSerializable = when (this) {
    is IncidentResponseType.FileIncident -> IncidentResponseTypeSerializable(
        type = IncidentResponseTypeType.FileIncident,
        concern = concern,
        raiseIssue = raiseIssue
    )
    is IncidentResponseType.ClientResponse -> IncidentResponseTypeSerializable(
        type = IncidentResponseTypeType.ClientResponse,
        fileDispute = fileDispute,
        proposingResolution = proposingResolution
    )
    IncidentResponseType.ClientResolution -> IncidentResponseTypeSerializable(
        type = IncidentResponseTypeType.ClientResolution
    )
    is IncidentResponseType.Credit -> IncidentResponseTypeSerializable(
        type = IncidentResponseTypeType.Credit,
        credit = credit
    )
    is IncidentResponseType.Remedy -> IncidentResponseTypeSerializable(
        type = IncidentResponseTypeType.Remedy,
        credit = credit
    )
    is IncidentResponseType.Remedy.RemedyHandled -> IncidentResponseTypeSerializable(
        type = IncidentResponseTypeType.RemedyHandled,
        remedy = remedy.serializable()
    )
    IncidentResponseType.Handled -> IncidentResponseTypeSerializable(
        type = IncidentResponseTypeType.Handled
    )
    IncidentResponseType.MoreInfo -> IncidentResponseTypeSerializable(
        type = IncidentResponseTypeType.MoreInfo
    )
    is IncidentResponseType.Accept -> IncidentResponseTypeSerializable(
        type = IncidentResponseTypeType.Accept,
        proposal = proposal.serializable(),
        accessInfo = accessInfo
    )
}