package com.crowpay.views.dialogs

import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.validation.validate
import com.crowpay.utils.validation.validating
import com.crowpay.views.components.label2
import com.crowpay.views.components.requiredField
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*


class GenericFeedBackDialog(
    val header: String,
    val initialFeedback: String? = null,
    val allowRemoving: Boolean,
    val required: Boolean,
    val onSubmit: suspend (String?) -> Unit,
    val onDismiss: () -> Unit,
) : Screen {

    val feedback = Property(initialFeedback ?: "")

    override fun ViewWriter.render() {
        dismissBackground {
            dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                col {
                    themeChoice = ThemeDerivation(appTheme)
                    validating {

                        title(header)

                        requiredField(if (required) "Feedback" else "Feedback (Optional)") {
                            val value = feedback.validate { if (required) it.isNotBlank() else true }
                            textArea {
                                content bind value
                            } in sizeConstraints(width = 40.rem, minHeight = 7.rem)
                        }

                        row {
                            spacing = AppDimensions.buttonRowSpacing

                            primaryButton - button {
                                specCenteredText {
                                    if (initialFeedback == null) "Submit Feedback" else "Update Feedback"
                                }
                                ::enabled{ allValid() }
                                onClick {
                                    onSubmit(feedback.value.trim().takeIf { it.isNotBlank() })
                                    screenNavigator.dismiss()
                                }
                            }

                            if (initialFeedback != null && allowRemoving)
                                secondaryButton - button {
                                    specCenteredText("Remove Feedback")
                                    onClick {
                                        onSubmit(null)
                                        screenNavigator.dismiss()
                                    }
                                }

                            textButton - button {
                                specCenteredText("Go Back")
                                onClick {
                                    onDismiss()
                                    screenNavigator.dismiss()
                                }
                            }
                        }
                    }
                }
            }
            onClick {
                onDismiss()
                screenNavigator.dismiss()
            }
        }
    }
}