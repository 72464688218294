package com.crowpay.views.dialogs

import com.crowpay.ContractorNote
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.toFileData
import com.crowpay.message
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.pushChanges
import com.crowpay.views.components.files.fileManager
import com.crowpay.views.components.files.resolveProjectAttachments
import com.crowpay.views.components.label2
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.dialog
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.fieldTheme
import com.lightningkite.serialization.lensPath

class CreateOrEditNote private constructor(
    project: UUID,
    lineItem: UUID?,
    val note: ContractorNote?,
    val subHeader: String? = null,
    val beforeSubmit: (suspend ()->Unit)? = null
) : Screen {
    // Edits a Note
    constructor(
        note: ContractorNote,
        subHeader: String?,
        beforeSubmit: (suspend ()->Unit)? = null
    ) : this(note.project, note.lineItem, note, subHeader, beforeSubmit)

    // Creates a Note
    constructor(
        project: UUID,
        lineItem: UUID?,
        subHeader: String?,
        beforeSubmit: (suspend ()->Unit)? = null
    ) : this(project, lineItem, null, subHeader, beforeSubmit)

    val draft = Draft(
        note ?: ContractorNote(
            message = "",
            project = project,
            lineItem = lineItem
        )
    )

    val message = draft.lensPath { it.message }
    val files = Property(note?.files?.toFileData() ?: emptyList())

    override fun ViewWriter.render() {
        dismissBackground {
            centered - sizeConstraints(width = 40.rem) - dialog - stack {
                spacing = AppDimensions.fullIndent
                col {
                    titleWithClose(if (note == null) "New Note" else "Edit Note") {
                        dialogScreenNavigator.dismiss()
                    }

                    body(subHeader ?: "Add notes, pictures, or files to show progress for the client. Notes can be added and modified until the work item is accepted.")

                    label2("Note") {
                        sizeConstraints(width = 40.rem, minHeight = 10.rem) - fieldTheme - textArea {
                            content bind message
                        }
                    }

                    label2("Files") {
                        sizeConstraints(width = 40.rem) - fieldTheme - fileManager(
                            files,
                            "No Files Uploaded"
                        ) { notNullSession().nonCached.lineItem::uploadFileForRequest }
                    }

                    row {
                        spacing = AppDimensions.buttonRowSpacing
                        primaryButton - button {
                            ::enabled { message().isNotBlank() || files().isNotEmpty() }

                            specCenteredText(if (note == null) "Create Note" else "Save Changes")

                            onClick {
                                draft.modify {
                                    it.copy(
                                        files = files().resolveProjectAttachments()
                                    )
                                }

                                beforeSubmit?.invoke()
                                draft.pushChanges(notNullSession().contractorNotes)

                                dialogScreenNavigator.dismiss()
                            }
                        }
                        textButton - button {
                            specCenteredText("Go Back")
                            onClick { dialogScreenNavigator.dismiss() }
                        }
                    }
                }
            }
        }
    }
}