package com.crowpay.extensions

import com.crowpay.*
import org.kotlincrypto.hash.sha2.SHA256
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi


@OptIn(ExperimentalEncodingApi::class)
fun PendingItemChange.hash(): String {

    val digest = SHA256()

    digest hash _id
    digest hash project
    digest hash changeRequest
    digest hash itemId
    digest hash updatedDescription
    digest hash priceChange
    digest hash cancelled
    digest hash created
    digest hash itemNumber

    return Base64.encode(digest.digest())

}