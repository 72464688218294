// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareProjectFields() {
    val props: Array<SerializableProperty<Project, *>> = arrayOf(Project__id, Project_contractor, Project_customer, Project_client, Project_name, Project_description, Project_address, Project_location, Project_jobNumber, Project_files, Project_retention, Project_published, Project_feedback, Project_created, Project_cancelled, Project_abandoned, Project_archived, Project_viewedByCustomer, Project_approved, Project_fullFundingRequested, Project_terminated, Project_complete, Project_isArchived, Project_state, Project_originalPrice, Project_activePrice, Project_totalCredits, Project_acceptedChangeAmount, Project_fundingNeeded, Project_clientDeposits, Project_pendingClientDeposits, Project_contractorPayments, Project_funded, Project_started, Project_substantialCompletion)
    Project.serializer().properties { props }
}
val <K> DataClassPath<K, Project>._id: DataClassPath<K, UUID> get() = this[Project__id]
val <K> DataClassPath<K, Project>.contractor: DataClassPath<K, UUID> get() = this[Project_contractor]
val <K> DataClassPath<K, Project>.customer: DataClassPath<K, UUID?> get() = this[Project_customer]
val <K> DataClassPath<K, Project>.client: DataClassPath<K, UUID?> get() = this[Project_client]
val <K> DataClassPath<K, Project>.name: DataClassPath<K, String> get() = this[Project_name]
val <K> DataClassPath<K, Project>.description: DataClassPath<K, String> get() = this[Project_description]
val <K> DataClassPath<K, Project>.address: DataClassPath<K, Address> get() = this[Project_address]
val <K> DataClassPath<K, Project>.location: DataClassPath<K, String?> get() = this[Project_location]
val <K> DataClassPath<K, Project>.jobNumber: DataClassPath<K, String?> get() = this[Project_jobNumber]
val <K> DataClassPath<K, Project>.files: DataClassPath<K, List<ProjectAttachment>> get() = this[Project_files]
val <K> DataClassPath<K, Project>.retention: DataClassPath<K, Float?> get() = this[Project_retention]
val <K> DataClassPath<K, Project>.published: DataClassPath<K, Instant?> get() = this[Project_published]
val <K> DataClassPath<K, Project>.feedback: DataClassPath<K, ClientFeedback?> get() = this[Project_feedback]
val <K> DataClassPath<K, Project>.created: DataClassPath<K, Instant> get() = this[Project_created]
val <K> DataClassPath<K, Project>.cancelled: DataClassPath<K, AccessInfo?> get() = this[Project_cancelled]
val <K> DataClassPath<K, Project>.abandoned: DataClassPath<K, AccessInfo?> get() = this[Project_abandoned]
val <K> DataClassPath<K, Project>.archived: DataClassPath<K, AccessInfo?> get() = this[Project_archived]
val <K> DataClassPath<K, Project>.viewedByCustomer: DataClassPath<K, Instant?> get() = this[Project_viewedByCustomer]
val <K> DataClassPath<K, Project>.approved: DataClassPath<K, AccessInfo?> get() = this[Project_approved]
val <K> DataClassPath<K, Project>.fullFundingRequested: DataClassPath<K, AccessInfo?> get() = this[Project_fullFundingRequested]
val <K> DataClassPath<K, Project>.terminated: DataClassPath<K, Instant?> get() = this[Project_terminated]
val <K> DataClassPath<K, Project>.complete: DataClassPath<K, CompletionRequest?> get() = this[Project_complete]
val <K> DataClassPath<K, Project>.isArchived: DataClassPath<K, Boolean> get() = this[Project_isArchived]
val <K> DataClassPath<K, Project>.state: DataClassPath<K, ProjectState> get() = this[Project_state]
val <K> DataClassPath<K, Project>.originalPrice: DataClassPath<K, Long> get() = this[Project_originalPrice]
val <K> DataClassPath<K, Project>.activePrice: DataClassPath<K, Long> get() = this[Project_activePrice]
val <K> DataClassPath<K, Project>.totalCredits: DataClassPath<K, Long> get() = this[Project_totalCredits]
val <K> DataClassPath<K, Project>.acceptedChangeAmount: DataClassPath<K, Long> get() = this[Project_acceptedChangeAmount]
val <K> DataClassPath<K, Project>.fundingNeeded: DataClassPath<K, Long> get() = this[Project_fundingNeeded]
val <K> DataClassPath<K, Project>.clientDeposits: DataClassPath<K, Long> get() = this[Project_clientDeposits]
val <K> DataClassPath<K, Project>.pendingClientDeposits: DataClassPath<K, Long> get() = this[Project_pendingClientDeposits]
val <K> DataClassPath<K, Project>.contractorPayments: DataClassPath<K, Long> get() = this[Project_contractorPayments]
val <K> DataClassPath<K, Project>.funded: DataClassPath<K, Instant?> get() = this[Project_funded]
val <K> DataClassPath<K, Project>.started: DataClassPath<K, Instant?> get() = this[Project_started]
val <K> DataClassPath<K, Project>.substantialCompletion: DataClassPath<K, Instant?> get() = this[Project_substantialCompletion]
inline val Project.Companion.path: DataClassPath<Project, Project> get() = path<Project>()


object Project__id: SerializableProperty<Project, UUID> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Project): UUID = receiver._id
    override fun setCopy(receiver: Project, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object Project_contractor: SerializableProperty<Project, UUID> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Project): UUID = receiver.contractor
    override fun setCopy(receiver: Project, value: UUID) = receiver.copy(contractor = value)
    override val serializer: KSerializer<UUID> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(1) }
}
object Project_customer: SerializableProperty<Project, UUID?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Project): UUID? = receiver.customer
    override fun setCopy(receiver: Project, value: UUID?) = receiver.copy(customer = value)
    override val serializer: KSerializer<UUID?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(2) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object Project_client: SerializableProperty<Project, UUID?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(3) }
    override fun get(receiver: Project): UUID? = receiver.client
    override fun setCopy(receiver: Project, value: UUID?) = receiver.copy(client = value)
    override val serializer: KSerializer<UUID?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(3) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object Project_name: SerializableProperty<Project, String> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(4) }
    override fun get(receiver: Project): String = receiver.name
    override fun setCopy(receiver: Project, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(4) }
}
object Project_description: SerializableProperty<Project, String> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(5) }
    override fun get(receiver: Project): String = receiver.description
    override fun setCopy(receiver: Project, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(5) }
}
object Project_address: SerializableProperty<Project, Address> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(6) }
    override fun get(receiver: Project): Address = receiver.address
    override fun setCopy(receiver: Project, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<Address> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(6) }
}
object Project_location: SerializableProperty<Project, String?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(7) }
    override fun get(receiver: Project): String? = receiver.location
    override fun setCopy(receiver: Project, value: String?) = receiver.copy(location = value)
    override val serializer: KSerializer<String?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(7) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object Project_jobNumber: SerializableProperty<Project, String?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(8) }
    override fun get(receiver: Project): String? = receiver.jobNumber
    override fun setCopy(receiver: Project, value: String?) = receiver.copy(jobNumber = value)
    override val serializer: KSerializer<String?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(8) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object Project_files: SerializableProperty<Project, List<ProjectAttachment>> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(9) }
    override fun get(receiver: Project): List<ProjectAttachment> = receiver.files
    override fun setCopy(receiver: Project, value: List<ProjectAttachment>) = receiver.copy(files = value)
    override val serializer: KSerializer<List<ProjectAttachment>> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<List<ProjectAttachment>> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(9) }
    override val default: List<ProjectAttachment> = listOf()
    override val defaultCode: String? = "listOf()" 
}
object Project_retention: SerializableProperty<Project, Float?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(10) }
    override fun get(receiver: Project): Float? = receiver.retention
    override fun setCopy(receiver: Project, value: Float?) = receiver.copy(retention = value)
    override val serializer: KSerializer<Float?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<Float?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(10) }
    override val default: Float? = null
    override val defaultCode: String? = "null" 
}
object Project_published: SerializableProperty<Project, Instant?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(11) }
    override fun get(receiver: Project): Instant? = receiver.published
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(published = value)
    override val serializer: KSerializer<Instant?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[11] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(11) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
object Project_feedback: SerializableProperty<Project, ClientFeedback?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(12) }
    override fun get(receiver: Project): ClientFeedback? = receiver.feedback
    override fun setCopy(receiver: Project, value: ClientFeedback?) = receiver.copy(feedback = value)
    override val serializer: KSerializer<ClientFeedback?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[12] as KSerializer<ClientFeedback?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(12) }
    override val default: ClientFeedback? = null
    override val defaultCode: String? = "null" 
}
object Project_created: SerializableProperty<Project, Instant> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(13) }
    override fun get(receiver: Project): Instant = receiver.created
    override fun setCopy(receiver: Project, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[13] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(13) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object Project_cancelled: SerializableProperty<Project, AccessInfo?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(14) }
    override fun get(receiver: Project): AccessInfo? = receiver.cancelled
    override fun setCopy(receiver: Project, value: AccessInfo?) = receiver.copy(cancelled = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[14] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(14) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object Project_abandoned: SerializableProperty<Project, AccessInfo?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(15) }
    override fun get(receiver: Project): AccessInfo? = receiver.abandoned
    override fun setCopy(receiver: Project, value: AccessInfo?) = receiver.copy(abandoned = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[15] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(15) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object Project_archived: SerializableProperty<Project, AccessInfo?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(16) }
    override fun get(receiver: Project): AccessInfo? = receiver.archived
    override fun setCopy(receiver: Project, value: AccessInfo?) = receiver.copy(archived = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[16] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(16) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object Project_viewedByCustomer: SerializableProperty<Project, Instant?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(17) }
    override fun get(receiver: Project): Instant? = receiver.viewedByCustomer
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(viewedByCustomer = value)
    override val serializer: KSerializer<Instant?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[17] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(17) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
object Project_approved: SerializableProperty<Project, AccessInfo?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(18) }
    override fun get(receiver: Project): AccessInfo? = receiver.approved
    override fun setCopy(receiver: Project, value: AccessInfo?) = receiver.copy(approved = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[18] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(18) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object Project_fullFundingRequested: SerializableProperty<Project, AccessInfo?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(19) }
    override fun get(receiver: Project): AccessInfo? = receiver.fullFundingRequested
    override fun setCopy(receiver: Project, value: AccessInfo?) = receiver.copy(fullFundingRequested = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[19] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(19) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object Project_terminated: SerializableProperty<Project, Instant?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(20) }
    override fun get(receiver: Project): Instant? = receiver.terminated
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(terminated = value)
    override val serializer: KSerializer<Instant?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[20] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(20) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
object Project_complete: SerializableProperty<Project, CompletionRequest?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(21) }
    override fun get(receiver: Project): CompletionRequest? = receiver.complete
    override fun setCopy(receiver: Project, value: CompletionRequest?) = receiver.copy(complete = value)
    override val serializer: KSerializer<CompletionRequest?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[21] as KSerializer<CompletionRequest?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(21) }
    override val default: CompletionRequest? = null
    override val defaultCode: String? = "null" 
}
object Project_isArchived: SerializableProperty<Project, Boolean> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(22) }
    override fun get(receiver: Project): Boolean = receiver.isArchived
    override fun setCopy(receiver: Project, value: Boolean) = receiver.copy(isArchived = value)
    override val serializer: KSerializer<Boolean> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[22] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(22) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object Project_state: SerializableProperty<Project, ProjectState> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(23) }
    override fun get(receiver: Project): ProjectState = receiver.state
    override fun setCopy(receiver: Project, value: ProjectState) = receiver.copy(state = value)
    override val serializer: KSerializer<ProjectState> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[23] as KSerializer<ProjectState> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(23) }
    override val default: ProjectState = ProjectState.Creating
    override val defaultCode: String? = "ProjectState.Creating" 
}
object Project_originalPrice: SerializableProperty<Project, Long> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(24) }
    override fun get(receiver: Project): Long = receiver.originalPrice
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(originalPrice = value)
    override val serializer: KSerializer<Long> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[24] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(24) }
    override val default: Long = 0
    override val defaultCode: String? = "0" 
}
object Project_activePrice: SerializableProperty<Project, Long> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(25) }
    override fun get(receiver: Project): Long = receiver.activePrice
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(activePrice = value)
    override val serializer: KSerializer<Long> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[25] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(25) }
    override val default: Long = 0
    override val defaultCode: String? = "0" 
}
object Project_totalCredits: SerializableProperty<Project, Long> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(26) }
    override fun get(receiver: Project): Long = receiver.totalCredits
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(totalCredits = value)
    override val serializer: KSerializer<Long> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[26] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(26) }
    override val default: Long = 0
    override val defaultCode: String? = "0" 
}
object Project_acceptedChangeAmount: SerializableProperty<Project, Long> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(27) }
    override fun get(receiver: Project): Long = receiver.acceptedChangeAmount
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(acceptedChangeAmount = value)
    override val serializer: KSerializer<Long> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[27] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(27) }
    override val default: Long = 0
    override val defaultCode: String? = "0" 
}
object Project_fundingNeeded: SerializableProperty<Project, Long> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(28) }
    override fun get(receiver: Project): Long = receiver.fundingNeeded
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(fundingNeeded = value)
    override val serializer: KSerializer<Long> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[28] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(28) }
    override val default: Long = 0L
    override val defaultCode: String? = "0L" 
}
object Project_clientDeposits: SerializableProperty<Project, Long> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(29) }
    override fun get(receiver: Project): Long = receiver.clientDeposits
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(clientDeposits = value)
    override val serializer: KSerializer<Long> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[29] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(29) }
    override val default: Long = 0L
    override val defaultCode: String? = "0L" 
}
object Project_pendingClientDeposits: SerializableProperty<Project, Long> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(30) }
    override fun get(receiver: Project): Long = receiver.pendingClientDeposits
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(pendingClientDeposits = value)
    override val serializer: KSerializer<Long> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[30] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(30) }
    override val default: Long = 0L
    override val defaultCode: String? = "0L" 
}
object Project_contractorPayments: SerializableProperty<Project, Long> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(31) }
    override fun get(receiver: Project): Long = receiver.contractorPayments
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(contractorPayments = value)
    override val serializer: KSerializer<Long> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[31] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(31) }
    override val default: Long = 0L
    override val defaultCode: String? = "0L" 
}
object Project_funded: SerializableProperty<Project, Instant?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(32) }
    override fun get(receiver: Project): Instant? = receiver.funded
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(funded = value)
    override val serializer: KSerializer<Instant?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[32] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(32) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
object Project_started: SerializableProperty<Project, Instant?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(33) }
    override fun get(receiver: Project): Instant? = receiver.started
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(started = value)
    override val serializer: KSerializer<Instant?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[33] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(33) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
object Project_substantialCompletion: SerializableProperty<Project, Instant?> {
    override val name: String by lazy { Project.serializer().descriptor.getElementName(34) }
    override fun get(receiver: Project): Instant? = receiver.substantialCompletion
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(substantialCompletion = value)
    override val serializer: KSerializer<Instant?> by lazy { (Project.serializer() as GeneratedSerializer<*>).childSerializers()[34] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { Project.serializer().descriptor.getElementAnnotations(34) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
