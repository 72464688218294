package com.crowpay.utils.validation

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter

fun <T, READABLE : Readable<T>> READABLE.interceptWrite(action: suspend READABLE.(T) -> Unit): Writable<T> =
    object : Writable<T>, Readable<T> by this {
        override suspend fun set(value: T) {
            action(this@interceptWrite, value)
        }
    }

interface Validated {
    val validator: Validator

    val valid: ValidCondition

    fun removeCondition() = validator.removeCondition(valid)
}

interface ValidatedWritable<T> : Validated, Writable<T> {
    suspend fun refresh()
}

fun <T> Writable<T>.withValidation(
    validator: Validator,
    startsValid: Boolean = false,
    validCondition: (T) -> Boolean,
): ValidatedWritable<T> = object : ValidatedWritable<T>, Readable<T> by this, BaseListenable() {
    override val validator: Validator = validator
    val source = this@withValidation
    private val debug: Console? = null

    override fun addListener(listener: () -> Unit): () -> Unit {
        val r1 = super.addListener(listener)
        val r2 = source.addListener(listener)
        return { r1(); r2() }
    }
    var listen: (()->Unit)? = null
    override fun activate() {
        super.activate()
        listen = source.addListener {
            source.state.onSuccess { checkValid(it) }
        }
    }
    override fun deactivate() {
        super.deactivate()
        listen?.invoke()
        listen = null
    }

    private val isValid = Property(startsValid)

    override val valid: ValidCondition = validator.addCondition(isValid)

    private fun checkValid(value: T) {
        val result: Boolean = validCondition(value)
        debug?.log("Setting Value: $value")
        isValid.value = result

        debug?.log("Checking validity with value [ $value ] with result [ $result ]")
    }

    override suspend fun refresh() {
        checkValid(this@withValidation.awaitOnce())
    }

    override suspend fun set(value: T) {
        checkValid(value)
        this@withValidation set value
    }

    init {
        launchGlobal {
            debug?.log("Initial Refresh")
            refresh()
        }
    }
}

infix fun Validated.and(other: Validated): Validated =
    object : Validated {
        override val validator: Validator get() = this@and.validator
        override val valid: ValidCondition = shared {
            this@and.valid() and other.valid()
        }

        init {
            if (this@and.validator != other.validator) {
                this@and.validator.addCondition(other.valid)
                other.validator.addCondition(this@and.valid)
            }
        }
    }

infix fun Validated.and(other: ValidCondition): Validated =
    object : Validated {
        override val validator: Validator get() = this@and.validator
        override val valid: ValidCondition = shared {
            this@and.valid() and other()
        }
    }