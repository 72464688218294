// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareIncidentMessageCommentFields() {
    val props: Array<SerializableProperty<IncidentMessageComment, *>> = arrayOf(IncidentMessageComment__id, IncidentMessageComment_incidentMessage, IncidentMessageComment_project, IncidentMessageComment_incident, IncidentMessageComment_sender, IncidentMessageComment_clientMessage, IncidentMessageComment_message, IncidentMessageComment_created)
    IncidentMessageComment.serializer().properties { props }
}
val <K> DataClassPath<K, IncidentMessageComment>._id: DataClassPath<K, UUID> get() = this[IncidentMessageComment__id]
val <K> DataClassPath<K, IncidentMessageComment>.incidentMessage: DataClassPath<K, UUID> get() = this[IncidentMessageComment_incidentMessage]
val <K> DataClassPath<K, IncidentMessageComment>.project: DataClassPath<K, UUID> get() = this[IncidentMessageComment_project]
val <K> DataClassPath<K, IncidentMessageComment>.incident: DataClassPath<K, UUID> get() = this[IncidentMessageComment_incident]
val <K> DataClassPath<K, IncidentMessageComment>.sender: DataClassPath<K, UUID> get() = this[IncidentMessageComment_sender]
val <K> DataClassPath<K, IncidentMessageComment>.clientMessage: DataClassPath<K, Boolean> get() = this[IncidentMessageComment_clientMessage]
val <K> DataClassPath<K, IncidentMessageComment>.message: DataClassPath<K, String> get() = this[IncidentMessageComment_message]
val <K> DataClassPath<K, IncidentMessageComment>.created: DataClassPath<K, Instant> get() = this[IncidentMessageComment_created]
inline val IncidentMessageComment.Companion.path: DataClassPath<IncidentMessageComment, IncidentMessageComment> get() = path<IncidentMessageComment>()


object IncidentMessageComment__id: SerializableProperty<IncidentMessageComment, UUID> {
    override val name: String by lazy { IncidentMessageComment.serializer().descriptor.getElementName(0) }
    override fun get(receiver: IncidentMessageComment): UUID = receiver._id
    override fun setCopy(receiver: IncidentMessageComment, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (IncidentMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { IncidentMessageComment.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object IncidentMessageComment_incidentMessage: SerializableProperty<IncidentMessageComment, UUID> {
    override val name: String by lazy { IncidentMessageComment.serializer().descriptor.getElementName(1) }
    override fun get(receiver: IncidentMessageComment): UUID = receiver.incidentMessage
    override fun setCopy(receiver: IncidentMessageComment, value: UUID) = receiver.copy(incidentMessage = value)
    override val serializer: KSerializer<UUID> by lazy { (IncidentMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { IncidentMessageComment.serializer().descriptor.getElementAnnotations(1) }
}
object IncidentMessageComment_project: SerializableProperty<IncidentMessageComment, UUID> {
    override val name: String by lazy { IncidentMessageComment.serializer().descriptor.getElementName(2) }
    override fun get(receiver: IncidentMessageComment): UUID = receiver.project
    override fun setCopy(receiver: IncidentMessageComment, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (IncidentMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { IncidentMessageComment.serializer().descriptor.getElementAnnotations(2) }
}
object IncidentMessageComment_incident: SerializableProperty<IncidentMessageComment, UUID> {
    override val name: String by lazy { IncidentMessageComment.serializer().descriptor.getElementName(3) }
    override fun get(receiver: IncidentMessageComment): UUID = receiver.incident
    override fun setCopy(receiver: IncidentMessageComment, value: UUID) = receiver.copy(incident = value)
    override val serializer: KSerializer<UUID> by lazy { (IncidentMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { IncidentMessageComment.serializer().descriptor.getElementAnnotations(3) }
}
object IncidentMessageComment_sender: SerializableProperty<IncidentMessageComment, UUID> {
    override val name: String by lazy { IncidentMessageComment.serializer().descriptor.getElementName(4) }
    override fun get(receiver: IncidentMessageComment): UUID = receiver.sender
    override fun setCopy(receiver: IncidentMessageComment, value: UUID) = receiver.copy(sender = value)
    override val serializer: KSerializer<UUID> by lazy { (IncidentMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { IncidentMessageComment.serializer().descriptor.getElementAnnotations(4) }
}
object IncidentMessageComment_clientMessage: SerializableProperty<IncidentMessageComment, Boolean> {
    override val name: String by lazy { IncidentMessageComment.serializer().descriptor.getElementName(5) }
    override fun get(receiver: IncidentMessageComment): Boolean = receiver.clientMessage
    override fun setCopy(receiver: IncidentMessageComment, value: Boolean) = receiver.copy(clientMessage = value)
    override val serializer: KSerializer<Boolean> by lazy { (IncidentMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { IncidentMessageComment.serializer().descriptor.getElementAnnotations(5) }
}
object IncidentMessageComment_message: SerializableProperty<IncidentMessageComment, String> {
    override val name: String by lazy { IncidentMessageComment.serializer().descriptor.getElementName(6) }
    override fun get(receiver: IncidentMessageComment): String = receiver.message
    override fun setCopy(receiver: IncidentMessageComment, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> by lazy { (IncidentMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { IncidentMessageComment.serializer().descriptor.getElementAnnotations(6) }
}
object IncidentMessageComment_created: SerializableProperty<IncidentMessageComment, Instant> {
    override val name: String by lazy { IncidentMessageComment.serializer().descriptor.getElementName(7) }
    override fun get(receiver: IncidentMessageComment): Instant = receiver.created
    override fun setCopy(receiver: IncidentMessageComment, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (IncidentMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { IncidentMessageComment.serializer().descriptor.getElementAnnotations(7) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
