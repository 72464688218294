package com.crowpay.views.dialogs

import com.crowpay.sdk.notNullSession
import com.crowpay.views.screens.common.ProjectView
import com.lightningkite.kiteui.reactive.invoke
import kotlinx.coroutines.delay

fun ProjectView.requestFundsDialog() = GenericConfirmationDialog(
    header = "Request Funds?",
    message = """
        Requesting funds notifies the client to deposit money for the project. Be ready to start soon, and communicate any changes to scope or cost through CrowPay to ensure approval and avoid payment issues.

        Once funds transfer, the status will update to 'Pending Start.' If the status remains 'Awarded' after 2-3 days, consider reminding the client to submit funds.
    """.trimIndent(),
    confirmationText = "Request Funds",
    messageType = MessageType.Confirmation,
    onSubmit = {
        if (it) {
            val session = notNullSession()
            val id = project()._id
            delay(200)
            session.nonCached.project.requestFunding(id)
            session.projects[id].invalidate()
        }
    }
)