package com.crowpay.views.components

import com.crowpay.actuals.AppDimensions
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.SubtextSemantic
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.errorText
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract


@ViewDsl
fun ViewWriter.titleLabel(value: String, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        title {
            content = value
        }
        setup()
    }
}

@ViewDsl
fun ViewWriter.titleLabel(value: Readable<String>, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        title {
            ::content{ value() }
        }
        setup()
    }
}


@ViewDsl
fun ViewWriter.subTitleLabel(value: String, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        subTitle {
            content = value
        }
        setup()
    }
}

@ViewDsl
fun ViewWriter.subTitleLabel(value: Readable<String>, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        subTitle {
            ::content{ value() }
        }
        setup()
    }
}


@ViewDsl
fun ViewWriter.label2(label: String, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        bodyBold(label)
        setup()
    }
}

@ViewDsl
fun ViewWriter.requiredLabel(label: String, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        row {
            spacing = AppDimensions.cornerRadii
            centered - bodyBold(label)
            colored(AppColors.red.main) - bodyBold("*")
        }
        setup()
    }
}

@ViewDsl
fun ViewWriter.label2(value: Readable<String>, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        bodyBold { ::content{ value() } }
        setup()
    }
}

@ViewDsl
fun ViewWriter.field2(label: String, field: RView.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        bodyBold(label)
        fieldTheme - field()
        SubtextSemantic.onNext - errorText()
    }
}

@ViewDsl
fun ViewWriter.requiredField(label: String, field: RView.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        row {
            spacing = AppDimensions.cornerRadii
            centered - bodyBold(label)
            colored(AppColors.red.main) - bodyBold("*")
        }
        fieldTheme - field()
        SubtextSemantic.onNext - errorText()
    }
}

@ViewDsl
fun ViewWriter.requiredField(label: ReactiveContext.()->String, field: RView.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        row {
            spacing = AppDimensions.cornerRadii
            centered - bodyBold { ::content.invoke(label) }
            colored(AppColors.red.main) - bodyBold("*")
        }
        fieldTheme - field()
        SubtextSemantic.onNext - errorText()
    }
}

@ViewDsl
fun ViewWriter.indicatesRequiredFieldKey() {
    row {
        spacing = 0.5.rem
        colored(AppColors.red.main) - bodyBold("*")
        body("- Indicates Required Field")
    }
}