package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.sdk.notFoundError
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.project.work.scope.LineItemCompletionForm
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.body
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.and
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningdb.neq

fun ProjectView.disputeBar(writer: ViewWriter) = with(writer) {
    danger - col {
        existsDefaultFalse { project().state in ProjectState.disputedStates }

        body("There is an active dispute in progress. The project has been paused until the dispute is resolved.")

        atEnd - row {
            buttonTheme - important - button {
                existsDefaultFalse {
                    disputes().any { it.lineItem != null }
                }
                body("Respond to ${if (lens == ProjectLens.Contractor) "Homeowner" else "Contractor"}")
                onClick {
                    val lineId = disputes().firstNotNullOfOrNull { it.lineItem?.itemId } ?: return@onClick
                    val line = lineItems().find { it._id == lineId } ?: notFoundError<LineItem>()
                    dialogScreenNavigator.navigate(
                        LineItemCompletionForm.createDialog(line, project(), lens)
                    )
                }
            }
            if (lens == ProjectLens.Customer) buttonTheme - important - button {
                body("End Dispute")
                onClick {
                    val p = project.await()
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            header = "End Project Dispute?",
                            message = "Once you end this dispute the project will no longer be on hold and can move forward. End the project dispute?",
                            confirmationText = "End Dispute",
                            messageType = MessageType.Confirmation,
                            onSubmit = { confirmed ->
                                if (confirmed) {
                                    val session = notNullSession()
                                    session.nonCached.project.endDispute(p._id)
                                    session.projects[p._id].invalidate()
                                    session.lineItems.totallyInvalidate()
                                }
                            },
                        )
                    )
                }
            } in gravity(Align.End, Align.Center)
        }
    }
}