package com.crowpay.views.theming

import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.utils.resize
import com.lightningkite.kabobCase
import com.lightningkite.kiteui.PlatformStorage
import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.DefaultJson
import com.lightningkite.kiteui.reactive.ImmediateWritable
import com.lightningkite.kiteui.reactive.PersistentProperty
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import kotlinx.serialization.builtins.serializer
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract

data class ToDoSemantic(val color: Color) : Semantic {
    override val key: String = "todo${color.toInt()}"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        outlineWidth = 2.dp,
        cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
        outline = color,
        revert = true
    ).withBack
}

@ViewModifierDsl3
fun ViewWriter.toDoSection(color: Color): ViewWrapper = ToDoSemantic(color).onNext

data class NotificationBarSemantic(val colors: ColorSet) : Semantic {
    override val key: String = "not${colors.main.toInt()}"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        background = colors.light3,
        outline = colors.light1,
        outlineWidth = AppDimensions.outlineWidth,
        cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
        spacing = 1.rem
    ).withBack
}

@ViewModifierDsl3
fun ViewWriter.notificationBar(colors: ColorSet): ViewWrapper = NotificationBarSemantic(colors).onNext

private class DismissibleBannerHandler(name: String): ImmediateWritable<Boolean> {
    val key = "${name.kabobCase()}-banner"
    val exposed = Property(
        PlatformStorage.get(key)
            ?.let { DefaultJson.decodeFromString(Boolean.serializer(), it) }
            ?: true
    )

    fun dismissForever() {
        exposed.value = false
        PlatformStorage.set(key, DefaultJson.encodeToString(Boolean.serializer(), false))
    }

    override var value by exposed::value
    override fun addListener(listener: () -> Unit): () -> Unit = exposed.addListener(listener)
}

@ViewDsl
fun ViewWriter.dismissibleBanner(name: String, setup: ViewWriter.() -> ViewModifiable) : ViewModifiable {
    val expandBanner = DismissibleBannerHandler(name)
    return stack {
        ::exists { expandBanner() }
        setup()
        atTopEnd - menuButton {
            spacing = 3.dp
            requireClick = true
            icon(Icon.close.resize(1.rem), "Dismiss Banner")
            val close = { closePopovers() }
            opensMenu {
                col {
                    spacing = 0.px
                    textButton - button {
                        body("Dismiss Once")
                        onClick {
                            expandBanner.value = false
                            close()
                        }
                    }
                    textButton - button {
                        body("Dismiss Forever")
                        onClick {
                            expandBanner.dismissForever()
                            close()
                        }
                    }
                }
            }
        }
    }
}