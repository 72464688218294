package com.crowpay.views.screens.common

import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.dismissBackground
import com.lightningkite.kiteui.views.direct.swapView
import com.lightningkite.kiteui.views.direct.swapping
import com.lightningkite.kiteui.views.rContextAddon

val ViewWriter.secondDialog: Property<Screen?> by rContextAddon(Property(null))

interface DialogWithDialog : Page {
    fun ViewWriter.renderPage(): ViewModifiable

    fun ViewWriter.dismissSecondDialog() { secondDialog.value = null }

    override fun ViewWriter.render2(): ViewModifiable {
        secondDialog.value = null
        return dismissBackground {
            renderPage()
            swapView {
                swapping(
                    current = { secondDialog() },
                    views = { it?.run { render() } }
                )
            }
        }
    }
}