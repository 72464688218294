package com.crowpay.utils

import com.lightningkite.now
import kotlinx.datetime.*
import kotlinx.datetime.format.DateTimeComponents
import kotlinx.datetime.format.DateTimeFormat
import kotlinx.datetime.format.Padding
import kotlinx.datetime.format.char

private val timeZone = TimeZone.currentSystemDefault()

fun Instant.formatLong(): String = buildString {
    val dateTime = this@formatLong.toLocalDateTime(timeZone)

    append("${dateTime.month.toString().allCapsToTitle()} ${dateTime.dayOfMonth}, ")
    append("${dateTime.year}, ")
    append(
        "${
            (dateTime.hour + 11) % 12 + 1
        }:${
            dateTime.minute.toString().padStart(2, '0')
        } ${
            if (dateTime.hour / 12 < 1) "AM" else "PM"
        }"
    )
}

fun Instant.format(formatter: DateTimeFormat<LocalDateTime>) = toLocalDateTime(timeZone).format(formatter)

private val today = now().toLocalDateTime(timeZone)
fun Instant.formatRelative(includeTimestamp: Boolean = true): String {
    val dateTime = toLocalDateTime(timeZone)

    return when (dateTime.date) {
        today.date -> {
            if (includeTimestamp) "Today ${dateTime.format(Formats.hmm)}"
            else "Today"
        }
        today.date.minus(DatePeriod(days = 1)) -> {
            if (includeTimestamp) "Yesterday ${dateTime.format(Formats.hmm)}"
            else "Yesterday"
        }
        else -> dateTime.format(
            if (includeTimestamp) Formats.mmddyyyy_hmm
            else Formats.mmddyyyy
        )
    }
}

object Formats {
    val mmddyyyy = LocalDateTime.Format {
        monthNumber(Padding.NONE)
        char('/')
        dayOfMonth(Padding.NONE)
        char('/')
        year()
    }
    val mmddyyyy_hmm = LocalDateTime.Format {
        monthNumber(Padding.NONE)
        char('/')
        dayOfMonth(Padding.NONE)
        char('/')
        year()
        char(' ')
        amPmHour(Padding.NONE)
        char(':')
        minute(Padding.ZERO)
        char(' ')
        amPmMarker("AM", "PM")
    }
    val hmm = LocalDateTime.Format {
        amPmHour(Padding.NONE)
        char(':')
        minute(Padding.ZERO)
        char(' ')
        amPmMarker("AM", "PM")
    }
}