package com.crowpay.views.screens.common

import com.crowpay.*
import com.crowpay.sdk.*
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.screens.auth.LogInScreen
import com.crowpay.views.screens.auth.UserRegistration
import com.crowpay.views.screens.auth.handleAuthRendering
import com.crowpay.views.screens.client.ClientTermsAndServices
import com.lightningkite.UUID
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.exceptions.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.lightningserver.LsErrorException
import kotlinx.coroutines.launch

interface PreviewScreen

@Routable("anonymous/projects/{projectId}")
class AnonymousProjectView(override val projectId: UUID) : ProjectView(ProjectLens.Preview), PreviewScreen {
    override val title: Readable<String> = Constant("Project Preview")

    init {
        ExceptionToMessages.root += ExceptionToMessage<LsErrorException>(
            additionalCondition = {
                it.message?.contains("You must be authorized as a ???") ?: false
            },
            handler = {
                ExceptionMessage(
                    "Link Expired",
                    "The link are using has expired, please acquire a new link to continue viewing anonymously."
                )
            }
        )
    }

    @QueryParameter
    val authorization: LateInitProperty<String> = LateInitProperty()

    @QueryParameter
    val server: LateInitProperty<String> = LateInitProperty()

    val apiOption = shared {
        println("In ApiOptions")
        val s = ApiOption.entries.find { it.http == server() } ?: ApiOption.Dev
        println("Found $s")
        selectedApi.value = s
        println("Set $s")
        s
    }

    val rawData = sharedSuspending {
        apiOption().api.project.viewProjectAnonymously( { authorization() }, null)
    }
    val projectData = LazyProperty { rawData() }

    override val project = shared { projectData().project }
    override val lineItems = shared {
        projectData().lineItems
            .sortedWith(compareBy<LineItem> { it.order }.thenBy { it.created })
            .map { AdjustedLineItem(it, emptyList()) }
    }
    override val scopes = shared { projectData().scopesViews }
    override val client = shared { projectData().client }
    override val contractor = shared { projectData().contractor }

    override val changeOrders: Readable<List<ChangeRequest>> = Constant(emptyList())
    override val disputes: Readable<List<Dispute>> = Constant(emptyList())
    override val contractorNotes: Readable<List<ContractorNote>> = Constant(emptyList())
    override val payAppItems: Readable<List<PayAppItem>> = Constant(emptyList())
    override val payApps: Readable<List<PayApplication>> = Constant(emptyList())
    override val pendingItemChanges: Readable<List<PendingItemChange>> = Constant(emptyList())
    override val pendingLineItems: Readable<List<PendingLineItem>> = Constant(emptyList())
    override val punchListItems: Readable<List<PunchListItem>> = Constant(emptyList())
    override val lineItemCompletionMessages: Readable<List<LineItemCompletionMessage>> = Constant(emptyList())
    override val incidents: Readable<List<Incident>> = Constant(emptyList())
    override val incidentMessages: Readable<List<IncidentMessage>> = Constant(emptyList())

    override suspend fun ViewWriter.onLogin() {
        val api = apiOption()
        screenNavigator.navigate(LogInScreen().apply {
            nextScreen = this@AnonymousProjectView
            selectedApi.set(api)
        })
    }

    override suspend fun ViewWriter.onAccept() {
        dialogScreenNavigator.navigate(GenericConfirmationDialog(
            header = "Accept Project?",
            message = "Accepting this project confirms that you have reviewed and agreed to the project details. Please check everything is correct, as changes after this step will need both parties to agree. Click 'Accept' and proceed to review CrowPay's Terms of Service for escrow project protection.",
            confirmationText = "Accept Project",
            declineText = "Go Back",
            messageType = MessageType.Confirmation,
            onSubmit = {
                if (it) {
                    val p = projectData.awaitOnce()
                    screenNavigator.navigate(ClientTermsAndServices(p.project._id).apply {
                        fullProject = p
                    })
                }
            }
        ))
    }

    override suspend fun ViewWriter.onRegister() {
        val api = apiOption()
        screenNavigator.navigate(UserRegistration().apply {
            nextScreen = this@AnonymousProjectView
            projectData = this@AnonymousProjectView.projectData.await()
            apiOption = api

            val client = client()
            userData.value = User(
                email = client.email,
                phoneNumber = client.phoneNumber,
                address = client.address,
                firstName = client.name.substringBefore(' '),
                lastName = client.name.substringAfter(' '),
                role = UserRole.Customer,
            )
        })
    }

    override fun ViewWriter.renderMainContent() =
        handleAuthRendering {
            launch {
                if (currentSession() == null) return@launch
                val p = project()
                if (p.state == ProjectState.Invited) {
                    println("In notify")
                    val session = notNullSession()
                    val updated = session.nonCached.project.notifyViewed(p._id)
                    projectData.modify {
                        it.copy(project = updated)
                    }
                }
            }
            projectView()
        }
}