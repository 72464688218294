package com.crowpay.views.components.project.work.scope

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.*
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.delay

class NewScopeViewDialog(
    options: List<AdjustedLineItem>,
    val scopeSet: Boolean
) : Screen, Validator() {
    val options = options
        .filter { !it.grouped && if (scopeSet) it.state < LineItemState.Complete else true }
        .sortedBy { it.wraps.created }

    val selectedItems = Property(emptyList<AdjustedLineItem>()).validate { it.isNotEmpty() }
    val sharedState = shared {
        selectedItems().map { it.state }.firstOrNull()
    }

    val draft = Draft(
        ScopeView(
            project = options
                .map { it.project }
                .toSet()
                .singleOrNull() ?: throw IllegalArgumentException("All options must be from the same project"),
            scopeTitle = "",
            scopeSet = scopeSet
        )
    )
    val scopeTitle = draft.lensPath { it.scopeTitle }.validateNotBlank()

    override fun ViewWriter.render() {
        dismissBackground {
            centered - dialog - stack {
                spacing = AppDimensions.fullIndent

                sizeConstraints(minWidth = 40.rem) - col {
                    spacing = AppDimensions.sectionIndent
                    title(if (scopeSet) "New Scope Set" else "New Scope View")

                    requiredField("Scope Title") {
                        textInput {
                            hint = "Scope Title"
                            content bind scopeTitle
                        }
                    }

                    field2("Description (Optional)") {
                        val desc = draft.lensPath { it.description }
                        sizeConstraints(height = 7.rem) - textArea {
                            hint = "Description"
                            content bind desc.nullToBlank()
                        }
                    }

                    label2("Add Work Items") {
                        sizeConstraints(height = 17.rem) - scrolls - col {
                            spacing = 0.5.rem
                            options.forEach { item ->
                                val select: Writable<Boolean> = selectedItems.contains(item)
                                row {
                                    centered - checkbox {
                                        if (scopeSet) ::enabled {
                                            val s = sharedState()
                                            s == null || s == item.state
                                        }
                                        checked bind select
                                    }
                                    centered - expanding - RemoveToggleSemantic.onNext - toggleButton {
                                        if (scopeSet) ::enabled {
                                            val s = sharedState()
                                            s == null || s == item.state
                                        }
                                        row {
                                            expanding - subTitle(item.name)
                                            if (scopeSet) LineItemStateSemantic(item.state).onNext - body(item.state.displayName)
                                            else stack {
                                                centered - row {
                                                    spacing = 0.2.rem
                                                    space(0.8.rem)
                                                    centered - body {
                                                        align = Align.Center
                                                        content = item.price.renderDollars()
                                                    }
                                                    centered - priceArrowIcon { item.totalPriceChange }
                                                }
                                            }
                                        }
                                        checked bind select
                                    }
                                }
                                greySeparator()
                            }
                        }
                    }

                    row {
                        secondaryButton - button {
                            specCenteredText("Discard")
                            onClick("Discard Scope View") { dialogScreenNavigator.dismiss() }
                        }
                        primaryButton - button {
                            ::enabled { allValid() }
                            specCenteredText {
                                "Create ${scopeTitle().ifBlank { 
                                    if (scopeSet) "Scope Set" else "Scope View" 
                                }}"
                            }

                            onClick(if (scopeSet) "Create Scope Set" else "Create Scope View") {
                                val session = notNullSession()
                                val scope = draft.pushChanges(session.scopeViews)

                                session.nonCached.scopeView.addItems(
                                    scope._id,
                                    selectedItems().map { it._id }
                                )
                                delay(500)
                                session.lineItems.totallyInvalidate()

                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    }
}