package com.crowpay.views.components.project.work.changeOrders.tables

import com.crowpay.views.theming.AppColors
import com.crowpay.ChangeRequest
import com.crowpay.actuals.AppDimensions
import com.crowpay.linked
import com.crowpay.pending
import com.crowpay.utils.*
import com.crowpay.views.components.*
import com.crowpay.views.components.project.work.changeOrders.*
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import kotlinx.coroutines.launch

private class ColumnWeights(lens: ProjectLens) {
    // Column Ratios. Kept separate in this class for easier editing
    val infoWeight = 6.75f
    val nameWeight = if (lens == ProjectLens.Customer) 3.5f else 4f
    val linkedWeight = 1f
    val idWeight = if (lens == ProjectLens.Customer) 0.5f else 1f
    val submittedWeight = 1.5f
    val statusWeight = if (lens == ProjectLens.Customer) 1.5f else 2.5f
    val originalWeight = 1.5f
    val totalWeight = 1.5f
    val dateWeight = 1.5f
}

fun ViewWriter.renderPendingItems(
    changeOrders: Readable<List<ChangeRequest>>,
    changeItems: Readable<List<ChangeRequestItemWrapper>>,
    lens: ProjectLens
) {
    val weights = ColumnWeights(lens)
    col {
        spacing = 0.px
        bold - subTitle("Work Items:")
        launch {
            for (change in changeOrders()) {
                JumpTo.PendingChangeOrder.newTarget(change._id, this@col)
            }
        }

        sectionIndentCol {
            spacing = 0.px
            sizeConstraints(height = 2.rem) - row {
                weight(weights.infoWeight) - row {
                    weight(weights.nameWeight) - centered - bodyBold("Work Item")
                    weight(weights.idWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "ID"
                    }
                    weight(weights.submittedWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Submitted"
                    }
                    weight(weights.statusWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Status"
                    }
                    weight(weights.originalWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Original"
                    }
                    weight(weights.totalWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Total"
                    }
                    weight(weights.dateWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Date"
                    }
                }
                if (lens == ProjectLens.Customer) weight(1f) - padded - space { spacing = 0.25.rem }
            }
            greySeparator()
            col {
                spacing = 0.px
                forEach(changeItems) { item ->
                    val itemExpanded = Property(false)
                    col {
                        spacing = 0.px
                        sizeConstraints(minHeight = 2.5.rem) - row {
                            val contents: ViewWriter.()->Unit = {
                                row {
                                    weight(weights.nameWeight) - row {
                                        spacing = AppDimensions.expandButtonSpace
                                        centered - expandIcon(itemExpanded) {
                                            visible = !item.cancelled
                                        }
                                        centered - body(item.name)
                                        centered - smallBody(item.changeType.preTense)
                                    }
                                    weight(weights.idWeight) - centered - body {
                                        align = Align.Center
                                        content = item.id
                                    }
                                    weight(weights.submittedWeight) - stack {
                                        centered - renderPriceChange { item.priceChange }
                                    }
                                    weight(weights.statusWeight) - centered - colored(AppColors.primary.light1) - body {
                                        align = Align.Center
                                        content = "Pending Approval"
                                    }
                                    weight(weights.originalWeight) - centered - body {
                                        align = Align.Center
                                        content = item.originalPrice.renderDollars()
                                    }
                                    weight(weights.totalWeight) - centered - body {
                                        align = Align.Center
                                        content = item.totalPrice.renderDollars()
                                    }
                                    weight(weights.dateWeight) - centered - body {
                                        align = Align.Center
                                        ::content {
                                            val id = item.changeRequest
                                            changeOrders().find { it._id == id }?.published?.format(Formats.mmddyyyy)
                                                ?: "ERR"
                                        }
                                    }
                                }
                            }

                            if (item.cancelled)
                                weight(weights.infoWeight) - contents()
                            else
                                weight(weights.infoWeight) - button {
                                    spacing = 0.px
                                    contents()
                                    action = expandAction(itemExpanded)
                                }

                            if (lens == ProjectLens.Customer) weight(1f) - padded - col {
                                spacing = 5.dp
                                primaryButton - button {
                                    spacing = 0.25.rem
                                    centered - body("Approve")
                                    action = approveSingle(item)
                                }
                                warningTextButton - button {
                                    spacing = 0.25.rem
                                    centered - body("Reject")
                                    action = denySingle(item)
                                }
                            }
                        }
                        onlyWhen { itemExpanded() } - renderChangeRequestItemDetails(item)
                        greySeparator()
                    }
                }
            }
        }
    }
}

fun ViewWriter.renderPendingChangeOrder(
    changeOrder: Readable<ChangeRequest>,
    changeItems: Readable<List<ChangeRequestItemWrapper>>,
    lens: ProjectLens
) {
    val expanded = Property(false)
    val pending = shared { changeItems().filter { it.pending } }
    val allPending = shared { changeItems().all { it.pending } }
    val total = shared { pending().sumOf { it.priceChange ?: 0 } }

    val weights = ColumnWeights(lens)
    col {
        launch {
            JumpTo.PendingChangeOrder.newTarget(changeOrder()._id, this@col) {
                expanded.value = true
            }
        }

        spacing = 0.px
        expandButtonTheme - button {
            row {
                spacing = AppDimensions.expandButtonSpace
                centered - expandIcon(expanded)
                subTitle {
                    ::content {
                        val c = changeOrder()
                        "Change #${c.number}: ${c.title}"
                    }
                }
            }
            onClick("Toggle Expanded") { expanded.toggle() }
        }

        onlyWhen { expanded() } - sectionIndentCol {
            spacing = 0.px
            space(1.rem)
            body {
                ::content {
                    "Description: ${changeOrder().description}"
                }
            }
            space(1.rem)

            sizeConstraints(height = 2.rem) - row {
                weight(weights.infoWeight) - row {
                    weight(weights.nameWeight) - space()
                    weight(weights.linkedWeight) - centered - bodyBold {
                        ::visible { changeOrder().linkItems }
                        align = Align.Center
                        content = "Linked?"
                    }
                    weight(weights.idWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "ID"
                    }
                    weight(weights.submittedWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Submitted"
                    }
                    weight(weights.statusWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Status"
                    }
                    weight(weights.originalWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Original"
                    }
                    weight(weights.totalWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Total"
                    }
                    weight(weights.dateWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Date"
                    }
                }
                if (lens == ProjectLens.Customer) weight(1f) - padded - space { spacing = 0.25.rem }
            }
            greySeparator()

            fun RowOrCol.tableRow(
                showLinked: ReactiveContext.() -> Boolean,
                id: ReactiveContext.() -> String,
                submitted: ReactiveContext.() -> Long?,
                showStatus: ReactiveContext.() -> Boolean,
                showOriginal: ReactiveContext.() -> Boolean,
                showTotal: ReactiveContext.() -> Boolean
            ) {
                weight(weights.linkedWeight) - stack {
                    colored(AppColors.secondary.main) - centered - icon {
                        ::exists.invoke(showLinked)
                        source = Icon.linked
                    }
                }
                weight(weights.idWeight) - centered - body {
                    align = Align.Center
                    ::content.invoke(id)
                }
                weight(1.5f) - stack {
                    centered - renderPriceChange(submitted)
                }
                weight(weights.statusWeight) - centered - body {
                    dynamicTheme {
                        if (showStatus()) ForegroundSemantic(ChangeStatus.PendingApproval.displayColor)
                        else null
                    }
                    align = Align.Center
                    ::content { if (showStatus()) ChangeStatus.PendingApproval.displayName else "-" }
                }
                weight(weights.originalWeight) - centered - body {
                    align = Align.Center
                    ::content { if (showOriginal()) "$0.00" else "-" }
                }
                weight(weights.totalWeight) - centered - body {
                    align = Align.Center
                    ::content { if (showTotal()) submitted().renderDollars() else "-" }
                }
                weight(weights.dateWeight) - centered - body {
                    align = Align.Center

                    ::content {
                        changeOrder().published?.format(Formats.mmddyyyy) ?: "-"
                    }
                }
            }

            col {
                spacing = 0.px
                sizeConstraints(minHeight = 3.rem) - row {
                    weight(weights.infoWeight) - row {
                        weight(weights.nameWeight) - centered - bodyBold("Group Summary")
                        tableRow(
                            showLinked = { changeOrder().fullyLinked },
                            id = { changeOrder().number.toString() },
                            submitted = { total() },
                            showStatus = { allPending() },
                            showOriginal = { allPending() },
                            showTotal = { allPending() }
                        )
                    }
                    if (lens == ProjectLens.Customer) weight(1f) - padded - col {
                        spacing = 5.dp
                        ::visible { pending().any { it.linked } }
                        primaryButton - button {
                            spacing = 0.25.rem
                            centered - row {
                                centered - body("Approve")
                                centered - icon(Icon.linked.copy(height = 1.rem, width = 1.rem), "Linked")
                            }
                            action = approveLinked { pending().filter { it.linked } }
                        }
                        warningTextButton - button {
                            spacing = 0.25.rem
                            centered - row {
                                centered - body("Reject")
                                centered - icon(Icon.linked.copy(height = 1.rem, width = 1.rem), "Linked")
                            }
                            action = denyLinked { pending().filter { it.linked } }
                        }
                    }
                }
            }
            greySeparator()
            col {
                spacing = 0.px
                forEach(pending) { item ->
                    val itemExpanded = Property(false)
                    col {
                        spacing = 0.px
                        sizeConstraints(minHeight = 2.5.rem) - row {
                            val contents: ViewWriter.()->Unit = {
                                row {
                                    weight(weights.nameWeight) - row {
                                        spacing = AppDimensions.expandButtonSpace
                                        centered - expandIcon(itemExpanded) {
                                            visible = !item.cancelled
                                        }
                                        centered - body(item.name)
                                        centered - smallBody("New")
                                    }
                                    tableRow(
                                        showLinked = { item.linked && !changeOrder().fullyLinked },
                                        id = { item.itemNumber },
                                        submitted = { item.priceChange },
                                        showStatus = { !allPending() },
                                        showOriginal = { !allPending() },
                                        showTotal = { !allPending() }
                                    )
                                }
                            }

                            if (item.cancelled)
                                weight(weights.infoWeight) - contents()
                            else
                                weight(weights.infoWeight) - button {
                                    spacing = 0.px
                                    contents()
                                    action = expandAction(itemExpanded)
                                }

                            if (lens == ProjectLens.Customer) weight(1f) - padded - col {
                                spacing = 5.dp
                                visible = !item.linked

                                primaryButton - button {
                                    spacing = 0.25.rem
                                    centered - body("Approve")
                                    action = approveSingle(item)
                                }
                                warningTextButton - button {
                                    spacing = 0.25.rem
                                    centered - body("Reject")
                                    action = denySingle(item)
                                }
                            }
                        }
                        onlyWhen { itemExpanded() } - renderChangeRequestItemDetails(item)
                        greySeparator()
                    }
                }
            }
            space()
        }

        greySeparator { ::exists { !expanded() } }
    }
}