package com.crowpay.sdk

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningserver.LSError
import com.lightningkite.lightningserver.LsErrorException
import com.lightningkite.lightningserver.StringArrayFormat
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.lightningkite.serialization.ClientModule
import com.lightningkite.uuid
import kotlin.coroutines.Continuation
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlinx.coroutines.*
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

val json = Json {
    serializersModule = ClientModule
    ignoreUnknownKeys = true
}

val stringArrayFormat = StringArrayFormat(ClientModule)
inline fun <reified T> T.urlify(): String = stringArrayFormat.encodeToString(this)
suspend inline fun <reified T> RequestResponse.readJson() = json.decodeFromString<T>(text())

suspend inline fun <reified OUT> handleResponse(response: RequestResponse): OUT =
    when {
        response.ok && OUT::class == Unit::class -> Unit as OUT
        response.ok -> response.readJson()
        else -> throw LsErrorException(response.status, json.decodeFromString<LSError>(response.text()))
    }


suspend inline fun <reified IN, reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () -> String)? = null,
    masquerade: String? = null,     // TODO: Figure out what this is supposed to do
    headers: HttpHeaders = httpHeaders(),
    body: IN,
): OUT = com.lightningkite.kiteui.fetch(
    url = url,
    method = method,
    headers = headers.apply {
        token?.let { append("Authorization", "Bearer ${it()}") }
    },
    body = RequestBodyText(json.encodeToString(body), "application/json")
).let { handleResponse(it) }

suspend inline fun <reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () -> String)? = null,
    masquerade: String? = null,     // TODO: Figure out what this is supposed to do
    headers: HttpHeaders = httpHeaders(),
): OUT = com.lightningkite.kiteui.fetch(
    url = url,
    method = method,
    headers = headers.apply {
        append("Content-Type", "application/json")
        token?.let { append("Authorization", "Bearer ${it()}") }
    },
    body = null
).let { handleResponse(it) }