package com.crowpay.views.dialogs

import com.crowpay.completionResponse.CompletionResponse
import com.crowpay.actuals.AppDimensions
import com.crowpay.completionResponse.DisputeType
import com.crowpay.views.screens.common.secondDialog
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.dialog
import com.lightningkite.kiteui.views.direct.*

class DisputeWorkItemConfirmation(
    val dismiss: ViewWriter.()->Unit = { secondDialog.value = null },
    val action: suspend (DisputeType)->Unit,
): Screen {
    suspend fun ViewWriter.submit(response: DisputeType) {
        action(response)
    }

    override fun ViewWriter.render() {
        dismissBackground {
            sizeConstraints(maxWidth = 40.rem) - dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                col {
                    themeChoice = ThemeDerivation(appTheme)

                    title("File dispute on this work item or entire project?")

                    body(
                        "Disputing this item allows the project to continue if the contractor chooses to proceed. Disputing the entire project puts all works items on hold until joint resolution or arbitration decision."
                    )

                    notificationBar(AppColors.blue) - body("""
                        If you haven’t requested a review and tried to resolve on site you should go back and request before disputing item or project. 
                        
                        Examples of Not Ready
                    """.trimIndent())

                    row {
                        spacing = AppDimensions.buttonRowSpacing

                        textButton - button {
                            body("Go Back")
                            onClick { dismiss() }
                        }

                        secondaryButton - button {
                            specCenteredText("Dispute Project")
                            onClick { submit(DisputeType.ProjectWide) }
                        }
                        dangerButton - button {
                            specCenteredText("Dispute Item")
                            onClick { submit(DisputeType.LineItem) }
                        }
                    }
                }
            }
            onClick {
                dismiss()
            }
        }
    }
}