@file: UseContextualSerialization(UUID::class, ServerFile::class, Instant::class)
package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization


// Type aliases to give meaning behind function arguments, return types, and property types.
// Everything from plaid is a string, but all have very special uses.
typealias PaymentToken = String
typealias PaymentAccountId = String
typealias PaymentId = String
typealias TransferId = String
typealias SweepId = String
typealias IntentId = String

@Serializable
@GenerateDataClassPaths
data class AccessInfo(
    @References(User::class) val user: UUID,
    val ip: String,
    val sessionId: UUID? = null,
    val userAgent: String,
    val timestamp: Instant,
){
    companion object
}

@Serializable
data class AccountInfo(
    val bankName: String,
    val accountName: String,
)

@Serializable
@GenerateDataClassPaths
data class Address(
    val line1: String = "",
    val line2: String? = null,
    val city: String = "",
    val state: State = State.Utah,
    val zipcode: String = "",
) {
    override fun toString(): String = "$line1${line2?.let { " $it" } ?: ""}, $city, ${state.abbreviated} $zipcode"
}

interface Attachment {
    val name: String
    val fileType: String
    val file: ServerFile
    val preview: ServerFile?
}

@Serializable
@GenerateDataClassPaths
data class ProjectAttachment(
    override val name: String,
    override val fileType: String,
    override val file: ServerFile,
    override val preview: ServerFile? = null,
) : Attachment

//@Serializable
//data class BankAccount(
//    val id: String,
//    val name: String,
//    val numberMask: String?,
//    val balance: Double?,
//    val currency: String?,
//    val type: String?,
//)

//@Serializable
//data class BankInfo(
//    val name: String,
//    val logo: String?,
//    val color: String?,
//    val accounts: List<BankAccount>,
//)

@Serializable
@GenerateDataClassPaths
data class ClientFeedback(
    val at: Instant = now(),
    val message: String
)

@Serializable
data class ChangeDenyRequest(
    val hash: String,
    val feedback: String? = null,
)


@Serializable
@GenerateDataClassPaths
data class CompletionRequest(
    val contractor: AccessInfo,
    val customer: AccessInfo? = null,
)

interface Contact {
    val name: String?
    val email: String?
    val phoneNumber: String?
    val address: Address?
    val image: ServerFile?
}

@Serializable
@GenerateDataClassPaths
data class LineItemDispute(
    @References(LineItem::class) val itemId: UUID,
    @References(LineItemCompletionMessage::class) val sourceMessage: UUID,
    val lineItemOnly: Boolean
)

@Serializable
@GenerateDataClassPaths
data class FiledForArbitration(
    val accessInfo: AccessInfo,
    val evidence: List<ServerFile>,
)

@Serializable
data class FullProject(
    val project: Project,
    val client: Client,
    val contractor: Contractor,
    val lineItems: List<LineItem>,
    val scopesViews: List<ScopeView>,
)


@Serializable
data class PaymentSetupRequest(
    val bankName: String,
    val routingNumber: Int,
    val accountNumber: String,
    val accountName: String,
)

@Serializable
data class PendingCashOut(
    val available: Long,
    val pending: Long,
)


@Serializable
@GenerateDataClassPaths
data class UserMembership(
    @References(Contractor::class) val contractor: UUID,
    val role: MemberRole,
)

@Serializable
data class RegisterUser(
    val user: User,
    val proof: Proof
)

@Serializable
data class RegisterContractor(
    val contractor: Contractor,
    val proof: Proof
)

interface LineItemInterface{
    val name: String
    val description: String
    val price: Long
    val files: List<ProjectAttachment>
}

interface ItemChangeInterface {
    val updatedDescription: String?
    val cancelled: Boolean
}

interface ChangeRequestItem : HasId<UUID> {
    val itemId: UUID
    val changeType: ChangeType
    val changeRequest: UUID
    val itemNumber: String
    val linked: Boolean
    val changeAccepted: Instant?
    val changeRejected: Instant?
    val priceChange: Long?
}

val ChangeRequestItem.pending: Boolean get() = (changeAccepted == null) && (changeRejected == null)

@Serializable
@GenerateDataClassPaths
data class ChangeRequestInfo(
    @References(ChangeRequest::class) val id: UUID,
    val itemNumber: String,
)

@Serializable
data class LineItemFromChangeRequest(
    override val name: String,
    override val _id: UUID,
    override val itemId: UUID,
    override val changeType: ChangeType,
    override val changeRequest: UUID,
    override val itemNumber: String,
    override val changeAccepted: Instant?,
    override val changeRejected: Instant?,
    override val priceChange: Long,
    override val description: String,
    override val price: Long,
    override val files: List<ProjectAttachment>
) : ChangeRequestItem, LineItemInterface{
    override val linked: Boolean = false
}

@Serializable
data class HashWithID(
    override val _id: UUID,
    val hash: String
) : HasId<UUID>

fun List<HashWithID>.toMap(): Map<UUID, String> = buildMap {
    for (item in this@toMap) {
        put(item._id, item.hash)
    }
}

interface ScopeViewInterface : HasId<UUID> {
    val scopeTitle: String
    val description: String?
    val scopeSet: Boolean
}

@Serializable
@GenerateDataClassPaths
data class Issue(
//    override val _id: UUID = UUID.random(),
//    @References(Project::class) val project: UUID,
//    @Denormalized @References(ApprovalResponse::class) val sourceResponse: UUID? = null,
//    @Denormalized val raisedByHomeowner: Boolean,
    val raised: AccessInfo,
    val resolved: AccessInfo? = null,
//    @Denormalized val active: Boolean = true    // Denormalized because you cannot query by AccessInfo
)

@Serializable
enum class TimerAction {
     CompleteLineItem,
     AllowRaiseIssue,
     ResumeAutoRelease,
     AllowFileDispute,
     AllowLockProject,
}

@Serializable
@GenerateDataClassPaths
data class Credit(
    val amount: Long,
    val reason: String,
    val group: UUID? = null,   // Project credits are actually just line credits but applied in a group distributed over all line items
)

interface HasDisplayName {
    val displayName: String
}