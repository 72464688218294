package com.crowpay.extensions

import com.crowpay.*
import org.kotlincrypto.hash.sha2.Bit32Digest
import org.kotlincrypto.hash.sha2.SHA256
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

@OptIn(ExperimentalEncodingApi::class)
fun PendingLineItem.hash(): String {
    val digest = SHA256()

    digest.hash(this)

    return Base64.encode(digest.digest())
}

@OptIn(ExperimentalEncodingApi::class)
fun List<PendingLineItem>.hash(): String {
    val digest = SHA256()

    for (item in this) {
        digest.hash(item)
    }

    return Base64.encode(digest.digest())
}