// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareClientFields() {
    val props: Array<SerializableProperty<Client, *>> = arrayOf(Client__id, Client_contractor, Client_name, Client_email, Client_phoneNumber, Client_address)
    Client.serializer().properties { props }
}
val <K> DataClassPath<K, Client>._id: DataClassPath<K, UUID> get() = this[Client__id]
val <K> DataClassPath<K, Client>.contractor: DataClassPath<K, UUID> get() = this[Client_contractor]
val <K> DataClassPath<K, Client>.name: DataClassPath<K, String> get() = this[Client_name]
val <K> DataClassPath<K, Client>.email: DataClassPath<K, String> get() = this[Client_email]
val <K> DataClassPath<K, Client>.phoneNumber: DataClassPath<K, String> get() = this[Client_phoneNumber]
val <K> DataClassPath<K, Client>.address: DataClassPath<K, Address> get() = this[Client_address]
inline val Client.Companion.path: DataClassPath<Client, Client> get() = path<Client>()


object Client__id: SerializableProperty<Client, UUID> {
    override val name: String by lazy { Client.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Client): UUID = receiver._id
    override fun setCopy(receiver: Client, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (Client.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Client.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object Client_contractor: SerializableProperty<Client, UUID> {
    override val name: String by lazy { Client.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Client): UUID = receiver.contractor
    override fun setCopy(receiver: Client, value: UUID) = receiver.copy(contractor = value)
    override val serializer: KSerializer<UUID> by lazy { (Client.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Client.serializer().descriptor.getElementAnnotations(1) }
}
object Client_name: SerializableProperty<Client, String> {
    override val name: String by lazy { Client.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Client): String = receiver.name
    override fun setCopy(receiver: Client, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> by lazy { (Client.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Client.serializer().descriptor.getElementAnnotations(2) }
}
object Client_email: SerializableProperty<Client, String> {
    override val name: String by lazy { Client.serializer().descriptor.getElementName(3) }
    override fun get(receiver: Client): String = receiver.email
    override fun setCopy(receiver: Client, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> by lazy { (Client.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Client.serializer().descriptor.getElementAnnotations(3) }
}
object Client_phoneNumber: SerializableProperty<Client, String> {
    override val name: String by lazy { Client.serializer().descriptor.getElementName(4) }
    override fun get(receiver: Client): String = receiver.phoneNumber
    override fun setCopy(receiver: Client, value: String) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String> by lazy { (Client.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Client.serializer().descriptor.getElementAnnotations(4) }
}
object Client_address: SerializableProperty<Client, Address> {
    override val name: String by lazy { Client.serializer().descriptor.getElementName(5) }
    override fun get(receiver: Client): Address = receiver.address
    override fun setCopy(receiver: Client, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> by lazy { (Client.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Address> }
    override val annotations: List<Annotation> by lazy { Client.serializer().descriptor.getElementAnnotations(5) }
}
