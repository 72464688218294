// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun preparePendingItemChangeFields() {
    val props: Array<SerializableProperty<PendingItemChange, *>> = arrayOf(PendingItemChange__id, PendingItemChange_project, PendingItemChange_changeRequest, PendingItemChange_itemId, PendingItemChange_updatedDescription, PendingItemChange_priceChange, PendingItemChange_cancelled, PendingItemChange_accepted, PendingItemChange_approved, PendingItemChange_denied, PendingItemChange_created, PendingItemChange_itemNumber)
    PendingItemChange.serializer().properties { props }
}
val <K> DataClassPath<K, PendingItemChange>._id: DataClassPath<K, UUID> get() = this[PendingItemChange__id]
val <K> DataClassPath<K, PendingItemChange>.project: DataClassPath<K, UUID> get() = this[PendingItemChange_project]
val <K> DataClassPath<K, PendingItemChange>.changeRequest: DataClassPath<K, UUID> get() = this[PendingItemChange_changeRequest]
val <K> DataClassPath<K, PendingItemChange>.itemId: DataClassPath<K, UUID> get() = this[PendingItemChange_itemId]
val <K> DataClassPath<K, PendingItemChange>.updatedDescription: DataClassPath<K, String?> get() = this[PendingItemChange_updatedDescription]
val <K> DataClassPath<K, PendingItemChange>.priceChange: DataClassPath<K, Long?> get() = this[PendingItemChange_priceChange]
val <K> DataClassPath<K, PendingItemChange>.cancelled: DataClassPath<K, Boolean> get() = this[PendingItemChange_cancelled]
val <K> DataClassPath<K, PendingItemChange>.accepted: DataClassPath<K, AccessInfo?> get() = this[PendingItemChange_accepted]
val <K> DataClassPath<K, PendingItemChange>.approved: DataClassPath<K, Boolean?> get() = this[PendingItemChange_approved]
val <K> DataClassPath<K, PendingItemChange>.denied: DataClassPath<K, AccessInfo?> get() = this[PendingItemChange_denied]
val <K> DataClassPath<K, PendingItemChange>.created: DataClassPath<K, Instant> get() = this[PendingItemChange_created]
val <K> DataClassPath<K, PendingItemChange>.itemNumber: DataClassPath<K, String> get() = this[PendingItemChange_itemNumber]
inline val PendingItemChange.Companion.path: DataClassPath<PendingItemChange, PendingItemChange> get() = path<PendingItemChange>()


object PendingItemChange__id: SerializableProperty<PendingItemChange, UUID> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(0) }
    override fun get(receiver: PendingItemChange): UUID = receiver._id
    override fun setCopy(receiver: PendingItemChange, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object PendingItemChange_project: SerializableProperty<PendingItemChange, UUID> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(1) }
    override fun get(receiver: PendingItemChange): UUID = receiver.project
    override fun setCopy(receiver: PendingItemChange, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(1) }
}
object PendingItemChange_changeRequest: SerializableProperty<PendingItemChange, UUID> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(2) }
    override fun get(receiver: PendingItemChange): UUID = receiver.changeRequest
    override fun setCopy(receiver: PendingItemChange, value: UUID) = receiver.copy(changeRequest = value)
    override val serializer: KSerializer<UUID> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(2) }
}
object PendingItemChange_itemId: SerializableProperty<PendingItemChange, UUID> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(3) }
    override fun get(receiver: PendingItemChange): UUID = receiver.itemId
    override fun setCopy(receiver: PendingItemChange, value: UUID) = receiver.copy(itemId = value)
    override val serializer: KSerializer<UUID> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(3) }
}
object PendingItemChange_updatedDescription: SerializableProperty<PendingItemChange, String?> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(4) }
    override fun get(receiver: PendingItemChange): String? = receiver.updatedDescription
    override fun setCopy(receiver: PendingItemChange, value: String?) = receiver.copy(updatedDescription = value)
    override val serializer: KSerializer<String?> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(4) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object PendingItemChange_priceChange: SerializableProperty<PendingItemChange, Long?> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(5) }
    override fun get(receiver: PendingItemChange): Long? = receiver.priceChange
    override fun setCopy(receiver: PendingItemChange, value: Long?) = receiver.copy(priceChange = value)
    override val serializer: KSerializer<Long?> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Long?> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(5) }
    override val default: Long? = null
    override val defaultCode: String? = "null" 
}
object PendingItemChange_cancelled: SerializableProperty<PendingItemChange, Boolean> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(6) }
    override fun get(receiver: PendingItemChange): Boolean = receiver.cancelled
    override fun setCopy(receiver: PendingItemChange, value: Boolean) = receiver.copy(cancelled = value)
    override val serializer: KSerializer<Boolean> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(6) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object PendingItemChange_accepted: SerializableProperty<PendingItemChange, AccessInfo?> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(7) }
    override fun get(receiver: PendingItemChange): AccessInfo? = receiver.accepted
    override fun setCopy(receiver: PendingItemChange, value: AccessInfo?) = receiver.copy(accepted = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(7) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object PendingItemChange_approved: SerializableProperty<PendingItemChange, Boolean?> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(8) }
    override fun get(receiver: PendingItemChange): Boolean? = receiver.approved
    override fun setCopy(receiver: PendingItemChange, value: Boolean?) = receiver.copy(approved = value)
    override val serializer: KSerializer<Boolean?> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Boolean?> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(8) }
    override val default: Boolean? = null
    override val defaultCode: String? = "null" 
}
object PendingItemChange_denied: SerializableProperty<PendingItemChange, AccessInfo?> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(9) }
    override fun get(receiver: PendingItemChange): AccessInfo? = receiver.denied
    override fun setCopy(receiver: PendingItemChange, value: AccessInfo?) = receiver.copy(denied = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(9) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object PendingItemChange_created: SerializableProperty<PendingItemChange, Instant> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(10) }
    override fun get(receiver: PendingItemChange): Instant = receiver.created
    override fun setCopy(receiver: PendingItemChange, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(10) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object PendingItemChange_itemNumber: SerializableProperty<PendingItemChange, String> {
    override val name: String by lazy { PendingItemChange.serializer().descriptor.getElementName(11) }
    override fun get(receiver: PendingItemChange): String = receiver.itemNumber
    override fun setCopy(receiver: PendingItemChange, value: String) = receiver.copy(itemNumber = value)
    override val serializer: KSerializer<String> by lazy { (PendingItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[11] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { PendingItemChange.serializer().descriptor.getElementAnnotations(11) }
}
