// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareItemChangeFields() {
    val props: Array<SerializableProperty<ItemChange, *>> = arrayOf(ItemChange__id, ItemChange_project, ItemChange_changeRequest, ItemChange_itemId, ItemChange_linked, ItemChange_updatedDescription, ItemChange_priceChange, ItemChange_cancelled, ItemChange_created, ItemChange_itemNumber)
    ItemChange.serializer().properties { props }
}
val <K> DataClassPath<K, ItemChange>._id: DataClassPath<K, UUID> get() = this[ItemChange__id]
val <K> DataClassPath<K, ItemChange>.project: DataClassPath<K, UUID> get() = this[ItemChange_project]
val <K> DataClassPath<K, ItemChange>.changeRequest: DataClassPath<K, UUID> get() = this[ItemChange_changeRequest]
val <K> DataClassPath<K, ItemChange>.itemId: DataClassPath<K, UUID> get() = this[ItemChange_itemId]
val <K> DataClassPath<K, ItemChange>.linked: DataClassPath<K, Boolean> get() = this[ItemChange_linked]
val <K> DataClassPath<K, ItemChange>.updatedDescription: DataClassPath<K, String?> get() = this[ItemChange_updatedDescription]
val <K> DataClassPath<K, ItemChange>.priceChange: DataClassPath<K, Long?> get() = this[ItemChange_priceChange]
val <K> DataClassPath<K, ItemChange>.cancelled: DataClassPath<K, Boolean> get() = this[ItemChange_cancelled]
val <K> DataClassPath<K, ItemChange>.created: DataClassPath<K, Instant> get() = this[ItemChange_created]
val <K> DataClassPath<K, ItemChange>.itemNumber: DataClassPath<K, String> get() = this[ItemChange_itemNumber]
inline val ItemChange.Companion.path: DataClassPath<ItemChange, ItemChange> get() = path<ItemChange>()


object ItemChange__id: SerializableProperty<ItemChange, UUID> {
    override val name: String by lazy { ItemChange.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ItemChange): UUID = receiver._id
    override fun setCopy(receiver: ItemChange, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ItemChange.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object ItemChange_project: SerializableProperty<ItemChange, UUID> {
    override val name: String by lazy { ItemChange.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ItemChange): UUID = receiver.project
    override fun setCopy(receiver: ItemChange, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (ItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ItemChange.serializer().descriptor.getElementAnnotations(1) }
}
object ItemChange_changeRequest: SerializableProperty<ItemChange, UUID> {
    override val name: String by lazy { ItemChange.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ItemChange): UUID = receiver.changeRequest
    override fun setCopy(receiver: ItemChange, value: UUID) = receiver.copy(changeRequest = value)
    override val serializer: KSerializer<UUID> by lazy { (ItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ItemChange.serializer().descriptor.getElementAnnotations(2) }
}
object ItemChange_itemId: SerializableProperty<ItemChange, UUID> {
    override val name: String by lazy { ItemChange.serializer().descriptor.getElementName(3) }
    override fun get(receiver: ItemChange): UUID = receiver.itemId
    override fun setCopy(receiver: ItemChange, value: UUID) = receiver.copy(itemId = value)
    override val serializer: KSerializer<UUID> by lazy { (ItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ItemChange.serializer().descriptor.getElementAnnotations(3) }
}
object ItemChange_linked: SerializableProperty<ItemChange, Boolean> {
    override val name: String by lazy { ItemChange.serializer().descriptor.getElementName(4) }
    override fun get(receiver: ItemChange): Boolean = receiver.linked
    override fun setCopy(receiver: ItemChange, value: Boolean) = receiver.copy(linked = value)
    override val serializer: KSerializer<Boolean> by lazy { (ItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ItemChange.serializer().descriptor.getElementAnnotations(4) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object ItemChange_updatedDescription: SerializableProperty<ItemChange, String?> {
    override val name: String by lazy { ItemChange.serializer().descriptor.getElementName(5) }
    override fun get(receiver: ItemChange): String? = receiver.updatedDescription
    override fun setCopy(receiver: ItemChange, value: String?) = receiver.copy(updatedDescription = value)
    override val serializer: KSerializer<String?> by lazy { (ItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { ItemChange.serializer().descriptor.getElementAnnotations(5) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object ItemChange_priceChange: SerializableProperty<ItemChange, Long?> {
    override val name: String by lazy { ItemChange.serializer().descriptor.getElementName(6) }
    override fun get(receiver: ItemChange): Long? = receiver.priceChange
    override fun setCopy(receiver: ItemChange, value: Long?) = receiver.copy(priceChange = value)
    override val serializer: KSerializer<Long?> by lazy { (ItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<Long?> }
    override val annotations: List<Annotation> by lazy { ItemChange.serializer().descriptor.getElementAnnotations(6) }
    override val default: Long? = null
    override val defaultCode: String? = "null" 
}
object ItemChange_cancelled: SerializableProperty<ItemChange, Boolean> {
    override val name: String by lazy { ItemChange.serializer().descriptor.getElementName(7) }
    override fun get(receiver: ItemChange): Boolean = receiver.cancelled
    override fun setCopy(receiver: ItemChange, value: Boolean) = receiver.copy(cancelled = value)
    override val serializer: KSerializer<Boolean> by lazy { (ItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ItemChange.serializer().descriptor.getElementAnnotations(7) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object ItemChange_created: SerializableProperty<ItemChange, Instant> {
    override val name: String by lazy { ItemChange.serializer().descriptor.getElementName(8) }
    override fun get(receiver: ItemChange): Instant = receiver.created
    override fun setCopy(receiver: ItemChange, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (ItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { ItemChange.serializer().descriptor.getElementAnnotations(8) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object ItemChange_itemNumber: SerializableProperty<ItemChange, String> {
    override val name: String by lazy { ItemChange.serializer().descriptor.getElementName(9) }
    override fun get(receiver: ItemChange): String = receiver.itemNumber
    override fun setCopy(receiver: ItemChange, value: String) = receiver.copy(itemNumber = value)
    override val serializer: KSerializer<String> by lazy { (ItemChange.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ItemChange.serializer().descriptor.getElementAnnotations(9) }
    override val default: String = "1"
    override val defaultCode: String? = "\"1\"" 
}
