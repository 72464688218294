// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareDisputeFields() {
    val props: Array<SerializableProperty<Dispute, *>> = arrayOf(Dispute__id, Dispute_project, Dispute_lineItem, Dispute_incident, Dispute_filed, Dispute_lockProject, Dispute_filedForArbitration, Dispute_resolved, Dispute_state)
    Dispute.serializer().properties { props }
}
val <K> DataClassPath<K, Dispute>._id: DataClassPath<K, UUID> get() = this[Dispute__id]
val <K> DataClassPath<K, Dispute>.project: DataClassPath<K, UUID> get() = this[Dispute_project]
val <K> DataClassPath<K, Dispute>.lineItem: DataClassPath<K, LineItemDispute?> get() = this[Dispute_lineItem]
val <K> DataClassPath<K, Dispute>.incident: DataClassPath<K, UUID?> get() = this[Dispute_incident]
val <K> DataClassPath<K, Dispute>.filed: DataClassPath<K, AccessInfo> get() = this[Dispute_filed]
val <K> DataClassPath<K, Dispute>.lockProject: DataClassPath<K, AccessInfo?> get() = this[Dispute_lockProject]
val <K> DataClassPath<K, Dispute>.filedForArbitration: DataClassPath<K, FiledForArbitration?> get() = this[Dispute_filedForArbitration]
val <K> DataClassPath<K, Dispute>.resolved: DataClassPath<K, AccessInfo?> get() = this[Dispute_resolved]
val <K> DataClassPath<K, Dispute>.state: DataClassPath<K, DisputeState> get() = this[Dispute_state]
inline val Dispute.Companion.path: DataClassPath<Dispute, Dispute> get() = path<Dispute>()


object Dispute__id: SerializableProperty<Dispute, UUID> {
    override val name: String by lazy { Dispute.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Dispute): UUID = receiver._id
    override fun setCopy(receiver: Dispute, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (Dispute.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Dispute.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object Dispute_project: SerializableProperty<Dispute, UUID> {
    override val name: String by lazy { Dispute.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Dispute): UUID = receiver.project
    override fun setCopy(receiver: Dispute, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (Dispute.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Dispute.serializer().descriptor.getElementAnnotations(1) }
}
object Dispute_lineItem: SerializableProperty<Dispute, LineItemDispute?> {
    override val name: String by lazy { Dispute.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Dispute): LineItemDispute? = receiver.lineItem
    override fun setCopy(receiver: Dispute, value: LineItemDispute?) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<LineItemDispute?> by lazy { (Dispute.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<LineItemDispute?> }
    override val annotations: List<Annotation> by lazy { Dispute.serializer().descriptor.getElementAnnotations(2) }
    override val default: LineItemDispute? = null
    override val defaultCode: String? = "null" 
}
object Dispute_incident: SerializableProperty<Dispute, UUID?> {
    override val name: String by lazy { Dispute.serializer().descriptor.getElementName(3) }
    override fun get(receiver: Dispute): UUID? = receiver.incident
    override fun setCopy(receiver: Dispute, value: UUID?) = receiver.copy(incident = value)
    override val serializer: KSerializer<UUID?> by lazy { (Dispute.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { Dispute.serializer().descriptor.getElementAnnotations(3) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object Dispute_filed: SerializableProperty<Dispute, AccessInfo> {
    override val name: String by lazy { Dispute.serializer().descriptor.getElementName(4) }
    override fun get(receiver: Dispute): AccessInfo = receiver.filed
    override fun setCopy(receiver: Dispute, value: AccessInfo) = receiver.copy(filed = value)
    override val serializer: KSerializer<AccessInfo> by lazy { (Dispute.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<AccessInfo> }
    override val annotations: List<Annotation> by lazy { Dispute.serializer().descriptor.getElementAnnotations(4) }
}
object Dispute_lockProject: SerializableProperty<Dispute, AccessInfo?> {
    override val name: String by lazy { Dispute.serializer().descriptor.getElementName(5) }
    override fun get(receiver: Dispute): AccessInfo? = receiver.lockProject
    override fun setCopy(receiver: Dispute, value: AccessInfo?) = receiver.copy(lockProject = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (Dispute.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { Dispute.serializer().descriptor.getElementAnnotations(5) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object Dispute_filedForArbitration: SerializableProperty<Dispute, FiledForArbitration?> {
    override val name: String by lazy { Dispute.serializer().descriptor.getElementName(6) }
    override fun get(receiver: Dispute): FiledForArbitration? = receiver.filedForArbitration
    override fun setCopy(receiver: Dispute, value: FiledForArbitration?) = receiver.copy(filedForArbitration = value)
    override val serializer: KSerializer<FiledForArbitration?> by lazy { (Dispute.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<FiledForArbitration?> }
    override val annotations: List<Annotation> by lazy { Dispute.serializer().descriptor.getElementAnnotations(6) }
    override val default: FiledForArbitration? = null
    override val defaultCode: String? = "null" 
}
object Dispute_resolved: SerializableProperty<Dispute, AccessInfo?> {
    override val name: String by lazy { Dispute.serializer().descriptor.getElementName(7) }
    override fun get(receiver: Dispute): AccessInfo? = receiver.resolved
    override fun setCopy(receiver: Dispute, value: AccessInfo?) = receiver.copy(resolved = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (Dispute.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { Dispute.serializer().descriptor.getElementAnnotations(7) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object Dispute_state: SerializableProperty<Dispute, DisputeState> {
    override val name: String by lazy { Dispute.serializer().descriptor.getElementName(8) }
    override fun get(receiver: Dispute): DisputeState = receiver.state
    override fun setCopy(receiver: Dispute, value: DisputeState) = receiver.copy(state = value)
    override val serializer: KSerializer<DisputeState> by lazy { (Dispute.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<DisputeState> }
    override val annotations: List<Annotation> by lazy { Dispute.serializer().descriptor.getElementAnnotations(8) }
    override val default: DisputeState = DisputeState.Ongoing
    override val defaultCode: String? = "DisputeState.Ongoing" 
}
