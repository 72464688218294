package com.crowpay.views.components.files

import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.SignalingList
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.utils.flatLens
import com.crowpay.utils.resize
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.RecyclerViewPlacerHorizontalGrid
import com.lightningkite.kiteui.views.l2.Recycler2
import com.lightningkite.kiteui.views.l2.children
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningserver.files.UploadInformation
import kotlinx.coroutines.launch

data object FileManagerSemantic : Semantic {
    override val key: String = "flmgr"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        outlineWidth = 0.dp,
        spacing = 0.dp,
        background = AppColors.grey.light2.applyAlpha(0f),
        outline = Color.transparent,
        cornerRadii = CornerRadii.Constant(0.px),
        derivations = mapOf(
            SelectedSemantic to { it.withoutBack }
        )
    ).withBack
}

fun ViewWriter.fileManager(
    files: Writable<List<FileData>>,
    emptyText: String,
    mimeTypes: List<String> = listOf("*/*"),
    uploader: suspend () -> (suspend () -> UploadInformation),
) {
    row {
        spacing = AppDimensions.buttonRowSpacing
        var recycler: RecyclerView?

        reactiveSuspending {
            val l = files()
                .filter { it.preview is FilePreview.LocalFile && it.preview.uploadStarted == null }

            l.forEach { item: FileData ->
                val preview = item.preview as? FilePreview.LocalFile ?: return@forEach
                preview.uploadAttachment(uploader())
            }
        }

        expanding - FileManagerSemantic.onNext - stack {

            horizontalRecyclerView {
                spacing = 8.dp
                new.placer = RecyclerViewPlacerHorizontalGrid(1)
                recycler = this
                children(
                    files,
                    id = { it.id }
                ) { file: Readable<FileData> ->
                    sizeConstraints(
                        width = AppDimensions.largeImagePreview,
                        height = AppDimensions.largeImagePreview
                    ) - stack {
                        stack {
                            val split = split()
                            reactiveScope {
                                this@stack.clearChildren()
                                val filePreview = file().preview
                                with(split) {
                                    renderFilePreview(filePreview, AppDimensions.largeImagePreview)
                                }
                            }
                        }

                        atTopEnd - alphaTheme - button {
                            spacing = 0.25.rem
                            icon(Icon.close.resize(2.rem), "Remove File")
                            onClick {
                                val removed = file.await()
                                val f = files.await()
                                val new = f.minus(removed)
                                files.set(new)
                            }
                        }

                        atBottom - alphaTheme - textInput {
                            this.spacing = appTheme.spacing
                            hint = "File Name"
                            content bind file.flatLens { it.name }
                        }

                    }
                }
            }

            centered - bold - subTitle {
                exists = false
                ::exists{ files().isEmpty() }
                content = emptyText
            }
        }

        tertiaryButton - button {
            centered - icon(Icon.add, "Add File")
            onClick {
                ExternalServices.requestFiles(mimeTypes) { results: List<FileReference> ->
                    if (results.isNotEmpty()) {
                        launch {
                            val f = files.await()
                            val currentSize = f.size
                            files.set(
                                f + results.map {
                                    FileData(
                                        name = Property(""),
                                        preview = FilePreview.LocalFile(it),
                                        original = FilePreview.LocalFile(it),
                                    )
                                }
                            )
                            launch {
                                recycler?.scrollToIndex(currentSize, Align.Start)
                            }
                        }
                    }
                }
            }
        } in sizeConstraints(width = AppDimensions.largeImagePreview / 3, height = AppDimensions.largeImagePreview)
    }
}

fun ViewWriter.fileCarousel(
    files: SignalingList<FileData>,
    emptyText: String = "No Files",
    size: Dimension = AppDimensions.largeImagePreview,
    setup: Stack.()->Unit = {}
): Recycler2? {
    var recycler: Recycler2? = null
    sizeConstraints(minHeight = size) - FileManagerSemantic.onNext - stack {
        setup()
        expanding - horizontalRecyclerView {
            ::exists { files().isNotEmpty() }
            recycler = this
            spacing = 8.dp
            children(files, { it.id }) { file: Readable<FileData> ->
                sizeConstraints(width = size, height = size) - stack {
                    stack image@{
                        reactive {
                            this@image.clearChildren()
                            renderFilePreview(file().preview, size)
                        }
                    }
                    atTopEnd - alphaTheme - button {
                        spacing = 0.25.rem
                        icon(Icon.close.resize(2.rem), "Remove File")
                        onClick("Remove File") {
                            files.remove(file())
                        }
                    }
                    atBottom - alphaTheme - textInput {
                        spacing = appTheme.spacing
                        hint = "File Name"
                        content bind file.flatLens { it.name }
                    }
                }
            }
        }
        centered - bold - subTitle {
            existsDefaultFalse { files().isEmpty() }
            content = emptyText
        }
    }
    return recycler
}