package com.crowpay.views.dialogs

import com.crowpay.actuals.AppDimensions
import com.crowpay.views.theming.body
import com.crowpay.views.theming.primaryButton
import com.crowpay.views.theming.specCenteredText
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.dialog
import com.lightningkite.kiteui.views.direct.*

fun ViewWriter.dialogView(content: ViewWriter.() -> Unit) {
    dismissBackground {
        dialog - centered - stack {
            spacing = AppDimensions.fullIndent
            this@stack.content()
        }
    }
}

class GenericDialog(val message: String, val dismiss: suspend ViewWriter.() -> Unit = { dialogScreenNavigator.dismiss() }) :
    Screen {
    override fun ViewWriter.render() {
        dismissBackground {
            dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                col {
                    body {
                        content = message
                    } in
                            sizeConstraints(maxWidth = 40.rem, minWidth = 10.rem)

                    primaryButton - button {
                        specCenteredText("Close")
                        onClick("Dismiss") {
                            dismiss()
                        }
                    } in gravity(Align.Start, Align.Start)
                }
            }
        }
    }
}