// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareClientTermsAndConditionsAgreementFields() {
    val props: Array<SerializableProperty<ClientTermsAndConditionsAgreement, *>> = arrayOf(ClientTermsAndConditionsAgreement__id, ClientTermsAndConditionsAgreement_accessInfo, ClientTermsAndConditionsAgreement_version)
    ClientTermsAndConditionsAgreement.serializer().properties { props }
}
val <K> DataClassPath<K, ClientTermsAndConditionsAgreement>._id: DataClassPath<K, UUID> get() = this[ClientTermsAndConditionsAgreement__id]
val <K> DataClassPath<K, ClientTermsAndConditionsAgreement>.accessInfo: DataClassPath<K, AccessInfo> get() = this[ClientTermsAndConditionsAgreement_accessInfo]
val <K> DataClassPath<K, ClientTermsAndConditionsAgreement>.version: DataClassPath<K, Instant> get() = this[ClientTermsAndConditionsAgreement_version]
inline val ClientTermsAndConditionsAgreement.Companion.path: DataClassPath<ClientTermsAndConditionsAgreement, ClientTermsAndConditionsAgreement> get() = path<ClientTermsAndConditionsAgreement>()


object ClientTermsAndConditionsAgreement__id: SerializableProperty<ClientTermsAndConditionsAgreement, UUID> {
    override val name: String by lazy { ClientTermsAndConditionsAgreement.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ClientTermsAndConditionsAgreement): UUID = receiver._id
    override fun setCopy(receiver: ClientTermsAndConditionsAgreement, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ClientTermsAndConditionsAgreement.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ClientTermsAndConditionsAgreement.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object ClientTermsAndConditionsAgreement_accessInfo: SerializableProperty<ClientTermsAndConditionsAgreement, AccessInfo> {
    override val name: String by lazy { ClientTermsAndConditionsAgreement.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ClientTermsAndConditionsAgreement): AccessInfo = receiver.accessInfo
    override fun setCopy(receiver: ClientTermsAndConditionsAgreement, value: AccessInfo) = receiver.copy(accessInfo = value)
    override val serializer: KSerializer<AccessInfo> by lazy { (ClientTermsAndConditionsAgreement.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<AccessInfo> }
    override val annotations: List<Annotation> by lazy { ClientTermsAndConditionsAgreement.serializer().descriptor.getElementAnnotations(1) }
}
object ClientTermsAndConditionsAgreement_version: SerializableProperty<ClientTermsAndConditionsAgreement, Instant> {
    override val name: String by lazy { ClientTermsAndConditionsAgreement.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ClientTermsAndConditionsAgreement): Instant = receiver.version
    override fun setCopy(receiver: ClientTermsAndConditionsAgreement, value: Instant) = receiver.copy(version = value)
    override val serializer: KSerializer<Instant> by lazy { (ClientTermsAndConditionsAgreement.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { ClientTermsAndConditionsAgreement.serializer().descriptor.getElementAnnotations(2) }
}
