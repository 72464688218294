package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.BulletPoint
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.utils.renderDollars
import com.crowpay.views.components.inlineKeyValue
import com.crowpay.views.components.project.activity.concerns.IncidentReportForm
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon

fun ProjectView.projectLevelMessages(writer: ViewWriter) = with(writer) {
    col {
        existsDefaultFalse { anonymous() }
        spacing = AppDimensions.cornerRadii
        body("Welcome to CrowPay, your escrow solution!")
        space()
        body {
            inlineKeyValue(
                "New Users",
                "Register to create an account. Once registered, log in to review and accept your project."
            )
        }
        body {
            inlineKeyValue("Returning Users", "Log in to securely access and accept your project.")
        }
        space()
        body("After logging in, you’ll be able to view project details and proceed with the next steps.")
    }

    if (lens == ProjectLens.Contractor) notificationBar(AppColors.blue) - body {
        existsDefaultFalse { project().state in setOf(ProjectState.Invited, ProjectState.WaitingApproval) }
        content = """
                This project is locked while waiting for the homeowner to accept. No edits to work items, creating tasks, or other project details can be altered at this time.

                If the homeowner requests changes to accept the project, unpublish the current invite link, make the necessary edits, and send a new invitation.
            """.trimIndent()
    }

    notificationBar(AppColors.blue) - body {
        exists = false
        ::exists { canAcceptProject() }
        ::content {
            val title = contractor().preferredTitle.name.lowercase()
            "Accepting a project awards the project to the $title and allows your $title to send you a payment request."
        }
    }

    notificationBar(AppColors.blue) - body {
        exists = false
        ::exists { needsFundingRequested() }

        ::content{
            if (lens == ProjectLens.Customer) {
                val c = contractor()
                "Congratulations! You've awarded the job to ${c.name}. They have been notified of your acceptance and will request project funding within 10 business days before anticipated project start. You are now officially on your ${c.preferredTitle}'s schedule."
            } else {
                "Congratulations - you've got work! ${client().name} has awarded you \"${project().name}\". Don't forget to request funds before starting."
            }
        }
    }

    notificationBar(AppColors.yellow) - body {
        exists = false
        ::exists { lens == ProjectLens.Contractor && needsFundingRequested() }

        ::content{
            "We've notified your client that funds should not be requested more than 10 days before site arrival or material purchases. Funds take 2-3 days to clear. Plan accordingly."
        }
    }

    notificationBar(AppColors.blue) - row {
        exists = false
        ::exists{ project().state == ProjectState.TransferringFunds }
        sizeConstraints(height = 1.rem, width = 1.rem) - centered - icon(
            Icon.notify,
            "Transferring Funds Info"
        )
        expanding - body {
            wraps = true
            content = "Funds are being transferred securely. During the transfer process, your project status will be locked to ensure security. Once funds settle the project status will update to Pending Start and will be able to be started by the contractor. Allow 2-3 days for funds to settle."
        }
    }

    if (lens == ProjectLens.Contractor) notificationBar(AppColors.blue) - col {
        exists = false
        ::exists { fundingNeeded() }
        bold - body {
            ::content{ "Funding Requested: ${project().fundingNeeded.renderDollars()}" }
        }
        body("Funds have been requested and have not begun transferring. If necessary you can still cancel the project or rescind the funds request to remain in the awarded status. Allow 2-3 days for funds to settle.")
    }

    if (lens == ProjectLens.Customer) notificationBar(AppColors.blue) - col {
        existsDefaultFalse { fundingNeeded() }
        body("It's time to fund your project. Deposit escrow funds to make sure your project is not delayed or canceled. The entire amount of the project must be deposited for the CrowPay system to update project status to Pending Start. This enables your contractor to tell CrowPay when they begin work.")
    }

    if (lens == ProjectLens.Customer) notificationBar(AppColors.blue) - body {
        existsDefaultFalse { project().state == ProjectState.PendingStart }
        ::content {
            "${project().clientDeposits.renderDollars()} has been safely deposited to CrowPay for escrow protection. Your contractor can now begin mobilization plans and other preparations required to start your project."
        }
    }

    if (lens == ProjectLens.Contractor) notificationBar(AppColors.blue) - body {
        existsDefaultFalse { project().state == ProjectState.PendingStart }
        ::content {
            """
                ${project().clientDeposits.renderDollars()} has been deposited into CrowPay. You've got the green light to get started.
                
                Now is the time to begin planning for mobilization, materials and crews. Don't forget to communicate even better going forward than you did to be awarded the job.

                You must start a work item for the project status to update to In Progress.
            """.trimIndent()
        }
    }

    if (lens == ProjectLens.Customer) notificationBar(AppColors.yellow) - body {
        existsDefaultFalse { project().state == ProjectState.PendingStart }
        content = "Once the contractor starts items in CrowPay the project status updates to In Progress. Just because your contractor is not physically on site does not mean they have not started. Ordering materials or mobilizing crews would be considered as started."
    }

    if (lens == ProjectLens.Customer) col {
        spacing = AppDimensions.majorColumnSpacing
        existsDefaultFalse { project().state == ProjectState.InProgress }
        notificationBar(AppColors.blue) - stack {
            col {
                spacing = AppDimensions.cornerRadii
                body("The contractor has started items, and the project is now in progress.")
                space()
                body { inlineKeyValue("Work Items", "Items move to 'Working' status once started.") }
                body { inlineKeyValue("Next Steps", "After work is completed, the contractor will request your sign-off for approval.") }
            }
        }
        notificationBar(AppColors.yellow) - stack {
            col {
                spacing = AppDimensions.cornerRadii
                body("Stay on top of your notifications and emails to follow work items as they progress.")
                space()
                body { inlineKeyValue("Approval Process", "Review each work item promptly and approve or send it back as 'Not Ready' if changes are needed.") }
                body { inlineKeyValue("Auto-Approval", "If no action is taken within 14 days, the item will auto-approve, and funds will be released to the contractor.") }
            }
        }
    }

    if (lens == ProjectLens.Contractor) notificationBar(AppColors.red) - col {
        existsDefaultFalse { ongoingIncidents().isNotEmpty() }
        body("There is an ongoing incident report for this project, resolve your client's concerns below.")
        atBottomEnd - secondaryButton - button {
            specCenteredText("View Report")
            onClick("View Report") {
                dialogScreenNavigator.navigate(
                    IncidentReportForm.createDialog(project(), ongoingIncidents().last(), incidentMessages(), lens)
                )
            }
        }
    }

    if (lens == ProjectLens.Customer)
        notificationBar(AppColors.blue) - body {
            existsDefaultFalse { inSubstantialCompletion() }
            content = """
                All work items have been completed, and your project is now in the Substantial Completion phase.

                During this time:

                $BulletPoint The contractor may conduct final checks, address punch list items, confirm warranty details, and handle post-completion establishment tasks like material cleanup or adjustments.
                $BulletPoint You can review the project carefully to ensure everything meets your expectations.
                $BulletPoint A request for final completion will be sent soon for your approval.

                If minor issues arise that don’t impact the project’s functionality, they may be best handled as part of a warranty phase after completion. Use CrowPay’s messaging to work with your contractor and ensure all concerns are documented.
            """.trimIndent()
        }

    if (lens == ProjectLens.Contractor) {
        notificationBar(AppColors.blue) - body {
            existsDefaultFalse { inSubstantialCompletion() }
            content = """
                Your project is now in the Substantial Completion phase.

                Focus on resolving all punch list items, leaving the workspace clean, and ensuring everything meets the homeowner’s expectations. Use this time to finalize any details and set the stage for a smooth approval process.

                All punch list items marked as 'Required' must be marked as complete to request Final Completion. Retention funds, if any, will be released upon the homeowner’s acceptance of Final Completion.
            """.trimIndent()
        }
        notificationBar(AppColors.yellow) - body {
            existsDefaultFalse { inSubstantialCompletion() }
            content = """
                Before requesting final completion:

                $BulletPoint Ensure all work is finished, and any remaining tasks, including 'required' punch list items, are documented or addressed.
                $BulletPoint Fix property damage, if applicable, and leave the workspace clean and organized.
                $BulletPoint Communicate warranty details and any remaining retention items clearly with the homeowner.
                $BulletPoint Consider a final walk-through to resolve any outstanding concerns and build trust. 

                Reminder: The homeowner can still file a dispute at this stage if concerns aren’t resolved, so be thorough and professional in your approach.
            """.trimIndent()
        }
    }

    if (lens == ProjectLens.Customer) col {
        spacing = AppDimensions.majorColumnSpacing
        existsDefaultFalse { requestedComplete() }
        notificationBar(AppColors.blue) - body(
            """
                Your contractor has requested Final Completion of the project.

                Review the completed work, including any punch list items, and prepare to approve. Retention funds (if applicable) will be released upon your approval, and the project will be marked as complete.

                If minor issues arise that don’t affect the project’s functionality, consider addressing them as part of the warranty phase rather than delaying completion.
            """.trimIndent()
        )
        notificationBar(AppColors.yellow) - body(
            """
                By clicking 'Accept & Pay Retention,' you confirm that all work has been completed to your satisfaction.

                This action will release retention funds (if applicable) and mark the project as complete. For minor issues or adjustments, use the warranty phase to address them without delaying completion. Ensure all major concerns are resolved before proceeding.
            """.trimIndent()
        )
    }

    if (lens == ProjectLens.Contractor) col {
        spacing = AppDimensions.majorColumnSpacing
        existsDefaultFalse { project().state == ProjectState.RequestingComplete }
        notificationBar(AppColors.blue) - body(
            """
                Your project is now in the Pending Complete phase.

                The homeowner is reviewing the work for final acceptance. Be prepared to address any concerns promptly if the project is placed on hold.

                Clear and proactive communication can help resolve questions or issues quickly, ensuring a smooth completion and the release of retention funds. For minor concerns or non-critical adjustments, be ready to outline how they will be addressed during the warranty phase.
            """.trimIndent()
        )
        notificationBar(AppColors.yellow) - body(
            """
                Follow up with the homeowner to ensure any outstanding concerns are resolved. Retention funds will be released once the homeowner approves final completion. For minor issues, consider offering resolution during the warranty phase to avoid delays.
            """.trimIndent()
        )
    }

    if (lens == ProjectLens.Customer)
        notificationBar(AppColors.blue) - body {
            existsDefaultFalse { project().state == ProjectState.Complete }
            content = """
                Congratulations! Your project is officially complete, and all funds, including retention, will be disbursed to your contractor.

                Thank you for using CrowPay to protect your project and ensure a smooth experience. For any warranty concerns or future projects, we’re here to help.
            """.trimIndent()
        }

    if (lens == ProjectLens.Contractor)
        notificationBar(AppColors.blue) - body {
            existsDefaultFalse { project().state == ProjectState.Complete }
            content = """
                Congratulations! The project is complete, and the homeowner has approved all work.

                Thank you for using CrowPay to manage and protect your project.
            """.trimIndent()
        }
}