// Automatically generated based off serialization.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay.completionResponse

import com.crowpay.AccessInfo
import com.lightningkite.lightningdb.GenerateDataClassPaths
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: 
fun prepareCompletionResponseSerializableFields() {
    val props: Array<SerializableProperty<CompletionResponseSerializable, *>> = arrayOf(CompletionResponseSerializable_type, CompletionResponseSerializable_accessInfo, CompletionResponseSerializable_reasons, CompletionResponseSerializable_raiseIssue, CompletionResponseSerializable_fileDispute, CompletionResponseSerializable_displayName, CompletionResponseSerializable_credit, CompletionResponseSerializable_remedyComplete, CompletionResponseSerializable_proposal)
    CompletionResponseSerializable.serializer().properties { props }
}
val <K> DataClassPath<K, CompletionResponseSerializable>.type: DataClassPath<K, CompletionResponseType> get() = this[CompletionResponseSerializable_type]
val <K> DataClassPath<K, CompletionResponseSerializable>.accessInfo: DataClassPath<K, AccessInfo?> get() = this[CompletionResponseSerializable_accessInfo]
val <K> DataClassPath<K, CompletionResponseSerializable>.reasons: DataClassPath<K, Set<LineItemConcern>> get() = this[CompletionResponseSerializable_reasons]
val <K> DataClassPath<K, CompletionResponseSerializable>.raiseIssue: DataClassPath<K, Boolean> get() = this[CompletionResponseSerializable_raiseIssue]
val <K> DataClassPath<K, CompletionResponseSerializable>.fileDispute: DataClassPath<K, DisputeType?> get() = this[CompletionResponseSerializable_fileDispute]
val <K> DataClassPath<K, CompletionResponseSerializable>.displayName: DataClassPath<K, String?> get() = this[CompletionResponseSerializable_displayName]
val <K> DataClassPath<K, CompletionResponseSerializable>.credit: DataClassPath<K, Long?> get() = this[CompletionResponseSerializable_credit]
val <K> DataClassPath<K, CompletionResponseSerializable>.remedyComplete: DataClassPath<K, CompletionResponseSerializable?> get() = this[CompletionResponseSerializable_remedyComplete]
val <K> DataClassPath<K, CompletionResponseSerializable>.proposal: DataClassPath<K, CompletionResponseSerializable?> get() = this[CompletionResponseSerializable_proposal]
inline val CompletionResponseSerializable.Companion.path: DataClassPath<CompletionResponseSerializable, CompletionResponseSerializable> get() = path<CompletionResponseSerializable>()


object CompletionResponseSerializable_type: SerializableProperty<CompletionResponseSerializable, CompletionResponseType> {
    override val name: String by lazy { CompletionResponseSerializable.serializer().descriptor.getElementName(0) }
    override fun get(receiver: CompletionResponseSerializable): CompletionResponseType = receiver.type
    override fun setCopy(receiver: CompletionResponseSerializable, value: CompletionResponseType) = receiver.copy(type = value)
    override val serializer: KSerializer<CompletionResponseType> by lazy { (CompletionResponseSerializable.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<CompletionResponseType> }
    override val annotations: List<Annotation> by lazy { CompletionResponseSerializable.serializer().descriptor.getElementAnnotations(0) }
}
object CompletionResponseSerializable_accessInfo: SerializableProperty<CompletionResponseSerializable, AccessInfo?> {
    override val name: String by lazy { CompletionResponseSerializable.serializer().descriptor.getElementName(1) }
    override fun get(receiver: CompletionResponseSerializable): AccessInfo? = receiver.accessInfo
    override fun setCopy(receiver: CompletionResponseSerializable, value: AccessInfo?) = receiver.copy(accessInfo = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (CompletionResponseSerializable.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { CompletionResponseSerializable.serializer().descriptor.getElementAnnotations(1) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object CompletionResponseSerializable_reasons: SerializableProperty<CompletionResponseSerializable, Set<LineItemConcern>> {
    override val name: String by lazy { CompletionResponseSerializable.serializer().descriptor.getElementName(2) }
    override fun get(receiver: CompletionResponseSerializable): Set<LineItemConcern> = receiver.reasons
    override fun setCopy(receiver: CompletionResponseSerializable, value: Set<LineItemConcern>) = receiver.copy(reasons = value)
    override val serializer: KSerializer<Set<LineItemConcern>> by lazy { (CompletionResponseSerializable.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<Set<LineItemConcern>> }
    override val annotations: List<Annotation> by lazy { CompletionResponseSerializable.serializer().descriptor.getElementAnnotations(2) }
    override val default: Set<LineItemConcern> = emptySet()
    override val defaultCode: String? = "emptySet()" 
}
object CompletionResponseSerializable_raiseIssue: SerializableProperty<CompletionResponseSerializable, Boolean> {
    override val name: String by lazy { CompletionResponseSerializable.serializer().descriptor.getElementName(3) }
    override fun get(receiver: CompletionResponseSerializable): Boolean = receiver.raiseIssue
    override fun setCopy(receiver: CompletionResponseSerializable, value: Boolean) = receiver.copy(raiseIssue = value)
    override val serializer: KSerializer<Boolean> by lazy { (CompletionResponseSerializable.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { CompletionResponseSerializable.serializer().descriptor.getElementAnnotations(3) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object CompletionResponseSerializable_fileDispute: SerializableProperty<CompletionResponseSerializable, DisputeType?> {
    override val name: String by lazy { CompletionResponseSerializable.serializer().descriptor.getElementName(4) }
    override fun get(receiver: CompletionResponseSerializable): DisputeType? = receiver.fileDispute
    override fun setCopy(receiver: CompletionResponseSerializable, value: DisputeType?) = receiver.copy(fileDispute = value)
    override val serializer: KSerializer<DisputeType?> by lazy { (CompletionResponseSerializable.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<DisputeType?> }
    override val annotations: List<Annotation> by lazy { CompletionResponseSerializable.serializer().descriptor.getElementAnnotations(4) }
    override val default: DisputeType? = null
    override val defaultCode: String? = "null" 
}
object CompletionResponseSerializable_displayName: SerializableProperty<CompletionResponseSerializable, String?> {
    override val name: String by lazy { CompletionResponseSerializable.serializer().descriptor.getElementName(5) }
    override fun get(receiver: CompletionResponseSerializable): String? = receiver.displayName
    override fun setCopy(receiver: CompletionResponseSerializable, value: String?) = receiver.copy(displayName = value)
    override val serializer: KSerializer<String?> by lazy { (CompletionResponseSerializable.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { CompletionResponseSerializable.serializer().descriptor.getElementAnnotations(5) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object CompletionResponseSerializable_credit: SerializableProperty<CompletionResponseSerializable, Long?> {
    override val name: String by lazy { CompletionResponseSerializable.serializer().descriptor.getElementName(6) }
    override fun get(receiver: CompletionResponseSerializable): Long? = receiver.credit
    override fun setCopy(receiver: CompletionResponseSerializable, value: Long?) = receiver.copy(credit = value)
    override val serializer: KSerializer<Long?> by lazy { (CompletionResponseSerializable.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<Long?> }
    override val annotations: List<Annotation> by lazy { CompletionResponseSerializable.serializer().descriptor.getElementAnnotations(6) }
    override val default: Long? = null
    override val defaultCode: String? = "null" 
}
object CompletionResponseSerializable_remedyComplete: SerializableProperty<CompletionResponseSerializable, CompletionResponseSerializable?> {
    override val name: String by lazy { CompletionResponseSerializable.serializer().descriptor.getElementName(7) }
    override fun get(receiver: CompletionResponseSerializable): CompletionResponseSerializable? = receiver.remedyComplete
    override fun setCopy(receiver: CompletionResponseSerializable, value: CompletionResponseSerializable?) = receiver.copy(remedyComplete = value)
    override val serializer: KSerializer<CompletionResponseSerializable?> by lazy { (CompletionResponseSerializable.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<CompletionResponseSerializable?> }
    override val annotations: List<Annotation> by lazy { CompletionResponseSerializable.serializer().descriptor.getElementAnnotations(7) }
    override val default: CompletionResponseSerializable? = null
    override val defaultCode: String? = "null" 
}
object CompletionResponseSerializable_proposal: SerializableProperty<CompletionResponseSerializable, CompletionResponseSerializable?> {
    override val name: String by lazy { CompletionResponseSerializable.serializer().descriptor.getElementName(8) }
    override fun get(receiver: CompletionResponseSerializable): CompletionResponseSerializable? = receiver.proposal
    override fun setCopy(receiver: CompletionResponseSerializable, value: CompletionResponseSerializable?) = receiver.copy(proposal = value)
    override val serializer: KSerializer<CompletionResponseSerializable?> by lazy { (CompletionResponseSerializable.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<CompletionResponseSerializable?> }
    override val annotations: List<Annotation> by lazy { CompletionResponseSerializable.serializer().descriptor.getElementAnnotations(8) }
    override val default: CompletionResponseSerializable? = null
    override val defaultCode: String? = "null" 
}
