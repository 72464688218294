// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareProjectMessageFields() {
    val props: Array<SerializableProperty<ProjectMessage, *>> = arrayOf(ProjectMessage__id, ProjectMessage_project, ProjectMessage_sender, ProjectMessage_clientMessage, ProjectMessage_sent, ProjectMessage_message, ProjectMessage_replyingToMessage, ProjectMessage_referencesChangeRequest, ProjectMessage_referencesPayApp)
    ProjectMessage.serializer().properties { props }
}
val <K> DataClassPath<K, ProjectMessage>._id: DataClassPath<K, UUID> get() = this[ProjectMessage__id]
val <K> DataClassPath<K, ProjectMessage>.project: DataClassPath<K, UUID> get() = this[ProjectMessage_project]
val <K> DataClassPath<K, ProjectMessage>.sender: DataClassPath<K, UUID> get() = this[ProjectMessage_sender]
val <K> DataClassPath<K, ProjectMessage>.clientMessage: DataClassPath<K, Boolean> get() = this[ProjectMessage_clientMessage]
val <K> DataClassPath<K, ProjectMessage>.sent: DataClassPath<K, Instant> get() = this[ProjectMessage_sent]
val <K> DataClassPath<K, ProjectMessage>.message: DataClassPath<K, String> get() = this[ProjectMessage_message]
val <K> DataClassPath<K, ProjectMessage>.replyingToMessage: DataClassPath<K, MessageReply?> get() = this[ProjectMessage_replyingToMessage]
val <K> DataClassPath<K, ProjectMessage>.referencesChangeRequest: DataClassPath<K, ChangeRequestMessageReference?> get() = this[ProjectMessage_referencesChangeRequest]
val <K> DataClassPath<K, ProjectMessage>.referencesPayApp: DataClassPath<K, PayAppMessageReference?> get() = this[ProjectMessage_referencesPayApp]
inline val ProjectMessage.Companion.path: DataClassPath<ProjectMessage, ProjectMessage> get() = path<ProjectMessage>()


object ProjectMessage__id: SerializableProperty<ProjectMessage, UUID> {
    override val name: String by lazy { ProjectMessage.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ProjectMessage): UUID = receiver._id
    override fun setCopy(receiver: ProjectMessage, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ProjectMessage.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ProjectMessage.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object ProjectMessage_project: SerializableProperty<ProjectMessage, UUID> {
    override val name: String by lazy { ProjectMessage.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ProjectMessage): UUID = receiver.project
    override fun setCopy(receiver: ProjectMessage, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (ProjectMessage.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ProjectMessage.serializer().descriptor.getElementAnnotations(1) }
}
object ProjectMessage_sender: SerializableProperty<ProjectMessage, UUID> {
    override val name: String by lazy { ProjectMessage.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ProjectMessage): UUID = receiver.sender
    override fun setCopy(receiver: ProjectMessage, value: UUID) = receiver.copy(sender = value)
    override val serializer: KSerializer<UUID> by lazy { (ProjectMessage.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ProjectMessage.serializer().descriptor.getElementAnnotations(2) }
}
object ProjectMessage_clientMessage: SerializableProperty<ProjectMessage, Boolean> {
    override val name: String by lazy { ProjectMessage.serializer().descriptor.getElementName(3) }
    override fun get(receiver: ProjectMessage): Boolean = receiver.clientMessage
    override fun setCopy(receiver: ProjectMessage, value: Boolean) = receiver.copy(clientMessage = value)
    override val serializer: KSerializer<Boolean> by lazy { (ProjectMessage.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ProjectMessage.serializer().descriptor.getElementAnnotations(3) }
}
object ProjectMessage_sent: SerializableProperty<ProjectMessage, Instant> {
    override val name: String by lazy { ProjectMessage.serializer().descriptor.getElementName(4) }
    override fun get(receiver: ProjectMessage): Instant = receiver.sent
    override fun setCopy(receiver: ProjectMessage, value: Instant) = receiver.copy(sent = value)
    override val serializer: KSerializer<Instant> by lazy { (ProjectMessage.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { ProjectMessage.serializer().descriptor.getElementAnnotations(4) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object ProjectMessage_message: SerializableProperty<ProjectMessage, String> {
    override val name: String by lazy { ProjectMessage.serializer().descriptor.getElementName(5) }
    override fun get(receiver: ProjectMessage): String = receiver.message
    override fun setCopy(receiver: ProjectMessage, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> by lazy { (ProjectMessage.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ProjectMessage.serializer().descriptor.getElementAnnotations(5) }
}
object ProjectMessage_replyingToMessage: SerializableProperty<ProjectMessage, MessageReply?> {
    override val name: String by lazy { ProjectMessage.serializer().descriptor.getElementName(6) }
    override fun get(receiver: ProjectMessage): MessageReply? = receiver.replyingToMessage
    override fun setCopy(receiver: ProjectMessage, value: MessageReply?) = receiver.copy(replyingToMessage = value)
    override val serializer: KSerializer<MessageReply?> by lazy { (ProjectMessage.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<MessageReply?> }
    override val annotations: List<Annotation> by lazy { ProjectMessage.serializer().descriptor.getElementAnnotations(6) }
    override val default: MessageReply? = null
    override val defaultCode: String? = "null" 
}
object ProjectMessage_referencesChangeRequest: SerializableProperty<ProjectMessage, ChangeRequestMessageReference?> {
    override val name: String by lazy { ProjectMessage.serializer().descriptor.getElementName(7) }
    override fun get(receiver: ProjectMessage): ChangeRequestMessageReference? = receiver.referencesChangeRequest
    override fun setCopy(receiver: ProjectMessage, value: ChangeRequestMessageReference?) = receiver.copy(referencesChangeRequest = value)
    override val serializer: KSerializer<ChangeRequestMessageReference?> by lazy { (ProjectMessage.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<ChangeRequestMessageReference?> }
    override val annotations: List<Annotation> by lazy { ProjectMessage.serializer().descriptor.getElementAnnotations(7) }
    override val default: ChangeRequestMessageReference? = null
    override val defaultCode: String? = "null" 
}
object ProjectMessage_referencesPayApp: SerializableProperty<ProjectMessage, PayAppMessageReference?> {
    override val name: String by lazy { ProjectMessage.serializer().descriptor.getElementName(8) }
    override fun get(receiver: ProjectMessage): PayAppMessageReference? = receiver.referencesPayApp
    override fun setCopy(receiver: ProjectMessage, value: PayAppMessageReference?) = receiver.copy(referencesPayApp = value)
    override val serializer: KSerializer<PayAppMessageReference?> by lazy { (ProjectMessage.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<PayAppMessageReference?> }
    override val annotations: List<Annotation> by lazy { ProjectMessage.serializer().descriptor.getElementAnnotations(8) }
    override val default: PayAppMessageReference? = null
    override val defaultCode: String? = "null" 
}
