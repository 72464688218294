package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.utils.renderDollars
import com.crowpay.views.components.project.work.changeOrders.*
import com.crowpay.views.components.project.work.payApps.LineItemWithPaymentInfo
import com.crowpay.views.components.requiredField
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.div
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

class UserTextInputDialog(
    val header: String,
    val message: String,
    val denialConfirmationText: String,
    val dismissText: String = "Go Back",
    val minChars: Int = 50,
    val denyWithFeedback: suspend (String)->Unit
): Page {
    private val reason = Property("")

    override fun ViewWriter.render2(): ViewModifiable = dismissBackground {
        dialog - centered - stack {
            spacing = AppDimensions.fullIndent
            removeOutline - col {
                title(header)

                body(message)

                col {
                    spacing = theme.spacing/2
                    requiredField("Reason") {
                        sizeConstraints(height = 8.rem) - textArea {
                            if (minChars > 0) {
                                hint = "Minimum $minChars characters"
                            }
                            content bind reason
                        }
                    }
                    atEnd - smallBody {
                        ::content {
                            val remaining = (minChars - reason().length).coerceAtLeast(0)
                            if (remaining > 0) "$remaining remaining"
                            else ""
                        }
                    }
                }

                space(0.5)

                row {
                    primaryButton - button {
                        ::enabled { reason().isNotBlank() }
                        specCenteredText(denialConfirmationText)
                        onClick(denialConfirmationText) {
                            denyWithFeedback(reason.value)
                            dialogScreenNavigator.dismiss()
                        }
                    }
                    secondaryButton - button {
                        specCenteredText(dismissText)
                        onClick(dismissText) {
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}

fun DenyChangeItemsDialog(
    items: List<ChangeRequestItem>,
    project: UUID,
    denial: suspend (List<ChangeRequestItem>)->Unit
): UserTextInputDialog {
    fun ChangeRequestItem.name(quotes: Boolean = false): String? = when (this) {
        is ChangeRequestItemWrapper -> wrapped.name()
        is PendingLineItem -> if (quotes) "\"$name\"" else name
        else -> itemNumber
    }

    if (items.isEmpty()) throw IllegalArgumentException("list cannot be empty")

    return UserTextInputDialog(
        header =
            if (items.size == 1) "Deny ${items.first().name()}?"
            else "Deny ${items.size} Change Items?",
        message = "Are you sure you want to deny? If you're sure, let the contractor know why below.",
        denialConfirmationText =
            if (items.size == 1) "Deny ${items.first().name()}"
            else "Deny Items"
    ) { feedback ->
        denial(items)

        val session = notNullSession()
        session.projectMessages.insert(
            ProjectMessage(
                project = project,
                sender = session.userId,
                clientMessage = true,
                message = feedback,
                referencesChangeRequest = ChangeRequestMessageReference(
                    id = items.first().changeRequest,
                    reason = buildString {
                        if (items.size == 1) {
                            append("Denied change item ")
                            fun ChangeRequestItem.title(): String = when (this) {
                                is ChangeRequestItemWrapper -> wrapped.title()
                                is PendingLineItem -> "\"$name\" ($itemNumber)"
                                else -> itemNumber
                            }
                            append(items.first().title())
                        }
                        else {
                            append("Denied change items ")
                            append(items.joinToString { it.itemNumber })
                        }
                    }
                )
            )
        )
    }
}

fun WaitDrawsDialog(
    draws: List<LineItemWithPaymentInfo>,
): UserTextInputDialog {
    val items = draws.filter { it.payApp != null }
    if (items.isEmpty()) throw IllegalArgumentException("Cannot have wait an empty list of pay app items")

    return UserTextInputDialog(
        header = "Wait Draw?",
        message = "Are you sure you want to wait on paying this draw? If you're sure, let the contractor know why below.",
        denialConfirmationText = "Wait"
    ) { feedback ->
        val session = notNullSession()

        session.nonCached.payAppItem.denyPayApps(items.mapNotNull { it.payApp?._id })

        coroutineScope {
            launch {
                delay(500)
                session.payAppItems.totallyInvalidate()
            }

            session.projectMessages.insert(
                ProjectMessage(
                    project = items.first().lineItem.project,
                    sender = session.userId,
                    clientMessage = true,
                    message = feedback,
                    referencesPayApp = PayAppMessageReference(
                        id = items.first().lineItem.project,
                        reason = buildString {
                            if (items.size == 1) {
                                val item = items.first()
                                append("Waited Draw on ${item.lineItem.name} (${item.payApp?.amount.renderDollars()})")
                            }
                            else {
                                append("Waited Draws: ")
                                append(items.joinToString { it.lineItem.name })
                                append(" (${items.sumOf { it.payApp?.amount ?: 0 }.renderDollars()} total)")
                            }
                        }
                    )
                )
            )
        }
    }
}