package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.views.theming.body
import com.crowpay.views.theming.redText
import com.crowpay.views.theming.textButton
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.sort

@Routable("/contractor/project/create")
class CreateProject() : ContractorScreen {
    override fun ViewWriter.renderMainContent() {
        val terms = shared {
            notNullSession().contractorTermsAndConditionsVersions.query(
                Query(
                    condition = condition(true),
                    orderBy = sort { it._id.descending() },
                    limit = 1,
                )
            )()
                .firstOrNull()
        }
        val agreed = Property(false)
        sizeConstraints(width = AppDimensions.pageWidth) - col {
            title {
                content = "Terms of Service"
            }
            sizeConstraints(minHeight = 10.rem) - body {
                ::content{ terms.awaitNotNull().contents }
            }
            space()
            row {
                centered - checkbox {
                    checked bind agreed
                }
                centered - body {
                    content = "I agree to the terms of service as stated above."
                }
            }
            row {
                spacing = AppDimensions.buttonRowSpacing
                buttonTheme - affirmative - button {
                    ::enabled{ agreed() }
                    body("Accept Terms")
                    onClick {
                        val c = guaranteedContractor.await()
                        val session = notNullSession()
                        val t = terms.awaitNotNull()._id

                        notNullSession().contractorTermsAndConditionsAgreements.insert(
                            ContractorTermsAndConditionsAgreement(
                                contractor = c,
                                version = t,
                                accessInfo = session.currentAccess()
                            )
                        )

                        screenNavigator.replace(CreateProjectForm())
                    }
                }
                textButton - button {
                    body("Discard")
                    onClick { screenNavigator.goBack() }
                }
            }
        }
    }
}