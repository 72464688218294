// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareContractorDocumentFields() {
    val props: Array<SerializableProperty<ContractorDocument, *>> = arrayOf(ContractorDocument__id, ContractorDocument_contractor, ContractorDocument_name, ContractorDocument_fileType, ContractorDocument_file, ContractorDocument_preview)
    ContractorDocument.serializer().properties { props }
}
val <K> DataClassPath<K, ContractorDocument>._id: DataClassPath<K, UUID> get() = this[ContractorDocument__id]
val <K> DataClassPath<K, ContractorDocument>.contractor: DataClassPath<K, UUID> get() = this[ContractorDocument_contractor]
val <K> DataClassPath<K, ContractorDocument>.name: DataClassPath<K, String> get() = this[ContractorDocument_name]
val <K> DataClassPath<K, ContractorDocument>.fileType: DataClassPath<K, String> get() = this[ContractorDocument_fileType]
val <K> DataClassPath<K, ContractorDocument>.file: DataClassPath<K, ServerFile> get() = this[ContractorDocument_file]
val <K> DataClassPath<K, ContractorDocument>.preview: DataClassPath<K, ServerFile?> get() = this[ContractorDocument_preview]
inline val ContractorDocument.Companion.path: DataClassPath<ContractorDocument, ContractorDocument> get() = path<ContractorDocument>()


object ContractorDocument__id: SerializableProperty<ContractorDocument, UUID> {
    override val name: String by lazy { ContractorDocument.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ContractorDocument): UUID = receiver._id
    override fun setCopy(receiver: ContractorDocument, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ContractorDocument.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ContractorDocument.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object ContractorDocument_contractor: SerializableProperty<ContractorDocument, UUID> {
    override val name: String by lazy { ContractorDocument.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ContractorDocument): UUID = receiver.contractor
    override fun setCopy(receiver: ContractorDocument, value: UUID) = receiver.copy(contractor = value)
    override val serializer: KSerializer<UUID> by lazy { (ContractorDocument.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ContractorDocument.serializer().descriptor.getElementAnnotations(1) }
}
object ContractorDocument_name: SerializableProperty<ContractorDocument, String> {
    override val name: String by lazy { ContractorDocument.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ContractorDocument): String = receiver.name
    override fun setCopy(receiver: ContractorDocument, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> by lazy { (ContractorDocument.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ContractorDocument.serializer().descriptor.getElementAnnotations(2) }
}
object ContractorDocument_fileType: SerializableProperty<ContractorDocument, String> {
    override val name: String by lazy { ContractorDocument.serializer().descriptor.getElementName(3) }
    override fun get(receiver: ContractorDocument): String = receiver.fileType
    override fun setCopy(receiver: ContractorDocument, value: String) = receiver.copy(fileType = value)
    override val serializer: KSerializer<String> by lazy { (ContractorDocument.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ContractorDocument.serializer().descriptor.getElementAnnotations(3) }
}
object ContractorDocument_file: SerializableProperty<ContractorDocument, ServerFile> {
    override val name: String by lazy { ContractorDocument.serializer().descriptor.getElementName(4) }
    override fun get(receiver: ContractorDocument): ServerFile = receiver.file
    override fun setCopy(receiver: ContractorDocument, value: ServerFile) = receiver.copy(file = value)
    override val serializer: KSerializer<ServerFile> by lazy { (ContractorDocument.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<ServerFile> }
    override val annotations: List<Annotation> by lazy { ContractorDocument.serializer().descriptor.getElementAnnotations(4) }
}
object ContractorDocument_preview: SerializableProperty<ContractorDocument, ServerFile?> {
    override val name: String by lazy { ContractorDocument.serializer().descriptor.getElementName(5) }
    override fun get(receiver: ContractorDocument): ServerFile? = receiver.preview
    override fun setCopy(receiver: ContractorDocument, value: ServerFile?) = receiver.copy(preview = value)
    override val serializer: KSerializer<ServerFile?> by lazy { (ContractorDocument.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<ServerFile?> }
    override val annotations: List<Annotation> by lazy { ContractorDocument.serializer().descriptor.getElementAnnotations(5) }
    override val default: ServerFile? = null
    override val defaultCode: String? = "null" 
}
