package com.crowpay.views.components.project

import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.space
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.body
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.row

fun ProjectView.previewInformation(writer: ViewWriter) = with(writer) {
    col {
        existsDefaultFalse { anonymous() }
        spacing = 0.dp
        title {
            ::content { project().name }
        }
        space(AppDimensions.backgroundIndent)
        row {
            spacing = 0.dp
            space(AppDimensions.fullIndent)
            body {
                ::content {
                    val description = project().description
                    if (description.length > 200)
                        description.take(200) + "..."
                    else
                        description
                }
            }
            space(AppDimensions.fullIndent)
        }
    }
}