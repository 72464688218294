package com.crowpay.views.screens.client

import com.crowpay.*
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.components.field2
import com.crowpay.views.components.label2
import com.crowpay.views.components.requiredField
import com.crowpay.views.theming.subTitle
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.serialization.lensPath

class ClientForm(
    val user: Writable<User>,
    val widthLimit: Dimension,
) : Validator(), Screen {

    val userDraft = Draft(user)

    override fun ViewWriter.render() {

        col {

            subTitle("Basic Information")

            rowCollapsingToColumn(widthLimit) {
                expanding - requiredField("First Name") {
                    val value = userDraft
                        .lens(
                            get = { it.firstName },
                            modify = { og, it -> og.copy(firstName = it) }
                        )
                        .validate { it.isNotBlank() }
                    textInput {
                        hint = "First Name"
                        content bind value
                    }
                }

                expanding - requiredField("Last Name") {
                    val value = userDraft
                        .lens(
                            get = { it.lastName },
                            modify = { og, it -> og.copy(lastName = it) }
                        )
                        .validate { it.isNotBlank() }
                    textField {
                        hint = "Last Name"
                        content bind value
                    }
                }
            }

            rowCollapsingToColumn(widthLimit) {
                expanding - requiredField("Email") {
                    val value = userDraft
                        .lens(
                            get = { it.email },
                            modify = { og, it -> og.copy(email = it) }
                        )
                        .validate { it.matches(SharedUtils.emailPattern) }
                    textInput {
                        hint = "Email"
                        content bind value
                    }
                }

                expanding - requiredField("Phone Number") {
                    val value =
                        userDraft
                            .lensPath(User.path.phoneNumber)
                            .validate { it.length == 10 }
                    phoneNumberInput {
                        hint = "Phone Number"
                        content bind value
                    }
                }
            }

            space()
            subTitle("Address")

            rowCollapsingToColumn(widthLimit) {
                expanding - requiredField("Line 1") {
                    val value = userDraft
                        .lens(
                            get = { it.address.line1 },
                            modify = { og, it ->
                                og.copy(address = og.address.copy(line1 = it))
                            }
                        )
                        .validate { it.isNotBlank() }
                    textInput {
                        hint = "Line 1"
                        content bind value
                    }
                }
                expanding - field2("Line 2 (Optional)") {
                    textInput {
                        hint = "Line 2"
                        content bind userDraft
                            .lens(
                                get = { it.address.line2 ?: "" },
                                modify = { og, it ->
                                    og.copy(address = og.address.copy(line2 = it.takeIf { it.isNotBlank() }))
                                }
                            )
                    }
                }
            }
            rowCollapsingToColumn(widthLimit) {
                expanding - requiredField("City") {
                    val value = userDraft
                        .lens(
                            get = { it.address.city },
                            modify = { og, it ->
                                og.copy(address = og.address.copy(city = it))
                            }
                        )
                        .validate { it.isNotBlank() }
                    textInput {
                        hint = "City"
                        content bind value
                    }
                }
                expanding - requiredField("State") {
                    select {
                        bind(
                            userDraft.lens(
                                get = { it.address.state },
                                modify = { og, it -> og.copy(address = og.address.copy(state = it)) }
                            ),
                            Constant(State.entries)
                        ) { it.display }
                    }
                }
                expanding - requiredField("Zipcode") {
                    val value = userDraft
                        .lens(
                            get = { it.address.zipcode },
                            modify = { og, it ->
                                og.copy(address = og.address.copy(zipcode = it))
                            }
                        )
                        .validate(true) { it.isNotBlank() }
                    textInput {
                        hint = "Zipcode"
                        content bind value
                    }
                }
            }

        }
    }
}