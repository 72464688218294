package com.crowpay.utils

import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningdb.HasId
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.ModificationBuilder
import com.lightningkite.lightningdb.modification
import com.lightningkite.lightningserver.db.ModelCache
import com.lightningkite.lightningserver.db.WritableModel
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.serializerOrContextual
import kotlinx.serialization.KSerializer


suspend inline fun <reified T> WritableModel<T>.modify2(setup: ModificationBuilder<T>.(DataClassPath<T, T>) -> Unit) =
    modify(modification(setup))

suspend fun <T> Draft<T>.diff(serializer: KSerializer<T>): Modification<T>? = modification(serializer, published.awaitOnce(), this.awaitOnce())
suspend inline fun <reified T> Draft<T>.diff(): Modification<T>? = diff(serializerOrContextual())

suspend fun <T: HasId<ID>, ID: Comparable<ID>> Draft<T>.pushChanges(cache: ModelCache<T, ID>): T {
    val id = published.awaitOnce()._id
    val p = publish()
    cache[id].set(p)
    return p
}

suspend fun <T: HasId<ID>, ID: Comparable<ID>> Draft<T>.pushChanges(getCache: suspend () -> ModelCache<T, ID>) =
    pushChanges(getCache())

fun <T> draftOf(getWritable: ReactiveContext.()->Writable<T>): Draft<T> =
    Draft(shared(action = getWritable).flatten())

/**
 * Updates the data for the published writable and draft, attempting to preserve changes made to the draft.
 * **WARN** If a modification for the draft cannot be generated then changes will be lost.
* */
suspend fun <T> Draft<T>.updatePublished(value: T, serializer: KSerializer<T>) {
    if (published.awaitOnce() == value) return
    val mod = diff(serializer)

    if (mod == null) {
        // Either no changes were made, or the modification couldn't be found, so changes can't be transferred
        published.set(value)
        cancel()
        return
    }

    published.set(value)
    set(mod(value))
}

/**
 * Updates the data for the published writable manually, attempting to preserve changes made to the draft. **WARN** If a
 * modification for the draft cannot be generated then changes will be lost.
 * */
suspend inline fun <reified T> Draft<T>.updatePublished(value: T) = updatePublished(value, serializerOrContextual())