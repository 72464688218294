package com.crowpay.views.screens.contractor

import com.crowpay.AccountInfo
import com.crowpay.PaymentSetupRequest
import com.crowpay.PendingCashOut
import com.crowpay.SharedUtils
import com.crowpay.actuals.AppDimensions
import com.crowpay.guaranteedContractor
import com.crowpay.notNullContractor
import com.crowpay.sdk.currentSession
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.validation.validating
import com.crowpay.views.components.requiredField
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.theming.body
import com.crowpay.views.theming.bodyBold
import com.crowpay.views.theming.redText
import com.crowpay.views.theming.section
import com.crowpay.views.theming.specCenteredText
import com.crowpay.views.theming.tertiaryButton
import com.crowpay.views.theming.textButton
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.LateInitProperty
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.awaitNotNull
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.direct.button
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.gravity
import com.lightningkite.kiteui.views.direct.onClick
import com.lightningkite.kiteui.views.direct.padded
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.scrolls
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.kiteui.views.direct.stack
import com.lightningkite.kiteui.views.direct.textInput
import com.lightningkite.kiteui.views.expanding
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

@Routable("contractor/bank-info")
class ContractorBankInfo() : ContractorScreen, PaymentScreen {

    override val title: Readable<String> = Constant("Bank Info")

    val contractor = shared { notNullSession().contractors[notNullContractor()]()!! }

    val paymentInfo = LateInitProperty<AccountInfo?>()

    suspend fun ViewWriter.retrievePaymentInfo() {
        val c = contractor.awaitNotNull()
        val result = currentSession.awaitNotNull().nonCached.contractor.getPaymentInfo(c._id)
        paymentInfo.set(result)
    }

    override fun ViewWriter.renderMainContent() {
        scrolls - col {
            spacing = 0.rem

            title("Bank Info")

            sizeConstraints(width = AppDimensions.pageWidth) - padded - stack {
                spacing = AppDimensions.backgroundIndent
                launch { retrievePaymentInfo() } // put this into the section that displays the info
                section - expanding - col {
                    col {
                        ::exists{ paymentInfo() != null }
                        row {
                            bodyBold {
                                ::content{ "${paymentInfo()?.bankName ?: ""}: " }
                            }
                            body {
                                ::content{ paymentInfo()?.accountName ?: "" }
                            }
                        }

                        gravity(Align.Start, Align.Center) - buttonTheme - button {
                            redText - body("Remove Bank Account")
                            onClick {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        header = "Clear Payment Info?",
                                        message = "This will clear out your submitted payment info. Without it you will not be able to cash out your money until you enter a new one.",
                                        confirmationText = "Clear Payment Info",
                                        messageType = MessageType.Warning,
                                        onSubmit = {
                                            if (it) {
                                                notNullSession().nonCached.contractor
                                                    .clearPaymentInfo(guaranteedContractor.await())
                                                delay(500)
                                                retrievePaymentInfo()
                                            }
                                        }
                                    )
                                )
                            }
                        }
                    }
                    col {
                        val addingBank = Property(false)
                        exists = false
                        ::exists{ paymentInfo() == null }

                        gravity(Align.Start, Align.Center) - buttonTheme - button {
                            ::exists{ !addingBank() }
                            body("Add Bank Account")
                            onClick {
                                addingBank.value = true
                            }
                        }

                        col {
                            ::exists{ addingBank() }

                            validating {

                                val bankName = Property("")
                                val accountName = Property("")
                                val routingNumber = Property("")
                                val accountNumber = Property("")
                                launch { retrievePaymentInfo() }

                                requiredField("Bank Name") {
                                    val value = bankName.validate { it.isNotBlank() }
                                    textInput {
                                        hint = "Bank Name"
                                        keyboardHints = KeyboardHints.title
                                        content bind value
                                    }
                                }

                                requiredField("Account Name") {
                                    val value = accountName.validate { it.isNotBlank() }
                                    textInput {
                                        hint = "Account Name"
                                        keyboardHints = KeyboardHints.title
                                        content bind value
                                    }
                                }

                                requiredField("Routing Number") {
                                    val value =
                                        routingNumber.validate { SharedUtils.isRoutingNumberValid(it) }
                                    textInput {
                                        hint = "Routing Number"
                                        keyboardHints = KeyboardHints.integer
                                        content bind value
                                    }
                                }

                                requiredField("Account Number") {
                                    val value = accountNumber.validate { it.isNotBlank() }
                                    textInput {
                                        hint = "Account Number"
                                        content bind value
                                    }
                                }

                                row {
                                    textButton - button {
                                        specCenteredText("Cancel")
                                        onClick {
                                            addingBank.value = false
                                            bankName.value = ""
                                            accountName.value = ""
                                            routingNumber.value = ""
                                            accountNumber.value = ""
                                        }
                                    }

                                    tertiaryButton - button {
                                        ::enabled{ allValid() }
                                        specCenteredText("Save Bank Account")
                                        onClick {
                                            val c = guaranteedContractor.await()

                                            notNullSession().nonCached.contractor.setupPaymentInfo(
                                                id = c,
                                                input = PaymentSetupRequest(
                                                    bankName = bankName.value,
                                                    routingNumber = routingNumber.value.toInt(),
                                                    accountNumber = accountNumber.value,
                                                    accountName = accountName.value
                                                )
                                            )
                                            delay(500)
                                            retrievePaymentInfo()

                                            addingBank.value = false
                                            bankName.value = ""
                                            accountName.value = ""
                                            routingNumber.value = ""
                                            accountNumber.value = ""
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}