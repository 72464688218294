package com.crowpay.views.screens.client

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.hash
import com.crowpay.sdk.notNullSession
import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.screens.common.PreviewScreen
import com.crowpay.views.theming.body
import com.crowpay.views.theming.textButton
import com.crowpay.views.theming.title
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.suspendCoroutineCancellable
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.sort
import kotlin.coroutines.resume

@Routable("client/accept-project/{projectId}")
class ClientTermsAndServices(val projectId: UUID) : Screen, PreviewScreen {
    var fullProject: FullProject? = null
    override fun ViewWriter.render() {
        if (fullProject == null) screenNavigator.navigate(LandingScreen())
        val terms = shared {
            notNullSession().clientTermsAndConditionsVersions.query(
                Query(
                    condition = condition(true),
                    orderBy = sort { it._id.descending() },
                    limit = 1,
                )
            )().firstOrNull()
        }
        val agreed = Property(false)

        suspend fun acceptProject() {
            val session = notNullSession()

            val t = terms.awaitNotNull()._id
            session.clientTermsAndConditionsAgreements.insert(
                ClientTermsAndConditionsAgreement(
                    version = t,
                    accessInfo = session.currentAccess()
                )
            )

            try {
                session.nonCached.project.acceptProject(
                    fullProject!!.project._id,
                    fullProject!!.project.hash(fullProject!!.lineItems.toSet())
                )
                session.projects[fullProject!!.project._id].invalidate()
                this.screenNavigator.reset(ClientProjects())
                this.screenNavigator.navigate(ClientProject(projectId))
            } catch (e: Exception) {
                suspendCoroutineCancellable { cont ->
                    dialogScreenNavigator.navigate(
                        GenericDialog(
                            message = "Accepting failed. You may be viewing old data. Refresh the page and try again. If the problem persists contact support.",
                            dismiss = {
                                cont.resume(Unit)
                                dialogScreenNavigator.dismiss()
                            }
                        ))
                    return@suspendCoroutineCancellable {}
                }
            }

        }

        sizeConstraints(width = AppDimensions.pageWidth) - col {
            title("Terms of Service")
            sizeConstraints(minHeight = 10.rem) - body {
                ::content{ terms.awaitNotNull().contents }
            }
            space()
            row {
                centered - checkbox {
                    checked bind agreed
                }
                centered - body("I agree to the terms of service as stated above.")
            }
            row {
                spacing = AppDimensions.buttonRowSpacing
                buttonTheme - affirmative - button {
                    ::enabled{ agreed() }
                    body("Accept Terms")
                    onClick(action = ::acceptProject)
                }
                textButton - button {
                    body("Go Back")
                    onClick { screenNavigator.goBack() }
                }
            }
        }
    }
}