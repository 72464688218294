// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareIncidentMessageFields() {
    val props: Array<SerializableProperty<IncidentMessage, *>> = arrayOf(IncidentMessage__id, IncidentMessage_incident, IncidentMessage_project, IncidentMessage_clientMessage, IncidentMessage_typeSerializable, IncidentMessage_message, IncidentMessage_attachments, IncidentMessage_created)
    IncidentMessage.serializer().properties { props }
}
val <K> DataClassPath<K, IncidentMessage>._id: DataClassPath<K, UUID> get() = this[IncidentMessage__id]
val <K> DataClassPath<K, IncidentMessage>.incident: DataClassPath<K, UUID> get() = this[IncidentMessage_incident]
val <K> DataClassPath<K, IncidentMessage>.project: DataClassPath<K, UUID> get() = this[IncidentMessage_project]
val <K> DataClassPath<K, IncidentMessage>.clientMessage: DataClassPath<K, Boolean> get() = this[IncidentMessage_clientMessage]
val <K> DataClassPath<K, IncidentMessage>.typeSerializable: DataClassPath<K, IncidentResponseTypeSerializable> get() = this[IncidentMessage_typeSerializable]
val <K> DataClassPath<K, IncidentMessage>.message: DataClassPath<K, String> get() = this[IncidentMessage_message]
val <K> DataClassPath<K, IncidentMessage>.attachments: DataClassPath<K, List<ProjectAttachment>> get() = this[IncidentMessage_attachments]
val <K> DataClassPath<K, IncidentMessage>.created: DataClassPath<K, Instant> get() = this[IncidentMessage_created]
inline val IncidentMessage.Companion.path: DataClassPath<IncidentMessage, IncidentMessage> get() = path<IncidentMessage>()


object IncidentMessage__id: SerializableProperty<IncidentMessage, UUID> {
    override val name: String by lazy { IncidentMessage.serializer().descriptor.getElementName(0) }
    override fun get(receiver: IncidentMessage): UUID = receiver._id
    override fun setCopy(receiver: IncidentMessage, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (IncidentMessage.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { IncidentMessage.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object IncidentMessage_incident: SerializableProperty<IncidentMessage, UUID> {
    override val name: String by lazy { IncidentMessage.serializer().descriptor.getElementName(1) }
    override fun get(receiver: IncidentMessage): UUID = receiver.incident
    override fun setCopy(receiver: IncidentMessage, value: UUID) = receiver.copy(incident = value)
    override val serializer: KSerializer<UUID> by lazy { (IncidentMessage.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { IncidentMessage.serializer().descriptor.getElementAnnotations(1) }
}
object IncidentMessage_project: SerializableProperty<IncidentMessage, UUID> {
    override val name: String by lazy { IncidentMessage.serializer().descriptor.getElementName(2) }
    override fun get(receiver: IncidentMessage): UUID = receiver.project
    override fun setCopy(receiver: IncidentMessage, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (IncidentMessage.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { IncidentMessage.serializer().descriptor.getElementAnnotations(2) }
}
object IncidentMessage_clientMessage: SerializableProperty<IncidentMessage, Boolean> {
    override val name: String by lazy { IncidentMessage.serializer().descriptor.getElementName(3) }
    override fun get(receiver: IncidentMessage): Boolean = receiver.clientMessage
    override fun setCopy(receiver: IncidentMessage, value: Boolean) = receiver.copy(clientMessage = value)
    override val serializer: KSerializer<Boolean> by lazy { (IncidentMessage.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { IncidentMessage.serializer().descriptor.getElementAnnotations(3) }
}
object IncidentMessage_typeSerializable: SerializableProperty<IncidentMessage, IncidentResponseTypeSerializable> {
    override val name: String by lazy { IncidentMessage.serializer().descriptor.getElementName(4) }
    override fun get(receiver: IncidentMessage): IncidentResponseTypeSerializable = receiver.typeSerializable
    override fun setCopy(receiver: IncidentMessage, value: IncidentResponseTypeSerializable) = receiver.copy(typeSerializable = value)
    override val serializer: KSerializer<IncidentResponseTypeSerializable> by lazy { (IncidentMessage.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<IncidentResponseTypeSerializable> }
    override val annotations: List<Annotation> by lazy { IncidentMessage.serializer().descriptor.getElementAnnotations(4) }
}
object IncidentMessage_message: SerializableProperty<IncidentMessage, String> {
    override val name: String by lazy { IncidentMessage.serializer().descriptor.getElementName(5) }
    override fun get(receiver: IncidentMessage): String = receiver.message
    override fun setCopy(receiver: IncidentMessage, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> by lazy { (IncidentMessage.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { IncidentMessage.serializer().descriptor.getElementAnnotations(5) }
}
object IncidentMessage_attachments: SerializableProperty<IncidentMessage, List<ProjectAttachment>> {
    override val name: String by lazy { IncidentMessage.serializer().descriptor.getElementName(6) }
    override fun get(receiver: IncidentMessage): List<ProjectAttachment> = receiver.attachments
    override fun setCopy(receiver: IncidentMessage, value: List<ProjectAttachment>) = receiver.copy(attachments = value)
    override val serializer: KSerializer<List<ProjectAttachment>> by lazy { (IncidentMessage.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<List<ProjectAttachment>> }
    override val annotations: List<Annotation> by lazy { IncidentMessage.serializer().descriptor.getElementAnnotations(6) }
    override val default: List<ProjectAttachment> = emptyList()
    override val defaultCode: String? = "emptyList()" 
}
object IncidentMessage_created: SerializableProperty<IncidentMessage, Instant> {
    override val name: String by lazy { IncidentMessage.serializer().descriptor.getElementName(7) }
    override fun get(receiver: IncidentMessage): Instant = receiver.created
    override fun setCopy(receiver: IncidentMessage, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (IncidentMessage.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { IncidentMessage.serializer().descriptor.getElementAnnotations(7) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
