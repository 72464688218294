// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun preparePayoutFields() {
    val props: Array<SerializableProperty<Payout, *>> = arrayOf(Payout__id, Payout_nachaFile, Payout_totalOut, Payout_onePercent)
    Payout.serializer().properties { props }
}
val <K> DataClassPath<K, Payout>._id: DataClassPath<K, Instant> get() = this[Payout__id]
val <K> DataClassPath<K, Payout>.nachaFile: DataClassPath<K, ServerFile> get() = this[Payout_nachaFile]
val <K> DataClassPath<K, Payout>.totalOut: DataClassPath<K, Int> get() = this[Payout_totalOut]
val <K> DataClassPath<K, Payout>.onePercent: DataClassPath<K, Int> get() = this[Payout_onePercent]
inline val Payout.Companion.path: DataClassPath<Payout, Payout> get() = path<Payout>()


object Payout__id: SerializableProperty<Payout, Instant> {
    override val name: String by lazy { Payout.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Payout): Instant = receiver._id
    override fun setCopy(receiver: Payout, value: Instant) = receiver.copy(_id = value)
    override val serializer: KSerializer<Instant> by lazy { (Payout.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { Payout.serializer().descriptor.getElementAnnotations(0) }
    override val default: Instant = System.now()
    override val defaultCode: String? = "System.now()" 
}
object Payout_nachaFile: SerializableProperty<Payout, ServerFile> {
    override val name: String by lazy { Payout.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Payout): ServerFile = receiver.nachaFile
    override fun setCopy(receiver: Payout, value: ServerFile) = receiver.copy(nachaFile = value)
    override val serializer: KSerializer<ServerFile> by lazy { (Payout.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<ServerFile> }
    override val annotations: List<Annotation> by lazy { Payout.serializer().descriptor.getElementAnnotations(1) }
}
object Payout_totalOut: SerializableProperty<Payout, Int> {
    override val name: String by lazy { Payout.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Payout): Int = receiver.totalOut
    override fun setCopy(receiver: Payout, value: Int) = receiver.copy(totalOut = value)
    override val serializer: KSerializer<Int> by lazy { (Payout.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<Int> }
    override val annotations: List<Annotation> by lazy { Payout.serializer().descriptor.getElementAnnotations(2) }
}
object Payout_onePercent: SerializableProperty<Payout, Int> {
    override val name: String by lazy { Payout.serializer().descriptor.getElementName(3) }
    override fun get(receiver: Payout): Int = receiver.onePercent
    override fun setCopy(receiver: Payout, value: Int) = receiver.copy(onePercent = value)
    override val serializer: KSerializer<Int> by lazy { (Payout.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Int> }
    override val annotations: List<Annotation> by lazy { Payout.serializer().descriptor.getElementAnnotations(3) }
}
