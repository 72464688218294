package com.crowpay.utils.validation

import com.crowpay.utils.HasDebug
import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.InvalidSemantic
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.dynamicTheme

@ViewModifierDsl3
fun ViewWriter.validate(validated: Validated): ViewWrapper {
    beforeNextElementSetup {
        dynamicTheme {
            if (!validated.valid()) InvalidSemantic else null
        }
    }
    return ViewWrapper
}

@ViewModifierDsl3
fun ViewWriter.validate(validated: Validated, display: ReactiveContext.()->Boolean): ViewWrapper {
    beforeNextElementSetup {
        dynamicTheme {
            if (!validated.valid() && display()) InvalidSemantic else null
        }
    }
    return ViewWrapper
}

@ViewModifierDsl3
fun ViewWriter.validate(validated: Readable<Validated>): ViewWrapper {
    beforeNextElementSetup {
        dynamicTheme {
            if (!validated().valid()) InvalidSemantic else null
        }
    }
    return ViewWrapper
}

@ViewModifierDsl3
fun ViewWriter.validateDebug(validated: Readable<Validated>): ViewWrapper {
    beforeNextElementSetup {
        dynamicTheme {
            val obj = validated()
            val valid = obj.valid()
            if (obj is HasDebug) {
                with(obj) {
                    obj.debug?.id("Found valid = $valid")
                }
            }
            if (!valid) InvalidSemantic else null
        }
    }
    return ViewWrapper
}