package com.crowpay.utils

import com.lightningkite.kiteui.AppScope
import com.lightningkite.kiteui.reactive.BaseReadable
import com.lightningkite.kiteui.reactive.ReadableEmitter
import com.lightningkite.kiteui.reactive.ReadableState
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch

class RestartableProcess<T>(
    val scope: CoroutineScope = AppScope,
    val calculation: suspend ReadableEmitter<T>.()->Unit
) : BaseReadable<T>() {
    private var job: Job? = null

    private inner class Emitter(val scope: CoroutineScope) : ReadableEmitter<T>, CoroutineScope by scope {
        override fun emit(value: T) {
            state = ReadableState(value)
        }
    }

    override fun activate() {
        state = ReadableState.notReady
        job = scope.launch { calculation(Emitter(this)) }
    }
    override fun deactivate() {
        job?.cancel()
        job = null
    }

    fun restart() {
        job?.cancel()
        job = scope.launch { calculation(Emitter(this)) }
    }
}