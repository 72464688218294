package com.crowpay

import com.crowpay.views.AutoRoutes
import com.crowpay.views.theming.appTheme
import com.lightningkite.kiteui.navigation.ScreenNavigator
import com.lightningkite.kiteui.root

fun main() {
    root(appTheme) {
        app(ScreenNavigator { AutoRoutes }, ScreenNavigator { AutoRoutes })
    }
}