package com.crowpay.views.components

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.SignalingList
import com.crowpay.utils.validation.ValidCondition
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.theming.subTitle
import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.rowCollapsingToColumn
import com.lightningkite.kiteui.views.direct.select
import com.lightningkite.kiteui.views.direct.textInput
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.serialization.lensPath

fun ViewWriter.addressForm(
    address: Writable<Address>,
    validator: Validator,
    widthLimit: Dimension = AppDimensions.normalCollapseWidth,
    allowEdit: (ReactiveContext.()->Boolean)? = null,
    forceValid: (ReactiveContext.()->Boolean)? = null
) = with(validator) {
    col {
        val conditions = SignalingList<ValidCondition>()
        rowCollapsingToColumn(widthLimit) {
            expanding - requiredField("Line 1") {
                val value = address.lensPath { it.line1 }.validateNotBlank()
                conditions.add(value.valid)
                textInput {
                    allowEdit?.let { ::enabled { it() } }
                    hint = "Line 1"
                    content bind value
                }
            }
            expanding - field2("Line 2 (Optional)") {
                textInput {
                    allowEdit?.let { ::enabled { it() } }
                    hint = "Line 2"
                    content bind address.lensPath { it.line2 }.nullToBlank()
                }
            }
        }
        rowCollapsingToColumn(widthLimit) {
            expanding - requiredField("City") {
                val value = address.lensPath { it.city }.validateNotBlank()
                conditions.add(value.valid)
                textInput {
                    allowEdit?.let { ::enabled { it() } }
                    hint = "City"
                    content bind value
                }
            }
            expanding - requiredField("State") {
                select {
                    allowEdit?.let { ::enabled { it() } }
                    bind(
                        address.lensPath { it.state },
                        Constant(State.entries)
                    ) { it.display }
                }
            }
            expanding - requiredField("Zipcode") {
                val value = address.lensPath { it.zipcode }.validateNotBlank()
                conditions.add(value.valid)
                textInput {
                    allowEdit?.let { ::enabled { it() } }
                    hint = "Zipcode"
                    content bind value
                }
            }
        }
        forceValid?.let { force ->
            reactive {
                if (force()) {
                    validator.validConditions.removeAll(conditions())
                }
                else {
                    validator.validConditions.addAll(conditions())
                }
            }
        }
    }
}
