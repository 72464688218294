package com.crowpay.sdk

import com.lightningkite.kiteui.reactive.PersistentProperty
import kotlinx.serialization.Serializable


@Serializable
enum class ApiOption(val apiName: String, val http: String, val ws: String) {
    Staging("Staging", "https://staging-api.crowpay.com", "wss://ws.staging-api.crowpay.com"),
    Dev("Dev", "https://dev-api.crowpay.com", "wss://ws.crowpay-api.cs.lightningkite.com"),
    BSvedin("BSvedin", "https://bsvedin.lightningkite.com/server", "wss://bsvedin.lightningkite.com/server"),
    Local("Local", "http://localhost:8080", "ws://localhost:8080")
    ;

    val api get() = LiveApi(http, ws)

    companion object {
        val SAFE = setOf(Local, BSvedin, Dev, Staging)
    }
}

val selectedApi = PersistentProperty<ApiOption>("apiOption", getDefaultServerBackend())


expect fun getDefaultServerBackend(): ApiOption