// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareLineItemCompletionMessageFields() {
    val props: Array<SerializableProperty<LineItemCompletionMessage, *>> = arrayOf(LineItemCompletionMessage__id, LineItemCompletionMessage_project, LineItemCompletionMessage_lineItem, LineItemCompletionMessage_sender, LineItemCompletionMessage_clientMessage, LineItemCompletionMessage_responseTypeSerializable, LineItemCompletionMessage_message, LineItemCompletionMessage_attachments, LineItemCompletionMessage_created)
    LineItemCompletionMessage.serializer().properties { props }
}
val <K> DataClassPath<K, LineItemCompletionMessage>._id: DataClassPath<K, UUID> get() = this[LineItemCompletionMessage__id]
val <K> DataClassPath<K, LineItemCompletionMessage>.project: DataClassPath<K, UUID> get() = this[LineItemCompletionMessage_project]
val <K> DataClassPath<K, LineItemCompletionMessage>.lineItem: DataClassPath<K, UUID> get() = this[LineItemCompletionMessage_lineItem]
val <K> DataClassPath<K, LineItemCompletionMessage>.sender: DataClassPath<K, UUID> get() = this[LineItemCompletionMessage_sender]
val <K> DataClassPath<K, LineItemCompletionMessage>.clientMessage: DataClassPath<K, Boolean> get() = this[LineItemCompletionMessage_clientMessage]
val <K> DataClassPath<K, LineItemCompletionMessage>.responseTypeSerializable: DataClassPath<K, CompletionResponseSerializable> get() = this[LineItemCompletionMessage_responseTypeSerializable]
val <K> DataClassPath<K, LineItemCompletionMessage>.message: DataClassPath<K, String> get() = this[LineItemCompletionMessage_message]
val <K> DataClassPath<K, LineItemCompletionMessage>.attachments: DataClassPath<K, List<ProjectAttachment>> get() = this[LineItemCompletionMessage_attachments]
val <K> DataClassPath<K, LineItemCompletionMessage>.created: DataClassPath<K, Instant> get() = this[LineItemCompletionMessage_created]
inline val LineItemCompletionMessage.Companion.path: DataClassPath<LineItemCompletionMessage, LineItemCompletionMessage> get() = path<LineItemCompletionMessage>()


object LineItemCompletionMessage__id: SerializableProperty<LineItemCompletionMessage, UUID> {
    override val name: String by lazy { LineItemCompletionMessage.serializer().descriptor.getElementName(0) }
    override fun get(receiver: LineItemCompletionMessage): UUID = receiver._id
    override fun setCopy(receiver: LineItemCompletionMessage, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (LineItemCompletionMessage.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { LineItemCompletionMessage.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object LineItemCompletionMessage_project: SerializableProperty<LineItemCompletionMessage, UUID> {
    override val name: String by lazy { LineItemCompletionMessage.serializer().descriptor.getElementName(1) }
    override fun get(receiver: LineItemCompletionMessage): UUID = receiver.project
    override fun setCopy(receiver: LineItemCompletionMessage, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (LineItemCompletionMessage.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { LineItemCompletionMessage.serializer().descriptor.getElementAnnotations(1) }
}
object LineItemCompletionMessage_lineItem: SerializableProperty<LineItemCompletionMessage, UUID> {
    override val name: String by lazy { LineItemCompletionMessage.serializer().descriptor.getElementName(2) }
    override fun get(receiver: LineItemCompletionMessage): UUID = receiver.lineItem
    override fun setCopy(receiver: LineItemCompletionMessage, value: UUID) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<UUID> by lazy { (LineItemCompletionMessage.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { LineItemCompletionMessage.serializer().descriptor.getElementAnnotations(2) }
}
object LineItemCompletionMessage_sender: SerializableProperty<LineItemCompletionMessage, UUID> {
    override val name: String by lazy { LineItemCompletionMessage.serializer().descriptor.getElementName(3) }
    override fun get(receiver: LineItemCompletionMessage): UUID = receiver.sender
    override fun setCopy(receiver: LineItemCompletionMessage, value: UUID) = receiver.copy(sender = value)
    override val serializer: KSerializer<UUID> by lazy { (LineItemCompletionMessage.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { LineItemCompletionMessage.serializer().descriptor.getElementAnnotations(3) }
}
object LineItemCompletionMessage_clientMessage: SerializableProperty<LineItemCompletionMessage, Boolean> {
    override val name: String by lazy { LineItemCompletionMessage.serializer().descriptor.getElementName(4) }
    override fun get(receiver: LineItemCompletionMessage): Boolean = receiver.clientMessage
    override fun setCopy(receiver: LineItemCompletionMessage, value: Boolean) = receiver.copy(clientMessage = value)
    override val serializer: KSerializer<Boolean> by lazy { (LineItemCompletionMessage.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { LineItemCompletionMessage.serializer().descriptor.getElementAnnotations(4) }
}
object LineItemCompletionMessage_responseTypeSerializable: SerializableProperty<LineItemCompletionMessage, CompletionResponseSerializable> {
    override val name: String by lazy { LineItemCompletionMessage.serializer().descriptor.getElementName(5) }
    override fun get(receiver: LineItemCompletionMessage): CompletionResponseSerializable = receiver.responseTypeSerializable
    override fun setCopy(receiver: LineItemCompletionMessage, value: CompletionResponseSerializable) = receiver.copy(responseTypeSerializable = value)
    override val serializer: KSerializer<CompletionResponseSerializable> by lazy { (LineItemCompletionMessage.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<CompletionResponseSerializable> }
    override val annotations: List<Annotation> by lazy { LineItemCompletionMessage.serializer().descriptor.getElementAnnotations(5) }
}
object LineItemCompletionMessage_message: SerializableProperty<LineItemCompletionMessage, String> {
    override val name: String by lazy { LineItemCompletionMessage.serializer().descriptor.getElementName(6) }
    override fun get(receiver: LineItemCompletionMessage): String = receiver.message
    override fun setCopy(receiver: LineItemCompletionMessage, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> by lazy { (LineItemCompletionMessage.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { LineItemCompletionMessage.serializer().descriptor.getElementAnnotations(6) }
}
object LineItemCompletionMessage_attachments: SerializableProperty<LineItemCompletionMessage, List<ProjectAttachment>> {
    override val name: String by lazy { LineItemCompletionMessage.serializer().descriptor.getElementName(7) }
    override fun get(receiver: LineItemCompletionMessage): List<ProjectAttachment> = receiver.attachments
    override fun setCopy(receiver: LineItemCompletionMessage, value: List<ProjectAttachment>) = receiver.copy(attachments = value)
    override val serializer: KSerializer<List<ProjectAttachment>> by lazy { (LineItemCompletionMessage.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<List<ProjectAttachment>> }
    override val annotations: List<Annotation> by lazy { LineItemCompletionMessage.serializer().descriptor.getElementAnnotations(7) }
    override val default: List<ProjectAttachment> = emptyList()
    override val defaultCode: String? = "emptyList()" 
}
object LineItemCompletionMessage_created: SerializableProperty<LineItemCompletionMessage, Instant> {
    override val name: String by lazy { LineItemCompletionMessage.serializer().descriptor.getElementName(8) }
    override fun get(receiver: LineItemCompletionMessage): Instant = receiver.created
    override fun setCopy(receiver: LineItemCompletionMessage, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (LineItemCompletionMessage.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { LineItemCompletionMessage.serializer().descriptor.getElementAnnotations(8) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
