package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.currentSession
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.renderDollars
import com.crowpay.utils.validation.validate
import com.crowpay.utils.validation.validating
import com.crowpay.views.components.label2
import com.crowpay.views.components.requiredField
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

interface PaymentScreen

@Routable("/contractor/payments")
class ContractorPayments() : ContractorScreen, PaymentScreen {

    override val title: Readable<String> = Constant("Payments")

    private var _contractor: Readable<Contractor>? = null
    val ViewWriter.contractor
        get() = _contractor ?: run {
            _contractor = shared {
                notNullSession().contractors[guaranteedContractor<UUID>()]()!!
            }
            _contractor!!
        }

    val pendingCash = LateInitProperty<PendingCashOut>()
    val paymentInfo = LateInitProperty<AccountInfo?>()


    suspend fun ViewWriter.retrievePaymentPendingCash() {
        val c = contractor.awaitNotNull()
        val result = currentSession.awaitNotNull().nonCached.contractor.pendingCashOut(c._id)
        pendingCash.set(result)
    }

    suspend fun ViewWriter.retrievePaymentInfo() {
        val c = contractor.awaitNotNull()
        val result = currentSession.awaitNotNull().nonCached.contractor.getPaymentInfo(c._id)
        paymentInfo.set(result)
    }

    override fun ViewWriter.renderMainContent() {

        scrolls - col {
            spacing = 0.dp

            title("Finances")

            space(AppDimensions.backgroundIndent)

            sizeConstraints(width = AppDimensions.pageWidth) - row {
                spacing = 0.dp
                space(AppDimensions.fullIndent)
                expanding - rowCollapsingToColumn(AppDimensions.pageWidth) {
                    spacing = 0.dp

                    expanding - col {
                        launch { retrievePaymentPendingCash() }

                        col {
                            spacing = 0.dp
                            subTitle("Total Available Funds:")

                            section - colored(AppColors.secondary.main) - bold - subTitle {
                                align = Align.Center
                                ::content{ pendingCash().available.renderDollars() }
                            }
                        }


                        col {
                            spacing = 0.dp
                            ::exists{ pendingCash().pending > 0 }

                            row {
                                centered - icon(Icon.simpleInfo, "Pending Funds") in
                                        sizeConstraints(height = 1.2.rem, width = 1.2.rem) in
                                        textPopover("Funds Pending Transfer are payments you have requested to collect but the transfer has not been initialized yet.\nIt takes a day for the transfer to be initialized.\nAfter payment is initiated it will take 3-5 business days.")
                                centered - subTitle("Funds Pending Transfer:")
                            }
                            section - colored(AppColors.secondary.main) - bold - subTitle {
                                align = Align.Center
                                ::content{ pendingCash().pending.renderDollars() }
                            }
                        }

                    }

                    space(AppDimensions.majorColumnSpacing)

                    expanding - col {
                        subTitle("Tell Us Where To Send Your Money!")

                        row {
                            centered - sizeConstraints(3.rem, 3.rem) - image {
                                source = Resources.imagesLogoColor
                            }

                            section - expanding - padded - col {
                                subTitle("CrowPay Card")
                                body("Coming Soon")
                            }
                        }

                        row {
                            centered - sizeConstraints(3.rem, 3.rem) - image {
                                source = Resources.imagesLogoColor
                            }

                            section - expanding - padded - col {
                                subTitle("Same Day")
                                body("Coming Soon")
                            }
                        }

                        row {
                            gravity(Align.Start, Align.Start) - sizeConstraints(3.rem, 3.rem) - image {
                                source = Resources.imagesLogoColor
                            }

                            section - expanding - col {
                                subTitle("2-3 Days")

                                col {
                                    ::exists{ paymentInfo() != null }
                                    row {
                                        bodyBold {
                                            ::content{ "${paymentInfo()?.bankName ?: ""}: " }
                                        }
                                        body {
                                            ::content{ paymentInfo()?.accountName ?: "" }
                                        }
                                    }

                                    gravity(Align.Start, Align.Center) - buttonTheme - button {
                                        redText - body("Remove Bank Account")
                                        onClick {
                                            dialogScreenNavigator.navigate(
                                                GenericConfirmationDialog(
                                                    header = "Clear Payment Info?",
                                                    message = "This will clear out your submitted payment info. Without it you will not be able to cash out your money until you enter a new one.",
                                                    confirmationText = "Clear Payment Info",
                                                    messageType = MessageType.Warning,
                                                    onSubmit = {
                                                        if (it) {
                                                            notNullSession().nonCached.contractor
                                                                .clearPaymentInfo(guaranteedContractor.await())
                                                            delay(500)
                                                            retrievePaymentInfo()
                                                        }
                                                    }
                                                )
                                            )
                                        }
                                    }
                                }
                                col {
                                    val addingBank = Property(false)
                                    exists = false
                                    ::exists{ paymentInfo() == null }

                                    gravity(Align.Start, Align.Center) - buttonTheme - button {
                                        ::exists{ !addingBank() }
                                        body("Add Bank Account")
                                        onClick {
                                            addingBank.value = true
                                        }
                                    }

                                    col {
                                        ::exists{ addingBank() }

                                        validating {

                                            val bankName = Property("")
                                            val accountName = Property("")
                                            val routingNumber = Property("")
                                            val accountNumber = Property("")
                                            launch { retrievePaymentInfo() }

                                            requiredField("Bank Name") {
                                                val value = bankName.validate { it.isNotBlank() }
                                                textInput {
                                                    hint = "Bank Name"
                                                    keyboardHints = KeyboardHints.title
                                                    content bind value
                                                }
                                            }

                                            requiredField("Account Name") {
                                                val value = accountName.validate { it.isNotBlank() }
                                                textInput {
                                                    hint = "Account Name"
                                                    keyboardHints = KeyboardHints.title
                                                    content bind value
                                                }
                                            }

                                            requiredField("Routing Number") {
                                                val value =
                                                    routingNumber.validate { SharedUtils.isRoutingNumberValid(it) }
                                                textInput {
                                                    hint = "Routing Number"
                                                    keyboardHints = KeyboardHints.integer
                                                    content bind value
                                                }
                                            }

                                            requiredField("Account Number") {
                                                val value = accountNumber.validate { it.isNotBlank() }
                                                textInput {
                                                    hint = "Account Number"
                                                    content bind value
                                                }
                                            }

                                            row {
                                                textButton - button {
                                                    specCenteredText("Cancel")
                                                    onClick {
                                                        addingBank.value = false
                                                        bankName.value = ""
                                                        accountName.value = ""
                                                        routingNumber.value = ""
                                                        accountNumber.value = ""
                                                    }
                                                }

                                                tertiaryButton - button {
                                                    ::enabled{ allValid() }
                                                    specCenteredText("Save Bank Account")
                                                    onClick {
                                                        val c = guaranteedContractor.await()

                                                        notNullSession().nonCached.contractor.setupPaymentInfo(
                                                            id = c,
                                                            input = PaymentSetupRequest(
                                                                bankName = bankName.value,
                                                                routingNumber = routingNumber.value.toInt(),
                                                                accountNumber = accountNumber.value,
                                                                accountName = accountName.value
                                                            )
                                                        )
                                                        delay(500)
                                                        retrievePaymentInfo()

                                                        addingBank.value = false
                                                        bankName.value = ""
                                                        accountName.value = ""
                                                        routingNumber.value = ""
                                                        accountNumber.value = ""
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        gravity(Align.End, Align.Center) - buttonTheme - affirmative - button {
                            ::enabled{ pendingCash().available > 0 && paymentInfo() != null }
                            bodyBold("Submit")
                            onClick {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        header = "Collect Cash?",
                                        message = "This will cash out you're current available balance. After initiating payment it will take 3-5 business days to complete.",
                                        confirmationText = "Collect Cash",
                                        messageType = MessageType.Confirmation,
                                        onSubmit = {
                                            if (it) {
                                                notNullSession().nonCached.contractor
                                                    .requestCashOut(guaranteedContractor.await())
                                                delay(500)
                                                retrievePaymentPendingCash()
                                            }
                                        }
                                    )
                                )
                            }
                        }
                    }
                }
                space(AppDimensions.fullIndent)
            }
        }
    }
}