package com.crowpay

import com.lightningkite.serialization.*
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.builtins.NothingSerializer

fun prepareModelsShared() { SharedModelsObject }
object SharedModelsObject {
    init {
        SerializationRegistry.master.registerShared()
        com.crowpay.completionResponse.prepareCompletionResponseSerializableFields()
        com.crowpay.prepareChangeRequestFields()
        com.crowpay.prepareClientTermsAndConditionsAgreementFields()
        com.crowpay.prepareClientTermsAndConditionsVersionFields()
        com.crowpay.prepareClientFields()
        com.crowpay.prepareContractorFields()
        com.crowpay.prepareContractorDocumentFields()
        com.crowpay.prepareContractorNoteFields()
        com.crowpay.prepareContractorTermsAndConditionsAgreementFields()
        com.crowpay.prepareContractorTermsAndConditionsVersionFields()
        com.crowpay.prepareItemChangeFields()
        com.crowpay.prepareLicenseFields()
        com.crowpay.prepareLineItemFields()
        com.crowpay.preparePayApplicationFields()
        com.crowpay.preparePayAppItemFields()
        com.crowpay.preparePayoutFields()
        com.crowpay.preparePendingItemChangeFields()
        com.crowpay.preparePendingLineItemFields()
        com.crowpay.prepareProjectFields()
        com.crowpay.prepareProjectLedgerItemFields()
        com.crowpay.preparePunchListItemFields()
        com.crowpay.prepareScopeViewFields()
        com.crowpay.prepareTradeFields()
        com.crowpay.prepareUserFields()
        com.crowpay.prepareLineItemCompletionMessageFields()
        com.crowpay.prepareCompletionMessageCommentFields()
        com.crowpay.prepareProjectTimerFields()
        com.crowpay.prepareDisputeFields()
        com.crowpay.prepareIncidentFields()
        com.crowpay.prepareIncidentMessageFields()
        com.crowpay.prepareIncidentMessageCommentFields()
        com.crowpay.prepareProjectMessageFields()
        com.crowpay.preparePaymentMethodFields()
        com.crowpay.prepareAccessInfoFields()
        com.crowpay.prepareAddressFields()
        com.crowpay.prepareProjectAttachmentFields()
        com.crowpay.prepareClientFeedbackFields()
        com.crowpay.prepareCompletionRequestFields()
        com.crowpay.prepareLineItemDisputeFields()
        com.crowpay.prepareFiledForArbitrationFields()
        com.crowpay.prepareUserMembershipFields()
        com.crowpay.prepareChangeRequestInfoFields()
        com.crowpay.prepareIssueFields()
        com.crowpay.prepareCreditFields()
    }
}

@OptIn(ExperimentalSerializationApi::class)
fun SerializationRegistry.registerShared() {
    register(com.crowpay.completionResponse.LineItemConcern.serializer())
    register(com.crowpay.completionResponse.DisputeType.serializer())
    register(com.crowpay.completionResponse.CompletionResponseSerializable.serializer())
    register(com.crowpay.completionResponse.CompletionResponseType.serializer())
    register(com.crowpay.ChangeRequest.serializer())
    register(com.crowpay.ClientTermsAndConditionsAgreement.serializer())
    register(com.crowpay.ClientTermsAndConditionsVersion.serializer())
    register(com.crowpay.Client.serializer())
    register(com.crowpay.Contractor.serializer())
    register(com.crowpay.ContractorDocument.serializer())
    register(com.crowpay.ContractorNote.serializer())
    register(com.crowpay.ContractorTermsAndConditionsAgreement.serializer())
    register(com.crowpay.ContractorTermsAndConditionsVersion.serializer())
    register(com.crowpay.ItemChange.serializer())
    register(com.crowpay.License.serializer())
    register(com.crowpay.LineItem.serializer())
    register(com.crowpay.PayApplication.serializer())
    register(com.crowpay.PayAppItem.serializer())
    register(com.crowpay.Payout.serializer())
    register(com.crowpay.PendingItemChange.serializer())
    register(com.crowpay.PendingLineItem.serializer())
    register(com.crowpay.Project.serializer())
    register(com.crowpay.ProjectLedgerItem.serializer())
    register(com.crowpay.PunchListItem.serializer())
    register(com.crowpay.ScopeView.serializer())
    register(com.crowpay.Trade.serializer())
    register(com.crowpay.User.serializer())
    register(com.crowpay.LineItemCompletionMessage.serializer())
    register(com.crowpay.CompletionMessageComment.serializer())
    register(com.crowpay.ProjectTimer.serializer())
    register(com.crowpay.DisputeState.serializer())
    register(com.crowpay.Dispute.serializer())
    register(com.crowpay.Incident.serializer())
    register(com.crowpay.IncidentMessage.serializer())
    register(com.crowpay.IncidentMessageComment.serializer())
    register(com.crowpay.ProjectMessage.serializer())
    register(com.crowpay.MessageReply.serializer())
    register(com.crowpay.ChangeRequestMessageReference.serializer())
    register(com.crowpay.PayAppMessageReference.serializer())
    register(com.crowpay.LicenseType.serializer())
    register(com.crowpay.LineItemResolutionType.serializer())
    register(com.crowpay.LineItemState.serializer())
    register(com.crowpay.IncidentState.serializer())
    register(com.crowpay.PaymentState.serializer())
    register(com.crowpay.PreferredTitle.serializer())
    register(com.crowpay.ProjectState.serializer())
    register(com.crowpay.MemberRole.serializer())
    register(com.crowpay.UserRole.serializer())
    register(com.crowpay.ContractorStatus.serializer())
    register(com.crowpay.ChangeType.serializer())
    register(com.crowpay.State.serializer())
    register(com.crowpay.incidentResponse.ClientConcern.serializer())
    register(com.crowpay.incidentResponse.IncidentResponseTypeSerializable.serializer())
    register(com.crowpay.incidentResponse.IncidentResponseTypeType.serializer())
    register(com.crowpay.BankAccountType.serializer())
    register(com.crowpay.BankAccountHolderType.serializer())
    register(com.crowpay.Bank.serializer())
    register(com.crowpay.PaymentMethod.serializer())
    register(com.crowpay.MicroDepositVerification.serializer())
    register(com.crowpay.PendingAction.serializer())
    register(com.crowpay.PendingPaymentMethod.serializer())
    register(com.crowpay.PaymentRequest.serializer())
    register(com.crowpay.Payment.serializer())
    register(com.crowpay.PaymentStatus.serializer())
    register(com.crowpay.AccessInfo.serializer())
    register(com.crowpay.AccountInfo.serializer())
    register(com.crowpay.Address.serializer())
    register(com.crowpay.ProjectAttachment.serializer())
    register(com.crowpay.ClientFeedback.serializer())
    register(com.crowpay.ChangeDenyRequest.serializer())
    register(com.crowpay.CompletionRequest.serializer())
    register(com.crowpay.LineItemDispute.serializer())
    register(com.crowpay.FiledForArbitration.serializer())
    register(com.crowpay.FullProject.serializer())
    register(com.crowpay.PaymentSetupRequest.serializer())
    register(com.crowpay.PendingCashOut.serializer())
    register(com.crowpay.UserMembership.serializer())
    register(com.crowpay.RegisterUser.serializer())
    register(com.crowpay.RegisterContractor.serializer())
    register(com.crowpay.ChangeRequestInfo.serializer())
    register(com.crowpay.LineItemFromChangeRequest.serializer())
    register(com.crowpay.HashWithID.serializer())
    register(com.crowpay.Issue.serializer())
    register(com.crowpay.TimerAction.serializer())
    register(com.crowpay.Credit.serializer())
}
