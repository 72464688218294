package com.crowpay.views.screens.common

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.ApiOption
import com.crowpay.sdk.UserSession
import com.crowpay.sdk.notNullSession
import com.crowpay.sdk.selectedApi
import com.crowpay.utils.*
import com.crowpay.views.components.*
import com.crowpay.views.screens.contractor.ContractorScreen
import com.crowpay.views.screens.contractor.CreateProject
import com.crowpay.views.screens.contractor.EditProject
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.forms.FormModule
import com.lightningkite.kiteui.forms.form
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import kotlinx.coroutines.delay
import kotlin.collections.map

private suspend fun safeSession(): UserSession {
    if (selectedApi() !in ApiOption.SAFE)
        throw IllegalStateException("Cannot access api ${selectedApi.awaitOnce()}!")

    return notNullSession()
}

private fun ReactiveContext.safeSession(): UserSession {
    if (selectedApi() !in ApiOption.SAFE)
        throw IllegalStateException("Cannot access api ${selectedApi.once()}!")

    return notNullSession()
}

@Routable("dev-project/{projectId}")
class DevProjectView(val projectId: UUID) : Screen {
    val devProject = LazyProperty {
        safeSession().projects[projectId]
    }

    val devLens = LazyProperty {
        if (selectedContractor() != null) ProjectLens.Contractor else ProjectLens.Customer
    }

    @QueryParameter
    val tab = Property(ProjectView.WorkSection.Scope)

    val devLineItem = Property<AdjustedLineItem?>(null)

    // -- Dependents --
    val liveLineItems = shared {
        safeSession().lineItems
            .query(
                Query(
                    condition = condition<LineItem> { it.project eq projectId },
                    orderBy = sort { it.order.ascending() }
                ))
    }
    val lineItems = Property<List<AdjustedLineItem>>(emptyList())
    val contractorNotes = shared {
        safeSession().contractorNotes.query(
            Query(
                condition { it.project eq projectId },
                limit = Int.MAX_VALUE
            )
        )
    }
    val client = shared {
        devProject().awaitNotNull().client?.let { notNullSession().clients[it] }
    }
    val contractor = shared {
        notNullSession().contractors[devProject().awaitNotNull().contractor]
    }
    val changeOrders = shared {
        notNullSession().changeRequests
            .query(
                Query(
                    condition { it.project eq projectId },
                    limit = Int.MAX_VALUE
                )
            )
    }
    val disputes = shared {
        notNullSession().disputes
            .query(
                Query(
                    condition { it.project eq projectId },
                    limit = Int.MAX_VALUE
                )
            )
    }
    val punchLists = shared {
        notNullSession().punchLists.query(
            Query(
                condition { it.project eq projectId },
                limit = Int.MAX_VALUE
            )
        )
    }
    val scopes = shared {
        notNullSession().scopeViews
            .query(
                Query(
                    condition { it.project eq projectId },
                    limit = Int.MAX_VALUE
                )
            )
    }
    val pendingItemChanges = shared {
        notNullSession().pendingItemChange
            .query(
                Query(limit = Int.MAX_VALUE) { it.project eq projectId }
            )
    }
    val pendingLineItems = shared {
        notNullSession().pendingLineItems
            .query(
                Query(limit = Int.MAX_VALUE) { it.project eq projectId }
            )
    }
    val itemChanges = shared {
        notNullSession().itemChanges
            .query(
                Query(limit = Int.MAX_VALUE) { it.project eq projectId }
            )
    }
    val payApps = shared {
        notNullSession().payApplications.query(
            Query(
                condition { it.project eq projectId },
                orderBy = sort { it.number.ascending() },
                limit = Int.MAX_VALUE
            )
        )
    }
    val payAppItems = shared {
        notNullSession().payAppItems.query(
            Query(
                condition { it.project eq projectId },
                orderBy = sort {
                    it.amount.descending()
                    it._id.ascending()
                },
                limit = Int.MAX_VALUE
            )
        )
    }
    val lineItemCompletionMessages = shared {
        notNullSession().lineItemCompletionMessages.query(
            Query(
                limit = Int.MAX_VALUE,
                condition = condition { it.project eq projectId },
                orderBy = sort { it.created.ascending() }
            )
        )
    }
    val incidents = shared {
        notNullSession().incidents.query(
            Query(
                limit = Int.MAX_VALUE,
                condition = condition { it.project eq projectId },
                orderBy = sort { it.state.ascending() }
            )
        )
    }
    val incidentMessages = shared {
        notNullSession().incidentMessages.query(
            Query(
                limit = Int.MAX_VALUE,
                condition = condition { it.project eq projectId },
                orderBy = sort { it.created.ascending() }
            )
        )
    }


    private val view = shared {
        object : ProjectView(devLens()) {
            override val projectId: UUID = this@DevProjectView.projectId
            override val project: Readable<Project> = shared{ devProject().awaitNotNull()  }
            override val lineItems: Readable<List<AdjustedLineItem>> = this@DevProjectView.lineItems
            override val contractorNotes: Readable<List<ContractorNote>> = shared{ this@DevProjectView.contractorNotes()() }
            override val client: Readable<Client> = shared{ this@DevProjectView.client.awaitNotNull().awaitNotNull() }
            override val contractor: Readable<Contractor> = shared{ this@DevProjectView.contractor().awaitNotNull()  }
            override val punchListItems: Readable<List<PunchListItem>> = shared{ this@DevProjectView.punchLists()() }
            override val changeOrders: Readable<List<ChangeRequest>> = shared{ this@DevProjectView.changeOrders()() }
            override val disputes: Readable<List<Dispute>> = shared{ this@DevProjectView.disputes()() }
            override val scopes: Readable<List<ScopeView>> = shared{ this@DevProjectView.scopes()() }
            override val pendingItemChanges: Readable<List<PendingItemChange>> =
                shared{ this@DevProjectView.pendingItemChanges()() }
            override val pendingLineItems: Readable<List<PendingLineItem>> = shared{ this@DevProjectView.pendingLineItems()() }
            override val payApps: Readable<List<PayApplication>> = shared{ this@DevProjectView.payApps()() }
            override val payAppItems: Readable<List<PayAppItem>> = shared{ this@DevProjectView.payAppItems()() }
            override val lineItemCompletionMessages: Readable<List<LineItemCompletionMessage>> =
                shared{ this@DevProjectView.lineItemCompletionMessages()() }
            override val incidents: Readable<List<Incident>> = shared{ this@DevProjectView.incidents()() }
            override val incidentMessages: Readable<List<IncidentMessage>> = shared{ this@DevProjectView.incidentMessages()() }
        }
    }

    override fun ViewWriter.render() {
        row {
            reactiveScope {
                val changes = itemChanges()()
                lineItems.value = liveLineItems()().map { item ->
                    AdjustedLineItem(item, changes.filter { it.itemId == item._id })
                }
            }
            reactiveScope {
                val temp = devLineItem() ?: return@reactiveScope
                val lines = lineItems()
                lineItems.value = lines
                    .indexOfFirst { it._id == temp._id }
                    .let { index ->
                        val mutable = lines.toMutableList()
                        mutable.removeAt(index)
                        mutable.add(index, temp)
                        mutable
                    }
            }

            scrolls - row {
                col {
                    lazy(view) { it.run { renderMainContent() } }
                }
                space()
            }
            greySeparator()
            expanding - col {
                spacing = 0.px
                val showProjectOptions = Property(true)
                val showLineItemOptions = Property(true)

                row {
                    button {
                        spacing = 0.px
                        sizeConstraints(height = 4.rem) - row {
                            centered - expandIcon(showProjectOptions)
                            centered - title("Project Options")
                        }
                        onClick { showProjectOptions.toggle() }
                    }
                    button {
                        spacing = 0.25.rem
                        centered - icon(Icon.edit, "Edit project")
                        onClick {
                            dialogScreenNavigator.navigate(ProjectDevForm(devProject().notNull))
                        }
                    }
                }
                onlyWhen { showProjectOptions() } - padded - col {
                    primaryButton - button {
                        centered - row {
                            centered - icon(Icon.sync, "Refresh Project State")
                            centered - body("Refresh Project State")
                        }
                        onClick {
                            val session = notNullSession()
                            session.nonCached.project.forceRefreshProjectState(projectId)
                            delay(500)
                            session.projects[projectId].invalidate()
                        }
                    }
                    primaryButton - button {
                        centered - row {
                            centered - icon(Icon.sync, "Refresh")
                            centered - body("Refresh Timers")
                        }
                        onClick {
                            notNullSession().nonCached.projectTimer.refreshTimers()
                        }
                    }
                    label2("Lens") {
                        fieldTheme - select {
                            bind(devLens, Constant(ProjectLens.entries)) { it.name }
                        }
                    }
                    label2("Project State") {
                        fieldTheme - select {
                            bind(
                                edits = devProject.flatLens(get = { it?.state ?: ProjectState.Creating }, modify = { p, value -> p?.copy(state = value) }),
                                data = Constant(ProjectState.entries),
                                render = { it.displayName }
                            )
                        }
                    }
                }

                space(AppDimensions.majorColumnSpacing)

                button {
                    spacing = 0.px
                    sizeConstraints(height = 4.rem) - row {
                        centered - expandIcon(showLineItemOptions)
                        centered - title("Work Scope Options")
                        centered - icon(Icon.edit, "Edit Work Scope Options")
                    }
                    onClick { showLineItemOptions.toggle() }
                }
                onlyWhen { showLineItemOptions() } - padded - col {
                    label2("Work Scope") {
                        fieldTheme - select {
                            bind(devLineItem, shared { listOf(null) + lineItems() }) { it?.name ?: "Select Line Item" }
                        }
                    }
                    label2("Work Scope State") {
                        fieldTheme - select {
                            bind(
                                edits = devLineItem.lens(
                                    get = { it?.state ?: LineItemState.NotApproved },
                                    modify = { og, it -> og?.copy(state = it) }
                                ),
                                data = Constant(LineItemState.entries),
                                render = { it.displayName }
                            )
                        }
                    }
                    primaryButton - button {
                        specCenteredText("Refresh State")
                        onClick {
                            val line = devLineItem()?._id ?: return@onClick
                            val session = notNullSession()
                            session.nonCached.lineItem.forceRefreshLineItemState(line)
                            delay(500)
                            session.lineItems[line].invalidate()
                        }
                    }
                }

            }
        }
    }

    private class ProjectDevForm(val project: Writable<Project>) : Screen {
        override fun ViewWriter.render() {
            dismissBackground {
                spacing = AppDimensions.fullIndent
                centered - scrolls - dialog - stack {
                    spacing = AppDimensions.fullIndent
                    val module = FormModule().apply {
                        visibilitySettings.clear()
                        typeInfo = { null }
                    }
                    sizeConstraints(width = AppDimensions.pageWidth) - form(module, Project.serializer(), project)
                }
            }
        }
    }
}

interface DevProjectScreen

@Routable("select-dev-project")
class SelectDevProjectScreen : ContractorScreen, DevProjectScreen {
//    val lens = Property(ProjectLens.Contractor)

    private val _projects = shared {
        val session = safeSession()
        val self = session.self()
        val membership = self.membership

        if (membership == null) Constant(emptyList()) else
            session.projects.query(
                Query(
                    condition {
                        (it.client eq self._id) or (it.contractor eq membership.contractor)
                    },
                    sort { it.name.ascending() }
                )
            )
    }
    val projects = shared {
        _projects()()
    }

    val _contractors = shared {
        val session = safeSession()
        val ps = projects()
        session.contractors.query(
            Query(condition { it._id inside ps.map { it.contractor }.toSet() })
        )
    }
    val _clients = shared {
        val session = safeSession()
        val ps = projects()
        session.clients.query(
            Query(condition { it._id inside ps.mapNotNull { it.client }.toSet() })
        )
    }

    val contacts: Readable<List<Contact>> = shared {
        _contractors()() + _clients()()
    }

    override fun ViewWriter.renderMainContent() {
        col {
            spacing = 0.dp
            title("Dev Projects")

            space(AppDimensions.majorColumnSpacing)

            row {
                spacing = 0.dp
                space(AppDimensions.fullIndent - AppDimensions.buttonSpacing)
                primaryButton - button {
                    existsDefaultFalse { selectedContractor() != null }
                    specCenteredText("+ Project to current contractor")
                    onClick {
                        screenNavigator.navigate(CreateProject())
                    }
                }
            }

            space(AppDimensions.majorColumnSpacing)

            centered - subTitle {
                existsDefaultFalse { projects().isEmpty() }
                content = "You have no projects"
            }

            expanding - recyclerView {
                children(projects) { project ->
                    val contact = shared {
                        val p = project()
                        contacts().find {
                            when (it) {
                                is Client -> {
                                    it._id == p.client
                                }

                                is Contractor -> {
                                    it._id == p.contractor
                                }

                                else -> false
                            }
                        }
                    }
                    sizeConstraints(width = AppDimensions.pageWidth) - row {
                        spacing = 0.dp
                        space(AppDimensions.backgroundIndent)

                        outlined - expanding - button {
                            spacing = AppDimensions.sectionIndent
                            onClick {
                                val p = project()
                                val to =
                                    if (p.state == ProjectState.Creating) EditProject(p._id)
                                    else DevProjectView(p._id)

                                screenNavigator.navigate(to)
                            }
                            col {
                                row {
                                    centered - subTitle {
                                        ::content { project().name }
                                    }

                                    greySeparator()

                                    expanding -
                                            row {
                                                ::visible{ contact() != null }
                                                centered - image {
                                                    ::exists{ contact()?.image != null }
                                                    ::source{ ImageRemote(contact()?.image?.location ?: "") }
                                                } in sizeConstraints(width = 3.rem, height = 3.rem)

                                                centered - contactInfo(contact)
                                            }

                                    centered - icon(Icon.chevronRight, "Open")
                                }

                                greySeparator()

                                row {
                                    expanding - col {
                                        col {
                                            exists = false
                                            ::exists{ project().fundingNeeded > 0 }
                                            keyValue("Funding Needed") { project().fundingNeeded.renderDollars() }
                                        }
                                        keyValue("Total Price") { project().activePrice.renderDollars() }
                                        keyValue("Contractor Payments") { project().contractorPayments.renderDollars() }
                                        keyValue("Escrow Balance") { project().balance.renderDollars() }
                                    }

                                    body {
                                        ::content{ project().state.displayName }
                                        dynamicTheme {
                                            ProjectStateSemantic(project().state)
                                        }
                                    }
                                }
                            }
                        }
                        space(AppDimensions.backgroundIndent)
                    }
                }
            }
        }
    }
}
