package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.contains
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.dialog
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.MassModification
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.inside
import com.lightningkite.lightningdb.modification
import com.lightningkite.now
import kotlinx.coroutines.delay

class StartWorkItemsDialog(val project: Project, val items: List<LineItem>): Screen {
    val selected = Property(items)

    override fun ViewWriter.render() {
        dismissBackground {
            centered - dialog - stack {
                spacing = AppDimensions.fullIndent
                col {
                    title("Start Work Items?")
//                    body("Insert starting work item text here")

                    lightSection - sizeConstraints(minHeight = 10.rem, maxHeight = 50.rem, width = 35.rem) - stack {
                        spacing = AppDimensions.sectionIndent

                        scrolls - col {
                            spacing = 0.35.rem
                            for (item in items) {
                                row {
                                    spacing = 0.5.rem
                                    centered - checkbox {
                                        checked bind selected.contains(item)
                                    }
                                    centered - body(item.name)
                                }
                            }
                        }
                    }

                    row {
                        primaryButton - button {
                            specCenteredText("Start Work")
                            onClick {
                                val session = notNullSession()

                                session.lineItems.bulkModify(
                                    MassModification(
                                        condition { item -> item._id inside selected.value.map { it._id } },
                                        modification { it.started assign now() }
                                    )
                                )
//                                session.nonCached.project.forceRefreshProjectState(project._id)
//                                delay(500)
                                session.projects.totallyInvalidate()
                                dialogScreenNavigator.dismiss()
                            }
                        }
                        textButton - button {
                            specCenteredText("Go Back")
                            onClick {
                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    }
}