// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareLineItemDisputeFields() {
    val props: Array<SerializableProperty<LineItemDispute, *>> = arrayOf(LineItemDispute_itemId, LineItemDispute_sourceMessage, LineItemDispute_lineItemOnly)
    LineItemDispute.serializer().properties { props }
}
val <K> DataClassPath<K, LineItemDispute>.itemId: DataClassPath<K, UUID> get() = this[LineItemDispute_itemId]
val <K> DataClassPath<K, LineItemDispute>.sourceMessage: DataClassPath<K, UUID> get() = this[LineItemDispute_sourceMessage]
val <K> DataClassPath<K, LineItemDispute>.lineItemOnly: DataClassPath<K, Boolean> get() = this[LineItemDispute_lineItemOnly]
inline val LineItemDispute.Companion.path: DataClassPath<LineItemDispute, LineItemDispute> get() = path<LineItemDispute>()


object LineItemDispute_itemId: SerializableProperty<LineItemDispute, UUID> {
    override val name: String by lazy { LineItemDispute.serializer().descriptor.getElementName(0) }
    override fun get(receiver: LineItemDispute): UUID = receiver.itemId
    override fun setCopy(receiver: LineItemDispute, value: UUID) = receiver.copy(itemId = value)
    override val serializer: KSerializer<UUID> by lazy { (LineItemDispute.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { LineItemDispute.serializer().descriptor.getElementAnnotations(0) }
}
object LineItemDispute_sourceMessage: SerializableProperty<LineItemDispute, UUID> {
    override val name: String by lazy { LineItemDispute.serializer().descriptor.getElementName(1) }
    override fun get(receiver: LineItemDispute): UUID = receiver.sourceMessage
    override fun setCopy(receiver: LineItemDispute, value: UUID) = receiver.copy(sourceMessage = value)
    override val serializer: KSerializer<UUID> by lazy { (LineItemDispute.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { LineItemDispute.serializer().descriptor.getElementAnnotations(1) }
}
object LineItemDispute_lineItemOnly: SerializableProperty<LineItemDispute, Boolean> {
    override val name: String by lazy { LineItemDispute.serializer().descriptor.getElementName(2) }
    override fun get(receiver: LineItemDispute): Boolean = receiver.lineItemOnly
    override fun setCopy(receiver: LineItemDispute, value: Boolean) = receiver.copy(lineItemOnly = value)
    override val serializer: KSerializer<Boolean> by lazy { (LineItemDispute.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { LineItemDispute.serializer().descriptor.getElementAnnotations(2) }
}
