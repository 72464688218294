package com.crowpay.views.components.project.projectInfo

import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.lazy
import com.crowpay.utils.renderDollars
import com.crowpay.views.components.space
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.AppState
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding

fun ProjectView.ledger(writer: ViewWriter) = with(writer) {
    col {
        spacing = 0.5.rem
        space()

        fun ViewWriter.tableItem(
            key: ReactiveContext.() -> String,
            description: (ReactiveContext.() -> String)? = null,
            value: ReactiveContext.() -> String,
        ) {
            col {
                spacing = 0.px
                row {
                    expanding - body { ::content { key() } }
                    body { ::content { value() } }
                }
                greySeparator()

                if (description != null) {
                    sizeConstraints(height = 1.9.rem) - smallBody { ::content { "(${description()})" } }
                } else space(1.5.rem)
            }
        }

        fun ViewWriter.tableItem(
            key: String,
            description: String? = null,
            value: ReactiveContext.() -> String,
        ) = tableItem({ key }, description?.let { { it } }, value)

        fun ViewWriter.costBreakdown() {
            col {
                spacing = 0.dp
                tableItem("Original Project Price") { project().originalPrice.renderDollars() }
                tableItem("Accepted Change Amount") { project().acceptedChangeAmount.renderDollars() }
                tableItem("Credits") { project().totalCredits.renderDollars() }
                tableItem("Active Price") { project().activePrice.renderDollars() }
                tableItem(
                    key = { "Retention: ${project().retention?.times(100) ?: 0}%" },
                    description = { "Hold % to be collected at completion. Extra client security." },
                    value = {
                        val p = project()
                        p.retention?.let {
                            val r = it * p.activePrice
                            r.toLong().renderDollars()
                        } ?: "N/A"
                    }
                )
            }
        }

        fun ViewWriter.fundsSummary() {
            col {
                spacing = 0.dp

                tableItem("Escrow Deposits") {
                    project().run { clientDeposits }.renderDollars()
                }
                tableItem(
                    key = { "Contractor Received" },
                    description = { "Payments to the contractor for completed Work Items" },
                    value = { project().contractorPayments.renderDollars() }
                )
                tableItem("Escrow Balance") { project().balance.renderDollars() }
                tableItem("Percent Complete") {
                    val p = project()
                    (p.activePrice
                        .takeIf { it != 0L }
                        ?.let { p.contractorPayments.toDouble() / it.toDouble() }
                        ?.times(100)
                        ?.toLong()
                        ?.toString() ?: "0") + "%"
                }
            }
        }

        col {
            lazy(AppState.windowInfo) { winfo ->
                // Wide
                if (winfo.width > AppDimensions.wideCollapseWidth) {
                    row {
                        spacing = 2.rem
                        space(0.1)
                        expanding - bodyBold("Cost Breakdown")
                        expanding - bodyBold("Funds Summary")
                    }
                    lightSection - row {
                        spacing = 2.rem
                        expanding - costBreakdown()
                        space(0.5)
                        expanding - fundsSummary()
                    }
                }
                // Narrow
                else {
                    bodyBold("Cost Breakdown")
                    lightSection - stack { costBreakdown() }
                    space()
                    bodyBold("Funds Summary")
                    lightSection - stack { fundsSummary() }
                }
            }
        }
    }
}