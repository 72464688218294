// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun preparePunchListItemFields() {
    val props: Array<SerializableProperty<PunchListItem, *>> = arrayOf(PunchListItem__id, PunchListItem_project, PunchListItem_lineItem, PunchListItem_order, PunchListItem_content, PunchListItem_required, PunchListItem_complete, PunchListItem_isMinorAdjustment)
    PunchListItem.serializer().properties { props }
}
val <K> DataClassPath<K, PunchListItem>._id: DataClassPath<K, UUID> get() = this[PunchListItem__id]
val <K> DataClassPath<K, PunchListItem>.project: DataClassPath<K, UUID> get() = this[PunchListItem_project]
val <K> DataClassPath<K, PunchListItem>.lineItem: DataClassPath<K, UUID?> get() = this[PunchListItem_lineItem]
val <K> DataClassPath<K, PunchListItem>.order: DataClassPath<K, Double> get() = this[PunchListItem_order]
val <K> DataClassPath<K, PunchListItem>.content: DataClassPath<K, String> get() = this[PunchListItem_content]
val <K> DataClassPath<K, PunchListItem>.required: DataClassPath<K, Boolean> get() = this[PunchListItem_required]
val <K> DataClassPath<K, PunchListItem>.complete: DataClassPath<K, AccessInfo?> get() = this[PunchListItem_complete]
val <K> DataClassPath<K, PunchListItem>.isMinorAdjustment: DataClassPath<K, String?> get() = this[PunchListItem_isMinorAdjustment]
inline val PunchListItem.Companion.path: DataClassPath<PunchListItem, PunchListItem> get() = path<PunchListItem>()


object PunchListItem__id: SerializableProperty<PunchListItem, UUID> {
    override val name: String by lazy { PunchListItem.serializer().descriptor.getElementName(0) }
    override fun get(receiver: PunchListItem): UUID = receiver._id
    override fun setCopy(receiver: PunchListItem, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (PunchListItem.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PunchListItem.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object PunchListItem_project: SerializableProperty<PunchListItem, UUID> {
    override val name: String by lazy { PunchListItem.serializer().descriptor.getElementName(1) }
    override fun get(receiver: PunchListItem): UUID = receiver.project
    override fun setCopy(receiver: PunchListItem, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (PunchListItem.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PunchListItem.serializer().descriptor.getElementAnnotations(1) }
}
object PunchListItem_lineItem: SerializableProperty<PunchListItem, UUID?> {
    override val name: String by lazy { PunchListItem.serializer().descriptor.getElementName(2) }
    override fun get(receiver: PunchListItem): UUID? = receiver.lineItem
    override fun setCopy(receiver: PunchListItem, value: UUID?) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<UUID?> by lazy { (PunchListItem.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { PunchListItem.serializer().descriptor.getElementAnnotations(2) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object PunchListItem_order: SerializableProperty<PunchListItem, Double> {
    override val name: String by lazy { PunchListItem.serializer().descriptor.getElementName(3) }
    override fun get(receiver: PunchListItem): Double = receiver.order
    override fun setCopy(receiver: PunchListItem, value: Double) = receiver.copy(order = value)
    override val serializer: KSerializer<Double> by lazy { (PunchListItem.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Double> }
    override val annotations: List<Annotation> by lazy { PunchListItem.serializer().descriptor.getElementAnnotations(3) }
    override val default: Double = 0.5
    override val defaultCode: String? = "0.5" 
}
object PunchListItem_content: SerializableProperty<PunchListItem, String> {
    override val name: String by lazy { PunchListItem.serializer().descriptor.getElementName(4) }
    override fun get(receiver: PunchListItem): String = receiver.content
    override fun setCopy(receiver: PunchListItem, value: String) = receiver.copy(content = value)
    override val serializer: KSerializer<String> by lazy { (PunchListItem.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { PunchListItem.serializer().descriptor.getElementAnnotations(4) }
}
object PunchListItem_required: SerializableProperty<PunchListItem, Boolean> {
    override val name: String by lazy { PunchListItem.serializer().descriptor.getElementName(5) }
    override fun get(receiver: PunchListItem): Boolean = receiver.required
    override fun setCopy(receiver: PunchListItem, value: Boolean) = receiver.copy(required = value)
    override val serializer: KSerializer<Boolean> by lazy { (PunchListItem.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { PunchListItem.serializer().descriptor.getElementAnnotations(5) }
}
object PunchListItem_complete: SerializableProperty<PunchListItem, AccessInfo?> {
    override val name: String by lazy { PunchListItem.serializer().descriptor.getElementName(6) }
    override fun get(receiver: PunchListItem): AccessInfo? = receiver.complete
    override fun setCopy(receiver: PunchListItem, value: AccessInfo?) = receiver.copy(complete = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (PunchListItem.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { PunchListItem.serializer().descriptor.getElementAnnotations(6) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object PunchListItem_isMinorAdjustment: SerializableProperty<PunchListItem, String?> {
    override val name: String by lazy { PunchListItem.serializer().descriptor.getElementName(7) }
    override fun get(receiver: PunchListItem): String? = receiver.isMinorAdjustment
    override fun setCopy(receiver: PunchListItem, value: String?) = receiver.copy(isMinorAdjustment = value)
    override val serializer: KSerializer<String?> by lazy { (PunchListItem.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { PunchListItem.serializer().descriptor.getElementAnnotations(7) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
