package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.uuid
import kotlin.math.abs

object SharedUtils {
    val emailPattern = Regex("^[a-zA-Z0-9-+._]+@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z0-9-]+[a-zA-Z0-9-]$")
    val phonePattern =
        Regex("""(?:\+\d+ )?\(\d{3}\) \d{3}-\d{4}|(?:\+\d+)?\d{10}|(?:\+\d+-)?\d{3}-\d{3}-\d{4}|(?:\+\d+ )?\d{3} \d{3} \d{4}""")

    val shrug = """¯\_(ツ)_/¯"""
    val shrugImage = "https://1000logos.net/wp-content/uploads/2023/10/Shrug-Kaomoji.png"
    val mockPayment = "Mock Payment"
    val mock = "mock"
    val nullUUID = UUID.parse("00000000-0000-0000-0000-000000000000")
    const val unknownError = "An unknown error occurred."

    fun isRoutingNumberValid(value: String): Boolean {
        if (value.any { !it.isDigit() } || value.length != 9) return false
        var check = 0
        for (index in value.indices step 3) {
            check += value[index].digitToInt() * 3
            check += value[index + 1].digitToInt() * 7
            check += value[index + 2].digitToInt()
        }
        return check != 0 && check % 10 == 0;
    }

    fun formatDollars(long: Long) = "${if (long < 0) "-" else ""}$${
        abs(long).toString().reversed().chunked(3).reversed().map { it.reversed() }.joinToString(",").ifBlank { "0" }
    }"
}