package com.crowpay.views.components.project

import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.radioToggleButton
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.separator

fun <T> ViewWriter.tabSectionHeader(sectionName: String, tab: ProjectView.PinnableTab<T>): ViewModifiable {
    return colored(AppColors.primary.main) - col {
        spacing = 0.5.rem
        row {
            expanding - centered - title(sectionName)
//            centered - buttonTheme - radioToggleButton {
//                body {
//                    ::content {
//                        if (tab.selectedIsPinned()) "Pinned"
//                        else "Pin Tab"
//                    }
//                }
//                checked bind tab.selectedIsPinned
//            }
        }
        separator()
    }
}