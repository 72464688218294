package com.crowpay.extensions

import com.crowpay.*
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi
import org.kotlincrypto.hash.sha2.SHA256


@OptIn(ExperimentalEncodingApi::class)
fun Project.hash(lineItems: Set<LineItem>, itemChanges: Set<ItemChange> = emptySet(), punchItems: Set<PunchListItem> = emptySet()): String {

    val digest = SHA256()

    digest hash _id
    digest hash contractor
    digest hash customer
    digest hash client
    digest hash name
    digest hash description
    files.forEach { attachment: Attachment ->
        digest hash attachment
    }
    digest hash retention
    digest hash cancelled
    digest hash terminated
//    digest hash allowPreview
    digest hash published
    digest hash feedback?.message
    digest hash feedback?.at
    digest hash approved
    digest hash fullFundingRequested
    digest hash complete?.contractor
    digest hash complete?.customer
    digest hash created

    digest hash state.name
    digest hash originalPrice
    digest hash activePrice
    digest hash fundingNeeded
    digest hash clientDeposits
    digest hash pendingClientDeposits
    digest hash contractorPayments
    digest hash funded
    digest hash started
    digest hash substantialCompletion

    // Sorting by a UUID is different in the jvm vs javascript.
    // This requires the string comparison work around.
    lineItems.sortedBy { it._id.toString() }.forEach { item ->
        digest hash item
    }
    // Sorting by a UUID is different in the jvm vs javascript.
    // This requires the string comparison work around.
    itemChanges.sortedBy { it._id.toString() }.forEach { item ->
        digest hash item
    }

    // Sorting by a UUID is different in the jvm vs javascript.
    // This requires the string comparison work around.
    punchItems.sortedBy { it._id.toString() }.forEach { item ->
        digest hash item
    }

    return Base64.encode(digest.digest())
}
