package com.crowpay.views.components

import com.crowpay.views.theming.AppColors
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.views.canvas.DrawingContext2D
import com.lightningkite.kiteui.views.canvas.fillPaint
import com.lightningkite.kiteui.views.canvas.height
import com.lightningkite.kiteui.views.canvas.width
import com.lightningkite.kiteui.views.direct.CanvasDelegate

class ProgressDelegate(val color: Readable<Color>, val progress: Readable<Float>): CanvasDelegate() {

    constructor(color: Color, progress: Float): this(Constant(color), Constant(progress))

    override fun draw(context: DrawingContext2D) {
        context.fillPaint = AppColors.grey.light2
        context.fillRect(0.0, 0.0, context.width, context.height)

        val cs = color.state
        val ps = progress.state
        if (cs.ready && ps.ready) {
            val c = cs.get()
            val p = ps.get()
            context.fillPaint = c
            context.fillRect(
                x = 0.0,
                y = 0.0,
                w = minOf(
                    context.width,
                    context.width * p
                ),
                h = context.height
            )
        }
    }

    override fun sizeThatFitsWidth(width: Double, height: Double): Double {
        return 0.0
    }
    override fun sizeThatFitsHeight(width: Double, height: Double): Double {
        return 20.0
    }

}