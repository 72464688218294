package com.crowpay.views.screens.common

import com.crowpay.ContractorStatus
import com.crowpay.ProjectState
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.selectedContractor
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.*
import com.crowpay.views.screens.client.ClientPayments
import com.crowpay.views.screens.contractor.ContractorPayments
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

fun ViewWriter.generalToDos() {
    val pullMethods = BasicListenable()
    val customerNeedsPaymentMethods = sharedSuspending {
        rerunOn(pullMethods)
        println("Retrieving customer methods")
        if (selectedContractor() != null) return@sharedSuspending false
        notNullSession().nonCached.userAuth.getPaymentMethods().also { println("Got ${it.size} methods") }.isEmpty()
    }
    val contractorNeedsFinanceSetup = sharedSuspending {
        rerunOn(pullMethods)
        println("Retrieving contractor methods")
        val contractorID = selectedContractor()
        if (contractorID == null) return@sharedSuspending false
        notNullSession().nonCached.contractor.getPaymentInfo(contractorID) == null
    }
    val approved = sharedSuspending {
        rerunOn(pullMethods)
        println("Checking approval")
        val self = notNullSession().nonCached.userAuth.getSelf()
        val activeMembership = listOfNotNull(self.membership).map { notNullSession().contractors[it.contractor] }
        if (activeMembership.isEmpty()) true else activeMembership.any { it()?.status == ContractorStatus.Approved }
    }

    val todo = shared {
        var n = 0
        if (customerNeedsPaymentMethods()) n += 1
        if (contractorNeedsFinanceSetup()) n += 1
        n
    }

    col {
        spacing = 0.dp
        exists = false
        ::exists { todo() > 0 }

        title {
            ::content{ "To-Do (${todo()})" }
        }

        space(AppDimensions.sectionIndent)

        row {
            spacing = 0.dp

            space(AppDimensions.backgroundIndent)
            expanding - col {

                toDoSection(Color.fromHexString(ProjectState.AwaitingFunding.displayColor)) - stack {
                    existsDefaultFalse { customerNeedsPaymentMethods() }
                    spacing = AppDimensions.sectionIndent
                    col {
                        notificationBar(AppColors.yellow) - body("You will need to connect your bank account to pass certain points of projects, you may connect them now to avoid this later.")

                        subTitle("Connect Bank Account")
                        gravity(Align.Start, Align.Start) - primaryButton - button {
                            specCenteredText("Connect Bank Account")
                            onClick {
                                dialogScreenNavigator.navigate(SetupMethodDialog {
                                    println("Inside SetupMethodDialog onComplete")
                                    pullMethods.invokeAll()
                                })
                            }
                        }
                    }
                }

                toDoSection(Color.fromHexString(ProjectState.AwaitingFunding.displayColor)) - stack {
                    existsDefaultFalse { contractorNeedsFinanceSetup() }
                    spacing = AppDimensions.sectionIndent
                    col {
                        notificationBar(AppColors.yellow) - body("You will need to connect your bank account to pass certain points of projects, you may connect them now to avoid this later.")

                        subTitle("Setup Contractor Finances")
                        gravity(Align.Start, Align.Start) - primaryButton - button {
                            specCenteredText("Setup Finances")
                            onClick {
                                // only contractors should see this button but I added this check anyway just in case?
                                if (selectedContractor.awaitOnce() != null) {
                                    screenNavigator.reset(ContractorPayments())
                                } else {
                                    screenNavigator.reset(ClientPayments())
                                }
                            }
                        }
                    }
                }
            }
        }
        space(AppDimensions.backgroundIndent)
    }
}