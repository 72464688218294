package com.crowpay.incidentResponse

import com.crowpay.AccessInfo
import com.crowpay.HasDisplayName
import com.crowpay.IncidentState
import com.crowpay.SharedUtils
import kotlinx.serialization.Serializable


@Serializable
enum class ClientConcern(val displayName: String) {
    NoStart("No Start"),
    Safety("Safety"),
    CrewBehavior("Crew Behavior"),
    SiteDamage("Site Damage"),
    Communication("Communication"),
    Other("Other")
}


// When client files an issue
//  - If no issue raised then that's the end of the flow. Logs the incident in a formal way, but requires no response
//  - If issue raised then response is required or can escalate to dispute in 7 days

sealed interface IncidentResponseType: HasDisplayName {
    val shortDisplayName: String get() = displayName
    fun resultantState(baseState: IncidentState): IncidentState

    data class FileIncident(
        val concern: ClientConcern,
        val raiseIssue: Boolean = false
    ) : IncidentResponseType {
        override val displayName: String = concern.displayName
        override fun resultantState(baseState: IncidentState): IncidentState =
            if (raiseIssue) IncidentState.Issue
            else IncidentState.Filed
    }

    data class ClientResponse(
        val fileDispute: Boolean = false,
        val proposingResolution: Boolean = false,
    ) : IncidentResponseType {
        override val displayName: String = ""
        override fun resultantState(baseState: IncidentState): IncidentState = when {
            fileDispute -> IncidentState.Disputed
            proposingResolution -> IncidentState.Resolving
            else -> baseState
        }
    }

    data object ClientResolution : IncidentResponseType {
        override val displayName: String = "Resolution"
        override fun resultantState(baseState: IncidentState): IncidentState = IncidentState.Resolving
    }

    sealed interface NeedsApproval : IncidentResponseType

    data class Credit(val credit: Long) : NeedsApproval {
        override val displayName: String = "Credit (${SharedUtils.formatDollars(credit)})"
        override val shortDisplayName: String = "Credit"
        override fun resultantState(baseState: IncidentState): IncidentState = IncidentState.Resolving
    }

    data class Remedy(val credit: Long?) : NeedsApproval {
        override val displayName: String = "Remedy" + if (credit != null) " (${SharedUtils.formatDollars(credit)})" else ""
        override val shortDisplayName: String = "Remedy"
        override fun resultantState(baseState: IncidentState): IncidentState = IncidentState.Resolving

        data class RemedyHandled(val remedy: Remedy) : NeedsApproval {
            override val displayName: String = "Remedy Complete"
            override fun resultantState(baseState: IncidentState): IncidentState = IncidentState.RequestingReview
        }
    }

    data object Handled : NeedsApproval {
        override val displayName: String = "Handled"
        override fun resultantState(baseState: IncidentState): IncidentState = IncidentState.RequestingReview
    }

    data object MoreInfo : IncidentResponseType {
        override val displayName: String = "More Info"
        override fun resultantState(baseState: IncidentState): IncidentState = baseState
    }

    data class Accept(val proposal: NeedsApproval, val accessInfo: AccessInfo) : IncidentResponseType {
        override val displayName: String = "Accept"
        override fun resultantState(baseState: IncidentState): IncidentState = when(proposal) {
            Handled -> IncidentState.Resolved
            is Credit -> IncidentState.Resolved
            is Remedy -> IncidentState.WorkingOnResolution
            is Remedy.RemedyHandled -> IncidentState.Resolved
        }
    }
}

val IncidentResponseType.isClientResponse: Boolean get() =
    this is IncidentResponseType.FileIncident ||
            this is IncidentResponseType.ClientResponse ||
            this is IncidentResponseType.Accept ||
            this == IncidentResponseType.ClientResolution

val IncidentResponseType.isContractorResponse: Boolean get() = !isClientResponse