package com.crowpay.views.components.project.projectInfo

import com.crowpay.actuals.AppDimensions
import com.crowpay.actuals.rotation
import com.crowpay.isContractorEditable
import com.crowpay.newNotesAllowed
import com.crowpay.utils.*
import com.crowpay.views.components.expandButton
import com.crowpay.views.components.label2
import com.crowpay.views.components.files.renderFiles
import com.crowpay.views.components.project.renderContractorNotes
import com.crowpay.views.components.sectionIndentCol
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.CreateOrEditNote
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon

fun ProjectView.projectDetails(writer: ViewWriter) = with(writer) {
    val showNotes = Property(false)
    val descriptionHistory = changeOrders.shareFilter { it.projectDescription != null }
    col {
        spacing = 0.px
        if (lens == ProjectLens.Contractor) atEnd - tertiaryButton - button {
            ::exists { project().state.isContractorEditable() }
            spacing = 0.3.rem
            specCenteredText("+ Contractor Note")
            onClick {
                showNotes.value = true
                dialogScreenNavigator.navigate(
                    CreateOrEditNote(
                        project = project()._id,
                        null,
                        "Add notes, pictures, or files that are for INTERNAL team only.  (ie. client or property considerations for managers and crews.)"
                    )
                )
            }
        }

        col {
            spacing = 1.5.rem
            label2("Description") {
                body {
                    ::content{ project().description }
                }
            }

            col {
                spacing = AppDimensions.cornerRadii
                existsDefaultFalse { descriptionHistory().isNotEmpty() }
                val expanded = Property(false)

                buttonTheme - button {
                    row {
                        space(AppDimensions.fullIndent - (AppDimensions.buttonSpacing * 2))
                        bold - centered - body("Change History")
                        centered - icon(Icon.chevronRight, "Open/Close Change History") {
                            ::rotation{
                                if (expanded())
                                    (-90f).degrees
                                else
                                    90f.degrees
                            }
                        }
                        onClick {
                            expanded.value = !expanded.value
                        }
                    }
                }

                greySeparator()

                onlyWhen { expanded() } - padded - stack {
                    spacing = AppDimensions.fullIndent
                    col {
                        forEach(descriptionHistory) { historical ->
                            val description = historical.projectDescription ?: return@forEach
                            val time = historical.published ?: return@forEach
                            body(
                                "${time.format(Formats.mmddyyyy)}: $description"
                            )
                            greySeparator()
                        }
                    }
                }

                space(0.dp)
            }

            if (lens == ProjectLens.Contractor) col {
                expandButton(showNotes) { bodyBold("Contractor Notes (Visible only to you)") }
                onlyWhen { showNotes() } - sectionIndentCol {
                    renderContractorNotes(
                        contractorNotes.shareFilter { it.lineItem == null },
                        groupName = { project().name },
                        lens = lens
                    )
                }
            }

            label2("Project Files (Images, Bids, Plans, Specs)") {
                renderFiles(shared { project().files }, AppDimensions.largeImagePreview)
            }
        }
    }
}
