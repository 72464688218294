package com.crowpay.views.components.project.work.payApps

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.creationError
import com.crowpay.sdk.notFoundError
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.utils.tables.ColumnWeights
import com.crowpay.utils.tables.trackWeights
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.components.infoBubble
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.screens.contractor.ContractorScreen
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlin.math.roundToLong

suspend fun CreatePayApp(project: UUID): PayAppForm {
    val session = notNullSession()
    val numPayApps = session.payApplications.query(
        Query { it.project eq project }
    )().size

    val new = session.payApplications.insert(
        PayApplication(
            project = project,
            number = numPayApps+1
        )
    )() ?: creationError<PayApplication>()

    return PayAppForm(new._id)
}

//private object TableWeights {
//    val columnSpacing = 0.5.rem
//
//    const val workItem = 2f
//    const val approvedAmount = 1.5f
//    const val pastPayments = 1f
//    const val thisDraw = 1f
//    const val totalCompleted = 1f
//    const val percentCompleted = 1f
//    const val remaining = 1f
//    const val retainage = 1f
//}

private fun LineItemState.drawable(): Boolean = this in setOf(LineItemState.InProgress, LineItemState.NotApproved)

@Routable("draws/{payAppID}/edit")
class PayAppForm(val payAppID: UUID) : ContractorScreen, Validator() {
    val payApp = Draft {
        notNullSession().payApplications[payAppID]() ?: notFoundError<PayApplication>()
    }
    val project = shared {
        notNullSession().projects[payApp().project]() ?: notFoundError<Project>()
    }

    private val _lineItems = shared {
        notNullSession().lineItems.query(
            Query(
                limit = Int.MAX_VALUE,
                condition = condition {
                    it.project eq project()._id
                }
            )
        )}
    private val _itemChanges = shared {
        notNullSession().itemChanges.query(
            Query(
                limit = Int.MAX_VALUE,
                condition = condition {
                    it.project eq project()._id
                }
            )
        )}
    val lineItems = shared {
        val items = _lineItems()()
        val changes = _itemChanges()()
        items.map { item ->
            AdjustedLineItem(item, changes.filter { it.itemId == item._id })
        }
    }


    val formCache = HashMap<UUID, PayAppItemForm>()
    val allPayItems = shared {
        notNullSession().payAppItems.query(
            Query(
                limit = Int.MAX_VALUE,
                condition = condition { (it.project eq project()._id) }
            )
        )()
    }
    val itemForms = shared(useLastWhileLoading = true) {
        val allItems = allPayItems()

        val existing = allItems.filter { it.payApp == payAppID }

        val lockedLines = allItems
            .filter { it.payApp != payAppID && it.approved == null && it.voided == null && it.denied == null }
            .map { it.lineItem }

        val lines = lineItems()
        val project = project()

        println("Existing draw items: ${existing.map { Pair(it.pending, it.amount) }}")

        val existingForms = existing.map { payAppItem ->
            val line = lines.find { it._id == payAppItem.lineItem } ?: throw NoSuchElementException("LineItem not found")

            formCache.getOrPut(line._id) {
                PayAppItemForm(
                    payAppItem.takeUnless { it.payApp != payAppID },
                    line,
                    project.safeRetention,
                    allItems.filter { it.lineItem == line._id && it.approved != null }.sumOf { it.amount },
                    this@PayAppForm
                )
            }
        }

        val existingLineIDs = existing.map { it.lineItem }
        val missingForms = lines
            .filter { it._id !in existingLineIDs }
            .map { line ->
                formCache.getOrPut(line._id) {
                    PayAppItemForm(
                        if (line.state.drawable() && line._id !in lockedLines) PayAppItem(
                            payApp = payAppID,
                            lineItem = line._id,
                            amount = 0L,
                            project = project._id,
                        )
                        else null,
                        line,
                        project.safeRetention,
                        allItems.filter { it.lineItem == line._id && it.approved != null }.sumOf { it.amount },
                        this@PayAppForm
                    )
                }
            }

        (existingForms + missingForms).sortedBy { it.lineItem.wraps.created }
    }

    val thisDraw = shared { itemForms().sumOf { it.draw() ?: 0.0 }.toLong() }
    val totalWorkCompleted = shared { itemForms().sumOf { it.previousPayments } + thisDraw() }

    private suspend fun save()  {
        val session = notNullSession()
        payApp.pushChanges(session.payApplications)
        coroutineScope {
            itemForms().forEach {
                launch { it.save() }
            }
        }
    }

    fun ViewWriter.exit() { if (!screenNavigator.goBack()) screenNavigator.reset(LandingScreen()) }

    fun ViewWriter.actionBar() {
        section - row {
            secondaryButton - button {
                specCenteredText("Save")
                action = Action("Save") { save() }
            }
            secondaryButton - button {
                specCenteredText("Save & Close")
                action = Action("Save and Exit") { save(); exit() }
            }
            deleteButton - button {
                specCenteredText("Discard")
                onClick("Discard Draft") {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            header = "Discard Work",
                            message = "Are you sure you want to discard? Any unsaved changes will be lost.",
                            confirmationText = "Discard",
                            onSubmit = { if (it) exit() }
                        )
                    )
                }
            }

            expanding - space()

            primaryButton - button {
                ::enabled {
                    thisDraw() > 0 && itemForms().all { it.draw.valid() }
                }
                specCenteredText("Submit")
                action = Action("Submit") {
                    save()
                    val session = notNullSession()
                    session.nonCached.payApplication.submitPayApplication(payAppID)
                    delay(500)
                    session.payApplications[payAppID].invalidate()
                    session.payAppItems.totallyInvalidate()
                    exit()
                }
            }
        }
    }

    override fun ViewWriter.renderMainContent() {
        scrolls - stack {
            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = AppDimensions.majorColumnSpacing

                title {
                    ::content { "${project().name} Draw ${payApp().number}" }
                }

                actionBar()

                subTitle("Draw Summary")
                centered - stack {
                    spacing = 5.dp

                    val rowSpacing = 1.rem

                    fun ViewWriter.projectValueStatement() {
                        fun RowOrCol.tableRow(
                            key: String,
                            value: ReactiveContext.() -> String
                        ) {
                            col {
                                spacing = 0.px
                                row {
                                    expanding - body(key)
                                    body {
                                        align = Align.Center
                                        ::content.invoke(value)
                                    }
                                }
                                greySeparator()
                            }
                        }

                        sizeConstraints(width = 25.rem) - col {
                            spacing = rowSpacing
                            tableRow("Original Project Price") { project().originalPrice.renderDollars() }
                            tableRow("Accepted Change Amount") {
                                project().let { it.activePrice - it.originalPrice }.renderDollars()
                            }
                            tableRow("Active Price") { project().activePrice.renderDollars() }
                            tableRow("Retention") { "${project().safeRetention.times(100)}%" }
                        }
                    }

                    fun ViewWriter.totalDrawsToDate() {
                        val keyWeight = 2.5f
                        val valueWeight = 1f
                        val valueSpacing = 4.dp

                        fun RowOrCol.tableRow(
                            key: String,
                            value: ReactiveContext.() -> String
                        ) {
                            row {
                                weight(keyWeight) - centered - body(key)
                                weight(valueWeight) - lightSection - body {
                                    spacing = valueSpacing
                                    align = Align.Center
                                    ::content.invoke(value)
                                }
                            }
                        }

                        sizeConstraints(width = 25.rem) - col {
                            spacing = rowSpacing - valueSpacing
                            row {
                                weight(keyWeight) - centered - body("Work Completed to Date")
                                weight(valueWeight) - lightSection - stack {
                                    spacing = valueSpacing
                                    centered - row {
                                        spacing = 5.dp
                                        body { ::content { totalWorkCompleted().renderDollars() } }
                                        centered - smallBody {
                                            ::content {
                                                "(${totalWorkCompleted().times(100) / project().activePrice}%)"
                                            }
                                        }
                                    }
                                }
                            }
                            tableRow("Less Retention To Date") {
                                (totalWorkCompleted() * project().safeRetention).roundToLong().renderDollars()
                            }
                            tableRow("Less Previous Payments") { project().contractorPayments.renderDollars() }
                            separator()
                            row {
                                weight(keyWeight) - centered - body("= Amount Due Request")
                                weight(valueWeight) - lightSection - redText - body {
                                    spacing = valueSpacing
                                    align = Align.Center
                                    ::content {
                                        (thisDraw() * (1 - project().safeRetention)).roundToLong().renderDollars()
                                    }
                                }
                            }
                        }
                    }

                    rowCollapsingToColumn(AppDimensions.pageWidth) {
                        spacing = AppDimensions.majorColumnSpacing*1.5
                        col {
                            row {
                                space(0.1)
                                bodyBold("Project Value Breakdown")
                            }
                            lightSection - projectValueStatement()
                        }
                        col {
                            bodyBold("Total Draws to Date")
                            totalDrawsToDate()
                        }
                    }
                }

                actionBar()

                col {
                    trackWeights {
                        columnSpacing = 0.5.rem

                        subTitle {
                            ::content { "Draw ${payApp().number}: ${payApp().created.format(Formats.mmddyyyy)}" }
                        }

                        row {
                            spacing = columnSpacing

                            setColWeight(2f) - atBottomStart - bodyBold("Work Item")
                            fun ViewWriter.columnLabel(name: String) {
                                bodyBold {
                                    align = Align.Center
                                    wraps = true
                                    content = name
                                }
                            }
                            setColWeight(1.5f) - atBottomCenter - columnLabel("Approved Amount")
                            setColWeight(1f) - atBottomCenter - columnLabel("Past Payments")
                            setColWeight(1f) - atBottomCenter - columnLabel("This Draw")
                            setColWeight(1f) - atBottomCenter - columnLabel("Total Completed")
                            setColWeight(1f) - atBottomCenter - columnLabel("% Completed")
                            setColWeight(1f) - atBottomCenter - columnLabel("Remaining")
                            setColWeight(1f) - atBottomCenter - bodyBold {
                                align = Align.Center
                                wraps = true
                                ::content { "Retainage (${project().safeRetention.times(100)}%)" }
                            }
                        }
                        greySeparator()

                        col {
                            forEach(itemForms) {
                                it.run { render(this@trackWeights) }
                                greySeparator()
                            }
                        }

                        row {
                            spacing = columnSpacing

                            fun ViewWriter.total(calculation: ReactiveContext.() -> Long) = bodyBold {
                                align = Align.Center
                                wraps = true
                                ::content { calculation().renderDollars() }
                            }
                            getColWeight - bodyBold("Totals")
                            getColWeight - total { lineItems().sumOf { it.price } }
                            getColWeight - total { itemForms().sumOf { it.previousPayments } }
                            getColWeight - total { thisDraw() }
                            getColWeight - total { totalWorkCompleted() }
                            getColWeight - bodyBold {
                                align = Align.Center
                                ::content { "${totalWorkCompleted().times(100) / project().activePrice}%" }
                            }
                            getColWeight - total { project().activePrice - totalWorkCompleted() }
                            getColWeight - total { (totalWorkCompleted() * project().safeRetention).roundToLong() }
                        }
                    }
                }

                space(0.25)

                col {
                    subTitle("This Draw")
                    fun ViewWriter.money(calculation: ReactiveContext.()->Long) = body {
                        align = Align.Center
                        ::content { calculation().renderDollars() }
                    }

                    row {
                        lightSection - stack {
                            centered - col {
                                spacing = AppDimensions.buttonSpacing
                                money { thisDraw() }
                                centered - body("Pay App Work Value")
                            }
                        }
                        centered - bodyBold("-")
                        lightSection - stack {
                            centered - col {
                                spacing = AppDimensions.buttonSpacing
                                money { (thisDraw() * project().safeRetention).roundToLong() }
                                centered - body("Retention Value")
                            }
                        }
                        centered - bodyBold("=")
                        lightSection - stack {
                            centered - col {
                                spacing = AppDimensions.buttonSpacing
                                redText - money { (thisDraw() * (1 - project().safeRetention)).roundToLong() }
                                centered - body("Amount Due Request")
                            }
                        }
                    }
                }

                space(10.0)
            }
        }
    }

    class PayAppItemForm(
        val item: PayAppItem?,  // Null indicates you cannot create a pay item for this work item (either the line item state disallows it or there is an existing pay app item for it)
        val lineItem: AdjustedLineItem,
        val retention: Float,
        val previousPayments: Long,
        reportTo: Validator
    ) : Validator(reportTo) {
        val maxDrawable = lineItem.price - previousPayments

        val draw = Property(item?.amount?.takeUnless { it <= 0L }?.toDouble())
            .validate {
                if (it == null) true
                else it > 0.0 && it <= maxDrawable
            }

        val drawable = (item != null) and lineItem.state.drawable()

        suspend fun save() {
            if (item == null) return
            val amount = draw()?.toLong() ?: return
            notNullSession().payAppItems.run {
                val model = get(item._id)

                if (model.awaitOnce() != null) model.modify2 { it.amount assign amount }
                else insert(item.copy(amount = amount))
            }
        }

        fun ViewWriter.thisDraw() {
            val lineItemStatus: String? = when (lineItem.state) {   // These status names are only used here, so not worth its own field on the enum
                LineItemState.NotStarted -> "Not Started"
                LineItemState.InProgress -> null
                LineItemState.NotApproved -> null
                LineItemState.Complete -> "Done"
                LineItemState.Cancelled -> "Withdrawn"

                LineItemState.RequestingReview,
                LineItemState.ForceComplete,
                LineItemState.ResolveLater,
                LineItemState.OnHold,
                LineItemState.Disputed,
                LineItemState.Resolved,
                LineItemState.Issue,
                LineItemState.Resolving,
                LineItemState.WorkingOnRemedy -> "Under Review"
            }

            when {
                lineItemStatus == null && item == null -> {
                    stack {
                        centered - row {
                            spacing = 0.5.rem
                            centered - infoBubble("There is an existing Pay Application for this line item, only one active pay application per line item is allowed.")
                            smallBody {
                                content = "Locked"
                            }
                        }
                    }
                }
                lineItemStatus != null -> {
                    smallBody {
                        align = Align.Center
                        content = "($lineItemStatus)"
                    }
                }
                else -> {
                    fieldTheme - validate(draw) { draw() != null } - row {
                        spacing = 0.2.rem
                        centered - smallBody("$")
                        centered - numberInput {
                            keyboardHints = KeyboardHints.integer
                            spacing = 0.2.rem
                            content bind draw
                        }
                    }
                }
            }
        }

        fun ViewWriter.render(columns: ColumnWeights) = with(columns) {
            sizeConstraints(height = 1.5.rem) - row {
                spacing = 0.px
                getColWeight - centered - body {
                    if (lineItem.state == LineItemState.Cancelled) themeChoice += GreyedOutSemantic
                    content = lineItem.name
                }

                weight(
                    weights.drop(1).sum() + 0.2f /*Correction for spacing in columns*/
                ) - row {
                    spacing = columnSpacing
                    if (!drawable) themeChoice += GreyedOutSemantic
                    getColWeight - centered - body {
                        align = Align.Center
                        content = lineItem.price.renderDollars()
                    }
                    getColWeight - centered - body {
                        align = Align.Center
                        content = previousPayments.renderDollars()
                    }
                    getColWeight - centered - thisDraw()

                    val totalCompleted = shared { previousPayments + (draw() ?: 0.0).toLong() }
                    getColWeight - centered - body {
                        align = Align.Center
                        ::content { totalCompleted().renderDollars() }
                    }
                    getColWeight - centered - body {
                        align = Align.Center
                        ::content {
                            lineItem.price
                                .takeUnless { it <= 0 }
                                ?.let {
                                    "${totalCompleted().times(100) / it}%"
                                }
                                ?: "-"
                        }
                    }
                    getColWeight - centered - body {
                        align = Align.Center
                        ::content {
                            (lineItem.price - totalCompleted()).renderDollars()
                        }
                    }
                    getColWeight - centered - body {
                        align = Align.Center
                        ::content { (totalCompleted() * retention).roundToLong().renderDollars() }
                    }
                }
            }
        }
    }
}