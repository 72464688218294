package com.crowpay.sdk

import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.screens.contractor.ContractorDashboard
import com.lightningkite.kiteui.exceptions.ExceptionHandler
import com.lightningkite.kiteui.exceptions.ExceptionMessage
import com.lightningkite.kiteui.exceptions.ExceptionToMessage
import com.lightningkite.kiteui.exceptions.ExceptionToMessages
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.views.RView
import kotlin.reflect.KClass

sealed class RequestException(val modelType: KClass<*>, override val message: String) : NullPointerException() {
    class NotFound(modelType: KClass<*>): RequestException(modelType, "${modelType.simpleName} was not found")

    class UpdateError(modelType: KClass<*>): RequestException(modelType, "Error in modifying ${modelType.simpleName}")

    class CreationError(modelType: KClass<*>): RequestException(modelType, "Error in creating ${modelType.simpleName}")

    class DeletionError(modelType: KClass<*>): RequestException(modelType, "Error in deleting ${modelType.simpleName}")

    object Handler : ExceptionToMessage {
        override val priority: Float = 2f

        override fun handle(view: RView, exception: Exception): ExceptionMessage? {
            if (exception !is RequestException) return null

            return ExceptionMessage(
                "Request Exception",
                exception.message
            )
        }
    }
}

inline fun <reified T> notFoundError(): Nothing { throw RequestException.NotFound(T::class) }
inline fun <reified T> updateError(): Nothing { throw RequestException.UpdateError(T::class) }
inline fun <reified T> creationError(): Nothing { throw RequestException.CreationError(T::class) }
inline fun <reified T> deletionError(): Nothing { throw RequestException.DeletionError(T::class) }

abstract class SpecificExceptionHandler<T : Exception>(val on: KClass<T>) : ExceptionHandler {
    override val priority: Float = 5f
    abstract fun RView.action(error: T)
    override fun handle(view: RView, working: Boolean, exception: Exception): (() -> Unit)? {
        if (on.isInstance(exception)) {
            view.action(exception as T)
            return {}
        }
        else return null
    }
}

class RedirectOnNotFound<T : Any>(
    val modelType: KClass<T>,
    val to: ()->Screen
) : SpecificExceptionHandler<RequestException.NotFound>(RequestException.NotFound::class) {
    override fun RView.action(error: RequestException.NotFound) {
        if (error.modelType == modelType) {
            dialogScreenNavigator.navigate(
                GenericDialog(
                    message = "An error occurred, and the change request could not be found. You will be redirected to the dashboard."
                ) {
                    mainScreenNavigator.navigate(to())
                }
            )
        }
    }
}