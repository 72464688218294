// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun preparePendingLineItemFields() {
    val props: Array<SerializableProperty<PendingLineItem, *>> = arrayOf(PendingLineItem__id, PendingLineItem_project, PendingLineItem_changeRequest, PendingLineItem_name, PendingLineItem_description, PendingLineItem_price, PendingLineItem_files, PendingLineItem_linked, PendingLineItem_accepted, PendingLineItem_denied, PendingLineItem_approved, PendingLineItem_created, PendingLineItem_itemNumber)
    PendingLineItem.serializer().properties { props }
}
val <K> DataClassPath<K, PendingLineItem>._id: DataClassPath<K, UUID> get() = this[PendingLineItem__id]
val <K> DataClassPath<K, PendingLineItem>.project: DataClassPath<K, UUID> get() = this[PendingLineItem_project]
val <K> DataClassPath<K, PendingLineItem>.changeRequest: DataClassPath<K, UUID> get() = this[PendingLineItem_changeRequest]
val <K> DataClassPath<K, PendingLineItem>.name: DataClassPath<K, String> get() = this[PendingLineItem_name]
val <K> DataClassPath<K, PendingLineItem>.description: DataClassPath<K, String> get() = this[PendingLineItem_description]
val <K> DataClassPath<K, PendingLineItem>.price: DataClassPath<K, Long> get() = this[PendingLineItem_price]
val <K> DataClassPath<K, PendingLineItem>.files: DataClassPath<K, List<ProjectAttachment>> get() = this[PendingLineItem_files]
val <K> DataClassPath<K, PendingLineItem>.linked: DataClassPath<K, Boolean> get() = this[PendingLineItem_linked]
val <K> DataClassPath<K, PendingLineItem>.accepted: DataClassPath<K, AccessInfo?> get() = this[PendingLineItem_accepted]
val <K> DataClassPath<K, PendingLineItem>.denied: DataClassPath<K, AccessInfo?> get() = this[PendingLineItem_denied]
val <K> DataClassPath<K, PendingLineItem>.approved: DataClassPath<K, Boolean?> get() = this[PendingLineItem_approved]
val <K> DataClassPath<K, PendingLineItem>.created: DataClassPath<K, Instant> get() = this[PendingLineItem_created]
val <K> DataClassPath<K, PendingLineItem>.itemNumber: DataClassPath<K, String> get() = this[PendingLineItem_itemNumber]
inline val PendingLineItem.Companion.path: DataClassPath<PendingLineItem, PendingLineItem> get() = path<PendingLineItem>()


object PendingLineItem__id: SerializableProperty<PendingLineItem, UUID> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(0) }
    override fun get(receiver: PendingLineItem): UUID = receiver._id
    override fun setCopy(receiver: PendingLineItem, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object PendingLineItem_project: SerializableProperty<PendingLineItem, UUID> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(1) }
    override fun get(receiver: PendingLineItem): UUID = receiver.project
    override fun setCopy(receiver: PendingLineItem, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(1) }
}
object PendingLineItem_changeRequest: SerializableProperty<PendingLineItem, UUID> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(2) }
    override fun get(receiver: PendingLineItem): UUID = receiver.changeRequest
    override fun setCopy(receiver: PendingLineItem, value: UUID) = receiver.copy(changeRequest = value)
    override val serializer: KSerializer<UUID> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(2) }
}
object PendingLineItem_name: SerializableProperty<PendingLineItem, String> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(3) }
    override fun get(receiver: PendingLineItem): String = receiver.name
    override fun setCopy(receiver: PendingLineItem, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(3) }
    override val default: String = ""
    override val defaultCode: String? = "\"\"" 
}
object PendingLineItem_description: SerializableProperty<PendingLineItem, String> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(4) }
    override fun get(receiver: PendingLineItem): String = receiver.description
    override fun setCopy(receiver: PendingLineItem, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(4) }
    override val default: String = ""
    override val defaultCode: String? = "\"\"" 
}
object PendingLineItem_price: SerializableProperty<PendingLineItem, Long> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(5) }
    override fun get(receiver: PendingLineItem): Long = receiver.price
    override fun setCopy(receiver: PendingLineItem, value: Long) = receiver.copy(price = value)
    override val serializer: KSerializer<Long> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(5) }
    override val default: Long = 0L
    override val defaultCode: String? = "0L" 
}
object PendingLineItem_files: SerializableProperty<PendingLineItem, List<ProjectAttachment>> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(6) }
    override fun get(receiver: PendingLineItem): List<ProjectAttachment> = receiver.files
    override fun setCopy(receiver: PendingLineItem, value: List<ProjectAttachment>) = receiver.copy(files = value)
    override val serializer: KSerializer<List<ProjectAttachment>> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<List<ProjectAttachment>> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(6) }
    override val default: List<ProjectAttachment> = listOf()
    override val defaultCode: String? = "listOf()" 
}
object PendingLineItem_linked: SerializableProperty<PendingLineItem, Boolean> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(7) }
    override fun get(receiver: PendingLineItem): Boolean = receiver.linked
    override fun setCopy(receiver: PendingLineItem, value: Boolean) = receiver.copy(linked = value)
    override val serializer: KSerializer<Boolean> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(7) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object PendingLineItem_accepted: SerializableProperty<PendingLineItem, AccessInfo?> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(8) }
    override fun get(receiver: PendingLineItem): AccessInfo? = receiver.accepted
    override fun setCopy(receiver: PendingLineItem, value: AccessInfo?) = receiver.copy(accepted = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(8) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object PendingLineItem_denied: SerializableProperty<PendingLineItem, AccessInfo?> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(9) }
    override fun get(receiver: PendingLineItem): AccessInfo? = receiver.denied
    override fun setCopy(receiver: PendingLineItem, value: AccessInfo?) = receiver.copy(denied = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(9) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object PendingLineItem_approved: SerializableProperty<PendingLineItem, Boolean?> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(10) }
    override fun get(receiver: PendingLineItem): Boolean? = receiver.approved
    override fun setCopy(receiver: PendingLineItem, value: Boolean?) = receiver.copy(approved = value)
    override val serializer: KSerializer<Boolean?> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<Boolean?> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(10) }
    override val default: Boolean? = null
    override val defaultCode: String? = "null" 
}
object PendingLineItem_created: SerializableProperty<PendingLineItem, Instant> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(11) }
    override fun get(receiver: PendingLineItem): Instant = receiver.created
    override fun setCopy(receiver: PendingLineItem, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[11] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(11) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object PendingLineItem_itemNumber: SerializableProperty<PendingLineItem, String> {
    override val name: String by lazy { PendingLineItem.serializer().descriptor.getElementName(12) }
    override fun get(receiver: PendingLineItem): String = receiver.itemNumber
    override fun setCopy(receiver: PendingLineItem, value: String) = receiver.copy(itemNumber = value)
    override val serializer: KSerializer<String> by lazy { (PendingLineItem.serializer() as GeneratedSerializer<*>).childSerializers()[12] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { PendingLineItem.serializer().descriptor.getElementAnnotations(12) }
}
