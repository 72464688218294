package com.crowpay.views.screens.auth

import com.crowpay.views.theming.AppColors
import com.crowpay.Resources
import com.crowpay.SharedUtils
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.ApiOption
import com.crowpay.sdk.notNullSession
import com.crowpay.sdk.selectedApi
import com.crowpay.sdk.sessionToken
import com.crowpay.views.components.label2
import com.crowpay.views.AutoRoutes
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.errorText
import com.lightningkite.lightningserver.auth.proof.FinishProof
import kotlinx.coroutines.delay


interface AuthScreen

@Routable("auth/log-in")
class LogInScreen : Screen, AuthScreen {

    @QueryParameter
    val next: Property<String?> = Property(null)
    var nextScreen: Screen? = null
        set(value) {
            field = value
            next.value = value?.let { AutoRoutes.render(it)?.urlLikePath?.render() }
        }

    val email = Property("")
    val passcodeSecret = Property<String?>(null)
    val passcode = Property("")

    override fun ViewWriter.render() {

        if (nextScreen == null && next.value != null) next.value = null

        val sendEmail = Action(
            title = "LOG IN",
            icon = Icon.login,
            action = {
                passcodeSecret.value = selectedApi.await().api.emailProof.beginEmailOwnershipProof(email.await())
            }
        )

        suspend fun confirmPasscode() {
            val api = selectedApi.await().api
            val proof = api.emailProof.proveEmailOwnership(
                FinishProof(
                    passcodeSecret.awaitOnce() ?: return,
                    passcode.awaitOnce()
                )
            )
            val session = api.userAuth.logIn(listOf(proof))
            sessionToken.set(session.session!!)
            delay(2000)

            if (nextScreen != null)
                screenNavigator.reset(nextScreen!!)
            else
                screenNavigator.reset(LandingScreen())
        }

        AuthBackgroundSemantic.onNext - stack {
            AuthDialogSemantic.onNext - centered - stack {
                spacing = AppDimensions.fullIndent
                sizeConstraints(width = 30.rem) - col {
                    themeChoice = ThemeDerivation(appTheme)

                    centered - image {
                        source = Resources.imagesFullLogoColor
                    } in sizeConstraints(height = 4.rem, width = 19.135.rem)

                    centered - body {
                        content = "Contractor's Project Escrow Solution!"
                    }

                    onlyWhen { next() != null } - body("Please login to continue to your project")

                    onlyWhen { passcodeSecret() == null } - col {

                        label2("Email") {
                            fieldTheme - textField {
                                hint = "Email"
                                keyboardHints = KeyboardHints.email
                                content bind email

                                action = sendEmail
                            }
                        }

                        space()

                        primaryButton - button {
                            ::enabled{ email().matches(SharedUtils.emailPattern) }
                            body {
                                align = Align.Center
                                content = "Log In"
                            }
                            action = sendEmail
                        }

                        space()

                        row {
                            spacing = 0.5.rem
                            body("Are you a contractor? ")
                            colored(AppColors.primary.main) - link {
                                spacing = 1.dp
                                body("Register for Account")
                                to = { ContractorRegistrationRouter }
                            }
                        }
                    }
                    onlyWhen { passcodeSecret() != null } - col {
                        body {
                            ::content{
                                "An email with a passcode was sent to ${email()}. Enter the passcode below to confirm login."
                            }
                        }

                        label2("Passcode") {
                            fieldTheme - textField {
                                hint = "Passcode"
                                keyboardHints = KeyboardHints.id
                                content bind passcode

                                        action = Action(
                                            title = "LOG IN",
                                            icon = Icon.login,
                                            action = ::confirmPasscode,
                                        )
                                    }
                                }
                                space()
                                col {
                                    primaryButton - button {
                                        ::enabled{ passcode().length == 6 }
                                        body {
                                            align = Align.Center
                                            content = "Log In"
                                        }
                                        onClick(action = ::confirmPasscode)
                                    }
                                    errorText()
                                }
                                smallBody("If you did not receive a code, please check the email address provided or try again.")
                                centered - textButton - button {
                                    val showIcon = Property(false)
                                    row {
                                        centered - smallBody {
                                            align = Align.Center
                                            content = "Send me a new code."
                                        }

                                centered - onlyWhen { showIcon() } - icon {
                                    source = Icon.done.copy(width = 1.rem, height = 1.rem)
                                    description = "Done"
                                }
                            }
                            onClick {
                                passcodeSecret.value = selectedApi.await().api.emailProof
                                    .beginEmailOwnershipProof(email.awaitOnce())
                                passcode.value = ""

                                launchGlobal {
                                    showIcon.set(true)
                                    delay(3000)
                                    showIcon.set(false)
                                }
                            }
                        }

                        centered - textButton - button {
                            smallBody {
                                align = Align.Center
                                content = "Use a different email."
                            }
                            onClick {
                                passcodeSecret.value = null
                                passcode.value = ""
                            }
                        }

                    }
                }

            }


            button {

                body {
                    align = Align.Center
                    ::content{
                        selectedApi().apiName
                    }
                } in sizeConstraints(minWidth = 6.rem)

                onClick {
                    val current = selectedApi.awaitOnce().ordinal
                    var newIndex = current + 1
                    if (newIndex >= ApiOption.entries.size) {
                        newIndex = 0
                    }
                    selectedApi.set(ApiOption.entries[newIndex])
                }
            } in gravity(Align.End, Align.Start)

        }
    }
}