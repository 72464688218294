package com.crowpay.completionResponse

import com.crowpay.LineItem
import com.crowpay.LineItemCompletionMessage
import com.crowpay.LineItemState
import kotlinx.datetime.Instant

/**
 * Takes in the completion message history and parses it, calculating what responses are allowed from the
 * next response.
 *
 * Rules Notes (not all rules but some):
 * - The line item has flags for "allowRaise_____". These flags are set by project timers, and are used to notify the system
 *   that the user can respond out-of-turn to raise an issue or such. Usually this is because one party hasn't responded
 *   quickly enough, and this provides a way to escalate the issue when no response comes.
 * - The client is allowed to raise an issue on their second response, first being "Not Ready" (CompletionResponse.ClientResponse)
 * - The client is allowed to file a dispute on their next response after raising an issue
 * */
class CompletionFlowPermissions(
    val lineItem: LineItem,
    val isClient: Boolean,
    lineItemMessages: List<LineItemCompletionMessage>
) {
    val messages = lineItemMessages.sortedBy { it.created }

    val previousResponse = messages.lastOrNull()

    private val previousWasFromClient = previousResponse?.clientMessage ?: true
    val disputeFiledAt: Instant? = messages.firstNotNullOfOrNull {
        if ((it.responseType as? CompletionResponse.ClientResponse)?.fileDispute != null) it.created
        else null
    }
    val disputeWasFiled get() = disputeFiledAt != null

    val baseState = when {
        disputeWasFiled -> LineItemState.Disputed
        lineItem.issue.let { it != null && it.resolved == null } -> LineItemState.Issue
        else -> LineItemState.NotApproved
    }

    val mustRequestComplete =
        messages.isEmpty() || previousResponse?.responseType == CompletionResponse.KeepWorking

    val mustRequestReviewOfRemedy =
        previousResponse?.responseType
            ?.let { it is CompletionResponse.Accept && it.proposal is CompletionResponse.Remedy }
            ?: false

    val awaitingClientResponse =
        lineItem.state < LineItemState.Resolved && !previousWasFromClient

    val awaitingContractorResponse =
        lineItem.state < LineItemState.Resolved &&
                (previousWasFromClient || mustRequestComplete || mustRequestReviewOfRemedy)

    val awaitingResponse = isClient && awaitingClientResponse || !isClient && awaitingContractorResponse

    private val awaitingClientAndClientHasRespondedOnce = awaitingClientResponse && messages.any { it.clientMessage }
    val canRaiseIssue = isClient && lineItem.issue == null &&
            (lineItem.allowRaiseIssue || awaitingClientAndClientHasRespondedOnce)

    val mustRaiseIssue = isClient &&
            lineItem.issue == null &&
            previousResponse?.responseType.let {
                it == CompletionResponse.ForceComplete || it == CompletionResponse.RequestResolveLater
            }

    private val awaitingClientAndClientHasRaisedIssue = awaitingClientResponse && lineItem.issue != null
    val canFileDispute = isClient && !disputeWasFiled &&
            (lineItem.allowFileDispute || awaitingClientAndClientHasRaisedIssue)

    val mustFileDispute = isClient &&
            lineItem.issue != null &&
            !disputeWasFiled &&
            previousResponse?.responseType.let {
                it == CompletionResponse.ForceComplete || it == CompletionResponse.RequestResolveLater
            }

    val canLockProject = lineItem.allowLockProject

    private val clientResponses = messages.map { it.responseType }.filterIsInstance<CompletionResponse.ClientResponse>()

    val canProposeResolveLater =
        lineItem.state in setOf(LineItemState.Issue, LineItemState.Disputed) &&
                clientResponses.none { it.fileDispute == DisputeType.ProjectWide } &&
                previousResponse?.responseType != CompletionResponse.RequestResolveLater


    val canProposeDisputeResolution = isClient && disputeWasFiled && previousResponse?.responseType !is CompletionResponse.ClientResolution
    val clientProposingResolution = !isClient && previousResponse?.responseType is CompletionResponse.ClientResolution

    val mostRecentProposal: CompletionResponse.NeedsApproval? = messages.asReversed().firstNotNullOfOrNull {
        it.responseType as? CompletionResponse.NeedsApproval
    }

    val canAccept get() = isClient && mostRecentProposal != null && lineItem.state > LineItemState.InProgress
    val canDeny = disputeWasFiled &&
            isClient != previousWasFromClient &&
            (previousResponse?.responseType is CompletionResponse.NeedsApproval || clientProposingResolution)

    val canRespond = awaitingResponse || canAccept || canRaiseIssue || canFileDispute || canProposeDisputeResolution || lineItem.allowLockProject
}