package com.crowpay.views.screens.client

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.views.screens.contractor.PaymentScreen
import com.lightningkite.kiteui.*
import com.crowpay.views.theming.section
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

@Routable("client/payments")
class ClientPayments() : Screen, PaymentScreen {

    override val title: Readable<String> = Constant("Payment Methods")

    val form = ClientPaymentMethodForm()

    override fun ViewWriter.render() {

        scrolls - col {

            title("Payment Methods")

            sizeConstraints(width = AppDimensions.pageWidth) -
                    col {
                        section
                        form.render(this@col)
                    }
        }
    }
}