package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.contactFullName
import com.crowpay.extensions.withSpacing
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.lazy
import com.crowpay.utils.pushChanges
import com.crowpay.utils.validation.validate
import com.crowpay.utils.validation.validating
import com.crowpay.views.components.*
import com.crowpay.views.components.files.*
import com.crowpay.views.dialogs.ImagePreview
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.requestFile
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.*

@Routable("/contractor/profile")
class ContractorProfile() : ContractorScreen {

    override val title: Readable<String> = Constant("Profile")

    val contractor = Draft { notNullSession().contractors[notNullContractor()]()!! }

    val displayImage = LazyProperty {
        contractor().image?.let { FilePreview.RemoteFile(it.location) }
            ?: FilePreview.Empty
    }

    override fun ViewWriter.renderMainContent() {

        scrolls - col {
            spacing = 0.dp

            title("Profile")

            sizeConstraints(width = AppDimensions.pageWidth) - padded - stack {
                spacing = AppDimensions.backgroundIndent

                rowCollapsingToColumn(AppDimensions.normalCollapseWidth) {

                    section - expanding - stack {
                        spacing = AppDimensions.sectionIndent
                        val editingContractor = Property(false)
                        col {

                            title {
                                ::content { "${contractor.published().preferredTitle.name} Information" }
                            }

                            onlyWhen { !editingContractor() } - col {
                                col {
                                    label2("Name") {
                                        body {
                                            ::content{ contractor.published().name }
                                        }
                                    }

                                    label2("Email") {
                                        body {
                                            ::content{ contractor.published().email }
                                        }
                                    }

                                    label2("Phone Number") {
                                        body {
                                            ::content{ PhoneNumberFormat.USA.format(contractor.published().phoneNumber.filter { it.isDigit() }) }
                                        }
                                    }

                                    label2("Address") {
                                        body {
                                            ::content{ contractor.published().address.toString() }
                                        }
                                    }

                                    label2("Logo") {
                                        image {
                                            scaleType = ImageScaleType.Fit
                                            description = "Logo"
                                            ::source{
                                                displayImage().getImage()
                                            }
                                        } in sizeConstraints(width = 5.rem, height = 5.rem)
                                    }

                                    label2("Preferred Title") {
                                        body {
                                            ::content{ contractor.published().preferredTitle.toString() }
                                        }
                                    }

                                    label2("Trade") {
                                        body {
                                            ::content{ contractor.published().trade ?: "" }
                                        }
                                    }

                                    space()

                                    subTitle("Other Legal Information")

                                    label2("State Entity Number") {
                                        body {
                                            ::content{ contractor.published().stateEntityNumber }
                                        }
                                    }
                                    label2("EIN/Social/Tax ID") {
                                        body {
                                            ::content{ contractor.published().ein }
                                        }
                                    }

                                    tertiaryButton - button {
                                        icon(Icon.edit, "Edit Business Information")
                                        onClick {
                                            editingContractor.set(true)
                                        }
                                    } in gravity(Align.End, Align.Center)
                                }
                            }


                            onlyWhen { editingContractor() } - col {
                                col {
                                    validating {
                                        val availableTrades = shared {
                                            notNullSession().trades
                                                .query(
                                                    Query(
                                                        condition(true),
                                                        sort { it._id.ascending() }
                                                    )
                                                )()
                                                .map { it._id }
                                        }

                                        requiredField("Name") {
                                            val value =contractor
                                                .lensPath { it.name }
                                                .validate { it.isNotBlank() }
                                            textInput {
                                                hint = "Name"
                                                content.bind(value)
                                            }
                                        }

                                        requiredField("Email") {
                                            val value = contractor
                                                .lensPath { it.email }
                                                .validate { it.matches(SharedUtils.emailPattern) }
                                            textInput {
                                                hint = "Email"
                                                content.bind(value)
                                            }
                                        }

                                        requiredField("Phone Number") {
                                            val value = contractor
                                                .lensPath { it.phoneNumber }
                                                .validate { it.length == 10 }
                                            phoneNumberInput {
                                                hint = "Phone Number"
                                                content.bind(value)
                                            }
                                        }

                                        space()

                                        subTitle("Address")
                                        addressForm(contractor.lensPath { it.address }, this@validating)

                                        requiredField("Logo") {
                                            val value = displayImage.validate { it !is FilePreview.Empty }
                                            withSpacing(0.0) - button {
                                                stack {
                                                    reactiveScope {
                                                        this@stack.clearChildren()
                                                        val filePreview = value()

                                                        renderFilePreview(filePreview, 6.rem)
                                                    }

                                                    body {
                                                        ::exists{ value() == FilePreview.Empty }
                                                        align = Align.Center
                                                        content = "Image\nUpload"
                                                    }
                                                }
                                                onClick {
                                                    ExternalServices.requestFile(listOf("image/*")) { it: FileReference? ->
                                                        it?.let { result ->
                                                            launch {
                                                                value.set(
                                                                    FilePreview.LocalFile(it)
                                                                    .also { it.upload(notNullSession().nonCached.contractor::uploadFileForRequest) })
                                                            }
                                                        }
                                                    }
                                                }
                                            } in sizeConstraints(width = 6.rem, height = 6.rem)
                                        }

                                        label2("Preferred Title") {
                                            fieldTheme - select {
                                                bind(
                                                    edits = contractor
                                                        .lens(
                                                            get = { it.preferredTitle },
                                                            modify = { og, it -> og.copy(preferredTitle = it) }
                                                        ),
                                                    data = shared { PreferredTitle.entries },
                                                    render = { it.toString() })
                                            }
                                        }

                                        requiredField("Trade") {
                                            val value = contractor
                                                .lens(
                                                    get = { it.trade },
                                                    modify = { og, it -> og.copy(trade = it) }
                                                )
                                                .validate { it != null }
                                            select {
                                                bind(
                                                    edits = value,
                                                    data = shared { listOf(null) + availableTrades() },
                                                    render = { it ?: "Select a Trade" })
                                            }
                                        }

                                        space()

                                        h4("Other Legal Information")

                                        requiredField("State Entity Number") {
                                            val value = contractor
                                                .lens(
                                                    get = { it.stateEntityNumber },
                                                    modify = { og, it -> og.copy(stateEntityNumber = it) }
                                                )
                                                .validate { it.isNotBlank() }
                                            textInput {
                                                hint = "State Entity Number"
                                                content.bind(value)
                                            }
                                        }

                                        requiredField("EIN/Social/Tax ID") {
                                            val value = contractor
                                                .lens(
                                                    get = { it.ein },
                                                    modify = { og, it -> og.copy(ein = it) }
                                                )
                                                .validate { it.isNotBlank() }
                                            textInput {
                                                hint = "EIN/Social/Tax IDr"
                                                content.bind(value)
                                            }
                                        }
                                        row {
                                            spacing = AppDimensions.buttonRowSpacing
                                            textButton - button {
                                                specCenteredText("Cancel")
                                                onClick {
                                                    editingContractor.set(false)
                                                    displayImage.reset()
                                                    contractor.cancel()
                                                }
                                            }
                                            tertiaryButton - button {

                                                ::enabled{ allValid() }
                                                specCenteredText("Save")

                                                onClick {
                                                    val image = displayImage.awaitOnce()
                                                    if (image is FilePreview.LocalFile) {
                                                        if (image.uploaded.await().awaitNotNull()) {
                                                            contractor.modify {
                                                                it.copy(image = image.mainFile!!)
                                                            }
                                                        }
                                                    }

                                                    contractor.pushChanges(notNullSession().contractors)
                                                    editingContractor.set(false)
                                                }
                                            }
                                        } in gravity(Align.End, Align.Center)
                                    }
                                }
                            }
                        }
                    }

                    section - expanding - stack {
                        spacing = AppDimensions.sectionIndent
                        val editingContact = Property(false)
                        col {
                            title {
                                ::content { "Contact Information" }
                            }
                            onlyWhen { !editingContact() } - col {
                                col {

                                    label2("Name") {
                                        body {
                                            ::content{ contractor().contactFullName }
                                        }
                                    }

                                    label2("Email") {
                                        body {
                                            ::content{ contractor().contactEmail }
                                        }
                                    }

                                    label2("Phone Number") {
                                        body {
                                            ::content{ PhoneNumberFormat.USA.format(contractor().contactPhoneNumber) }
                                        }
                                    }

                                    label2("Address") {
                                        body {
                                            ::content{ contractor().contactAddress.toString().also { println("Address: $it") } }
                                        }
                                    }

                                    tertiaryButton - button {
                                        icon(Icon.edit, "Edit Contact Information")
                                        onClick {
                                            editingContact.set(true)
                                        }
                                    } in gravity(Align.End, Align.Center)
                                }
                            }
                            onlyWhen { editingContact() } - col {
                                col {
                                    validating {
                                        requiredField("First Name") {
                                            val value = contractor
                                                .lensPath { it.contactFirstName }
                                                .validate { it.isNotBlank() }
                                            textInput {
                                                hint = "First Name"
                                                content.bind(value)
                                            }
                                        }

                                        requiredField("Last Name") {
                                            val value = contractor
                                                .lensPath { it.contactLastName }
                                                .validate { it.isNotBlank() }
                                            textInput {
                                                hint = "Last Name"
                                                content.bind(value)
                                            }
                                        }

                                        requiredField("Email") {
                                            val value = contractor
                                                .lensPath { it.contactEmail }
                                                .validate { it.matches(SharedUtils.emailPattern) }
                                            textInput {
                                                hint = "Email"
                                                content.bind(value)
                                            }
                                        }

                                        requiredField("Phone Number") {
                                            val value = contractor
                                                .lensPath { it.contactPhoneNumber }
                                                .validate { it.length == 10 }
                                            phoneNumberInput {
                                                hint = "Phone Number"
                                                content.bind(value)
                                            }
                                        }

                                        space()

                                        subTitle("Address")
                                        addressForm(contractor.lensPath { it.contactAddress }, this@validating)

                                        row {
                                            spacing = AppDimensions.buttonRowSpacing
                                            textButton - button {
                                                specCenteredText("Cancel")
                                                onClick {
                                                    editingContact.set(false)
                                                    contractor.cancel()
                                                }
                                            }
                                            tertiaryButton - button {
                                                ::enabled{ allValid() }
                                                specCenteredText("Save")
                                                onClick {
                                                    contractor.pushChanges(notNullSession().contractors)
                                                    editingContact.set(false)
                                                }
                                            }
                                        } in gravity(Align.End, Align.Center)
                                    }
                                }
                            }
                        }
                    }

                }
            }

            space(AppDimensions.majorColumnSpacing)

            title("Licenses")

            val licenses = shared {
                notNullSession().licenses.query(Query(condition { it.contractor eq guaranteedContractor() }))()
            }
            sizeConstraints(width = AppDimensions.pageWidth) - padded - stack {
                spacing = AppDimensions.backgroundIndent
                section - expanding - renderLicenses(licenses)
            }

            space(AppDimensions.majorColumnSpacing)


            title("Other Documents")

            val docs = shared {
                notNullSession().contractorDocuments
                    .query(Query(condition {
                        it.contractor eq guaranteedContractor<UUID>()
                    }))
                    .await()
            }
            sizeConstraints(width = AppDimensions.pageWidth) - padded - stack {
                spacing = AppDimensions.backgroundIndent
                section - renderContractorDocuments(docs)
            }
        }
    }
}