package com.crowpay.views.components.project

import com.crowpay.ClientFeedback
import com.crowpay.ProjectState
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.hash
import com.crowpay.feedback
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.*
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.screens.contractor.EditProject
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.suspendCoroutineCancellable
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.kiteui.views.l2.toast
import com.lightningkite.lightningdb.modification
import kotlinx.coroutines.delay
import kotlin.coroutines.resume

fun ProjectView.actionBar2(writer: ViewWriter) = with(writer) {
    val tertiaryAction: Readable<ButtonAction?> = caching("tertiaryAction") {
        shared {
            val project = project()
            when {
                canCancelProject() -> ButtonAction("Cancel Project", SecondaryButtonSemantic) {
                    when (lens) {
                        ProjectLens.Contractor -> {
                            val mainMessage =
                                "Canceling a project often offends clients and may result in a bad review. If canceling is absolutely necessary you should communicate and notify client directly prior to clicking \"Cancel Project\" button."
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Cancel Project?",
                                    message = mainMessage,
                                    messageType = MessageType.Danger,
                                    confirmationText = "Cancel Project",
                                    onSubmit = { confirmed ->
                                        if (confirmed) {
                                            val session = notNullSession()
                                            session.nonCached.project.cancelProject(project._id)
                                            session.projects[project._id].invalidate()
                                        }
                                    }
                                )
                            )
                        }

                        ProjectLens.Customer -> {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Cancel Project?",
                                    message = "Canceling a project delays your project and impacts the contractor who has invested into this project thus far. Reasons for cancellation should be serious, such as lack of professionalism or communication by the contractor; or a homeowner experiencing significant life changes or income loss.",
                                    messageType = MessageType.Danger,
                                    confirmationText = "Cancel Project",
                                    onSubmit = { confirmed ->
                                        if (confirmed) {
                                            val session = notNullSession()
                                            session.nonCached.project.cancelProject(project._id)
                                            session.projects[project._id].invalidate()
                                        }
                                    }
                                )
                            )
                        }

                        ProjectLens.Preview -> {}
                    }
                }

                canAbandonProject() -> ButtonAction("Abandon Project", SecondaryButtonSemantic) {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            header = "Abandon Project?",
                            message = "Abandoning this project will notify the homeowner. Ensure you clearly communicate your reasons for this action. The project will be archived and shared links will no longer work.",
                            messageType = MessageType.Danger,
                            confirmationText = "Abandon Project",
                            onSubmit = { confirmed ->
                                if (confirmed) {
                                    val session = notNullSession()
                                    session.nonCached.project.abandonProject(project._id)
                                    session.projects[project._id].invalidate()
                                    screenNavigator.goBack()
                                }
                            }
                        )
                    )
                }

                // the archive button is removed for now
//                lens == ProjectLens.Contractor && project.state != ProjectState.Creating ->
//                    ButtonAction("Archive Project", SecondaryButtonSemantic) {
//                        dialogScreenNavigator.navigate(
//                            GenericConfirmationDialog(
//                                header = "Archive Project?",
//                                message = "Archiving this project will notify the homeowner. Ensure you clearly communicate your reasons for this action. The project will be archived and shared links will no longer work.",
//                                messageType = MessageType.Danger,
//                                confirmationText = "Archive Project",
//                                onSubmit = { confirmed ->
//                                    if (confirmed) {
//                                        val session = notNullSession()
//                                        session.nonCached.project.archiveProject(project._id)
//                                        session.projects[project._id].invalidate()
//                                        screenNavigator.goBack()
//                                    }
//                                }
//                            )
//                        )
//                    }

                lens == ProjectLens.Customer && canAcceptComplete() -> ButtonAction(
                    "Not Ready",
                    TertiaryButtonSemantic
                ) {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            header = "What's The Hold Up?",
                            message = "Tell your contractor why you aren’t approving yet so he can respond and after he feels he’s fully ready, he will resubmit for approval.",
                            confirmationText = "Hold Approval",
                            messageType = MessageType.Danger,
                            onSubmit = { confirmed ->
                                if (confirmed) {
                                    suspendCoroutineCancellable { cont ->
                                        dialogScreenNavigator.navigate(
                                            GenericFeedBackDialog(
                                                header = "Project Feedback",
                                                required = true,
                                                allowRemoving = false,
                                                onSubmit = { feedback ->
                                                    val session = notNullSession()
                                                    session.projects[project._id].modify(modification {
                                                        it.feedback assign feedback?.let {
                                                            ClientFeedback(
                                                                message = it
                                                            )
                                                        }
                                                    })
                                                    cont.resume(Unit)
                                                },
                                                onDismiss = {
                                                    cont.resume(Unit)
                                                }
                                            )
                                        )
                                        return@suspendCoroutineCancellable { dialogScreenNavigator.clear() }
                                    }
                                }
                            }
                        )
                    )
                }

                else -> null
            }
        }
    }

    val secondaryAction: Readable<ButtonAction?> = caching("secondaryAction") {
        shared {
            val project = project()
            when {
                lens == ProjectLens.Contractor && canRequestComplete() ->
                    ButtonAction("Request Final Completion", PrimaryButtonSemantic) {
                        if (punchListItems.awaitSuspending().any { it.required && it.complete == null }) {
                            dialogScreenNavigator.navigate(
                                GenericDialog(
                                    message = """
                                    Unable to proceed with final completion.
    
                                    All 'required' punch list tasks must be completed before you can request final completion. Return to the Punch List to resolve outstanding items.
                                """.trimIndent()
                                )
                            )
                        } else {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Submit For Project Completion?",
                                    message = """
                                    Requesting final completion will notify the homeowner to review and approve the project as complete.
    
                                    Before submitting:
    
                                    $BulletPoint Ensure all 'required' punch list tasks are completed.
                                    $BulletPoint Confirm the workspace is clean and free of tools or materials.
                                    $BulletPoint Address any remaining concerns or warranty items with the homeowner.
    
                                    Once submitted, the project will await homeowner approval for final completion. Any retention funds held in escrow will be released upon the homeowner’s acceptance.
                                """.trimIndent(),
                                    confirmationText = "Request Project Completion",
                                    messageType = MessageType.Confirmation,
                                    onSubmit = { confirmed ->
                                        if (confirmed) {
                                            val session = notNullSession()
                                            val id = project._id
                                            session.nonCached.project.requestComplete(id)
                                            session.projects[id].invalidate()
                                            delay(200)
                                        }
                                    }
                                ))
                        }
                    }

                lens == ProjectLens.Customer && canAcceptComplete() -> ButtonAction("Accept Complete") {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            header = "Finalize and Complete Project?",
                            message = """
                                    By approving this project:

                                    You confirm that all work, including punch list items, has been completed to your satisfaction.
                                    Retention funds (if applicable) will be released to the contractor.
                                    The project will be marked as complete in CrowPay, concluding the agreement.

                                    For minor concerns or adjustments, the warranty phase is available to address them after completion. If you have unresolved major concerns, do not proceed. Use CrowPay’s messaging to communicate with your contractor before finalizing.
                                """.trimIndent(),
                            confirmationText =
                                if (project.retention != null)
                                    "Accept & Pay Retention"
                                else
                                    "Accept Project Completion",
                            messageType = MessageType.Confirmation,
                            onSubmit = { confirmed ->
                                if (confirmed) {
                                    val session = notNullSession()
                                    val l = lineItems.awaitSuspending()
                                    val workItems = l.map { it.wraps }.toSet()
                                    val itemChanges = l.flatMap { it.changes }.toSet()
                                    val punchLists = punchListItems.awaitSuspending().toSet()
                                    session.nonCached.project.acceptComplete(
                                        project._id,
                                        project.hash(workItems, itemChanges, punchLists)
                                    )
                                    session.projects[project._id].invalidate()
                                    delay(200)
                                }
                            }
                        )
                    )
                }

                canRequestFunding() -> ButtonAction("Request Funds") {
                    dialogScreenNavigator.navigate(requestFundsDialog())
                }

                canCancelFundingRequest() -> ButtonAction("Rescind Funds Request", ButtonSemantic + ImportantSemantic) {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            header = "Rescind Funds Request?",
                            message = "You are about to cancel your request for funds. Make sure to communicate with your client why you are taking this action to prevent confusion.",
                            confirmationText = "Rescind Funds Request",
                            messageType = MessageType.Danger,
                            onSubmit = {
                                if (it) {
                                    val session = notNullSession()
                                    val id = project._id
                                    session.nonCached.project.cancelFundingRequest(id)
                                    session.projects[id].invalidate()
                                    delay(200)
                                }
                            }
                        )
                    )
                }

                canMakePayment() -> ButtonAction("Deposit Escrow Funds") {
                    dialogScreenNavigator.navigate(MakePaymentDialog(project))
                }

                lens == ProjectLens.Preview ->
                    ButtonAction("Accept Project", ButtonSemantic + AffirmativeSemantic) { onAccept() }

                canAbandonProject() -> ButtonAction("Unpublish", DangerButtonSemantic) {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            header = "Unpublish Project?",
                            message = "You are about to unpublish this project. Though it may be viewable, the customer will no longer be able to accept the project. Are you sure?",
                            confirmationText = "Unpublish Project",
                            messageType = MessageType.Danger,
                            onSubmit = {
                                if (it) {
                                    val id = project._id
                                    val session = notNullSession()
                                    val updated = session
                                        .nonCached
                                        .project
                                        .unpublishProject(id)

                                    session.projects.localSignalUpdate(
                                        matching = { it._id == id },
                                        modify = { updated }
                                    )

                                    screenNavigator.replace(
                                        EditProject(id)
                                    )
                                }
                            }
                        )
                    )
                }

                else -> null
            }
        }
    }

    val primaryAction: Readable<ButtonAction?> = caching("primaryAction") {
        shared {
            val project = project()
            when {
                canAbandonProject() -> ButtonAction("Copy Link") {
                    val link = notNullSession().nonCached.project.anonymousLink(project._id)
                    ExternalServices.setClipboardText(link)
                    toast("Project Link Copied")
                }

                else -> null
            }
        }
    }

    sizeConstraints(minHeight = AppDimensions.actionBarHeight) - darkSection - stack {
        spacing = AppDimensions.backgroundIndent/2

        ::exists { primaryAction() != null || secondaryAction() != null || tertiaryAction() != null }

        row {
            spacing = AppDimensions.buttonRowSpacing

            centered - button { applyReactiveActionFormatting(tertiaryAction) }

            if (lens == ProjectLens.Preview) {
                expanding - space()

                centered - row {
                    existsDefaultFalse{ anonymous() }
                    spacing = AppDimensions.buttonSpacing
                    primaryButton - button {
                        specCenteredText("Register")

                        action = Action("Register") { onRegister() }
                    }
                    secondaryButton - button {
                        specCenteredText("Log In")

                        action = Action("Log In") { onLogin() }
                    }
                }
            }

            expanding - space()

            gravity(Align.End, Align.Center) - button { applyReactiveActionFormatting(secondaryAction) }
            gravity(Align.End, Align.Center) - button { applyReactiveActionFormatting(primaryAction) }
        }
    }
}