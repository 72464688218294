package com.crowpay

import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.serialization.notNull
import com.lightningkite.serialization.properties
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.serializer

fun <K> DataClassPath<K, PayAppItem>.unresolved(): Condition<K> = (approved eq null) and (voided eq null) and (denied eq null)

fun <K> DataClassPath<K, ProjectLedgerItem>.deposited(): Condition<K> =
    (state inside setOf(PaymentState.Succeeded, PaymentState.Pending) or
        ((state eq PaymentState.AwaitingConfirmation) and (contractor eq true)))

fun <K> DataClassPath<K, ProjectLedgerItem>.contractorPayment(): Condition<K> =
    (state inside setOf(PaymentState.Succeeded, PaymentState.Pending, PaymentState.AwaitingConfirmation)) and (contractor eq true)

fun <K> DataClassPath<K, ProjectState>.isActive(): Condition<K> = notInside(ProjectState.inactiveStates)
fun <K> DataClassPath<K, ProjectState>.isContractorEditable(): Condition<K> = notInside(ProjectState.nonContractorEditableStates)

fun <K> DataClassPath<K, ProjectTimer>.paused(): Condition<K> =
    (executesAt eq null) and (executed eq null)

fun <K> DataClassPath<K, ProjectTimer>.awaitingExecution(): Condition<K> =
    (executesAt.notNull.lte(now())) and (executed eq null)

