package com.crowpay.views.theming

import com.crowpay.actuals.AppDimensions
import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter

data object AlphaSemantic : Semantic {
    override val key: String = "alpha"
    override fun default(theme: Theme): ThemeAndBack {
        val newBack = theme.background
            .closestColor()
            .copy(alpha = 0.5f)

        val newFront = if (newBack.toHSP().brightness < 0.5f) Color.white else Color.black

        return theme.copy(
            id = "alpha",
            background = newBack,
            foreground = newFront,
            derivations = mapOf(
                HoverSemantic to {
                    it.copy(background = it.background.closestColor().copy(alpha = 0.6f)).withBack
                },
                DownSemantic to {
                    it.copy(background = it.background.closestColor().copy(alpha = 0.5f)).withBack
                },
            ),
        ).withBack
    }
}

@ViewModifierDsl3
val ViewWriter.alphaTheme: ViewWrapper get() = AlphaSemantic.onNext

data object ImagePreviewSemantic : Semantic {
    override val key: String = "imgprv"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        background = AppColors.grey.dark,
        outlineWidth = 0.px,
        cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
        derivations = mapOf(
            CardSemantic to AlphaSemantic::default
        )
    ).withBack
}