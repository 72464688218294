package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.contactInfo
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.screens.client.ClientProject
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.contractor.ContractorProject
import com.crowpay.views.screens.contractor.ContractorProjects
import com.crowpay.views.screens.contractor.CreateProject
import com.crowpay.views.screens.contractor.EditProject
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.children
import com.lightningkite.kiteui.views.l2.icon


fun ViewWriter.projectList(
    lens: ProjectLens,
    projects: Readable<List<Project>>,
    contacts: Readable<List<Contact>>,
    showArchivedProjects: Property<Boolean>,
): ViewModifiable {
    return col {
        spacing = 0.dp
        row {
            title("Projects")
            expanding - space()
            row {
                existsDefaultFalse {
                    val active = contractorActive()
                    active != ContractorStatus.AwaitingApproval
                }
                centered - subTitle("Show Archived Projects")
                centered - checkbox {
                    checked bind showArchivedProjects
                }
            }
        }

        space(AppDimensions.majorColumnSpacing)

        atStart - primaryButton - button {
            existsDefaultFalse { selectedContractor() != null && contractorActive() == ContractorStatus.Approved }
            specCenteredText("+ Project")
            onClick {
                screenNavigator.navigate(CreateProject())
            }
        }

        space(AppDimensions.majorColumnSpacing)

        subTitle {
            existsDefaultFalse {
                val active = contractorActive()
                projects().isEmpty() && active != ContractorStatus.AwaitingApproval
            }
            content = when (lens) {
                ProjectLens.Contractor -> "You have no contracts yet. Create a new Contract."
                ProjectLens.Customer -> "You have no Projects"
                ProjectLens.Preview -> ""
            }
        }

        expanding - recyclerView {
            children(
                projects,
                id = { it._id }
            ) { project ->
                val contact = shared {
                    val p = project()
                    contacts().find {
                        when (it) {
                            is Client -> {
                                it._id == p.client
                            }

                            is Contractor -> {
                                it._id == p.contractor
                            }

                            else -> false
                        }
                    }
                }
                outlined - row {
                    spacing = 0.dp
                    space(AppDimensions.backgroundIndent)
                    expanding - button {
                        rowCollapsingToColumn(60.rem) {
                            spacing = AppDimensions.sectionIndent
                            rowCollapsingToColumn(80.rem) {
                                centered - subTitle {
                                    ::content { project().name }
                                }

                                greySeparator()

                                row {
                                    ::visible{ contact() != null }
                                    sizeConstraints(width = 3.rem, height = 3.rem) - centered - image {
                                        ::exists{ contact()?.image != null }
                                        ::source{ ImageRemote(contact()?.image?.location ?: "") }
                                    }

                                    centered - contactInfo(contact)
                                }
                            }

                            expanding - gravity(Align.End, Align.Center) - body {
                                align = Align.End
                                ::content{ project().state.displayName }
                                dynamicTheme {
                                    ProjectStateSemantic(project().state)
                                }
                            }
                        }
                        onClick {
                            val p = project.await()
                            val to = when {
                                lens == ProjectLens.Customer -> ClientProject(p._id)
                                p.state == ProjectState.Creating -> EditProject(p._id)
                                else -> ContractorProject(p._id)
                            }
                            screenNavigator.navigate(to)
                        }
                    }
                    compact - deleteButton - button {
                        existsDefaultFalse { project().state == ProjectState.Creating }
                        centered - icon(Icon.trash, "Delete")
                        onClick {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Delete Draft?",
                                    message = "You are about to delete this project draft. Deleted drafts cannot be recovered. Delete this draft?",
                                    confirmationText = "Delete Draft",
                                    messageType = MessageType.Danger,
                                    onSubmit = { confirmed ->
                                        if (confirmed) {
                                            notNullSession().projects[project()._id].delete()
                                            screenNavigator.reset(ContractorProjects())

                                        }
                                    }
                                )
                            )
                        }
                    }
                    space(AppDimensions.backgroundIndent)
                }
            }
        }
    }
}
