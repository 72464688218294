package com.crowpay.utils

import com.crowpay.actuals.AppDimensions
import com.crowpay.views.components.space
import com.crowpay.views.theming.PrimaryButtonSemantic
import com.crowpay.views.theming.body
import com.crowpay.views.theming.specCenteredContent
import com.crowpay.views.theming.specCenteredText
import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.reactive
import com.lightningkite.kiteui.views.ViewDsl
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.dynamicTheme
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.l2.icon

data class ButtonAction(
    val name: String,
    val theme: ThemeDerivation = PrimaryButtonSemantic,
    val displayName: (ReactiveContext.()->String)? = null,
    val displayIcon: Icon? = null,
    val enabled: (ReactiveContext.()->Boolean)? = null,
    val action: suspend ()->Unit
): Action by Action(name, action)

fun Button.applyActionFormatting(action: ButtonAction) {
    themeChoice += action.theme
    when {
        action.displayIcon != null -> specCenteredContent {
            row {
                icon(action.displayIcon, action.name)
                body {
                    if (action.displayName != null) ::content.invoke(action.displayName)
                    else { content = action.name }
                }
            }
        }
        action.displayName != null -> specCenteredText(action.displayName)
        else -> specCenteredText(action.name)
    }
    if (action.displayName != null) specCenteredText(action.displayName)
    else specCenteredText(action.name)
    action.enabled?.let { ::enabled.invoke(it) }
    this.action = action
}
fun Button.applyReactiveActionFormatting(action: Readable<ButtonAction?>) {
    val before = themeChoice
    var icon: IconView? = null

    specCenteredContent {
        row {
            icon = icon { exists = false }
            body {
                ::content { action()?.let { it.displayName?.invoke() ?: it.name } ?: "" }
            }
        }
    }
    ::enabled { action()?.enabled?.invoke() ?: true }

    reactive {
        val a = action()
        if (a == null) {
            exists = false
            return@reactive
        }
        if (a.displayIcon != null) {
            icon?.source = a.displayIcon
            icon?.exists = true
        }
        themeChoice = before + a.theme
        this@applyReactiveActionFormatting.action = a
    }
}