// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareUserFields() {
    val props: Array<SerializableProperty<User, *>> = arrayOf(User__id, User_email, User_phoneNumber, User_firstName, User_lastName, User_address, User_membership, User_paymentId, User_role, User_receivesNewContractorEmails, User_lastAgreement)
    User.serializer().properties { props }
}
val <K> DataClassPath<K, User>._id: DataClassPath<K, UUID> get() = this[User__id]
val <K> DataClassPath<K, User>.email: DataClassPath<K, String> get() = this[User_email]
val <K> DataClassPath<K, User>.phoneNumber: DataClassPath<K, String> get() = this[User_phoneNumber]
val <K> DataClassPath<K, User>.firstName: DataClassPath<K, String> get() = this[User_firstName]
val <K> DataClassPath<K, User>.lastName: DataClassPath<K, String> get() = this[User_lastName]
val <K> DataClassPath<K, User>.address: DataClassPath<K, Address> get() = this[User_address]
val <K> DataClassPath<K, User>.membership: DataClassPath<K, UserMembership?> get() = this[User_membership]
val <K> DataClassPath<K, User>.paymentId: DataClassPath<K, String?> get() = this[User_paymentId]
val <K> DataClassPath<K, User>.role: DataClassPath<K, UserRole> get() = this[User_role]
val <K> DataClassPath<K, User>.receivesNewContractorEmails: DataClassPath<K, Boolean> get() = this[User_receivesNewContractorEmails]
val <K> DataClassPath<K, User>.lastAgreement: DataClassPath<K, ClientTermsAndConditionsAgreement?> get() = this[User_lastAgreement]
inline val User.Companion.path: DataClassPath<User, User> get() = path<User>()


object User__id: SerializableProperty<User, UUID> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(0) }
    override fun get(receiver: User): UUID = receiver._id
    override fun setCopy(receiver: User, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object User_email: SerializableProperty<User, String> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(1) }
    override fun get(receiver: User): String = receiver.email
    override fun setCopy(receiver: User, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(1) }
}
object User_phoneNumber: SerializableProperty<User, String> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(2) }
    override fun get(receiver: User): String = receiver.phoneNumber
    override fun setCopy(receiver: User, value: String) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(2) }
}
object User_firstName: SerializableProperty<User, String> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(3) }
    override fun get(receiver: User): String = receiver.firstName
    override fun setCopy(receiver: User, value: String) = receiver.copy(firstName = value)
    override val serializer: KSerializer<String> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(3) }
}
object User_lastName: SerializableProperty<User, String> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(4) }
    override fun get(receiver: User): String = receiver.lastName
    override fun setCopy(receiver: User, value: String) = receiver.copy(lastName = value)
    override val serializer: KSerializer<String> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(4) }
}
object User_address: SerializableProperty<User, Address> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(5) }
    override fun get(receiver: User): Address = receiver.address
    override fun setCopy(receiver: User, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Address> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(5) }
}
object User_membership: SerializableProperty<User, UserMembership?> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(6) }
    override fun get(receiver: User): UserMembership? = receiver.membership
    override fun setCopy(receiver: User, value: UserMembership?) = receiver.copy(membership = value)
    override val serializer: KSerializer<UserMembership?> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<UserMembership?> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(6) }
    override val default: UserMembership? = null
    override val defaultCode: String? = "null" 
}
object User_paymentId: SerializableProperty<User, String?> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(7) }
    override fun get(receiver: User): String? = receiver.paymentId
    override fun setCopy(receiver: User, value: String?) = receiver.copy(paymentId = value)
    override val serializer: KSerializer<String?> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(7) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object User_role: SerializableProperty<User, UserRole> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(8) }
    override fun get(receiver: User): UserRole = receiver.role
    override fun setCopy(receiver: User, value: UserRole) = receiver.copy(role = value)
    override val serializer: KSerializer<UserRole> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<UserRole> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(8) }
}
object User_receivesNewContractorEmails: SerializableProperty<User, Boolean> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(9) }
    override fun get(receiver: User): Boolean = receiver.receivesNewContractorEmails
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(receivesNewContractorEmails = value)
    override val serializer: KSerializer<Boolean> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(9) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object User_lastAgreement: SerializableProperty<User, ClientTermsAndConditionsAgreement?> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(10) }
    override fun get(receiver: User): ClientTermsAndConditionsAgreement? = receiver.lastAgreement
    override fun setCopy(receiver: User, value: ClientTermsAndConditionsAgreement?) = receiver.copy(lastAgreement = value)
    override val serializer: KSerializer<ClientTermsAndConditionsAgreement?> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<ClientTermsAndConditionsAgreement?> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(10) }
    override val default: ClientTermsAndConditionsAgreement? = null
    override val defaultCode: String? = "null" 
}
