// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareScopeViewFields() {
    val props: Array<SerializableProperty<ScopeView, *>> = arrayOf(ScopeView__id, ScopeView_project, ScopeView_scopeTitle, ScopeView_description, ScopeView_scopeSet, ScopeView_fromChangeRequest)
    ScopeView.serializer().properties { props }
}
val <K> DataClassPath<K, ScopeView>._id: DataClassPath<K, UUID> get() = this[ScopeView__id]
val <K> DataClassPath<K, ScopeView>.project: DataClassPath<K, UUID> get() = this[ScopeView_project]
val <K> DataClassPath<K, ScopeView>.scopeTitle: DataClassPath<K, String> get() = this[ScopeView_scopeTitle]
val <K> DataClassPath<K, ScopeView>.description: DataClassPath<K, String?> get() = this[ScopeView_description]
val <K> DataClassPath<K, ScopeView>.scopeSet: DataClassPath<K, Boolean> get() = this[ScopeView_scopeSet]
val <K> DataClassPath<K, ScopeView>.fromChangeRequest: DataClassPath<K, Boolean> get() = this[ScopeView_fromChangeRequest]
inline val ScopeView.Companion.path: DataClassPath<ScopeView, ScopeView> get() = path<ScopeView>()


object ScopeView__id: SerializableProperty<ScopeView, UUID> {
    override val name: String by lazy { ScopeView.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ScopeView): UUID = receiver._id
    override fun setCopy(receiver: ScopeView, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ScopeView.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ScopeView.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object ScopeView_project: SerializableProperty<ScopeView, UUID> {
    override val name: String by lazy { ScopeView.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ScopeView): UUID = receiver.project
    override fun setCopy(receiver: ScopeView, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (ScopeView.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ScopeView.serializer().descriptor.getElementAnnotations(1) }
}
object ScopeView_scopeTitle: SerializableProperty<ScopeView, String> {
    override val name: String by lazy { ScopeView.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ScopeView): String = receiver.scopeTitle
    override fun setCopy(receiver: ScopeView, value: String) = receiver.copy(scopeTitle = value)
    override val serializer: KSerializer<String> by lazy { (ScopeView.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ScopeView.serializer().descriptor.getElementAnnotations(2) }
}
object ScopeView_description: SerializableProperty<ScopeView, String?> {
    override val name: String by lazy { ScopeView.serializer().descriptor.getElementName(3) }
    override fun get(receiver: ScopeView): String? = receiver.description
    override fun setCopy(receiver: ScopeView, value: String?) = receiver.copy(description = value)
    override val serializer: KSerializer<String?> by lazy { (ScopeView.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { ScopeView.serializer().descriptor.getElementAnnotations(3) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object ScopeView_scopeSet: SerializableProperty<ScopeView, Boolean> {
    override val name: String by lazy { ScopeView.serializer().descriptor.getElementName(4) }
    override fun get(receiver: ScopeView): Boolean = receiver.scopeSet
    override fun setCopy(receiver: ScopeView, value: Boolean) = receiver.copy(scopeSet = value)
    override val serializer: KSerializer<Boolean> by lazy { (ScopeView.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ScopeView.serializer().descriptor.getElementAnnotations(4) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object ScopeView_fromChangeRequest: SerializableProperty<ScopeView, Boolean> {
    override val name: String by lazy { ScopeView.serializer().descriptor.getElementName(5) }
    override fun get(receiver: ScopeView): Boolean = receiver.fromChangeRequest
    override fun setCopy(receiver: ScopeView, value: Boolean) = receiver.copy(fromChangeRequest = value)
    override val serializer: KSerializer<Boolean> by lazy { (ScopeView.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { ScopeView.serializer().descriptor.getElementAnnotations(5) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
