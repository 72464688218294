package com.crowpay.views.components.project.projectInfo

import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.keyValue
import com.crowpay.views.components.project.activity.ProjectMessageThread
import com.crowpay.views.components.sectionIndentCol
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.px
import com.crowpay.views.theming.lightSection
import com.crowpay.views.theming.specCenteredText
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlin.time.Duration.Companion.milliseconds

private fun ProjectView.contractorInformation(writer: ViewWriter) = with(writer) {
    col {
        title { ::content{ "${contractor().preferredTitle} Information" } }
        lightSection - stack {
            col {
                keyValue("Name") { contractor().name }
                keyValue("Address") { contractor().address.toString() }
                keyValue("Email") { contractor().email }
                keyValue("Phone") { PhoneNumberFormat.USA.format(contractor().phoneNumber.filter { it.isDigit() }) }
            }
            atBottomEnd - creamButton - button {
                specCenteredText("Message")
                onClick {
                    dialogScreenNavigator.navigate(
                        ProjectMessageThread.Dialog(lens, project())
                    )
                }
            }
        }
    }
}

private fun ProjectView.projectInformation(writer: ViewWriter) = with(writer) {
    col {
        val opened = Property(lens == ProjectLens.Contractor)
        val openedDebounced = opened.debounce(this.theme.transitionDuration + 1.milliseconds)
        reactive {
            if (openedDebounced()) scrollIntoView(null, null, true)
        }
        button {
            spacing = 0.rem
            row {
                spacing = 0.rem
                title("Project Information")
                expanding - space()
                centered - expandIcon(opened)
            }
            onClick {
                opened.toggle()
            }
        }
        onlyWhen { opened() } - sizeConstraints(minHeight = 11.rem) - lightSection - col {
            keyValue("Project Name") { project().name }
            keyValue("Address") { project().address.toString() }
            keyValue("Location") { project().location?.takeIf { it.isNotBlank() } }
        }
    }
}

private fun ProjectView.billingInformation(writer: ViewWriter) = with(writer) {
    col {
        val opened = Property(lens == ProjectLens.Contractor)
        val openedDebounced = opened.debounce(this.theme.transitionDuration + 1.milliseconds)
        reactive {
            if (openedDebounced()) scrollIntoView(null, null, true)
        }
        button {
            spacing = 0.rem
            row {
                spacing = 0.rem
                title("Billing Information")
                expanding - space()
                centered - expandIcon(opened)
            }
            onClick {
                opened.set(!opened())
            }
        }
        onlyWhen { opened() } - sizeConstraints(minHeight = 11.rem) - lightSection - col {
            keyValue("Name") { client().name }
            keyValue("Address") { client().address.toString() }
            keyValue("Email") { client().email }
            keyValue("Phone") { PhoneNumberFormat.USA.format(client().phoneNumber.filter { it.isDigit() }) }
        }
    }
}

fun ProjectView.contacts(writer: ViewWriter) = with(writer) {
    col {
//        space()
        if (lens != ProjectLens.Contractor) rowCollapsingToColumn(AppDimensions.pageWidth) {
            expanding - contractorInformation(this)
            expanding - space()
        }
        rowCollapsingToColumn(AppDimensions.pageWidth) {
            expanding - projectInformation(this)
            expanding - billingInformation(this)
        }
    }
}