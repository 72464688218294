package com.crowpay.views.screens.client

import com.crowpay.views.screens.common.AuthorizedProjectView
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.lightningkite.UUID
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.reactive.ImmediateWritable
import com.lightningkite.kiteui.reactive.Property

@Routable("client/projects/{projectId}")
class ClientProject(
    projectId: UUID
) : AuthorizedProjectView(
    ProjectLens.Customer,
    projectId,
)