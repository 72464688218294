package com.crowpay.views.theming

import com.lightningkite.kiteui.models.Semantic
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.Theme
import com.lightningkite.kiteui.models.ThemeAndBack
import com.lightningkite.kiteui.views.ViewDsl
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.Separator
import com.lightningkite.kiteui.views.direct.separator

data object SeparatorSemantic : Semantic {
    override val key: String = "sep"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        foreground = AppColors.grey.light1 //Color.fromHex(0xE5E8E5)
    ).withoutBack
}

@ViewDsl
fun ViewWriter.greySeparator(setup: Separator.()->Unit = {}): Separator {
    SeparatorSemantic.onNext
    return separator(setup)
}