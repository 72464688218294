// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun preparePayAppItemFields() {
    val props: Array<SerializableProperty<PayAppItem, *>> = arrayOf(PayAppItem__id, PayAppItem_payApp, PayAppItem_lineItem, PayAppItem_amount, PayAppItem_approved, PayAppItem_denied, PayAppItem_voided, PayAppItem_project, PayAppItem_submitted, PayAppItem_accepted)
    PayAppItem.serializer().properties { props }
}
val <K> DataClassPath<K, PayAppItem>._id: DataClassPath<K, UUID> get() = this[PayAppItem__id]
val <K> DataClassPath<K, PayAppItem>.payApp: DataClassPath<K, UUID> get() = this[PayAppItem_payApp]
val <K> DataClassPath<K, PayAppItem>.lineItem: DataClassPath<K, UUID> get() = this[PayAppItem_lineItem]
val <K> DataClassPath<K, PayAppItem>.amount: DataClassPath<K, Long> get() = this[PayAppItem_amount]
val <K> DataClassPath<K, PayAppItem>.approved: DataClassPath<K, AccessInfo?> get() = this[PayAppItem_approved]
val <K> DataClassPath<K, PayAppItem>.denied: DataClassPath<K, AccessInfo?> get() = this[PayAppItem_denied]
val <K> DataClassPath<K, PayAppItem>.voided: DataClassPath<K, AccessInfo?> get() = this[PayAppItem_voided]
val <K> DataClassPath<K, PayAppItem>.project: DataClassPath<K, UUID> get() = this[PayAppItem_project]
val <K> DataClassPath<K, PayAppItem>.submitted: DataClassPath<K, Instant?> get() = this[PayAppItem_submitted]
val <K> DataClassPath<K, PayAppItem>.accepted: DataClassPath<K, Boolean?> get() = this[PayAppItem_accepted]
inline val PayAppItem.Companion.path: DataClassPath<PayAppItem, PayAppItem> get() = path<PayAppItem>()


object PayAppItem__id: SerializableProperty<PayAppItem, UUID> {
    override val name: String by lazy { PayAppItem.serializer().descriptor.getElementName(0) }
    override fun get(receiver: PayAppItem): UUID = receiver._id
    override fun setCopy(receiver: PayAppItem, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (PayAppItem.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PayAppItem.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object PayAppItem_payApp: SerializableProperty<PayAppItem, UUID> {
    override val name: String by lazy { PayAppItem.serializer().descriptor.getElementName(1) }
    override fun get(receiver: PayAppItem): UUID = receiver.payApp
    override fun setCopy(receiver: PayAppItem, value: UUID) = receiver.copy(payApp = value)
    override val serializer: KSerializer<UUID> by lazy { (PayAppItem.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PayAppItem.serializer().descriptor.getElementAnnotations(1) }
}
object PayAppItem_lineItem: SerializableProperty<PayAppItem, UUID> {
    override val name: String by lazy { PayAppItem.serializer().descriptor.getElementName(2) }
    override fun get(receiver: PayAppItem): UUID = receiver.lineItem
    override fun setCopy(receiver: PayAppItem, value: UUID) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<UUID> by lazy { (PayAppItem.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PayAppItem.serializer().descriptor.getElementAnnotations(2) }
}
object PayAppItem_amount: SerializableProperty<PayAppItem, Long> {
    override val name: String by lazy { PayAppItem.serializer().descriptor.getElementName(3) }
    override fun get(receiver: PayAppItem): Long = receiver.amount
    override fun setCopy(receiver: PayAppItem, value: Long) = receiver.copy(amount = value)
    override val serializer: KSerializer<Long> by lazy { (PayAppItem.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Long> }
    override val annotations: List<Annotation> by lazy { PayAppItem.serializer().descriptor.getElementAnnotations(3) }
}
object PayAppItem_approved: SerializableProperty<PayAppItem, AccessInfo?> {
    override val name: String by lazy { PayAppItem.serializer().descriptor.getElementName(4) }
    override fun get(receiver: PayAppItem): AccessInfo? = receiver.approved
    override fun setCopy(receiver: PayAppItem, value: AccessInfo?) = receiver.copy(approved = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (PayAppItem.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { PayAppItem.serializer().descriptor.getElementAnnotations(4) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object PayAppItem_denied: SerializableProperty<PayAppItem, AccessInfo?> {
    override val name: String by lazy { PayAppItem.serializer().descriptor.getElementName(5) }
    override fun get(receiver: PayAppItem): AccessInfo? = receiver.denied
    override fun setCopy(receiver: PayAppItem, value: AccessInfo?) = receiver.copy(denied = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (PayAppItem.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { PayAppItem.serializer().descriptor.getElementAnnotations(5) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object PayAppItem_voided: SerializableProperty<PayAppItem, AccessInfo?> {
    override val name: String by lazy { PayAppItem.serializer().descriptor.getElementName(6) }
    override fun get(receiver: PayAppItem): AccessInfo? = receiver.voided
    override fun setCopy(receiver: PayAppItem, value: AccessInfo?) = receiver.copy(voided = value)
    override val serializer: KSerializer<AccessInfo?> by lazy { (PayAppItem.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<AccessInfo?> }
    override val annotations: List<Annotation> by lazy { PayAppItem.serializer().descriptor.getElementAnnotations(6) }
    override val default: AccessInfo? = null
    override val defaultCode: String? = "null" 
}
object PayAppItem_project: SerializableProperty<PayAppItem, UUID> {
    override val name: String by lazy { PayAppItem.serializer().descriptor.getElementName(7) }
    override fun get(receiver: PayAppItem): UUID = receiver.project
    override fun setCopy(receiver: PayAppItem, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (PayAppItem.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PayAppItem.serializer().descriptor.getElementAnnotations(7) }
}
object PayAppItem_submitted: SerializableProperty<PayAppItem, Instant?> {
    override val name: String by lazy { PayAppItem.serializer().descriptor.getElementName(8) }
    override fun get(receiver: PayAppItem): Instant? = receiver.submitted
    override fun setCopy(receiver: PayAppItem, value: Instant?) = receiver.copy(submitted = value)
    override val serializer: KSerializer<Instant?> by lazy { (PayAppItem.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { PayAppItem.serializer().descriptor.getElementAnnotations(8) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
object PayAppItem_accepted: SerializableProperty<PayAppItem, Boolean?> {
    override val name: String by lazy { PayAppItem.serializer().descriptor.getElementName(9) }
    override fun get(receiver: PayAppItem): Boolean? = receiver.accepted
    override fun setCopy(receiver: PayAppItem, value: Boolean?) = receiver.copy(accepted = value)
    override val serializer: KSerializer<Boolean?> by lazy { (PayAppItem.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<Boolean?> }
    override val annotations: List<Annotation> by lazy { PayAppItem.serializer().descriptor.getElementAnnotations(9) }
    override val default: Boolean? = null
    override val defaultCode: String? = "null" 
}
