// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareCompletionMessageCommentFields() {
    val props: Array<SerializableProperty<CompletionMessageComment, *>> = arrayOf(CompletionMessageComment__id, CompletionMessageComment_completionMessage, CompletionMessageComment_project, CompletionMessageComment_lineItem, CompletionMessageComment_sender, CompletionMessageComment_clientMessage, CompletionMessageComment_message, CompletionMessageComment_created)
    CompletionMessageComment.serializer().properties { props }
}
val <K> DataClassPath<K, CompletionMessageComment>._id: DataClassPath<K, UUID> get() = this[CompletionMessageComment__id]
val <K> DataClassPath<K, CompletionMessageComment>.completionMessage: DataClassPath<K, UUID> get() = this[CompletionMessageComment_completionMessage]
val <K> DataClassPath<K, CompletionMessageComment>.project: DataClassPath<K, UUID> get() = this[CompletionMessageComment_project]
val <K> DataClassPath<K, CompletionMessageComment>.lineItem: DataClassPath<K, UUID> get() = this[CompletionMessageComment_lineItem]
val <K> DataClassPath<K, CompletionMessageComment>.sender: DataClassPath<K, UUID> get() = this[CompletionMessageComment_sender]
val <K> DataClassPath<K, CompletionMessageComment>.clientMessage: DataClassPath<K, Boolean> get() = this[CompletionMessageComment_clientMessage]
val <K> DataClassPath<K, CompletionMessageComment>.message: DataClassPath<K, String> get() = this[CompletionMessageComment_message]
val <K> DataClassPath<K, CompletionMessageComment>.created: DataClassPath<K, Instant> get() = this[CompletionMessageComment_created]
inline val CompletionMessageComment.Companion.path: DataClassPath<CompletionMessageComment, CompletionMessageComment> get() = path<CompletionMessageComment>()


object CompletionMessageComment__id: SerializableProperty<CompletionMessageComment, UUID> {
    override val name: String by lazy { CompletionMessageComment.serializer().descriptor.getElementName(0) }
    override fun get(receiver: CompletionMessageComment): UUID = receiver._id
    override fun setCopy(receiver: CompletionMessageComment, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (CompletionMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { CompletionMessageComment.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object CompletionMessageComment_completionMessage: SerializableProperty<CompletionMessageComment, UUID> {
    override val name: String by lazy { CompletionMessageComment.serializer().descriptor.getElementName(1) }
    override fun get(receiver: CompletionMessageComment): UUID = receiver.completionMessage
    override fun setCopy(receiver: CompletionMessageComment, value: UUID) = receiver.copy(completionMessage = value)
    override val serializer: KSerializer<UUID> by lazy { (CompletionMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { CompletionMessageComment.serializer().descriptor.getElementAnnotations(1) }
}
object CompletionMessageComment_project: SerializableProperty<CompletionMessageComment, UUID> {
    override val name: String by lazy { CompletionMessageComment.serializer().descriptor.getElementName(2) }
    override fun get(receiver: CompletionMessageComment): UUID = receiver.project
    override fun setCopy(receiver: CompletionMessageComment, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> by lazy { (CompletionMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { CompletionMessageComment.serializer().descriptor.getElementAnnotations(2) }
}
object CompletionMessageComment_lineItem: SerializableProperty<CompletionMessageComment, UUID> {
    override val name: String by lazy { CompletionMessageComment.serializer().descriptor.getElementName(3) }
    override fun get(receiver: CompletionMessageComment): UUID = receiver.lineItem
    override fun setCopy(receiver: CompletionMessageComment, value: UUID) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<UUID> by lazy { (CompletionMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { CompletionMessageComment.serializer().descriptor.getElementAnnotations(3) }
}
object CompletionMessageComment_sender: SerializableProperty<CompletionMessageComment, UUID> {
    override val name: String by lazy { CompletionMessageComment.serializer().descriptor.getElementName(4) }
    override fun get(receiver: CompletionMessageComment): UUID = receiver.sender
    override fun setCopy(receiver: CompletionMessageComment, value: UUID) = receiver.copy(sender = value)
    override val serializer: KSerializer<UUID> by lazy { (CompletionMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { CompletionMessageComment.serializer().descriptor.getElementAnnotations(4) }
}
object CompletionMessageComment_clientMessage: SerializableProperty<CompletionMessageComment, Boolean> {
    override val name: String by lazy { CompletionMessageComment.serializer().descriptor.getElementName(5) }
    override fun get(receiver: CompletionMessageComment): Boolean = receiver.clientMessage
    override fun setCopy(receiver: CompletionMessageComment, value: Boolean) = receiver.copy(clientMessage = value)
    override val serializer: KSerializer<Boolean> by lazy { (CompletionMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { CompletionMessageComment.serializer().descriptor.getElementAnnotations(5) }
}
object CompletionMessageComment_message: SerializableProperty<CompletionMessageComment, String> {
    override val name: String by lazy { CompletionMessageComment.serializer().descriptor.getElementName(6) }
    override fun get(receiver: CompletionMessageComment): String = receiver.message
    override fun setCopy(receiver: CompletionMessageComment, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> by lazy { (CompletionMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { CompletionMessageComment.serializer().descriptor.getElementAnnotations(6) }
}
object CompletionMessageComment_created: SerializableProperty<CompletionMessageComment, Instant> {
    override val name: String by lazy { CompletionMessageComment.serializer().descriptor.getElementName(7) }
    override fun get(receiver: CompletionMessageComment): Instant = receiver.created
    override fun setCopy(receiver: CompletionMessageComment, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> by lazy { (CompletionMessageComment.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { CompletionMessageComment.serializer().descriptor.getElementAnnotations(7) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
