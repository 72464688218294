package com.crowpay.extensions

import com.crowpay.Contractor
import com.crowpay.SharedUtils

fun Contractor.basicInfoComplete(): Boolean =
    name.isNotBlank() &&
            email.matches(SharedUtils.emailPattern) &&
            phoneNumber.matches(SharedUtils.phonePattern) &&
            address.isNotBlank() &&

            contactFirstName.isNotBlank() &&
            contactLastName.isNotBlank() &&
            contactEmail.matches(SharedUtils.emailPattern) &&
            contactPhoneNumber.matches(SharedUtils.phonePattern) &&
            contactAddress.isNotBlank()

fun Contractor.businessInfoComplete(): Boolean =
    stateEntityNumber.isNotBlank() &&
            ein.isNotBlank() &&
            !trade.isNullOrBlank()

fun Contractor.infoComplete(): Boolean =
    basicInfoComplete() && businessInfoComplete()

