package com.crowpay.views.components

import com.crowpay.actuals.AppDimensions
import com.crowpay.LineItem
import com.crowpay.LineItemInterface
import com.crowpay.PendingLineItem
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.lazy
import com.crowpay.utils.validation.ValidatedWritable
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.zeroToNull
import com.crowpay.views.components.files.FileData
import com.crowpay.views.components.files.fileManager
import com.crowpay.views.components.files.renderFiles
import com.crowpay.views.components.files.resolveProjectAttachments
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.fieldTheme


class LineItemForm<T: LineItemInterface>(
    val line: Draft<T>,
    val files: Writable<List<FileData>>,
    val allowEditing: Readable<Boolean>? = null
) : Validator(), Screen {

    private val _name: ValidatedWritable<String> = line
        .lens(
            get = { it.name },
            modify = { og, it ->
                when (og) {
                    is LineItem -> og.copy(name = it) as T
                    is PendingLineItem -> og.copy(name = it) as T
                    else -> og
                }
            }
        )
        .validate { it.isNotBlank() }
    val name: Writable<String> get() = _name
    private val _description: ValidatedWritable<String> = line
        .lens(
            get = { it.description },
            modify = { og, it ->
                when (og) {
                    is LineItem -> og.copy(description = it) as T
                    is PendingLineItem -> og.copy(description = it) as T
                    else -> og
                }
            }
        )
        .validate { it.isNotBlank() }
    val description: Writable<String> get() = _description
    private val _price: ValidatedWritable<Long> = line
        .lens(
            get = { it.price },
            modify = { og, it ->
                when (og) {
                    is LineItem -> og.copy(originalPrice = it) as T
                    is PendingLineItem -> og.copy(price = it) as T
                    else -> og
                }
            }
        )
        .validate { it >= 0 }
    val price: Writable<Long> get() = _price

    override fun ViewWriter.render() {

        col {

            rowCollapsingToColumn(AppDimensions.thinCollapseWidth) {
                expanding - requiredField("Work Item Title") {
                    textInput {
                        allowEditing?.let { ::enabled { it() } }
                        keyboardHints = KeyboardHints.title
                        hint = "Name"
                        content bind _name
                    }
                }
                expanding - requiredField("Price (Dollars)") {
                    numberInput {
                        allowEditing?.let { ::enabled { it() } }
                        keyboardHints = KeyboardHints.integer
                        hint = "Price"
                        content bind _price.zeroToNull()
                    }
                }
            }
            requiredField("Description") {
                textArea {
                    allowEditing?.let { ::enabled { it() } }
                    hint = "Description"
                    content bind _description
                } in sizeConstraints(minHeight = 7.rem)
            }

            col {
                label2(shared { "Files (Images, Specs, Info Related to Work this Item)" }) {
                    if (allowEditing == null)
                        fieldTheme - fileManager(
                            files = files,
                            emptyText = "No Files Uploaded"
                        ) { notNullSession().nonCached.lineItem::uploadFileForRequest }
                    else
                        lazy(allowEditing) { edit ->
                            if (edit)
                                fieldTheme - fileManager(
                                    files = files,
                                    emptyText = "No Files Uploaded"
                                ) { notNullSession().nonCached.lineItem::uploadFileForRequest }
                            else
                                renderFiles(
                                    sharedSuspending { files().resolveProjectAttachments() },
                                    10.rem
                                )
                        }
                }
            }
        }
    }
}