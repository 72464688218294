// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareAccessInfoFields() {
    val props: Array<SerializableProperty<AccessInfo, *>> = arrayOf(AccessInfo_user, AccessInfo_ip, AccessInfo_sessionId, AccessInfo_userAgent, AccessInfo_timestamp)
    AccessInfo.serializer().properties { props }
}
val <K> DataClassPath<K, AccessInfo>.user: DataClassPath<K, UUID> get() = this[AccessInfo_user]
val <K> DataClassPath<K, AccessInfo>.ip: DataClassPath<K, String> get() = this[AccessInfo_ip]
val <K> DataClassPath<K, AccessInfo>.sessionId: DataClassPath<K, UUID?> get() = this[AccessInfo_sessionId]
val <K> DataClassPath<K, AccessInfo>.userAgent: DataClassPath<K, String> get() = this[AccessInfo_userAgent]
val <K> DataClassPath<K, AccessInfo>.timestamp: DataClassPath<K, Instant> get() = this[AccessInfo_timestamp]
inline val AccessInfo.Companion.path: DataClassPath<AccessInfo, AccessInfo> get() = path<AccessInfo>()


object AccessInfo_user: SerializableProperty<AccessInfo, UUID> {
    override val name: String by lazy { AccessInfo.serializer().descriptor.getElementName(0) }
    override fun get(receiver: AccessInfo): UUID = receiver.user
    override fun setCopy(receiver: AccessInfo, value: UUID) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID> by lazy { (AccessInfo.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { AccessInfo.serializer().descriptor.getElementAnnotations(0) }
}
object AccessInfo_ip: SerializableProperty<AccessInfo, String> {
    override val name: String by lazy { AccessInfo.serializer().descriptor.getElementName(1) }
    override fun get(receiver: AccessInfo): String = receiver.ip
    override fun setCopy(receiver: AccessInfo, value: String) = receiver.copy(ip = value)
    override val serializer: KSerializer<String> by lazy { (AccessInfo.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { AccessInfo.serializer().descriptor.getElementAnnotations(1) }
}
object AccessInfo_sessionId: SerializableProperty<AccessInfo, UUID?> {
    override val name: String by lazy { AccessInfo.serializer().descriptor.getElementName(2) }
    override fun get(receiver: AccessInfo): UUID? = receiver.sessionId
    override fun setCopy(receiver: AccessInfo, value: UUID?) = receiver.copy(sessionId = value)
    override val serializer: KSerializer<UUID?> by lazy { (AccessInfo.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { AccessInfo.serializer().descriptor.getElementAnnotations(2) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object AccessInfo_userAgent: SerializableProperty<AccessInfo, String> {
    override val name: String by lazy { AccessInfo.serializer().descriptor.getElementName(3) }
    override fun get(receiver: AccessInfo): String = receiver.userAgent
    override fun setCopy(receiver: AccessInfo, value: String) = receiver.copy(userAgent = value)
    override val serializer: KSerializer<String> by lazy { (AccessInfo.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { AccessInfo.serializer().descriptor.getElementAnnotations(3) }
}
object AccessInfo_timestamp: SerializableProperty<AccessInfo, Instant> {
    override val name: String by lazy { AccessInfo.serializer().descriptor.getElementName(4) }
    override fun get(receiver: AccessInfo): Instant = receiver.timestamp
    override fun setCopy(receiver: AccessInfo, value: Instant) = receiver.copy(timestamp = value)
    override val serializer: KSerializer<Instant> by lazy { (AccessInfo.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { AccessInfo.serializer().descriptor.getElementAnnotations(4) }
}
