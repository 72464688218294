package com.crowpay.views.screens.common

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.components.project.activity.ProjectMessageThread
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.div
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*

@Routable("messages/{lens}")
class MessagingScreen(val lens: ProjectLens) : Page {
    val projects = shared {
        val session = notNullSession()
        session.projects.query(
            Query(
                condition { project ->
                    Condition.And(
                        Condition.orNotNull(
                            project.customer eq session.userId,
                            selectedContractor()?.let { project.contractor eq it }
                        ),
                        project.state notInside (ProjectState.inactiveStates + ProjectState.Creating)
                    )
                },
                limit = Int.MAX_VALUE,
                orderBy = sort {
                    it.created.ascending()
                }
            )
        )()
    }

    val mostRecentMessages = shared {
        val session = notNullSession()
        projects()
            .map { project ->
                session.projectMessages.query(
                    Query(
                        condition { it.project eq project._id },
                        limit = 1,
                        orderBy = sort { it.sent.descending() }
                    )
                )
            }
            .let(::invokeAll)
            .mapNotNull { it.firstOrNull() }
            .associateBy { it.project }
    }

    override fun ViewWriter.render2(): ViewModifiable {
        return sizeConstraints(width = AppDimensions.pageWidth) - col {
            title("Message Threads")

            scrolls - col {
                forEachUpdating(projects) { project ->
                    val recentMessage = shared {
                        mostRecentMessages()[project()._id]
                    }
                    lightSection - button {
                        col {
                            row {
                                centered - expanding - bold - subTitle { ::content { project().name } }
                                centered - body {
                                    dynamicTheme {
                                        ProjectStateSemantic(project().state)
                                    }
                                    ::content { project().state.displayName }
                                }
                            }

                            col {
                                ::exists { recentMessage() != null }

                                col {
                                    spacing = theme.spacing/2
                                    body {
                                        dynamicTheme {
                                            val clientMessage = recentMessage()?.clientMessage ?: false
                                            MessageSemantic(
                                                clientMessage == lens.isClient(),
                                                clientMessage
                                            )
                                        }
                                        ::content { recentMessage()?.message ?: "" }
                                    }
                                    row {
                                        expanding - space {
                                            ::exists { recentMessage()?.clientMessage?.equals(lens.isClient()) ?: false }
                                        }
                                        smallBody {
                                            ::content { recentMessage()?.sent?.formatRelative() ?: "" }
                                        }
                                    }
                                }
                            }
                            GreyedOutSemantic.onNext - body {
                                existsDefaultFalse { recentMessage() == null }
                                align = Align.Center
                                content = "No Messages"
                            }
                        }

                        onClick("View Messages") {
                            dialogScreenNavigator.navigate(ProjectMessageThread.Dialog(lens, project()))
                        }
                    }
                }
            }
        }
    }
}

private fun <T> ReactiveContext.invokeAll(items: List<Readable<T>>): List<T> {
    for(item in items) rerunOn(item)
    return items.map { it() }
}