package com.crowpay.incidentResponse

import com.crowpay.*

class IncidentFlowPermissions(
    val project: Project,
    val incident: Incident?,
    rawMessages: List<IncidentMessage>,
    val isClient: Boolean
) {
    val messages = rawMessages.sortedBy { it.created }
    val previousMessage = messages.lastOrNull()
    private val previousWasClient = previousMessage?.clientMessage ?: false

    val awaitingClientResponse = !previousWasClient

    val mustRequestReview = incident?.state == IncidentState.WorkingOnResolution

    val awaitingContractorResponse = (previousWasClient || mustRequestReview)

    val awaitingResponse = isClient && awaitingClientResponse || !isClient && awaitingContractorResponse

    val canRaiseIssue = isClient && incident == null
    val canFileDispute = isClient && incident?.issue != null &&
            (incident.canFileDispute || awaitingClientResponse)

    val canLockProject = incident?.canLockProject ?: false

    val disputeWasFiled = messages.any { msg ->
        msg.type.let { it is IncidentResponseType.ClientResponse && it.fileDispute }
    }

    val canProposeDisputeResolution = awaitingResponse && disputeWasFiled

    val lastProposedResolution: IncidentResponseType.NeedsApproval? =
        messages.asReversed().firstNotNullOfOrNull {
            it.type as? IncidentResponseType.NeedsApproval
        }

    val canAccept = isClient && messages.any { it.type is IncidentResponseType.NeedsApproval }
    val canDeny =
        if (isClient) previousMessage?.let { !it.clientMessage && it.type is IncidentResponseType.NeedsApproval } == true
        else disputeWasFiled && previousMessage?.let { it.clientMessage && it.type is IncidentResponseType.ClientResolution } == true

    val canRespond = awaitingResponse || canAccept || canDeny || canRaiseIssue || canLockProject
}