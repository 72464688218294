package com.crowpay.views.components.project.work.scope

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.components.*
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.dialogs.*
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.ConsoleRoot
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

fun ProjectView.scopeV3(writer: ViewWriter) = with(writer) {
    col {
        spacing = AppDimensions.sectionIndent
        if (lens == ProjectLens.Contractor) gravity(Align.End, Align.Center) - row {
            spacing = 1.rem
            secondaryButton - button {
                specCenteredText("+ Scope Set")
                onClick {
                    dialogScreenNavigator.navigate(
                        NewScopeViewDialog(
                            options = lineItems(),
                            scopeSet = true
                        )
                    )
                }
            }
            secondaryButton - button {
                specCenteredText("+ Scope View")
                onClick {
                    dialogScreenNavigator.navigate(
                        NewScopeViewDialog(
                            options = lineItems(),
                            scopeSet = false
                        )
                    )
                }
            }
            tertiaryButton - button {
                ::enabled {
                    project().state.newNotesAllowed() && lineItems().any { it.state.newNotesAllowed() }
                }
                specCenteredText("+ Work Note")
                onClick {
                    dialogScreenNavigator.navigate(RootCreateItemNote(lineItems().filter { it.state.newNotesAllowed() }))
                }
            }
            tertiaryButton - button {
                ::enabled { lineItems().any { it.state.newTasksAllowed() } && project().state.newTasksAllowed() }
                specCenteredText("+ Work Task")

                onClick {
                    dialogScreenNavigator.navigate(
                        RootCreateItemTask(
                            lineItems().filter { it.state < LineItemState.Complete }
                        )
                    )
                }
            }
        }

        col {
            val customScopes = scopes.lens { it.filter { !it.fromChangeRequest } }
            ::exists { customScopes().isNotEmpty() }

            col {
                spacing = AppDimensions.sectionIndent
                forEachUpdating(customScopes) { scope ->
                    renderScopeView(
                        scope,
                        this
                    )
                }
            }
            space()
        }


        col {
            val individualItems = shared {
                lineItems().filter { !it.grouped }
            }
            ::exists {
                individualItems().isNotEmpty()
            }

            subTitle("Work Items")

            sectionIndentCol {
                spacing = 0.px
                workItemTableHeader(showNameHeader = true)
                greySeparator()
                col {
                    spacing = 0.px
                    forEachUpdating(individualItems) { item ->
                        col {
                            spacing = 0.px
                            val expand = Property(false)
                            val row = workItemTableRow(expand, item)

                            launch {
                                JumpTo.LineItem.newTarget(item()._id, row) {
                                    expand.value = true
                                }
                            }

                            onlyWhen { expand() } - renderLineItemDetails(
                                item,
                                contractorNotes.shareFilter { it.lineItem == item()._id },
                                punchListItems.shareFilter { it.lineItem == item()._id },
                                payAppItems,
                                lineItemCompletionMessages.shareFilter { it.lineItem == item()._id },
                                project,
                                lens
                            )
                            greySeparator()
                        }
                    }
                }
            }
        }

        space()

        col {
            val fromChangeOrders = scopes.lens { it.filter { it.fromChangeRequest } }
            ::exists { fromChangeOrders().isNotEmpty() }

            subTitle("Groups")

            col {
                spacing = AppDimensions.sectionIndent
                forEachUpdating(fromChangeOrders) { scope ->
                    renderScopeView(
                        scope,
                        this
                    )
                }
            }
        }
    }
}

fun ProjectView.renderScopeView(
    scope: Readable<ScopeView>,
    writer: ViewWriter
) = with(writer) {
    val expand = Property(false)
    col {
        spacing = 0.px
        button {
            spacing = 0.px
            row {
                spacing = AppDimensions.expandButtonSpace
                centered - expandIcon(expand)
                centered - col {
                    spacing = AppDimensions.expandButtonSpace
                    bold - subTitle { ::content { scope().scopeTitle } }
                    body {
                        ::content {
                            (scope().description?.let { "Description: $it - " } ?: "") + lineItems().filter { it.scopeView == scope()._id }.sumOf { it.price }.renderDollars()
                        }
                    }
                }
            }
            action = expandAction(expand)
        }
        onlyWhen { expand() } - sectionIndentCol {
            spacing = 0.px

            workItemTableHeader(showNameHeader = false)

            greySeparator()

            val items = lineItems.shareFilter { it.scopeView == scope()._id }

//            workItemTableSummary(items, scope)
//
//            greySeparator()

            col {
                spacing = 0.px
                forEachUpdating(items) { item ->
                    val expandItem = Property(false)
                    col {
                        spacing = 0.px
                        val row = workItemTableRow(expandItem, item)

                        launch {
                            JumpTo.LineItem.newTarget(item()._id, view = row) {
                                expand.value = true
                                expandItem.value = true
                            }
                        }

                        onlyWhen { expandItem() } - renderLineItemDetails(
                            item,
                            contractorNotes.shareFilter { it.lineItem == item()._id },
                            punchListItems.shareFilter { it.lineItem == item()._id },
                            payAppItems,
                            lineItemCompletionMessages.shareFilter { it.lineItem == item()._id },
                            project,
                            lens
                        )
                        greySeparator()
                    }
                }
            }

            row {
                expanding - space()
                textButton - button {
                    text("Edit")
                    onClick {
                        dialogScreenNavigator.navigate(
                            EditScopeViewDialog(
                                options = lineItems(),
                                scope = scope()
                            )
                        )
                    }
                }
                textButton - button {
                    text("Delete")
                    onClick {
                        dialogScreenNavigator.navigate(
                            GenericConfirmationDialog(
                                header = "Delete Scope ${if (scope().scopeSet) "Set" else "View"}?",
                                message = "You are about to delete this scope ${if (scope().scopeSet) "set" else "view"} This action cannot be undone. Are you sure?",
                                confirmationText = "Delete Scope",
                                messageType = MessageType.Danger,
                                onSubmit = {
                                    if (it) {
                                        val session = notNullSession()
                                        session.scopeViews[scope()._id].delete()

                                        delay(500)
                                        session.lineItems.totallyInvalidate()
                                    }
                                }
                            )
                        )
                    }
                }
            }
        }
    }
}