package com.crowpay.views.screens.auth

import com.crowpay.views.theming.AppColors
import com.crowpay.actuals.AppDimensions
import com.crowpay.views.AutoRoutes
import com.crowpay.views.screens.client.ClientPaymentMethodForm
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.themeFromLast


@Routable("auth/payment-setup")
class PaymentSetup : Screen, AuthScreen {

    override val title: Readable<String> = Constant("Payment Setup")

    @QueryParameter
    val next: Property<String?> = Property(null)
    var nextScreen: Screen? = null
        set(value) {
            field = value
            next.value = value?.let { AutoRoutes.render(it)?.urlLikePath?.render() }
        }


    val form = ClientPaymentMethodForm()

    override fun ViewWriter.render() {
        if (nextScreen == null) {
            screenNavigator.reset(LandingScreen())
            return
        }

        themeFromLast { it.copy(background = AppColors.grey.light1) } -
                stack {
                    centered - stack {
                        themeChoice += ThemeDerivation { AuthDialogSemantic(it) }
                        spacing = AppDimensions.fullIndent
                        sizeConstraints(width = AppDimensions.pageWidth) -
                                col {
                                    themeChoice = ThemeDerivation(appTheme)

                                    title("Payment Methods")
                                    form.render(this)

                                    space()

                                    body {
                                        align = Align.Center
                                        content =
                                            "Connect Bank Account - Connect a bank account now to get set up quicker. You can always connect after reviewing and accepting the project"
                                    }

                                    primaryButton -
                                            gravity(Align.Start, Align.Center) -
                                            button {
                                                specCenteredText ("Continue to Project")
                                                onClick {
                                                    this.screenNavigator.reset(nextScreen!!)
                                                }
                                            }

                                }

                    }
                }
    }
}