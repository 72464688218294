package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.components.project.projectList
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningkite.serialization.notNull
import kotlin.time.Duration
import kotlin.time.Duration.Companion.hours

interface ProjectScreen

val twentyFourHoursAgo = now() - 24.hours

@Routable("/contractor/projects")
class ContractorProjects() : ContractorScreen, ProjectScreen {

    override val title: Readable<String> = Constant("Projects")

    val inactiveStatus = setOf(
        ProjectState.Terminated,
        ProjectState.Cancelled,
        ProjectState.Abandoned,
    )

    val activeStatus = ProjectState.entries.toSet()
        .minus(inactiveStatus)

    val showArchivedProjects = Property(false)

    val inactiveProjects = shared {
        val session = notNullSession()
        session.projects
            .query(
                Query(
                    condition = condition {
                        it.contractor.eq(notNullContractor()) and (it.isArchived.eq(true) or it.state.inside(
                            inactiveStatus
                        ))
                    },
                    sort {
                        it.state.ascending()
                        it.name.ascending()
                    }
                )
            )
    }

    val activeProjects = shared {
        val session = notNullSession()
        session.projects
            .query(
                Query(
                    condition = condition {
                        (it.contractor eq notNullContractor()) and (it.isArchived.eq(false)) and (it.state inside activeStatus)
                    },
                    sort {
                        it.state.ascending()
                        it.name.ascending()
                    }
                )
            )
    }


    val projects = shared {
        activeProjects()() + if (showArchivedProjects())
            inactiveProjects()()
        else
            emptyList()
    }

    val clients = shared {
        val ids = projects().mapNotNull { it.client }.toSet()
        notNullSession().clients
            .query(Query(condition { it._id inside ids }))()
    }

    override fun ViewWriter.renderMainContent() {
        sizeConstraints(maxWidth = AppDimensions.pageWidth) - projectList(
            lens = ProjectLens.Contractor,
            projects,
            clients,
            showArchivedProjects
        )
    }
}