package com.crowpay.views.screens.client

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.components.project.projectList
import com.crowpay.views.screens.contractor.ProjectScreen
import com.crowpay.views.screens.contractor.twentyFourHoursAgo
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningkite.serialization.notNull
import kotlin.time.Duration.Companion.hours

@Routable("/client/projects")
class ClientProjects() : Screen, ProjectScreen {
    override val title: Readable<String> = Constant("Your Projects")

    val inactiveStatus = setOf(
        ProjectState.Terminated,
        ProjectState.Cancelled,
        ProjectState.Abandoned,
    )

    val activeStatus = ProjectState.entries.toSet()
        .minus(inactiveStatus)


    val showArchivedProjects = Property(false)

    val inactiveProjects = shared {
        val session = notNullSession()
        session.projects
            .query(
                Query(
                    condition = condition {
                        (it.customer eq session.userId) and (it.isArchived.eq(true) or it.state.inside(inactiveStatus))
                    },
                    sort {
                        it.state.ascending()
                        it.name.ascending()
                    }
                )
            )
    }

    val activeProjects = shared {
        val session = notNullSession()
        session.projects
            .query(
                Query(
                    condition = condition {
                        (it.customer eq session.userId) and (it.isArchived.eq(false)) and (it.state inside activeStatus)
                    },
                    sort {
                        it.state.ascending()
                        it.name.ascending()
                    }
                )
            )
    }

    val projects = shared {

        activeProjects()() + if (showArchivedProjects())
            inactiveProjects()()
        else
            emptyList()
    }

    val contractors = shared {
        notNullSession().contractors
            .query(Query(condition { it._id inside projects.await().map { it.contractor }.toSet() }))()
    }

    override fun ViewWriter.render() {
        sizeConstraints(maxWidth = AppDimensions.pageWidth) - projectList(
            lens = ProjectLens.Customer,
            projects,
            contractors,
            showArchivedProjects
        )
    }
}