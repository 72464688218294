package com.crowpay.utils.validation

import com.crowpay.utils.SignalingList
import com.crowpay.utils.SignalingSet
import com.lightningkite.kiteui.Console
import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.InvalidSemantic
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.dynamicTheme

typealias ValidCondition = Readable<Boolean>

open class Validator(private val parent: Validator? = null) {
    var debug: Console? = null

    val validConditions = SignalingSet<ValidCondition>()
    private val children = SignalingList<Validator>()

    fun child(): Validator = Validator(this)
    fun addChild(validator: Validator) { children.add(validator) }
    fun removeFromParent() { parent?.children?.remove(this) }

    /**
     * Only reads true when no conditions in the invalidConditions list read invalid
     */
    val allValid: Readable<Boolean> = shared {
        val conditions = validConditions()
        val valid = conditions.all { it() } and children().all { it.allValid() }

        debug?.log("Calculating allValid")
        debug?.log("List Size: ${validConditions.value.size}  Children: ${children.size}")
        debug?.log("Result: $valid")

        return@shared valid
    }

    init {
        parent?.children?.add(this)
    }

    /**
     * Creates an InvalidCondition and adds it to the invalidConditions list.
     */
    fun addCondition(condition: Readable<Boolean>): ValidCondition {
        val cond = condition
        validConditions.add(cond)
        return cond
    }

    fun addCondition(condition: ReactiveContext.() -> Boolean): ValidCondition {
        val cond = shared { condition() }
        validConditions.add(cond)
        return cond
    }

    fun addConditions(conditions: Set<ValidCondition>) {
        validConditions.addAll(conditions)
    }

    fun removeCondition(condition: ValidCondition) {
        validConditions.remove(condition)
    }

    fun removeCondition(validated: Validated) {
        validConditions.remove(validated.valid)
    }
    suspend fun removeCondition(validated: Readable<Validated>) {
        validConditions.remove(validated.awaitOnce().valid)
    }

    fun clearConditions() {
        validConditions.clear()
    }

    @ViewModifierDsl3
    fun ViewWriter.validate(condition: ValidCondition): ViewWrapper {
        beforeNextElementSetup {
            dynamicTheme {
                if (condition()) null
                else InvalidSemantic
            }
        }
        return ViewWrapper
    }

    @ViewModifierDsl3
    fun ViewWriter.validate(condition: ReactiveContext.() -> Boolean): ViewWrapper = validate(addCondition(condition))

    fun <T> Writable<T>.validate(
        startsValid: Boolean = false,
        validCondition: (T) -> Boolean,
    ): ValidatedWritable<T> = withValidation(this@Validator, startsValid, validCondition)
//
//    fun <T> Writable<T>.validate(
//        invalidWhileLoading: Boolean = false,
//        context: SelfCancellingContext,
//        validCondition: ReactiveContext.(T) -> Boolean
//    ): ValidatedWritable<T> = withValidation(this@Validator, invalidWhileLoading, context, validCondition)

    inner class DynamicValidator {
        val dynamicConditions = ArrayList<Validated>()

        fun refreshDynamicConditions() {
            dynamicConditions.forEach { it.removeCondition() }
            dynamicConditions.clear()
        }

        fun <V : Validated> V.dynamic(): V = also { dynamicConditions.add(this@dynamic) }
    }

    fun <T> dynamicValidation(block: DynamicValidator.() -> T): T = DynamicValidator().block()

    fun <T> Writable<T>.validateNotNull(startsValid: Boolean = false) = validate(startsValid) { it != null }
    fun Writable<String>.validateNotBlank() = validate { it.isNotBlank() }
}

fun validating(block: Validator.() -> Unit) = Validator().block()