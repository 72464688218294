// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareChangeRequestInfoFields() {
    val props: Array<SerializableProperty<ChangeRequestInfo, *>> = arrayOf(ChangeRequestInfo_id, ChangeRequestInfo_itemNumber)
    ChangeRequestInfo.serializer().properties { props }
}
val <K> DataClassPath<K, ChangeRequestInfo>.id: DataClassPath<K, UUID> get() = this[ChangeRequestInfo_id]
val <K> DataClassPath<K, ChangeRequestInfo>.itemNumber: DataClassPath<K, String> get() = this[ChangeRequestInfo_itemNumber]
inline val ChangeRequestInfo.Companion.path: DataClassPath<ChangeRequestInfo, ChangeRequestInfo> get() = path<ChangeRequestInfo>()


object ChangeRequestInfo_id: SerializableProperty<ChangeRequestInfo, UUID> {
    override val name: String by lazy { ChangeRequestInfo.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ChangeRequestInfo): UUID = receiver.id
    override fun setCopy(receiver: ChangeRequestInfo, value: UUID) = receiver.copy(id = value)
    override val serializer: KSerializer<UUID> by lazy { (ChangeRequestInfo.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ChangeRequestInfo.serializer().descriptor.getElementAnnotations(0) }
}
object ChangeRequestInfo_itemNumber: SerializableProperty<ChangeRequestInfo, String> {
    override val name: String by lazy { ChangeRequestInfo.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ChangeRequestInfo): String = receiver.itemNumber
    override fun setCopy(receiver: ChangeRequestInfo, value: String) = receiver.copy(itemNumber = value)
    override val serializer: KSerializer<String> by lazy { (ChangeRequestInfo.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ChangeRequestInfo.serializer().descriptor.getElementAnnotations(1) }
}
