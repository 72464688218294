package com.crowpay.views.components.project.work.changeOrders

import com.crowpay.views.theming.AppColors
import com.crowpay.ChangeRequestItem
import com.crowpay.ItemChangeInterface
import com.crowpay.LineItemInterface
import com.lightningkite.kiteui.models.Color

data class ChangeRequestItemWrapper(
    val wrapped: ChangeRequestItem,
    val name: String,
    val originalPrice: Long,
    val changeRequestNumber: String,
) : ChangeRequestItem by wrapped {
    val id = wrapped.itemNumber
    val totalPrice: Long get() = originalPrice + (priceChange ?: 0L)
}

enum class ChangeStatus(val displayName: String, val displayColor: Color) {
    Accepted("Approved", AppColors.primary.light1),
    Rejected("Rejected", AppColors.red.main),
    PendingApproval("Pending Approval", AppColors.primary.light1)
}
val ChangeRequestItem.status: ChangeStatus get() = when {
    changeRejected != null -> ChangeStatus.Rejected
    changeAccepted != null -> ChangeStatus.Accepted
    else -> ChangeStatus.PendingApproval
}

val ChangeRequestItem.cancelled: Boolean get() {
    return when (this) {
        is ChangeRequestItemWrapper -> wrapped.cancelled
        is LineItemInterface -> false
        is ItemChangeInterface -> cancelled
        else -> throw IllegalArgumentException("Unsupported ChangeRequestItem type")
    }
}