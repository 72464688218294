package com.crowpay.views.components.project.work.changeOrders

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.components.project.work.changeOrders.tables.renderDraftChangeOrder
import com.crowpay.views.components.project.work.changeOrders.tables.renderPendingChangeOrder
import com.crowpay.views.components.project.work.changeOrders.tables.renderPendingItems
import com.crowpay.views.components.project.work.changeOrders.tables.renderReviewedChangeOrder
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.components.sectionIndentCol
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*


// Filters and combines different lists of change items. Filters only ones for the provided change order, and then
// sorts by id.
private fun ProjectView.getChangeRequestItems(
    changeOrder: Readable<ChangeRequest>,
    additionalConstraints: (ChangeRequestItem) -> Boolean = { true },
): Readable<List<ChangeRequestItemWrapper>> = shared {
    val co = changeOrder()
    val adjusted = lineItems()

    val groupedPendingItems = pendingItemChanges()
        .filter { it.changeRequest == co._id && additionalConstraints(it) }

    val groupedPendingLines = pendingLineItems()
        .filter { it.changeRequest == co._id && additionalConstraints(it) }


    groupedPendingLines.plus(groupedPendingItems)
        .map { item ->

            val infoSource: LineItemInterface? =
                if (item is LineItemInterface) item
                else adjusted.find { it._id == item.itemId }?.original

            ChangeRequestItemWrapper(
                item,
                infoSource?.name ?: "Not Found",
                if (item is LineItemInterface) 0 else infoSource?.price ?: -1,
                co.number
            )
        }
        .sortedWith(
            compareBy(
                { it.changeAccepted == null },
                { it.itemNumber })
        )
}


fun ProjectView.projectChangeOrders(writer: ViewWriter) = with(writer) {
    col {
        if (lens == ProjectLens.Contractor) atTopEnd - tertiaryButton - button {
            ::exists{
                val s = project().state
                s > ProjectState.Creating && s < ProjectState.SubstantialCompletion
            }
            ::enabled {
                !project().state.isProjectLocked()
            }
            specCenteredText("+ Change Order")
            onClick("Create New Change Order") {
                dialogScreenNavigator.navigate(
                    GenericConfirmationDialog(
                        "Create Change Order",
                        "Once a change order is created it cannot be deleted.",
                        confirmationText = "Create Change Order",
                        messageType = MessageType.Confirmation
                    ) { confirmed ->
                        if (confirmed) {
                            val new = ChangeRequest(
                                project = project()._id,
                                description = "",
                                number = (changeOrders().size + 1).toString()
                            )
                            notNullSession().changeRequests.insert(new)
                            mainScreenNavigator.navigate(ChangeOrderForm2(new._id))
                        }
                    }
                )
            }
        }

        col {
            col {
                text {
                    existsDefaultFalse { project().state == ProjectState.WaitingApproval }
                    content = "This info will be available once the project is Awarded or In Progress."
                }
            }

            spacing = AppDimensions.majorColumnSpacing
            if (lens == ProjectLens.Contractor) col {
                val draftChangeOrders = shared { changeOrders().filter { it.published == null } }
                ::exists { draftChangeOrders().isNotEmpty() }

                title("Draft Changes")

                sectionIndentCol {
                    spacing = 0.px
                    forEachUpdating(draftChangeOrders) { draft ->
                        renderDraftChangeOrder(
                            draft,
                            getChangeRequestItems(draft)
                        )
                    }
                }
            }

            col {
                val pendingChangeOrders = shared { changeOrders().filter { it.pending > 0 } }
                existsDefaultFalse { pendingChangeOrders().isNotEmpty() }

                title("Pending Changes")

                col {
                    stack {
                        spacing = 0.px
                        val pendingItems = shared {
                            val adjusted = lineItems()
                            val nonCardinal = changeOrders().filter { it.published != null && !it.cardinalChange }

                            val pendingChanges = pendingItemChanges().filter { it.pending }

                            val pendingNewLineItems = pendingLineItems().filter { it.pending }

                            (pendingChanges + pendingNewLineItems)
                                .mapNotNull { item ->
                                    nonCardinal.find { it._id == item.changeRequest }?.let { cr ->
                                        val infoSource: LineItemInterface? =
                                            if (item is LineItemInterface) item
                                            else adjusted.find { it._id == item.itemId }

                                        ChangeRequestItemWrapper(
                                            item,
                                            infoSource?.name ?: "Not Found",
                                            if (item is LineItemInterface) 0 else infoSource?.price ?: -1,
                                            cr.number
                                        )
                                    }
                                }
                                .sortedBy { it.id }
                        }
                        ::exists { pendingItems().isNotEmpty() }

                        renderPendingItems(
                            changeOrders,
                            pendingItems,
                            lens
                        )
                    }

                    col {
                        spacing = 0.px
                        val groups = shared { pendingChangeOrders().filter { it.cardinalChange } }
                        ::exists { groups().isNotEmpty() }

                        bold - subTitle("Groups:")

                        sectionIndentCol {
                            spacing = 0.px
                            forEachUpdating(groups) { change ->
                                renderPendingChangeOrder(
                                    change,
                                    getChangeRequestItems(change),
                                    lens
                                )
                            }
                        }
                    }
                }
            }

            col {
                val changeOrdersWithReviewedChanges = shared {

                    val reviewedIds =
                        pendingItemChanges().filter { it.approved != null }.map { it.changeRequest }.toSet() +
                                pendingLineItems().filter { it.approved != null }.map { it.changeRequest }.toSet()

                    changeOrders().filter { it._id in reviewedIds }
                }
                ::exists { changeOrdersWithReviewedChanges().isNotEmpty() }

                title("Scope Changes")

                col {
                    spacing = AppDimensions.majorColumnSpacing
                    col {
                        val nonCardinalChanges =
                            shared { changeOrdersWithReviewedChanges().filter { !it.cardinalChange } }
                        ::exists { nonCardinalChanges().isNotEmpty() }

                        bold - subTitle("Work Items:")

                        sectionIndentCol {
                            spacing = 0.px
                            forEachUpdating(nonCardinalChanges) { change ->
                                renderReviewedChangeOrder(
                                    change,
                                    getChangeRequestItems(change) { !it.pending },
                                    cardinal = false
                                )
                            }
                        }
                    }

                    col {
                        val cardinalChanges = shared { changeOrdersWithReviewedChanges().filter { it.cardinalChange } }
                        ::exists { cardinalChanges().isNotEmpty() }

                        bold - subTitle("Groups:")

                        sectionIndentCol {
                            spacing = 0.px
                            forEachUpdating(cardinalChanges) { change ->
                                renderReviewedChangeOrder(
                                    change,
                                    getChangeRequestItems(change) { !it.pending },
                                    cardinal = true
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}