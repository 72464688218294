package com.crowpay.views.components.project.work.scope

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.completionResponse.CompletionFlowPermissions
import com.crowpay.completionResponse.CompletionResponse
import com.crowpay.completionResponse.*
import com.crowpay.sdk.notFoundError
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.interceptWrite
import com.crowpay.views.components.*
import com.crowpay.views.components.files.*
import com.crowpay.views.dialogs.DisputeWorkItemConfirmation
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.screens.client.ClientProject
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.screens.common.DialogWithDialog
import com.crowpay.views.screens.common.secondDialog
import com.crowpay.views.screens.contractor.ContractorProject
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.space
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningkite.serialization.notNull
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.delay

class LineItemCompletionForm private constructor(
    val lineItem: AdjustedLineItem,
    val project: Project,
    val messages: List<LineItemCompletionMessage>,
    val replies: Readable<List<CompletionMessageComment>>,
    val client: Client,
    val contractor: Contractor,
    val lens: ProjectLens
) : DialogWithDialog, Validator() {
    private val outerPadding = AppDimensions.fullIndent

    val previousMessage = messages.lastOrNull()

    val isClient = when (lens) {
        ProjectLens.Contractor -> false
        ProjectLens.Customer -> true
        ProjectLens.Preview -> true
    }

    val permissions = CompletionFlowPermissions(lineItem.wraps, isClient, messages)

    val proposeResolution = Property(false)

    // Parts of response construction
    val raiseIssue = Property(permissions.mustRaiseIssue)
    val fileDispute = Property<DisputeType?>(null)

    // TODO: This system was created before CompletionResponseSerializable existed. In the future this should probably
    //      be refactored to simply use that data class instead of these individual properties.
    val response = Property<CompletionResponse?>(null).validateNotNull()
    val maxCredit = lineItem.wraps.remainingFundsWithRetention(project.retention)
    val credit = Property<Double?>(null)

    val creditCondition = addCondition {
        when (response()) {
            is CompletionResponse.Credit -> credit().let {
                it != null && it > 0 && it <= maxCredit
            }
            is CompletionResponse.Remedy -> credit().let {
                it == null || (it > 0 && it <= maxCredit)
            }
            else -> true
        }
    }


    val files = SignalingList<FileData>()

    val minChars = 50
    val message = Property("").validate { it.length >= minChars }

    private enum class ResponseState(val displayName: String, val theme: ThemeDerivation) {
        Completed("COMPLETED", GreenSection),
        Dispute("DISPUTE", DangerSemantic),
        FileDispute("FILE A DISPUTE", DangerSemantic),
        Resolving("RESOLVING", PurpleSection),
        Issue("ISSUE", WarningSemantic),
        RaiseIssue("RAISE ISSUE", WarningSemantic),
        NotReady("NOT READY", DarkSection),
    }

    private val responseState = shared {
        when {
            lineItem.state == LineItemState.Complete -> ResponseState.Completed
            proposeResolution() -> ResponseState.Resolving
            fileDispute() != null -> ResponseState.FileDispute
            raiseIssue() -> ResponseState.RaiseIssue
            lineItem.state in setOf(LineItemState.Resolving, LineItemState.WorkingOnRemedy) -> ResponseState.Resolving
            permissions.disputeWasFiled -> ResponseState.Dispute
            lineItem.issue.let { it != null && it.resolved == null } -> ResponseState.Issue
            else -> ResponseState.NotReady
        }
    }

    private val resolving = shared {
        proposeResolution() ||
                permissions.clientProposingResolution ||
                (!isClient && lineItem.state == LineItemState.Disputed)
    }

    private suspend fun sendResponse() {
        val session = notNullSession()
        val sent = session.nonCached.lineItemCompletionMessage.sendMessage(
            LineItemCompletionMessage(
                project = project._id,
                lineItem = lineItem._id,
                sender = session.userId,
                clientMessage = isClient,
                responseTypeSerializable = response()?.let {
                    when (it) {
                        is CompletionResponse.ClientResponse -> it.copy(
                            raiseIssue = raiseIssue.value,
                            fileDispute = fileDispute.value
                        )
                        is CompletionResponse.Credit -> it.copy(credit = credit.value?.toLong() ?: return)
                        is CompletionResponse.Remedy -> it.copy(credit = credit.value?.toLong())
                        else -> it
                    }.serializable()
                } ?: return,
                message = message(),
                attachments = files.resolveProjectAttachments()
            )
        )
        session.lineItemCompletionMessages.localInsert(sent)
        delay(50)
        session.lineItems.totallyInvalidate()
        if (
            isClient &&
            (sent.responseType as? CompletionResponse.ClientResponse)?.fileDispute == DisputeType.ProjectWide
        ) session.projects.totallyInvalidate()
    }

    private enum class DisputeResolutionOption { Credit, Remedy, Appeal, ResolveLater }

    private fun ViewWriter.disputeResolutionSelector() {
        col {
            val selectedOption = Property<DisputeResolutionOption?>(null).interceptWrite {
                if (it != value && it != null)
                    response set when(it) {
                        DisputeResolutionOption.Credit -> CompletionResponse.Credit(0)
                        DisputeResolutionOption.Remedy -> CompletionResponse.Remedy(null)
                        DisputeResolutionOption.Appeal -> CompletionResponse.Appeal
                        DisputeResolutionOption.ResolveLater -> CompletionResponse.RequestResolveLater
                    }
                value = it
            }

            fieldTheme - select {
                bind(
                    selectedOption,
                    Constant(
                        listOf(null) + DisputeResolutionOption.entries.let {
                            if (!permissions.canProposeResolveLater) it - DisputeResolutionOption.ResolveLater
                            else it
                        }
                    )
                ) { it?.name ?: "Select Resolution" }
            }
            onlyWhen { selectedOption() in setOf(DisputeResolutionOption.Credit, DisputeResolutionOption.Remedy) } - row {
                centered - row {
                    spacing = 0.px
                    centered - body("Credit:")
                    atTopStart - colored(AppColors.red.main) - bodyBold {
                        content = "*"
                        ::exists { selectedOption() == DisputeResolutionOption.Credit }
                    }
                }
                centered - compact - fieldTheme - validate(creditCondition) - row {
                    centered - body("$")
                    centered - compact - numberInput {
                        keyboardHints = KeyboardHints.integer
                        range = 0.0..Double.MAX_VALUE
                        content bind credit
                        hint = "Max ${maxCredit.renderDollars()}"
                    }
                }
            }
        }
    }

    private fun ViewWriter.responseSelector() {
        if (isClient) {
            val clientResponse =
                Property<Set<LineItemConcern>>(emptySet())
                    .interceptWrite {
                        response set CompletionResponse.ClientResponse(reasons = it)
                        value = it
                    }

            menuButton {
                requireClick = true
                preferredDirection = PopoverPreferredDirection.belowCenter
                row {
                    expanding - centered - body {
                        ::content {
                            clientResponse()
                                .takeUnless { it.isEmpty() }
                                ?.joinToString { it.displayName }
                                ?: "Select a Response"
                        }
                    }
                    centered - icon(Icon.chevronDown, "Select Responses")
                }
                opensMenu {
                    sizeConstraints(width = AppDimensions.pageWidth - outerPadding*2.5) - compact - col {
                        for (response in LineItemConcern.entries) {
                            val container = clientResponse.contains(response)
                            button {
                                row {
                                    centered - checkbox {
                                        checked bind container
                                    }
                                    centered - body(response.displayName)
                                }
                                onClick { container.toggle() }
                            }
                        }
                    }
                }
            }
        }
        else {
            select {
                bind(
                    edits = response,
                    data = Constant(
                        listOf(null) +
                        listOfNotNull(
                            CompletionResponse.MoreInfo,
                            CompletionResponse.Handled,
                            CompletionResponse.KeepWorking,
                            CompletionResponse.ForceComplete,
                            if (permissions.canProposeResolveLater) CompletionResponse.RequestResolveLater else null
                        )
                    ),
                    render = { it?.displayName ?: "Select a Response" }
                )
            }
        }
    }

    private fun ViewWriter.responseForm() {
        when {
            lineItem.state == LineItemState.Complete -> {}
            permissions.mustRequestComplete -> sizeConstraints(height = 8.rem) - stack {
                subTitle("Request Sign Off before sending more messages")
            }
            permissions.awaitingResponse -> col {
                stack {
                    reactive {
                        clearChildren()
                        if (resolving()) col {
                            subTitle(
                                if (permissions.clientProposingResolution) "Review Client Resolution"
                                else "Propose Resolution To ${if (isClient) "Contractor" else "Homeowner"}"
                            )

                            if (!isClient) requiredLabel("Resolution Type") {
                                disputeResolutionSelector()
                            }

                            notificationBar(AppColors.yellow) - body {
                                content = "Informative (FYI) Bar"
                            }
                        }
                        else col {
                            subTitle("Respond To ${if (isClient) "Contractor" else "Homeowner"}")

                            if (permissions.canRaiseIssue) compact - row {
                                centered - checkbox {
                                    enabled = !permissions.mustRaiseIssue
                                    checked bind raiseIssue
                                }
                                centered - body("Raise Issue")
                                space(2.0)
                                secondaryButton - button {
                                    specCenteredText("More Info")
                                    onClick {
                                        secondDialog.value = GenericDialog(
                                            "More info for raise issue dialog",
                                            dismiss = { dismissSecondDialog() }
                                        )
                                    }
                                }
                            }

                            requiredField("Response Type") {
                                responseSelector()
                            }

                            notificationBar(AppColors.yellow) - body {
                                content = "Informative (FYI) Bar"
                            }
                        }
                    }
                }

                col {
                    spacing = 0.px
                    requiredField({
                        if (resolving()) "Explain Resolution"
                        else "Explain Response Type Selection"
                    }) {
                        sizeConstraints(minHeight = 10.rem) - textArea {
                            hint = "Minimum $minChars characters"
                            content bind message
                        }
                    }
                    atEnd - smallBody {
                        ::content {
                            val remaining = minChars - message().length
                            if (remaining <= 0) ""
                            else "($remaining) more characters required"
                        }
                    }
                }

                label2("Attachments") {
                    existsDefaultFalse { files().isNotEmpty() }
                    fileCarousel(files)
                }
            }
            else -> sizeConstraints(height = 8.rem) - stack {
                subTitle("Waiting on response from ${if (isClient) "Contractor" else "Homeowner"}")
            }
        }
    }

    private fun ViewWriter.previousMessages() {
        fun ViewWriter.formattedBody(text: String) = body {
            wraps = false
            content = text
        }

        fun ViewWriter.setWidth(dimension: Dimension) = sizeConstraints(minWidth = dimension)

        col {
            spacing = 3.dp
            val dateWidth = 5.rem
            val statusWidth = 10.rem
            val responseWidth = 8.rem

            row {
                space(0.05)
                setWidth(dateWidth) - body("Date")
                setWidth(statusWidth) - formattedBody("Status")
                setWidth(responseWidth) - formattedBody("Response Type")
            }
            col {


                for (message in messages) {
                    val messageReplies = replies.shareFilter { it.completionMessage == message._id }
                    val viewReplies = Property(false)

                    val newReply = Property<String?>(null)

                    val senderName = if (message.clientMessage) client.name else contractor.name

                    col {
                        spacing = AppDimensions.expandButtonSpace

                        lightSection - col {
                            row {
                                setWidth(dateWidth) - body(message.created.format(Formats.mmddyyyy))

                                val state = message.responseType.resultantState(
                                    when {
                                        permissions.disputeFiledAt?.let { message.created > it } == true -> LineItemState.Disputed
                                        lineItem.issue?.raised?.let { message.created > it.timestamp } == true -> LineItemState.Issue
                                        else -> LineItemState.NotApproved
                                    }
                                )

                                setWidth(statusWidth) - LineItemStateSemantic(state).onNext - formattedBody(state.displayName)
                                setWidth(responseWidth) - formattedBody(message.responseType.displayName)
                            }

                            if (message.message.isNotBlank()) row {
                                atTopStart - body("$senderName:")
                                body(message.message)
                            }

                            if (message.attachments.isNotEmpty()) label2("Attachments") {
                                renderFiles(Constant(message.attachments), 5.rem)
                            }
                        }

                        compact - row {
                            expanding - SmallBodySemantic.onNext - leftExpandButton(viewReplies) {
                                centered - text {
                                    ::content {
                                        messageReplies().size.let {
                                            when (it) {
                                                0 -> "No Replies"
                                                1 -> "1 Reply"
                                                else -> "$it Replies"
                                            }
                                        }
                                    }
                                }
                            }
                            textButton - button {
                                ::exists { messageReplies().isEmpty() }
                                smallBody("Reply")
                                onClick {
                                    viewReplies.value = true
                                    newReply.value = ""
                                }
                            }
                        }

                        onlyWhen { viewReplies() } - indentedCol(Indent.Section.before, 0.px) {
                            col {
                                spacing = theme.spacing*1.4
                                forEachUpdating(messageReplies) { reply ->
                                    compact - col {
                                        spacing = AppDimensions.cornerRadii
                                        row {
                                            centered - body {
                                                ::content { if (reply().clientMessage) client.name else contractor.name }
                                            }
                                            centered - smallBody {
                                                ::content { reply().created.format(Formats.mmddyyyy_hmm) }
                                            }
                                        }
                                        lightSection - stack {
                                            col {
                                                spacing = 0.px
                                                body {
                                                    ::content { reply().message }
                                                }
                                                atEnd - textButton - button {
                                                    spacing = 2.dp
                                                    existsDefaultFalse { messageReplies().lastOrNull()?._id == reply()._id }
                                                    smallBody("Reply")
                                                    onClick {
                                                        newReply.value = ""
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            onlyWhen { newReply() != null } - col {
                                space(0.1)
                                field2("New Reply") {
                                    sizeConstraints(height = 8.rem) - textArea {
                                        content bind newReply.lens(
                                            get = { it ?: "" },
                                            set = { it }
                                        )
                                    }
                                }
                                atEnd - row {
                                    secondaryButton - button {
                                        specCenteredText("Discard")
                                        onClick { newReply.value = null }
                                    }
                                    primaryButton - button {
                                        specCenteredText("Send Reply")
                                        onClick {
                                            val session = notNullSession()
                                            session.completionMessageComments.insert(
                                                CompletionMessageComment(
                                                    completionMessage = message._id,
                                                    project = project._id,
                                                    lineItem = lineItem._id,
                                                    clientMessage = isClient,
                                                    sender = session.userId,
                                                    message = newReply() ?: return@onClick
                                                )
                                            )
                                            newReply.value = null
                                        }
                                    }
                                }
                            }

                            space(0.25)
                        }
                    }
                }
            }
        }
    }

    override fun ViewWriter.renderPage(): ViewModifiable {
        return scrolls - col {
            spacing = AppDimensions.fullIndent
            space()
            sizeConstraints(width = AppDimensions.pageWidth) - atTopCenter - dialog - col {
                spacing = 0.px
                row {
                    spacing = 0.px
                    expanding - compact - xlTitle {
                        dynamicTheme { responseState().theme }
                        align = Align.Center
                        ::content { responseState().displayName }
                    }
                    sizeConstraints(width = 3.25.rem) - secondaryButton - button {
                        centered - icon(Icon.close, "Close Form")
                        onClick { dialogScreenNavigator.dismiss() }
                    }
                }

                row {
                    spacing = 0.px
                    space(outerPadding)
                    sizeConstraints(height = 5.rem) - expanding - SubtitleSemantic.onNext - row {
                        expanding - compact - row {
                            centered - text("Status:")
                            centered - LineItemStateSemantic(lineItem.state).onNext - text(lineItem.state.displayName)
                            centered - text {
                                ::content { response()?.let { "[${it.shortDisplayName}]" } ?: "" }
                            }
                        }
                        centered - secondaryButton - button {
                            specCenteredText("View History")
                        }
                        centered - sizeConstraints(width = 7.rem) - compact - col {
                            spacing = 2.dp
                            text {
                                align = Align.Center
                                content = now().format(Formats.mmddyyyy)
                            }
                            text {
                                align = Align.Center
                                content = "12:34:56"
                            }
                        }
                    }
                    space(outerPadding)
                }

                separator()

                row {
                    spacing = 0.px
                    space(outerPadding)
                    expanding - col {
                        space(0.2)
                        spacing = AppDimensions.majorColumnSpacing

                        col {
                            row {
                                expanding - lightSection - row {
                                    expanding - centered - body(lineItem.name)
                                    centered - body(lineItem.price.renderDollars())
                                }
                                secondaryButton - button {
                                    specCenteredText("View Item")
                                    onClick("View Item") {
                                        mainScreenNavigator.run {
                                            if (stack.value.lastOrNull() !is ProjectView) {
                                                navigate(
                                                    if (isClient) ClientProject(project._id)
                                                    else ContractorProject(project._id)
                                                )
                                            }
                                        }
                                        JumpTo.LineItem(lineItem._id, animate = false)
                                        dialogScreenNavigator.dismiss()
                                    }
                                }
                            }

                            notificationBar(AppColors.blue) - body {
                                content = "Informative (FYI) Bar"
                            }
                        }

                        col {
                            bodyBold("Previous Messages")
                            previousMessages()
                        }

                        greySeparator()

                        if (lineItem.state != LineItemState.Complete){
                            responseForm()

                            space(0.5)

                            actionBar()

                            greySeparator()
                        }

                        val showItemHistory = Property(false)
                        leftExpandButton(showItemHistory) { subTitle("Work Item History") }
                        sizeConstraints(height = 20.rem) - onlyWhen { showItemHistory() } - col {
                            centered - body("TODO")
                        }
                    }
                    space(outerPadding)
                }
            }
            space(2.0)
        }
    }

    private fun FormAction(
        name: String,
        displayName: (ReactiveContext.()->String)? = null,
        theme: ThemeDerivation = PrimaryButtonSemantic,
        requiresValidMessage: Boolean = true,
        action: suspend ()->Unit
    ) = ButtonAction(
        name,
        theme,
        displayName,
        enabled = if (requiresValidMessage) { { allValid() } } else null,
        action = action
    )

    private fun ViewWriter.confirmationDialog(header: String, body: String, buttonText: String, action: suspend ()->Unit) {
        secondDialog.value = GenericConfirmationDialog(
            header,
            body,
            confirmationText = buttonText,
            dismiss = { dismissSecondDialog() }
        ) {
            if (it) action()
        }
    }

    private fun ViewWriter.acceptAction(proposal: CompletionResponse.NeedsApproval) =
        FormAction(
            when (proposal) {
                is CompletionResponse.RequestComplete -> "Approve Work"
                CompletionResponse.ForceComplete -> "Accept Force Complete"
                CompletionResponse.Handled -> "Accept Handled"
                CompletionResponse.Appeal -> "Accept Appeal"
                is CompletionResponse.Credit -> "Accept Credit"
                is CompletionResponse.Remedy -> "Accept Remedy"
                is CompletionResponse.Remedy.RemedyComplete -> "Approve Work"
                CompletionResponse.RequestResolveLater -> "Resolve Later"
            },
            requiresValidMessage = false
        ) {
            val text: Triple<String, String, String> = when (proposal) {
                CompletionResponse.ForceComplete,
                CompletionResponse.Handled,
                is CompletionResponse.RequestComplete -> Triple(
                    "Approve & Pay ${lineItem.name}?",
                    "Approving this work item releases funds to the contractor as payment.",
                    "Approve & Pay"
                )
                CompletionResponse.Appeal -> Triple(
                    "Accept Appeal and Pay ${lineItem.name}?",
                    "Approving this work item releases funds to the contractor as payment.",
                    "Approve & Pay"
                )
                is CompletionResponse.Credit -> Triple(
                    "Accept Credit and Pay Out ${lineItem.name}?",
                    "This will apply a ${proposal.credit.renderDollars()} credit to the work item and then pay out the remaining balance to the contractor.",
                    "Approve & Pay"
                )
                is CompletionResponse.Remedy -> Triple(
                    "Approve Remedy Plan?",
                    "The contractor will begin work on this plan once approved, and then request sign off once he feels the Remedy has been completed. It was also apply any proposed credit to the line item when the work is done.",
                    "Approve Remedy and Start Work"
                )
                CompletionResponse.RequestResolveLater -> Triple(
                    "Resolve Later?",
                    "This will stop any work and funds release for the line item. At the end of the project the line item will be revisited and problems addressed.",
                    "Resolve Later"
                )
                is CompletionResponse.Remedy.RemedyComplete -> Triple(
                    "Accept Complete & Pay ${lineItem.name}?",
                    "Approving this will complete the line item and apply ${proposal.remedy.credit.renderDollars()} of credit to the line item. It will then pay out the remaining balance to the contractor.",
                    "Accept Complete"
                )
            }
            confirmationDialog(text.first, text.second, text.third) {
                val session = notNullSession()
                response set CompletionResponse.Accept(
                    proposal,
                    session.currentAccess()
                )
                sendResponse()
                session.projects[lineItem.project].invalidate()
                dialogScreenNavigator.dismiss()
            }
        }

    private fun ViewWriter.actionBar() {
        val primaryAction =
            if (isClient) {
                if (previousMessage == null) null
                else if (messages.size == 1)
                    FormAction("Not Ready", theme = DangerButtonSemantic) {
                        confirmationDialog(
                            "Not Ready",
                            "Not Ready confirmation text",
                            "Not Ready"
                        ) {
                            sendResponse()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                else when (val prev = previousMessage.responseType) {
                    is CompletionResponse.NeedsApproval -> acceptAction(prev)

                    CompletionResponse.MoreInfo -> FormAction("Provide Info") {
                        sendResponse()
                        dialogScreenNavigator.dismiss()
                    }

                    else -> permissions.mostRecentProposal?.let { acceptAction(it) }
                }
            }
            else when {
                permissions.mustRequestComplete ->
                    FormAction("Request Sign Off", requiresValidMessage = false) {
                        confirmationDialog(
                            "Is ${lineItem.name} ready for sign off?",
                            """
                            Before resubmitting for approval, ensure the work is fully complete and meets the client’s expectations.
    
                            If the client does not approve again, they may escalate the issue, which could lead to further delays and formal resolution steps. Resubmit only if you’ve addressed all feedback and communicated clearly with the client.
    
                            Tips:
                            - Attach updated pictures or documentation to help the client approve your work faster.
                            - If small, low-value items remain (e.g., a sprinkler clock on backorder), communicate with the client and list them as punch list or warranty items. This reassures the client that loose ends will be resolved during project closeout.
                            """.trimIndent(),
                            "Request Sign Off"
                        ) {
                            val session = notNullSession()
                            response set CompletionResponse.RequestComplete(session.currentAccess())
                            sendResponse()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                permissions.mustRequestReviewOfRemedy ->
                    FormAction("Request Sign Off", requiresValidMessage = false) {
                        confirmationDialog(
                            "Is the remedy ready for sign off?",
                            "Confirmation text for remedy request sign off",
                            "Request Sign Off"
                        ) {
                            val remedy: CompletionResponse.Remedy = messages.asReversed().firstNotNullOfOrNull {
                                val res = it.responseType
                                if (res is CompletionResponse.Remedy) res
                                else null
                            } ?: notFoundError<CompletionResponse.Remedy>()

                            response set CompletionResponse.Remedy.RemedyComplete(remedy)
                            sendResponse()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                permissions.awaitingResponse -> {
                    val actionName: ReactiveContext.() -> String = {
                        when (response()) {
                            is CompletionResponse.RequestComplete -> "Request Complete"

                            CompletionResponse.KeepWorking -> "Keep Working"
                            CompletionResponse.Handled -> "Submit Handled"
                            CompletionResponse.MoreInfo -> "Request More Info"
                            CompletionResponse.ForceComplete -> "Force Complete"

                            CompletionResponse.RequestResolveLater -> "Resolve Later"

                            CompletionResponse.Appeal -> "Submit Appeal"
                            is CompletionResponse.Credit -> "Submit Credit"
                            is CompletionResponse.Remedy -> "Submit Remedy Plan"
                            else -> "Select a Response"
                        }
                    }

                    FormAction(
                        "Send Response",
                        displayName = actionName
                    ) {
                        val a = actionName()
                        confirmationDialog(
                            a,
                            "$a confirmation text.",
                            a
                        ) {
                            sendResponse()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
                else -> null
            }

        val secondaryAction: Readable<ButtonAction?> = shared {
            when {
                raiseIssue() -> {
                    FormAction("Submit Issue", theme = WarningButtonSemantic()) {
                        secondDialog.value = GenericConfirmationDialog(
                            "Submit Issue",
                            "Filler text for submit issue",
                            confirmationText = "Submit Issue",
                            messageType = MessageType.Warning,
                            dismiss = { dismissSecondDialog() },
                        ) {
                            if (it) {
                                sendResponse()
                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
                fileDispute() != null ->
                    FormAction("Submit Dispute", theme = DangerButtonSemantic) {
                        if (fileDispute.value == DisputeType.ProjectWide)
                            confirmationDialog(
                                "File Project-Wide Dispute?",
                                "This will put a hold on all other work items in the project.",
                                "File Dispute"
                            ) {
                                sendResponse()
                                dialogScreenNavigator.dismiss()
                            }
                        else
                            confirmationDialog(
                                "File Dispute on Line Item?",
                                "This will prevent further work on the line item until an agreement is reached by both parties. Work on other line items can continue.",
                                "File Dispute"
                            ) {
                                sendResponse()
                                dialogScreenNavigator.dismiss()
                            }
                    }
                permissions.canFileDispute || permissions.mustFileDispute ->
                    FormAction("File Dispute", theme = DangerButtonSemantic, requiresValidMessage = false) {
                        secondDialog.value = DisputeWorkItemConfirmation {
                            fileDispute.value = it
                            secondDialog.value = null
                        }
                    }
                permissions.canLockProject ->
                    FormAction("Lock Project", theme = DangerButtonSemantic) {
                        confirmationDialog(
                            "Lock Project?",
                            "Locking the project freezes the state of all work. It can only be unlocked by joint resolution or arbitration.",
                            "Lock Project"
                        ) {
                            val session = notNullSession()
                            val dispute = session.disputes.query(
                                Query(limit = 1) {
                                    (it.lineItem.notNull.itemId eq lineItem._id) and (it.state eq DisputeState.Ongoing)
                                }
                            )().singleOrNull() ?: notFoundError<Dispute>()

                            session.nonCached.dispute.lockProject(dispute._id)
                            delay(50)
                            session.lineItems.totallyInvalidate()
                            session.projects.totallyInvalidate()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                proposeResolution() ->
                    FormAction(
                        "Submit Resolution",
                        displayName =
                            if (isClient) null
                            else { {
                                when (response()) {
                                    CompletionResponse.Appeal -> "Submit Appeal"
                                    is CompletionResponse.Credit -> "Submit Credit"
                                    is CompletionResponse.Remedy -> "Submit Remedy"
                                    else -> "Select Resolution"
                                }
                            } },
                        theme = SecondaryButtonSemantic
                    ) {
                        val actionName = "Submit ${response()?.displayName}"
                        confirmationDialog(
                            "$actionName?",
                            "Filler text for submit resolution proposal",
                            actionName
                        ) {
                            sendResponse()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                permissions.canDeny ->
                    ButtonAction(
                        "Deny ${previousMessage?.responseType?.shortDisplayName}",
                        theme = DangerButtonSemantic,
                        enabled = { message.valid() }
                    ) {
                        val actionName = "Deny ${previousMessage?.responseType?.displayName}"
                        confirmationDialog(
                            "$actionName?",
                            "Insert denial text here",
                            actionName
                        ) {
                            response set CompletionResponse.Deny
                            sendResponse()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                isClient && permissions.awaitingResponse && messages.size > 1 ->
                    FormAction("Not Ready", theme = SecondaryButtonSemantic) {
                        confirmationDialog(
                            "Not Ready",
                            "Not ready confirmation text",
                            "Not Ready",
                        ) {
                            sendResponse()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                else -> null
            }
        }

        val tertiaryAction = shared {
            when {
                !proposeResolution() && permissions.canProposeDisputeResolution ->
                    FormAction("Resolve", theme = SecondaryButtonSemantic, requiresValidMessage = false) {
                        confirmationDialog(
                            "Propose Resolution?",
                            "Do you have a resolution in mind to end the dispute? If so, you can propose a resolution to the contractor. Once sent, the contractor will review it and send back a formal proposal for you to accept or deny.",
                            "Resolve"
                        ) {
                            proposeResolution.value = true
                            if (isClient) response set CompletionResponse.ClientResolution
                        }
                    }

                else -> null
            }
        }

        row {
            tertiaryButton - button {
                ::exists { permissions.canRespond }
                specCenteredContent {
                    row {
                        centered - icon(Icon.download, "Attachments")   // TODO: attachment icon
                        centered - body("Add Attachment")
                    }
                }
                onClick("Upload Attachments") {
                    ExternalServices.requestFiles()
                        .map { file ->
                            val preview = FilePreview.LocalFile(file)
                            files.add(
                                FileData(
                                    name = Property(""),
                                    preview = preview,
                                    original = preview
                                )
                            )
                            async {
                                preview.uploadAttachment(notNullSession().nonCached.lineItemCompletionMessage::uploadFileForRequest)
                            }
                        }
                        .awaitAll()
                }
            }
            expanding - space()
            button {
                applyReactiveActionFormatting(tertiaryAction)
            }
            button {
                applyReactiveActionFormatting(secondaryAction)
            }
            if (primaryAction != null) button {
                applyActionFormatting(primaryAction)
            }
        }
    }

    companion object {
        // This is only used as a dialog, so we can have all the data at launch with a suspending "constructor"
        suspend fun createDialog(
            lineItem: AdjustedLineItem,
            project: Project,
            lens: ProjectLens,
        ): LineItemCompletionForm {
            val session = notNullSession()

            val messages = session.lineItemCompletionMessages.query(
                Query(
                    limit = Int.MAX_VALUE,
                    condition = condition { it.lineItem eq lineItem._id },
                    orderBy = sort { it.created.ascending() }
                )
            )()

            val replies = session.completionMessageComments.query(
                Query(
                    limit = Int.MAX_VALUE,
                    condition = condition { it.lineItem eq lineItem._id },
                    orderBy = sort { it.created.ascending() }
                )
            )

            val contractor = session.contractors[project.contractor]() ?: notFoundError<Contractor>()
            val client = session.clients[project.client ?: throw IllegalStateException("Project has no client")]() ?: notFoundError<Client>()

            return LineItemCompletionForm(
                lineItem,
                project,
                messages,
                replies,
                client,
                contractor,
                lens
            )
        }
    }
}