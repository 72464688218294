package com.crowpay.views.components.project.activity.concerns

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notFoundError
import com.crowpay.utils.*
import com.crowpay.views.components.project.work.scope.LineItemCompletionForm
import com.crowpay.views.dialogs.FileIncidentConfirmation
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.times
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.datetime.Instant

enum class ConcernSection(val displayName: String) {
    All("All"),
    Work("Work"),
    Service("Service"),
    Incidents("Incidents")
}

fun ProjectView.concerns(writer: ViewWriter) = with(writer) {
    val selectedSection = Property(ConcernSection.All)

    val concernCards = shared {
        val cards = ArrayList<ConcernCardInfo>()

        val lineItemMessages = lineItemCompletionMessages().groupBy { it.lineItem }
        val incidentMessages = incidentMessages().groupBy { it.incident }

        fun List<AdjustedLineItem>.getCards() = mapNotNullTo(cards) { lineItem ->
            val messages = lineItemMessages[lineItem._id] ?: return@mapNotNullTo null
            if (messages.isEmpty()) return@mapNotNullTo null
            ConcernCardInfo(
                ConcernCardInfo.Type.LineItem,
                lineItem.name.also { println("Name: $it") },
                lineItem.state,
                messages.first().created,
                messages.last()
            ) {
                LineItemCompletionForm.createDialog(lineItem, project(), lens)
            }
        }

        fun List<Incident>.getCards() = mapNotNullTo(cards) { incident ->
            val messages = incidentMessages[incident._id] ?: emptyList()
            ConcernCardInfo(
                ConcernCardInfo.Type.Incident,
                incident.concern.displayName,
                incident.state,
                incident.filed.timestamp,
                messages.lastOrNull() ?: notFoundError<IncidentMessage>()
            ) {
                IncidentReportForm.createDialog(project(), incident, messages, lens)
            }
        }

        when (selectedSection()) {
            ConcernSection.All -> {
                lineItems().filter { it.state.showsCard() }.getCards()
                incidents().getCards()
            }
            ConcernSection.Work -> lineItems().filter { it.state.showsCard() }.getCards()
            ConcernSection.Service -> ongoingIncidents().getCards()
            ConcernSection.Incidents -> incidents().filter { it.resolved != null }.getCards()
        }

        cards.sortDescending()

        cards
    }

    col {
        if (lens == ProjectLens.Customer) dismissibleBanner("concern tab info") {
            notificationBar(AppColors.blue) - body(
                """
                Concerns about contractor behavior or work performance are listed and accessed here.  Work concerns originate from approval requests and begin by marking the item as Not Ready.  Behavior concerns should be reported as an incident using the “Report Incident” button.  Both types of concerns, if unresolved initially, can escalate to issue and dispute, with opportunities to resolve before the project locks.
                    
                Reporting an Incident makes the contractor aware for documentation, or can be raised to an Issue which formally notices the contractor and requires a contractor response.
                """.trimIndent()
            )
        }

        row {
            for (tab in ConcernSection.entries) {
                buttonTheme - radioToggleButton {
                    specCenteredText(tab.displayName)
                    checked bind selectedSection.equalTo(tab)
                }
            }
            if (lens == ProjectLens.Customer) {
                expanding - space()
                creamButton - button {
                    existsDefaultFalse { project().state.canFileIncidents() }
                    specCenteredText("Report Incident")
                    onClick {
                        dialogScreenNavigator.navigate(FileIncidentConfirmation(project(), lens))
                    }
                }
            }
        }

        stack {
            sizeConstraints(height = AppDimensions.activityTabMinSize * 0.6) - stack {
                existsDefaultFalse { concernCards().isEmpty() }
                centered - col {
                    colored(AppColors.secondary.light1) - title {
                        content = "Nothing Yet"
                        align = Align.Center
                    }
                    body {
                        content = "When concerns are raised or action is needed they will appear here."
                        align = Align.Center
                    }
                }
            }

            col {
                existsDefaultFalse { concernCards().isNotEmpty() }
                forEachUpdating(concernCards) { info ->
                    lightSection - button {
                        col {
                            row {
                                subTitle { ::content { info().title } }
                                expanding - centered - smallBody { ::content { info().timestamp.format(Formats.mmddyyyy) } }
                                centered - bodyBold {
                                    dynamicTheme { DisplayableStateSemantic(info().state) }
                                    ::content { info().state.displayName }
                                }
                            }
                            compact - col {
                                row {
                                    bodyBold {
                                        ::content {
                                            if (info().mostRecentMessage.clientMessage) client().name
                                            else contractor().name
                                        }
                                    }
                                    atBottom - smallBody {
                                        ::content { info().mostRecentMessage.created.format(Formats.mmddyyyy_hmm) }
                                    }
                                }
                                col {
                                    dynamicTheme {
                                        MessageSemantic(
                                            info().mostRecentMessage.clientMessage == lens.isClient(),
                                            lens.isClient()
                                        )
                                    }
                                    body { ::content { info().mostRecentMessage.type.displayName } }
                                    body {
                                        ::exists { info().mostRecentMessage.message.isNotBlank() }
                                        ::content { info().mostRecentMessage.message }
                                    }
                                }
                            }
                        }
                        onClick("View Concern") {
                            dialogScreenNavigator.navigate(info().dialog())
                        }
                    }
                }
            }
        }

        space(2.0)
    }
}