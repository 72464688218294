package com.crowpay.views.components.project.work

import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.project.tabSectionHeader
import com.crowpay.views.components.project.work.changeOrders.projectChangeOrders
import com.crowpay.views.components.project.work.payApps.payApps
import com.crowpay.views.components.project.work.scope.scopeV3
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.screens.common.ProjectView.WorkSection
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.ThemeDerivation.Companion.invoke
import com.lightningkite.kiteui.models.minus
import com.lightningkite.kiteui.models.times
import com.lightningkite.kiteui.reactive.AppState
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.equalTo
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding

fun ProjectView.workTabs(writer: ViewWriter) = with (writer) {
    if (lens != ProjectLens.Preview) {
        col {
            existsDefaultFalse { !anonymous() }
            tabSectionHeader("Work", selectedWorkTab)
            workTabs - row {
                for (tab in WorkSection.entries) {
                    expanding - buttonTheme - radioToggleButton {
                        checked bind selectedWorkTab.equalTo(tab)
                        subTitle {
                            content = tab.displayName
                            align = Align.Center
                        }
                    }
                }
            }

            swapView {
                swapping(
                    current = { selectedWorkTab() to AppState.windowInfo() },
                    views = { (tab, window) ->
                        sizeConstraints(minHeight = window.height - AppDimensions.topNavHeight - (AppDimensions.fullIndent * 4) - AppDimensions.actionBarHeight)
                        when (tab) {
                            WorkSection.Scope -> scopeV3(this)
                            WorkSection.ChangeOrders -> projectChangeOrders(this)
                            WorkSection.Tasks -> projectTasks(project, lineItems, punchListItems, lens)
                            WorkSection.PayApps -> payApps(this)
                        }
                    }
                )
            }
        }
    } else {
        scopeV3(this)
    }
}